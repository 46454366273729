*, ::before, ::after{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

.skindr-widget-container{
  width: 100%
}

@media (min-width: 640px){
  .skindr-widget-container{
    max-width: 640px
  }
}

@media (min-width: 768px){
  .skindr-widget-container{
    max-width: 768px
  }
}

@media (min-width: 1024px){
  .skindr-widget-container{
    max-width: 1024px
  }
}

@media (min-width: 1280px){
  .skindr-widget-container{
    max-width: 1280px
  }
}

@media (min-width: 1536px){
  .skindr-widget-container{
    max-width: 1536px
  }
}

#skindrWidget.skindrWidget .skindr-widget-sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

#skindrWidget.skindrWidget .skindr-widget-not-sr-only{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-sr-only:focus-within{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-not-sr-only:focus-within{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

#skindrWidget.skindrWidget .focus\:skindr-widget-sr-only:focus{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-not-sr-only:focus{
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

#skindrWidget.skindrWidget .skindr-widget-pointer-events-none{
  pointer-events: none
}

#skindrWidget.skindrWidget .skindr-widget-pointer-events-auto{
  pointer-events: auto
}

#skindrWidget.skindrWidget .skindr-widget-visible{
  visibility: visible
}

#skindrWidget.skindrWidget .skindr-widget-invisible{
  visibility: hidden
}

#skindrWidget.skindrWidget .skindr-widget-static{
  position: static
}

#skindrWidget.skindrWidget .skindr-widget-fixed{
  position: fixed
}

#skindrWidget.skindrWidget .skindr-widget-absolute{
  position: absolute
}

#skindrWidget.skindrWidget .skindr-widget-relative{
  position: relative
}

#skindrWidget.skindrWidget .skindr-widget-sticky{
  position: sticky
}

#skindrWidget.skindrWidget .skindr-widget-inset-0{
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

#skindrWidget.skindrWidget .skindr-widget-inset-1{
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-2{
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-3{
  top: 0.75rem;
  right: 0.75rem;
  bottom: 0.75rem;
  left: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-4{
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-5{
  top: 1.25rem;
  right: 1.25rem;
  bottom: 1.25rem;
  left: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-6{
  top: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
  left: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-7{
  top: 1.75rem;
  right: 1.75rem;
  bottom: 1.75rem;
  left: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-8{
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-9{
  top: 2.25rem;
  right: 2.25rem;
  bottom: 2.25rem;
  left: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-10{
  top: 2.5rem;
  right: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-11{
  top: 2.75rem;
  right: 2.75rem;
  bottom: 2.75rem;
  left: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-12{
  top: 3rem;
  right: 3rem;
  bottom: 3rem;
  left: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-14{
  top: 3.5rem;
  right: 3.5rem;
  bottom: 3.5rem;
  left: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-16{
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-20{
  top: 5rem;
  right: 5rem;
  bottom: 5rem;
  left: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-24{
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-28{
  top: 7rem;
  right: 7rem;
  bottom: 7rem;
  left: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-32{
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-36{
  top: 9rem;
  right: 9rem;
  bottom: 9rem;
  left: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-40{
  top: 10rem;
  right: 10rem;
  bottom: 10rem;
  left: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-44{
  top: 11rem;
  right: 11rem;
  bottom: 11rem;
  left: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-48{
  top: 12rem;
  right: 12rem;
  bottom: 12rem;
  left: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-52{
  top: 13rem;
  right: 13rem;
  bottom: 13rem;
  left: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-56{
  top: 14rem;
  right: 14rem;
  bottom: 14rem;
  left: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-60{
  top: 15rem;
  right: 15rem;
  bottom: 15rem;
  left: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-64{
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-72{
  top: 18rem;
  right: 18rem;
  bottom: 18rem;
  left: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-80{
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-96{
  top: 24rem;
  right: 24rem;
  bottom: 24rem;
  left: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-auto{
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

#skindrWidget.skindrWidget .skindr-widget-inset-px{
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px
}

#skindrWidget.skindrWidget .skindr-widget-inset-0\.5{
  top: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
  left: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-1\.5{
  top: 0.375rem;
  right: 0.375rem;
  bottom: 0.375rem;
  left: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-2\.5{
  top: 0.625rem;
  right: 0.625rem;
  bottom: 0.625rem;
  left: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-3\.5{
  top: 0.875rem;
  right: 0.875rem;
  bottom: 0.875rem;
  left: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-0{
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

#skindrWidget.skindrWidget .skindr-widget--inset-1{
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-2{
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-3{
  top: -0.75rem;
  right: -0.75rem;
  bottom: -0.75rem;
  left: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-4{
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-5{
  top: -1.25rem;
  right: -1.25rem;
  bottom: -1.25rem;
  left: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-6{
  top: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  left: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-7{
  top: -1.75rem;
  right: -1.75rem;
  bottom: -1.75rem;
  left: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-8{
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-9{
  top: -2.25rem;
  right: -2.25rem;
  bottom: -2.25rem;
  left: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-10{
  top: -2.5rem;
  right: -2.5rem;
  bottom: -2.5rem;
  left: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-11{
  top: -2.75rem;
  right: -2.75rem;
  bottom: -2.75rem;
  left: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-12{
  top: -3rem;
  right: -3rem;
  bottom: -3rem;
  left: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-14{
  top: -3.5rem;
  right: -3.5rem;
  bottom: -3.5rem;
  left: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-16{
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-20{
  top: -5rem;
  right: -5rem;
  bottom: -5rem;
  left: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-24{
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-28{
  top: -7rem;
  right: -7rem;
  bottom: -7rem;
  left: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-32{
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-36{
  top: -9rem;
  right: -9rem;
  bottom: -9rem;
  left: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-40{
  top: -10rem;
  right: -10rem;
  bottom: -10rem;
  left: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-44{
  top: -11rem;
  right: -11rem;
  bottom: -11rem;
  left: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-48{
  top: -12rem;
  right: -12rem;
  bottom: -12rem;
  left: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-52{
  top: -13rem;
  right: -13rem;
  bottom: -13rem;
  left: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-56{
  top: -14rem;
  right: -14rem;
  bottom: -14rem;
  left: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-60{
  top: -15rem;
  right: -15rem;
  bottom: -15rem;
  left: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-64{
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-72{
  top: -18rem;
  right: -18rem;
  bottom: -18rem;
  left: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-80{
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-96{
  top: -24rem;
  right: -24rem;
  bottom: -24rem;
  left: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-px{
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px
}

#skindrWidget.skindrWidget .skindr-widget--inset-0\.5{
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-1\.5{
  top: -0.375rem;
  right: -0.375rem;
  bottom: -0.375rem;
  left: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-2\.5{
  top: -0.625rem;
  right: -0.625rem;
  bottom: -0.625rem;
  left: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-3\.5{
  top: -0.875rem;
  right: -0.875rem;
  bottom: -0.875rem;
  left: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-1\/2{
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

#skindrWidget.skindrWidget .skindr-widget-inset-1\/3{
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-inset-2\/3{
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-inset-1\/4{
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%
}

#skindrWidget.skindrWidget .skindr-widget-inset-2\/4{
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%
}

#skindrWidget.skindrWidget .skindr-widget-inset-3\/4{
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%
}

#skindrWidget.skindrWidget .skindr-widget-inset-full{
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%
}

#skindrWidget.skindrWidget .skindr-widget--inset-1\/2{
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

#skindrWidget.skindrWidget .skindr-widget--inset-1\/3{
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--inset-2\/3{
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--inset-1\/4{
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%
}

#skindrWidget.skindrWidget .skindr-widget--inset-2\/4{
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%
}

#skindrWidget.skindrWidget .skindr-widget--inset-3\/4{
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%
}

#skindrWidget.skindrWidget .skindr-widget--inset-full{
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-0{
  left: 0px;
  right: 0px
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-1{
  left: 0.25rem;
  right: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-2{
  left: 0.5rem;
  right: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-3{
  left: 0.75rem;
  right: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-4{
  left: 1rem;
  right: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-5{
  left: 1.25rem;
  right: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-6{
  left: 1.5rem;
  right: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-7{
  left: 1.75rem;
  right: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-8{
  left: 2rem;
  right: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-9{
  left: 2.25rem;
  right: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-10{
  left: 2.5rem;
  right: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-11{
  left: 2.75rem;
  right: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-12{
  left: 3rem;
  right: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-14{
  left: 3.5rem;
  right: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-16{
  left: 4rem;
  right: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-20{
  left: 5rem;
  right: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-24{
  left: 6rem;
  right: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-28{
  left: 7rem;
  right: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-32{
  left: 8rem;
  right: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-36{
  left: 9rem;
  right: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-40{
  left: 10rem;
  right: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-44{
  left: 11rem;
  right: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-48{
  left: 12rem;
  right: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-52{
  left: 13rem;
  right: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-56{
  left: 14rem;
  right: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-60{
  left: 15rem;
  right: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-64{
  left: 16rem;
  right: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-72{
  left: 18rem;
  right: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-80{
  left: 20rem;
  right: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-96{
  left: 24rem;
  right: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-auto{
  left: auto;
  right: auto
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-px{
  left: 1px;
  right: 1px
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-0\.5{
  left: 0.125rem;
  right: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-1\.5{
  left: 0.375rem;
  right: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-2\.5{
  left: 0.625rem;
  right: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-3\.5{
  left: 0.875rem;
  right: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-0{
  left: 0px;
  right: 0px
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-1{
  left: -0.25rem;
  right: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-2{
  left: -0.5rem;
  right: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-3{
  left: -0.75rem;
  right: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-4{
  left: -1rem;
  right: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-5{
  left: -1.25rem;
  right: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-6{
  left: -1.5rem;
  right: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-7{
  left: -1.75rem;
  right: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-8{
  left: -2rem;
  right: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-9{
  left: -2.25rem;
  right: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-10{
  left: -2.5rem;
  right: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-11{
  left: -2.75rem;
  right: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-12{
  left: -3rem;
  right: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-14{
  left: -3.5rem;
  right: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-16{
  left: -4rem;
  right: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-20{
  left: -5rem;
  right: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-24{
  left: -6rem;
  right: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-28{
  left: -7rem;
  right: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-32{
  left: -8rem;
  right: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-36{
  left: -9rem;
  right: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-40{
  left: -10rem;
  right: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-44{
  left: -11rem;
  right: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-48{
  left: -12rem;
  right: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-52{
  left: -13rem;
  right: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-56{
  left: -14rem;
  right: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-60{
  left: -15rem;
  right: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-64{
  left: -16rem;
  right: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-72{
  left: -18rem;
  right: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-80{
  left: -20rem;
  right: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-96{
  left: -24rem;
  right: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-px{
  left: -1px;
  right: -1px
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-0\.5{
  left: -0.125rem;
  right: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-1\.5{
  left: -0.375rem;
  right: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-2\.5{
  left: -0.625rem;
  right: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-3\.5{
  left: -0.875rem;
  right: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-1\/2{
  left: 50%;
  right: 50%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-1\/3{
  left: 33.333333%;
  right: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-2\/3{
  left: 66.666667%;
  right: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-1\/4{
  left: 25%;
  right: 25%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-2\/4{
  left: 50%;
  right: 50%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-3\/4{
  left: 75%;
  right: 75%
}

#skindrWidget.skindrWidget .skindr-widget-inset-x-full{
  left: 100%;
  right: 100%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-1\/2{
  left: -50%;
  right: -50%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-1\/3{
  left: -33.333333%;
  right: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-2\/3{
  left: -66.666667%;
  right: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-1\/4{
  left: -25%;
  right: -25%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-2\/4{
  left: -50%;
  right: -50%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-3\/4{
  left: -75%;
  right: -75%
}

#skindrWidget.skindrWidget .skindr-widget--inset-x-full{
  left: -100%;
  right: -100%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-0{
  top: 0px;
  bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-1{
  top: 0.25rem;
  bottom: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-2{
  top: 0.5rem;
  bottom: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-3{
  top: 0.75rem;
  bottom: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-4{
  top: 1rem;
  bottom: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-5{
  top: 1.25rem;
  bottom: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-6{
  top: 1.5rem;
  bottom: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-7{
  top: 1.75rem;
  bottom: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-8{
  top: 2rem;
  bottom: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-9{
  top: 2.25rem;
  bottom: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-10{
  top: 2.5rem;
  bottom: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-11{
  top: 2.75rem;
  bottom: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-12{
  top: 3rem;
  bottom: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-14{
  top: 3.5rem;
  bottom: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-16{
  top: 4rem;
  bottom: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-20{
  top: 5rem;
  bottom: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-24{
  top: 6rem;
  bottom: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-28{
  top: 7rem;
  bottom: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-32{
  top: 8rem;
  bottom: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-36{
  top: 9rem;
  bottom: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-40{
  top: 10rem;
  bottom: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-44{
  top: 11rem;
  bottom: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-48{
  top: 12rem;
  bottom: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-52{
  top: 13rem;
  bottom: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-56{
  top: 14rem;
  bottom: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-60{
  top: 15rem;
  bottom: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-64{
  top: 16rem;
  bottom: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-72{
  top: 18rem;
  bottom: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-80{
  top: 20rem;
  bottom: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-96{
  top: 24rem;
  bottom: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-auto{
  top: auto;
  bottom: auto
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-px{
  top: 1px;
  bottom: 1px
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-0\.5{
  top: 0.125rem;
  bottom: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-1\.5{
  top: 0.375rem;
  bottom: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-2\.5{
  top: 0.625rem;
  bottom: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-3\.5{
  top: 0.875rem;
  bottom: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-0{
  top: 0px;
  bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-1{
  top: -0.25rem;
  bottom: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-2{
  top: -0.5rem;
  bottom: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-3{
  top: -0.75rem;
  bottom: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-4{
  top: -1rem;
  bottom: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-5{
  top: -1.25rem;
  bottom: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-6{
  top: -1.5rem;
  bottom: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-7{
  top: -1.75rem;
  bottom: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-8{
  top: -2rem;
  bottom: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-9{
  top: -2.25rem;
  bottom: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-10{
  top: -2.5rem;
  bottom: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-11{
  top: -2.75rem;
  bottom: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-12{
  top: -3rem;
  bottom: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-14{
  top: -3.5rem;
  bottom: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-16{
  top: -4rem;
  bottom: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-20{
  top: -5rem;
  bottom: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-24{
  top: -6rem;
  bottom: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-28{
  top: -7rem;
  bottom: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-32{
  top: -8rem;
  bottom: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-36{
  top: -9rem;
  bottom: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-40{
  top: -10rem;
  bottom: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-44{
  top: -11rem;
  bottom: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-48{
  top: -12rem;
  bottom: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-52{
  top: -13rem;
  bottom: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-56{
  top: -14rem;
  bottom: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-60{
  top: -15rem;
  bottom: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-64{
  top: -16rem;
  bottom: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-72{
  top: -18rem;
  bottom: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-80{
  top: -20rem;
  bottom: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-96{
  top: -24rem;
  bottom: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-px{
  top: -1px;
  bottom: -1px
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-0\.5{
  top: -0.125rem;
  bottom: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-1\.5{
  top: -0.375rem;
  bottom: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-2\.5{
  top: -0.625rem;
  bottom: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-3\.5{
  top: -0.875rem;
  bottom: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-1\/2{
  top: 50%;
  bottom: 50%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-1\/3{
  top: 33.333333%;
  bottom: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-2\/3{
  top: 66.666667%;
  bottom: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-1\/4{
  top: 25%;
  bottom: 25%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-2\/4{
  top: 50%;
  bottom: 50%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-3\/4{
  top: 75%;
  bottom: 75%
}

#skindrWidget.skindrWidget .skindr-widget-inset-y-full{
  top: 100%;
  bottom: 100%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-1\/2{
  top: -50%;
  bottom: -50%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-1\/3{
  top: -33.333333%;
  bottom: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-2\/3{
  top: -66.666667%;
  bottom: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-1\/4{
  top: -25%;
  bottom: -25%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-2\/4{
  top: -50%;
  bottom: -50%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-3\/4{
  top: -75%;
  bottom: -75%
}

#skindrWidget.skindrWidget .skindr-widget--inset-y-full{
  top: -100%;
  bottom: -100%
}

#skindrWidget.skindrWidget .skindr-widget-top-0{
  top: 0px
}

#skindrWidget.skindrWidget .skindr-widget-top-1{
  top: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-top-2{
  top: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-top-3{
  top: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-top-4{
  top: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-top-5{
  top: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-top-6{
  top: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-top-7{
  top: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-top-8{
  top: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-top-9{
  top: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-top-10{
  top: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-top-11{
  top: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-top-12{
  top: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-top-14{
  top: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-top-16{
  top: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-top-20{
  top: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-top-24{
  top: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-top-28{
  top: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-top-32{
  top: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-top-36{
  top: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-top-40{
  top: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-top-44{
  top: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-top-48{
  top: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-top-52{
  top: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-top-56{
  top: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-top-60{
  top: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-top-64{
  top: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-top-72{
  top: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-top-80{
  top: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-top-96{
  top: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-top-auto{
  top: auto
}

#skindrWidget.skindrWidget .skindr-widget-top-px{
  top: 1px
}

#skindrWidget.skindrWidget .skindr-widget-top-0\.5{
  top: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-top-1\.5{
  top: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-top-2\.5{
  top: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-top-3\.5{
  top: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--top-0{
  top: 0px
}

#skindrWidget.skindrWidget .skindr-widget--top-1{
  top: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--top-2{
  top: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--top-3{
  top: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--top-4{
  top: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--top-5{
  top: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--top-6{
  top: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--top-7{
  top: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--top-8{
  top: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--top-9{
  top: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--top-10{
  top: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--top-11{
  top: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--top-12{
  top: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--top-14{
  top: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--top-16{
  top: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--top-20{
  top: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--top-24{
  top: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--top-28{
  top: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--top-32{
  top: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--top-36{
  top: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--top-40{
  top: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--top-44{
  top: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--top-48{
  top: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--top-52{
  top: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--top-56{
  top: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--top-60{
  top: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--top-64{
  top: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--top-72{
  top: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--top-80{
  top: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--top-96{
  top: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--top-px{
  top: -1px
}

#skindrWidget.skindrWidget .skindr-widget--top-0\.5{
  top: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--top-1\.5{
  top: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--top-2\.5{
  top: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--top-3\.5{
  top: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-top-1\/2{
  top: 50%
}

#skindrWidget.skindrWidget .skindr-widget-top-1\/3{
  top: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-top-2\/3{
  top: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-top-1\/4{
  top: 25%
}

#skindrWidget.skindrWidget .skindr-widget-top-2\/4{
  top: 50%
}

#skindrWidget.skindrWidget .skindr-widget-top-3\/4{
  top: 75%
}

#skindrWidget.skindrWidget .skindr-widget-top-full{
  top: 100%
}

#skindrWidget.skindrWidget .skindr-widget--top-1\/2{
  top: -50%
}

#skindrWidget.skindrWidget .skindr-widget--top-1\/3{
  top: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--top-2\/3{
  top: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--top-1\/4{
  top: -25%
}

#skindrWidget.skindrWidget .skindr-widget--top-2\/4{
  top: -50%
}

#skindrWidget.skindrWidget .skindr-widget--top-3\/4{
  top: -75%
}

#skindrWidget.skindrWidget .skindr-widget--top-full{
  top: -100%
}

#skindrWidget.skindrWidget .skindr-widget-right-0{
  right: 0px
}

#skindrWidget.skindrWidget .skindr-widget-right-1{
  right: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-right-2{
  right: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-right-3{
  right: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-right-4{
  right: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-right-5{
  right: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-right-6{
  right: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-right-7{
  right: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-right-8{
  right: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-right-9{
  right: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-right-10{
  right: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-right-11{
  right: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-right-12{
  right: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-right-14{
  right: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-right-16{
  right: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-right-20{
  right: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-right-24{
  right: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-right-28{
  right: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-right-32{
  right: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-right-36{
  right: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-right-40{
  right: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-right-44{
  right: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-right-48{
  right: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-right-52{
  right: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-right-56{
  right: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-right-60{
  right: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-right-64{
  right: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-right-72{
  right: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-right-80{
  right: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-right-96{
  right: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-right-auto{
  right: auto
}

#skindrWidget.skindrWidget .skindr-widget-right-px{
  right: 1px
}

#skindrWidget.skindrWidget .skindr-widget-right-0\.5{
  right: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-right-1\.5{
  right: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-right-2\.5{
  right: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-right-3\.5{
  right: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--right-0{
  right: 0px
}

#skindrWidget.skindrWidget .skindr-widget--right-1{
  right: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--right-2{
  right: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--right-3{
  right: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--right-4{
  right: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--right-5{
  right: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--right-6{
  right: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--right-7{
  right: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--right-8{
  right: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--right-9{
  right: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--right-10{
  right: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--right-11{
  right: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--right-12{
  right: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--right-14{
  right: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--right-16{
  right: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--right-20{
  right: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--right-24{
  right: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--right-28{
  right: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--right-32{
  right: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--right-36{
  right: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--right-40{
  right: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--right-44{
  right: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--right-48{
  right: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--right-52{
  right: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--right-56{
  right: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--right-60{
  right: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--right-64{
  right: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--right-72{
  right: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--right-80{
  right: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--right-96{
  right: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--right-px{
  right: -1px
}

#skindrWidget.skindrWidget .skindr-widget--right-0\.5{
  right: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--right-1\.5{
  right: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--right-2\.5{
  right: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--right-3\.5{
  right: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-right-1\/2{
  right: 50%
}

#skindrWidget.skindrWidget .skindr-widget-right-1\/3{
  right: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-right-2\/3{
  right: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-right-1\/4{
  right: 25%
}

#skindrWidget.skindrWidget .skindr-widget-right-2\/4{
  right: 50%
}

#skindrWidget.skindrWidget .skindr-widget-right-3\/4{
  right: 75%
}

#skindrWidget.skindrWidget .skindr-widget-right-full{
  right: 100%
}

#skindrWidget.skindrWidget .skindr-widget--right-1\/2{
  right: -50%
}

#skindrWidget.skindrWidget .skindr-widget--right-1\/3{
  right: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--right-2\/3{
  right: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--right-1\/4{
  right: -25%
}

#skindrWidget.skindrWidget .skindr-widget--right-2\/4{
  right: -50%
}

#skindrWidget.skindrWidget .skindr-widget--right-3\/4{
  right: -75%
}

#skindrWidget.skindrWidget .skindr-widget--right-full{
  right: -100%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-0{
  bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget-bottom-1{
  bottom: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-2{
  bottom: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-3{
  bottom: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-4{
  bottom: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-5{
  bottom: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-6{
  bottom: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-7{
  bottom: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-8{
  bottom: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-9{
  bottom: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-10{
  bottom: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-11{
  bottom: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-12{
  bottom: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-14{
  bottom: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-16{
  bottom: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-20{
  bottom: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-24{
  bottom: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-28{
  bottom: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-32{
  bottom: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-36{
  bottom: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-40{
  bottom: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-44{
  bottom: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-48{
  bottom: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-52{
  bottom: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-56{
  bottom: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-60{
  bottom: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-64{
  bottom: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-72{
  bottom: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-80{
  bottom: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-96{
  bottom: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-auto{
  bottom: auto
}

#skindrWidget.skindrWidget .skindr-widget-bottom-px{
  bottom: 1px
}

#skindrWidget.skindrWidget .skindr-widget-bottom-0\.5{
  bottom: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-1\.5{
  bottom: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-2\.5{
  bottom: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-3\.5{
  bottom: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-0{
  bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget--bottom-1{
  bottom: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-2{
  bottom: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-3{
  bottom: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-4{
  bottom: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-5{
  bottom: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-6{
  bottom: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-7{
  bottom: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-8{
  bottom: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-9{
  bottom: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-10{
  bottom: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-11{
  bottom: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-12{
  bottom: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-14{
  bottom: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-16{
  bottom: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-20{
  bottom: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-24{
  bottom: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-28{
  bottom: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-32{
  bottom: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-36{
  bottom: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-40{
  bottom: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-44{
  bottom: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-48{
  bottom: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-52{
  bottom: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-56{
  bottom: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-60{
  bottom: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-64{
  bottom: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-72{
  bottom: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-80{
  bottom: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-96{
  bottom: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-px{
  bottom: -1px
}

#skindrWidget.skindrWidget .skindr-widget--bottom-0\.5{
  bottom: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-1\.5{
  bottom: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-2\.5{
  bottom: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--bottom-3\.5{
  bottom: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-bottom-1\/2{
  bottom: 50%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-1\/3{
  bottom: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-2\/3{
  bottom: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-1\/4{
  bottom: 25%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-2\/4{
  bottom: 50%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-3\/4{
  bottom: 75%
}

#skindrWidget.skindrWidget .skindr-widget-bottom-full{
  bottom: 100%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-1\/2{
  bottom: -50%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-1\/3{
  bottom: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-2\/3{
  bottom: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-1\/4{
  bottom: -25%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-2\/4{
  bottom: -50%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-3\/4{
  bottom: -75%
}

#skindrWidget.skindrWidget .skindr-widget--bottom-full{
  bottom: -100%
}

#skindrWidget.skindrWidget .skindr-widget-left-0{
  left: 0px
}

#skindrWidget.skindrWidget .skindr-widget-left-1{
  left: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-left-2{
  left: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-left-3{
  left: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-left-4{
  left: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-left-5{
  left: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-left-6{
  left: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-left-7{
  left: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-left-8{
  left: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-left-9{
  left: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-left-10{
  left: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-left-11{
  left: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-left-12{
  left: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-left-14{
  left: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-left-16{
  left: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-left-20{
  left: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-left-24{
  left: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-left-28{
  left: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-left-32{
  left: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-left-36{
  left: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-left-40{
  left: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-left-44{
  left: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-left-48{
  left: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-left-52{
  left: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-left-56{
  left: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-left-60{
  left: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-left-64{
  left: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-left-72{
  left: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-left-80{
  left: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-left-96{
  left: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-left-auto{
  left: auto
}

#skindrWidget.skindrWidget .skindr-widget-left-px{
  left: 1px
}

#skindrWidget.skindrWidget .skindr-widget-left-0\.5{
  left: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-left-1\.5{
  left: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-left-2\.5{
  left: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-left-3\.5{
  left: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--left-0{
  left: 0px
}

#skindrWidget.skindrWidget .skindr-widget--left-1{
  left: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--left-2{
  left: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--left-3{
  left: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--left-4{
  left: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--left-5{
  left: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--left-6{
  left: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--left-7{
  left: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--left-8{
  left: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--left-9{
  left: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--left-10{
  left: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--left-11{
  left: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--left-12{
  left: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--left-14{
  left: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--left-16{
  left: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--left-20{
  left: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--left-24{
  left: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--left-28{
  left: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--left-32{
  left: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--left-36{
  left: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--left-40{
  left: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--left-44{
  left: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--left-48{
  left: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--left-52{
  left: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--left-56{
  left: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--left-60{
  left: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--left-64{
  left: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--left-72{
  left: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--left-80{
  left: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--left-96{
  left: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--left-px{
  left: -1px
}

#skindrWidget.skindrWidget .skindr-widget--left-0\.5{
  left: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--left-1\.5{
  left: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--left-2\.5{
  left: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--left-3\.5{
  left: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-left-1\/2{
  left: 50%
}

#skindrWidget.skindrWidget .skindr-widget-left-1\/3{
  left: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-left-2\/3{
  left: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-left-1\/4{
  left: 25%
}

#skindrWidget.skindrWidget .skindr-widget-left-2\/4{
  left: 50%
}

#skindrWidget.skindrWidget .skindr-widget-left-3\/4{
  left: 75%
}

#skindrWidget.skindrWidget .skindr-widget-left-full{
  left: 100%
}

#skindrWidget.skindrWidget .skindr-widget--left-1\/2{
  left: -50%
}

#skindrWidget.skindrWidget .skindr-widget--left-1\/3{
  left: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--left-2\/3{
  left: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--left-1\/4{
  left: -25%
}

#skindrWidget.skindrWidget .skindr-widget--left-2\/4{
  left: -50%
}

#skindrWidget.skindrWidget .skindr-widget--left-3\/4{
  left: -75%
}

#skindrWidget.skindrWidget .skindr-widget--left-full{
  left: -100%
}

#skindrWidget.skindrWidget .skindr-widget-isolate{
  isolation: isolate
}

#skindrWidget.skindrWidget .skindr-widget-isolation-auto{
  isolation: auto
}

#skindrWidget.skindrWidget .skindr-widget-z-0{
  z-index: 0
}

#skindrWidget.skindrWidget .skindr-widget-z-10{
  z-index: 10
}

#skindrWidget.skindrWidget .skindr-widget-z-20{
  z-index: 20
}

#skindrWidget.skindrWidget .skindr-widget-z-30{
  z-index: 30
}

#skindrWidget.skindrWidget .skindr-widget-z-40{
  z-index: 40
}

#skindrWidget.skindrWidget .skindr-widget-z-50{
  z-index: 50
}

#skindrWidget.skindrWidget .skindr-widget-z-auto{
  z-index: auto
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-0:focus-within{
  z-index: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-10:focus-within{
  z-index: 10
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-20:focus-within{
  z-index: 20
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-30:focus-within{
  z-index: 30
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-40:focus-within{
  z-index: 40
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-50:focus-within{
  z-index: 50
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-z-auto:focus-within{
  z-index: auto
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-0:focus{
  z-index: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-10:focus{
  z-index: 10
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-20:focus{
  z-index: 20
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-30:focus{
  z-index: 30
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-40:focus{
  z-index: 40
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-50:focus{
  z-index: 50
}

#skindrWidget.skindrWidget .focus\:skindr-widget-z-auto:focus{
  z-index: auto
}

#skindrWidget.skindrWidget .skindr-widget-order-1{
  order: 1
}

#skindrWidget.skindrWidget .skindr-widget-order-2{
  order: 2
}

#skindrWidget.skindrWidget .skindr-widget-order-3{
  order: 3
}

#skindrWidget.skindrWidget .skindr-widget-order-4{
  order: 4
}

#skindrWidget.skindrWidget .skindr-widget-order-5{
  order: 5
}

#skindrWidget.skindrWidget .skindr-widget-order-6{
  order: 6
}

#skindrWidget.skindrWidget .skindr-widget-order-7{
  order: 7
}

#skindrWidget.skindrWidget .skindr-widget-order-8{
  order: 8
}

#skindrWidget.skindrWidget .skindr-widget-order-9{
  order: 9
}

#skindrWidget.skindrWidget .skindr-widget-order-10{
  order: 10
}

#skindrWidget.skindrWidget .skindr-widget-order-11{
  order: 11
}

#skindrWidget.skindrWidget .skindr-widget-order-12{
  order: 12
}

#skindrWidget.skindrWidget .skindr-widget-order-first{
  order: -9999
}

#skindrWidget.skindrWidget .skindr-widget-order-last{
  order: 9999
}

#skindrWidget.skindrWidget .skindr-widget-order-none{
  order: 0
}

#skindrWidget.skindrWidget .skindr-widget-col-auto{
  grid-column: auto
}

#skindrWidget.skindrWidget .skindr-widget-col-span-1{
  grid-column: span 1 / span 1
}

#skindrWidget.skindrWidget .skindr-widget-col-span-2{
  grid-column: span 2 / span 2
}

#skindrWidget.skindrWidget .skindr-widget-col-span-3{
  grid-column: span 3 / span 3
}

#skindrWidget.skindrWidget .skindr-widget-col-span-4{
  grid-column: span 4 / span 4
}

#skindrWidget.skindrWidget .skindr-widget-col-span-5{
  grid-column: span 5 / span 5
}

#skindrWidget.skindrWidget .skindr-widget-col-span-6{
  grid-column: span 6 / span 6
}

#skindrWidget.skindrWidget .skindr-widget-col-span-7{
  grid-column: span 7 / span 7
}

#skindrWidget.skindrWidget .skindr-widget-col-span-8{
  grid-column: span 8 / span 8
}

#skindrWidget.skindrWidget .skindr-widget-col-span-9{
  grid-column: span 9 / span 9
}

#skindrWidget.skindrWidget .skindr-widget-col-span-10{
  grid-column: span 10 / span 10
}

#skindrWidget.skindrWidget .skindr-widget-col-span-11{
  grid-column: span 11 / span 11
}

#skindrWidget.skindrWidget .skindr-widget-col-span-12{
  grid-column: span 12 / span 12
}

#skindrWidget.skindrWidget .skindr-widget-col-span-full{
  grid-column: 1 / -1
}

#skindrWidget.skindrWidget .skindr-widget-col-start-1{
  grid-column-start: 1
}

#skindrWidget.skindrWidget .skindr-widget-col-start-2{
  grid-column-start: 2
}

#skindrWidget.skindrWidget .skindr-widget-col-start-3{
  grid-column-start: 3
}

#skindrWidget.skindrWidget .skindr-widget-col-start-4{
  grid-column-start: 4
}

#skindrWidget.skindrWidget .skindr-widget-col-start-5{
  grid-column-start: 5
}

#skindrWidget.skindrWidget .skindr-widget-col-start-6{
  grid-column-start: 6
}

#skindrWidget.skindrWidget .skindr-widget-col-start-7{
  grid-column-start: 7
}

#skindrWidget.skindrWidget .skindr-widget-col-start-8{
  grid-column-start: 8
}

#skindrWidget.skindrWidget .skindr-widget-col-start-9{
  grid-column-start: 9
}

#skindrWidget.skindrWidget .skindr-widget-col-start-10{
  grid-column-start: 10
}

#skindrWidget.skindrWidget .skindr-widget-col-start-11{
  grid-column-start: 11
}

#skindrWidget.skindrWidget .skindr-widget-col-start-12{
  grid-column-start: 12
}

#skindrWidget.skindrWidget .skindr-widget-col-start-13{
  grid-column-start: 13
}

#skindrWidget.skindrWidget .skindr-widget-col-start-auto{
  grid-column-start: auto
}

#skindrWidget.skindrWidget .skindr-widget-col-end-1{
  grid-column-end: 1
}

#skindrWidget.skindrWidget .skindr-widget-col-end-2{
  grid-column-end: 2
}

#skindrWidget.skindrWidget .skindr-widget-col-end-3{
  grid-column-end: 3
}

#skindrWidget.skindrWidget .skindr-widget-col-end-4{
  grid-column-end: 4
}

#skindrWidget.skindrWidget .skindr-widget-col-end-5{
  grid-column-end: 5
}

#skindrWidget.skindrWidget .skindr-widget-col-end-6{
  grid-column-end: 6
}

#skindrWidget.skindrWidget .skindr-widget-col-end-7{
  grid-column-end: 7
}

#skindrWidget.skindrWidget .skindr-widget-col-end-8{
  grid-column-end: 8
}

#skindrWidget.skindrWidget .skindr-widget-col-end-9{
  grid-column-end: 9
}

#skindrWidget.skindrWidget .skindr-widget-col-end-10{
  grid-column-end: 10
}

#skindrWidget.skindrWidget .skindr-widget-col-end-11{
  grid-column-end: 11
}

#skindrWidget.skindrWidget .skindr-widget-col-end-12{
  grid-column-end: 12
}

#skindrWidget.skindrWidget .skindr-widget-col-end-13{
  grid-column-end: 13
}

#skindrWidget.skindrWidget .skindr-widget-col-end-auto{
  grid-column-end: auto
}

#skindrWidget.skindrWidget .skindr-widget-row-auto{
  grid-row: auto
}

#skindrWidget.skindrWidget .skindr-widget-row-span-1{
  grid-row: span 1 / span 1
}

#skindrWidget.skindrWidget .skindr-widget-row-span-2{
  grid-row: span 2 / span 2
}

#skindrWidget.skindrWidget .skindr-widget-row-span-3{
  grid-row: span 3 / span 3
}

#skindrWidget.skindrWidget .skindr-widget-row-span-4{
  grid-row: span 4 / span 4
}

#skindrWidget.skindrWidget .skindr-widget-row-span-5{
  grid-row: span 5 / span 5
}

#skindrWidget.skindrWidget .skindr-widget-row-span-6{
  grid-row: span 6 / span 6
}

#skindrWidget.skindrWidget .skindr-widget-row-span-full{
  grid-row: 1 / -1
}

#skindrWidget.skindrWidget .skindr-widget-row-start-1{
  grid-row-start: 1
}

#skindrWidget.skindrWidget .skindr-widget-row-start-2{
  grid-row-start: 2
}

#skindrWidget.skindrWidget .skindr-widget-row-start-3{
  grid-row-start: 3
}

#skindrWidget.skindrWidget .skindr-widget-row-start-4{
  grid-row-start: 4
}

#skindrWidget.skindrWidget .skindr-widget-row-start-5{
  grid-row-start: 5
}

#skindrWidget.skindrWidget .skindr-widget-row-start-6{
  grid-row-start: 6
}

#skindrWidget.skindrWidget .skindr-widget-row-start-7{
  grid-row-start: 7
}

#skindrWidget.skindrWidget .skindr-widget-row-start-auto{
  grid-row-start: auto
}

#skindrWidget.skindrWidget .skindr-widget-row-end-1{
  grid-row-end: 1
}

#skindrWidget.skindrWidget .skindr-widget-row-end-2{
  grid-row-end: 2
}

#skindrWidget.skindrWidget .skindr-widget-row-end-3{
  grid-row-end: 3
}

#skindrWidget.skindrWidget .skindr-widget-row-end-4{
  grid-row-end: 4
}

#skindrWidget.skindrWidget .skindr-widget-row-end-5{
  grid-row-end: 5
}

#skindrWidget.skindrWidget .skindr-widget-row-end-6{
  grid-row-end: 6
}

#skindrWidget.skindrWidget .skindr-widget-row-end-7{
  grid-row-end: 7
}

#skindrWidget.skindrWidget .skindr-widget-row-end-auto{
  grid-row-end: auto
}

#skindrWidget.skindrWidget .skindr-widget-float-right{
  float: right
}

#skindrWidget.skindrWidget .skindr-widget-float-left{
  float: left
}

#skindrWidget.skindrWidget .skindr-widget-float-none{
  float: none
}

#skindrWidget.skindrWidget .skindr-widget-clear-left{
  clear: left
}

#skindrWidget.skindrWidget .skindr-widget-clear-right{
  clear: right
}

#skindrWidget.skindrWidget .skindr-widget-clear-both{
  clear: both
}

#skindrWidget.skindrWidget .skindr-widget-clear-none{
  clear: none
}

#skindrWidget.skindrWidget .skindr-widget-m-0{
  margin: 0px
}

#skindrWidget.skindrWidget .skindr-widget-m-1{
  margin: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-m-2{
  margin: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-m-3{
  margin: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-m-4{
  margin: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-m-5{
  margin: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-m-6{
  margin: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-m-7{
  margin: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-m-8{
  margin: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-m-9{
  margin: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-m-10{
  margin: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-m-11{
  margin: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-m-12{
  margin: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-m-14{
  margin: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-m-16{
  margin: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-m-20{
  margin: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-m-24{
  margin: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-m-28{
  margin: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-m-32{
  margin: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-m-36{
  margin: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-m-40{
  margin: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-m-44{
  margin: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-m-48{
  margin: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-m-52{
  margin: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-m-56{
  margin: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-m-60{
  margin: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-m-64{
  margin: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-m-72{
  margin: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-m-80{
  margin: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-m-96{
  margin: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-m-auto{
  margin: auto
}

#skindrWidget.skindrWidget .skindr-widget-m-px{
  margin: 1px
}

#skindrWidget.skindrWidget .skindr-widget-m-0\.5{
  margin: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-m-1\.5{
  margin: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-m-2\.5{
  margin: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-m-3\.5{
  margin: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--m-0{
  margin: 0px
}

#skindrWidget.skindrWidget .skindr-widget--m-1{
  margin: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--m-2{
  margin: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--m-3{
  margin: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--m-4{
  margin: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--m-5{
  margin: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--m-6{
  margin: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--m-7{
  margin: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--m-8{
  margin: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--m-9{
  margin: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--m-10{
  margin: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--m-11{
  margin: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--m-12{
  margin: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--m-14{
  margin: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--m-16{
  margin: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--m-20{
  margin: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--m-24{
  margin: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--m-28{
  margin: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--m-32{
  margin: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--m-36{
  margin: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--m-40{
  margin: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--m-44{
  margin: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--m-48{
  margin: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--m-52{
  margin: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--m-56{
  margin: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--m-60{
  margin: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--m-64{
  margin: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--m-72{
  margin: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--m-80{
  margin: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--m-96{
  margin: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--m-px{
  margin: -1px
}

#skindrWidget.skindrWidget .skindr-widget--m-0\.5{
  margin: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--m-1\.5{
  margin: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--m-2\.5{
  margin: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--m-3\.5{
  margin: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-0{
  margin-left: 0px;
  margin-right: 0px
}

#skindrWidget.skindrWidget .skindr-widget-mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-3{
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-4{
  margin-left: 1rem;
  margin-right: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-5{
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-6{
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-7{
  margin-left: 1.75rem;
  margin-right: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-8{
  margin-left: 2rem;
  margin-right: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-9{
  margin-left: 2.25rem;
  margin-right: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-10{
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-11{
  margin-left: 2.75rem;
  margin-right: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-12{
  margin-left: 3rem;
  margin-right: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-14{
  margin-left: 3.5rem;
  margin-right: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-16{
  margin-left: 4rem;
  margin-right: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-20{
  margin-left: 5rem;
  margin-right: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-24{
  margin-left: 6rem;
  margin-right: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-28{
  margin-left: 7rem;
  margin-right: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-32{
  margin-left: 8rem;
  margin-right: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-36{
  margin-left: 9rem;
  margin-right: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-40{
  margin-left: 10rem;
  margin-right: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-44{
  margin-left: 11rem;
  margin-right: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-48{
  margin-left: 12rem;
  margin-right: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-52{
  margin-left: 13rem;
  margin-right: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-56{
  margin-left: 14rem;
  margin-right: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-60{
  margin-left: 15rem;
  margin-right: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-64{
  margin-left: 16rem;
  margin-right: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-72{
  margin-left: 18rem;
  margin-right: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-80{
  margin-left: 20rem;
  margin-right: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-96{
  margin-left: 24rem;
  margin-right: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-auto{
  margin-left: auto;
  margin-right: auto
}

#skindrWidget.skindrWidget .skindr-widget-mx-px{
  margin-left: 1px;
  margin-right: 1px
}

#skindrWidget.skindrWidget .skindr-widget-mx-0\.5{
  margin-left: 0.125rem;
  margin-right: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-1\.5{
  margin-left: 0.375rem;
  margin-right: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-2\.5{
  margin-left: 0.625rem;
  margin-right: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-mx-3\.5{
  margin-left: 0.875rem;
  margin-right: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-0{
  margin-left: 0px;
  margin-right: 0px
}

#skindrWidget.skindrWidget .skindr-widget--mx-1{
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-2{
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-3{
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-4{
  margin-left: -1rem;
  margin-right: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-5{
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-6{
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-7{
  margin-left: -1.75rem;
  margin-right: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-8{
  margin-left: -2rem;
  margin-right: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-9{
  margin-left: -2.25rem;
  margin-right: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-10{
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-11{
  margin-left: -2.75rem;
  margin-right: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-12{
  margin-left: -3rem;
  margin-right: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-14{
  margin-left: -3.5rem;
  margin-right: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-16{
  margin-left: -4rem;
  margin-right: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-20{
  margin-left: -5rem;
  margin-right: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-24{
  margin-left: -6rem;
  margin-right: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-28{
  margin-left: -7rem;
  margin-right: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-32{
  margin-left: -8rem;
  margin-right: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-36{
  margin-left: -9rem;
  margin-right: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-40{
  margin-left: -10rem;
  margin-right: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-44{
  margin-left: -11rem;
  margin-right: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-48{
  margin-left: -12rem;
  margin-right: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-52{
  margin-left: -13rem;
  margin-right: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-56{
  margin-left: -14rem;
  margin-right: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-60{
  margin-left: -15rem;
  margin-right: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-64{
  margin-left: -16rem;
  margin-right: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-72{
  margin-left: -18rem;
  margin-right: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-80{
  margin-left: -20rem;
  margin-right: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-96{
  margin-left: -24rem;
  margin-right: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-px{
  margin-left: -1px;
  margin-right: -1px
}

#skindrWidget.skindrWidget .skindr-widget--mx-0\.5{
  margin-left: -0.125rem;
  margin-right: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-1\.5{
  margin-left: -0.375rem;
  margin-right: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-2\.5{
  margin-left: -0.625rem;
  margin-right: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--mx-3\.5{
  margin-left: -0.875rem;
  margin-right: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-my-0{
  margin-top: 0px;
  margin-bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget-my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-my-4{
  margin-top: 1rem;
  margin-bottom: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-my-7{
  margin-top: 1.75rem;
  margin-bottom: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-my-8{
  margin-top: 2rem;
  margin-bottom: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-my-9{
  margin-top: 2.25rem;
  margin-bottom: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-my-10{
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-my-11{
  margin-top: 2.75rem;
  margin-bottom: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-my-12{
  margin-top: 3rem;
  margin-bottom: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-my-14{
  margin-top: 3.5rem;
  margin-bottom: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-my-16{
  margin-top: 4rem;
  margin-bottom: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-my-20{
  margin-top: 5rem;
  margin-bottom: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-my-24{
  margin-top: 6rem;
  margin-bottom: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-my-28{
  margin-top: 7rem;
  margin-bottom: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-my-32{
  margin-top: 8rem;
  margin-bottom: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-my-36{
  margin-top: 9rem;
  margin-bottom: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-my-40{
  margin-top: 10rem;
  margin-bottom: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-my-44{
  margin-top: 11rem;
  margin-bottom: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-my-48{
  margin-top: 12rem;
  margin-bottom: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-my-52{
  margin-top: 13rem;
  margin-bottom: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-my-56{
  margin-top: 14rem;
  margin-bottom: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-my-60{
  margin-top: 15rem;
  margin-bottom: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-my-64{
  margin-top: 16rem;
  margin-bottom: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-my-72{
  margin-top: 18rem;
  margin-bottom: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-my-80{
  margin-top: 20rem;
  margin-bottom: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-my-96{
  margin-top: 24rem;
  margin-bottom: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-my-auto{
  margin-top: auto;
  margin-bottom: auto
}

#skindrWidget.skindrWidget .skindr-widget-my-px{
  margin-top: 1px;
  margin-bottom: 1px
}

#skindrWidget.skindrWidget .skindr-widget-my-0\.5{
  margin-top: 0.125rem;
  margin-bottom: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-my-1\.5{
  margin-top: 0.375rem;
  margin-bottom: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-my-2\.5{
  margin-top: 0.625rem;
  margin-bottom: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-my-3\.5{
  margin-top: 0.875rem;
  margin-bottom: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--my-0{
  margin-top: 0px;
  margin-bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget--my-1{
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--my-2{
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--my-3{
  margin-top: -0.75rem;
  margin-bottom: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--my-4{
  margin-top: -1rem;
  margin-bottom: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--my-5{
  margin-top: -1.25rem;
  margin-bottom: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--my-6{
  margin-top: -1.5rem;
  margin-bottom: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--my-7{
  margin-top: -1.75rem;
  margin-bottom: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--my-8{
  margin-top: -2rem;
  margin-bottom: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--my-9{
  margin-top: -2.25rem;
  margin-bottom: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--my-10{
  margin-top: -2.5rem;
  margin-bottom: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--my-11{
  margin-top: -2.75rem;
  margin-bottom: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--my-12{
  margin-top: -3rem;
  margin-bottom: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--my-14{
  margin-top: -3.5rem;
  margin-bottom: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--my-16{
  margin-top: -4rem;
  margin-bottom: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--my-20{
  margin-top: -5rem;
  margin-bottom: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--my-24{
  margin-top: -6rem;
  margin-bottom: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--my-28{
  margin-top: -7rem;
  margin-bottom: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--my-32{
  margin-top: -8rem;
  margin-bottom: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--my-36{
  margin-top: -9rem;
  margin-bottom: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--my-40{
  margin-top: -10rem;
  margin-bottom: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--my-44{
  margin-top: -11rem;
  margin-bottom: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--my-48{
  margin-top: -12rem;
  margin-bottom: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--my-52{
  margin-top: -13rem;
  margin-bottom: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--my-56{
  margin-top: -14rem;
  margin-bottom: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--my-60{
  margin-top: -15rem;
  margin-bottom: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--my-64{
  margin-top: -16rem;
  margin-bottom: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--my-72{
  margin-top: -18rem;
  margin-bottom: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--my-80{
  margin-top: -20rem;
  margin-bottom: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--my-96{
  margin-top: -24rem;
  margin-bottom: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--my-px{
  margin-top: -1px;
  margin-bottom: -1px
}

#skindrWidget.skindrWidget .skindr-widget--my-0\.5{
  margin-top: -0.125rem;
  margin-bottom: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--my-1\.5{
  margin-top: -0.375rem;
  margin-bottom: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--my-2\.5{
  margin-top: -0.625rem;
  margin-bottom: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--my-3\.5{
  margin-top: -0.875rem;
  margin-bottom: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-0{
  margin-top: 0px
}

#skindrWidget.skindrWidget .skindr-widget-mt-1{
  margin-top: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-2{
  margin-top: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-3{
  margin-top: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-4{
  margin-top: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-5{
  margin-top: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-6{
  margin-top: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-7{
  margin-top: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-8{
  margin-top: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-9{
  margin-top: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-10{
  margin-top: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-11{
  margin-top: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-12{
  margin-top: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-14{
  margin-top: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-16{
  margin-top: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-20{
  margin-top: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-24{
  margin-top: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-28{
  margin-top: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-32{
  margin-top: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-36{
  margin-top: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-40{
  margin-top: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-44{
  margin-top: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-48{
  margin-top: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-52{
  margin-top: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-56{
  margin-top: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-60{
  margin-top: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-64{
  margin-top: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-72{
  margin-top: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-80{
  margin-top: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-96{
  margin-top: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-auto{
  margin-top: auto
}

#skindrWidget.skindrWidget .skindr-widget-mt-px{
  margin-top: 1px
}

#skindrWidget.skindrWidget .skindr-widget-mt-0\.5{
  margin-top: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-1\.5{
  margin-top: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-2\.5{
  margin-top: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-mt-3\.5{
  margin-top: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-0{
  margin-top: 0px
}

#skindrWidget.skindrWidget .skindr-widget--mt-1{
  margin-top: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-2{
  margin-top: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-3{
  margin-top: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-4{
  margin-top: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-5{
  margin-top: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-6{
  margin-top: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-7{
  margin-top: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-8{
  margin-top: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-9{
  margin-top: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-10{
  margin-top: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-11{
  margin-top: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-12{
  margin-top: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-14{
  margin-top: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-16{
  margin-top: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-20{
  margin-top: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-24{
  margin-top: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-28{
  margin-top: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-32{
  margin-top: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-36{
  margin-top: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-40{
  margin-top: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-44{
  margin-top: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-48{
  margin-top: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-52{
  margin-top: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-56{
  margin-top: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-60{
  margin-top: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-64{
  margin-top: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-72{
  margin-top: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-80{
  margin-top: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-96{
  margin-top: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-px{
  margin-top: -1px
}

#skindrWidget.skindrWidget .skindr-widget--mt-0\.5{
  margin-top: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-1\.5{
  margin-top: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-2\.5{
  margin-top: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--mt-3\.5{
  margin-top: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-0{
  margin-right: 0px
}

#skindrWidget.skindrWidget .skindr-widget-mr-1{
  margin-right: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-2{
  margin-right: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-3{
  margin-right: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-4{
  margin-right: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-5{
  margin-right: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-6{
  margin-right: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-7{
  margin-right: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-8{
  margin-right: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-9{
  margin-right: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-10{
  margin-right: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-11{
  margin-right: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-12{
  margin-right: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-14{
  margin-right: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-16{
  margin-right: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-20{
  margin-right: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-24{
  margin-right: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-28{
  margin-right: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-32{
  margin-right: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-36{
  margin-right: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-40{
  margin-right: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-44{
  margin-right: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-48{
  margin-right: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-52{
  margin-right: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-56{
  margin-right: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-60{
  margin-right: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-64{
  margin-right: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-72{
  margin-right: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-80{
  margin-right: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-96{
  margin-right: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-auto{
  margin-right: auto
}

#skindrWidget.skindrWidget .skindr-widget-mr-px{
  margin-right: 1px
}

#skindrWidget.skindrWidget .skindr-widget-mr-0\.5{
  margin-right: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-1\.5{
  margin-right: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-2\.5{
  margin-right: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-mr-3\.5{
  margin-right: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-0{
  margin-right: 0px
}

#skindrWidget.skindrWidget .skindr-widget--mr-1{
  margin-right: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-2{
  margin-right: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-3{
  margin-right: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-4{
  margin-right: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-5{
  margin-right: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-6{
  margin-right: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-7{
  margin-right: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-8{
  margin-right: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-9{
  margin-right: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-10{
  margin-right: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-11{
  margin-right: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-12{
  margin-right: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-14{
  margin-right: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-16{
  margin-right: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-20{
  margin-right: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-24{
  margin-right: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-28{
  margin-right: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-32{
  margin-right: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-36{
  margin-right: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-40{
  margin-right: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-44{
  margin-right: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-48{
  margin-right: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-52{
  margin-right: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-56{
  margin-right: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-60{
  margin-right: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-64{
  margin-right: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-72{
  margin-right: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-80{
  margin-right: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-96{
  margin-right: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-px{
  margin-right: -1px
}

#skindrWidget.skindrWidget .skindr-widget--mr-0\.5{
  margin-right: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-1\.5{
  margin-right: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-2\.5{
  margin-right: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--mr-3\.5{
  margin-right: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-0{
  margin-bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget-mb-1{
  margin-bottom: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-2{
  margin-bottom: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-3{
  margin-bottom: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-4{
  margin-bottom: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-5{
  margin-bottom: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-6{
  margin-bottom: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-7{
  margin-bottom: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-8{
  margin-bottom: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-9{
  margin-bottom: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-10{
  margin-bottom: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-11{
  margin-bottom: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-12{
  margin-bottom: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-14{
  margin-bottom: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-16{
  margin-bottom: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-20{
  margin-bottom: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-24{
  margin-bottom: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-28{
  margin-bottom: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-32{
  margin-bottom: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-36{
  margin-bottom: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-40{
  margin-bottom: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-44{
  margin-bottom: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-48{
  margin-bottom: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-52{
  margin-bottom: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-56{
  margin-bottom: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-60{
  margin-bottom: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-64{
  margin-bottom: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-72{
  margin-bottom: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-80{
  margin-bottom: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-96{
  margin-bottom: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-auto{
  margin-bottom: auto
}

#skindrWidget.skindrWidget .skindr-widget-mb-px{
  margin-bottom: 1px
}

#skindrWidget.skindrWidget .skindr-widget-mb-0\.5{
  margin-bottom: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-1\.5{
  margin-bottom: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-2\.5{
  margin-bottom: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-mb-3\.5{
  margin-bottom: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-0{
  margin-bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget--mb-1{
  margin-bottom: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-2{
  margin-bottom: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-3{
  margin-bottom: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-4{
  margin-bottom: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-5{
  margin-bottom: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-6{
  margin-bottom: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-7{
  margin-bottom: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-8{
  margin-bottom: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-9{
  margin-bottom: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-10{
  margin-bottom: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-11{
  margin-bottom: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-12{
  margin-bottom: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-14{
  margin-bottom: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-16{
  margin-bottom: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-20{
  margin-bottom: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-24{
  margin-bottom: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-28{
  margin-bottom: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-32{
  margin-bottom: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-36{
  margin-bottom: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-40{
  margin-bottom: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-44{
  margin-bottom: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-48{
  margin-bottom: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-52{
  margin-bottom: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-56{
  margin-bottom: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-60{
  margin-bottom: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-64{
  margin-bottom: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-72{
  margin-bottom: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-80{
  margin-bottom: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-96{
  margin-bottom: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-px{
  margin-bottom: -1px
}

#skindrWidget.skindrWidget .skindr-widget--mb-0\.5{
  margin-bottom: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-1\.5{
  margin-bottom: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-2\.5{
  margin-bottom: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--mb-3\.5{
  margin-bottom: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-0{
  margin-left: 0px
}

#skindrWidget.skindrWidget .skindr-widget-ml-1{
  margin-left: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-2{
  margin-left: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-3{
  margin-left: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-4{
  margin-left: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-5{
  margin-left: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-6{
  margin-left: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-7{
  margin-left: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-8{
  margin-left: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-9{
  margin-left: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-10{
  margin-left: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-11{
  margin-left: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-12{
  margin-left: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-14{
  margin-left: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-16{
  margin-left: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-20{
  margin-left: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-24{
  margin-left: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-28{
  margin-left: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-32{
  margin-left: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-36{
  margin-left: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-40{
  margin-left: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-44{
  margin-left: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-48{
  margin-left: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-52{
  margin-left: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-56{
  margin-left: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-60{
  margin-left: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-64{
  margin-left: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-72{
  margin-left: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-80{
  margin-left: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-96{
  margin-left: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-auto{
  margin-left: auto
}

#skindrWidget.skindrWidget .skindr-widget-ml-px{
  margin-left: 1px
}

#skindrWidget.skindrWidget .skindr-widget-ml-0\.5{
  margin-left: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-1\.5{
  margin-left: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-2\.5{
  margin-left: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-ml-3\.5{
  margin-left: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-0{
  margin-left: 0px
}

#skindrWidget.skindrWidget .skindr-widget--ml-1{
  margin-left: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-2{
  margin-left: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-3{
  margin-left: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-4{
  margin-left: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-5{
  margin-left: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-6{
  margin-left: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-7{
  margin-left: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-8{
  margin-left: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-9{
  margin-left: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-10{
  margin-left: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-11{
  margin-left: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-12{
  margin-left: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-14{
  margin-left: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-16{
  margin-left: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-20{
  margin-left: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-24{
  margin-left: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-28{
  margin-left: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-32{
  margin-left: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-36{
  margin-left: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-40{
  margin-left: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-44{
  margin-left: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-48{
  margin-left: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-52{
  margin-left: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-56{
  margin-left: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-60{
  margin-left: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-64{
  margin-left: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-72{
  margin-left: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-80{
  margin-left: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-96{
  margin-left: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-px{
  margin-left: -1px
}

#skindrWidget.skindrWidget .skindr-widget--ml-0\.5{
  margin-left: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-1\.5{
  margin-left: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-2\.5{
  margin-left: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--ml-3\.5{
  margin-left: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-box-border{
  box-sizing: border-box
}

#skindrWidget.skindrWidget .skindr-widget-box-content{
  box-sizing: content-box
}

#skindrWidget.skindrWidget .skindr-widget-block{
  display: block
}

#skindrWidget.skindrWidget .skindr-widget-inline-block{
  display: inline-block
}

#skindrWidget.skindrWidget .skindr-widget-inline{
  display: inline
}

#skindrWidget.skindrWidget .skindr-widget-flex{
  display: flex
}

#skindrWidget.skindrWidget .skindr-widget-inline-flex{
  display: inline-flex
}

#skindrWidget.skindrWidget .skindr-widget-table{
  display: table
}

#skindrWidget.skindrWidget .skindr-widget-inline-table{
  display: inline-table
}

#skindrWidget.skindrWidget .skindr-widget-table-caption{
  display: table-caption
}

#skindrWidget.skindrWidget .skindr-widget-table-cell{
  display: table-cell
}

#skindrWidget.skindrWidget .skindr-widget-table-column{
  display: table-column
}

#skindrWidget.skindrWidget .skindr-widget-table-column-group{
  display: table-column-group
}

#skindrWidget.skindrWidget .skindr-widget-table-footer-group{
  display: table-footer-group
}

#skindrWidget.skindrWidget .skindr-widget-table-header-group{
  display: table-header-group
}

#skindrWidget.skindrWidget .skindr-widget-table-row-group{
  display: table-row-group
}

#skindrWidget.skindrWidget .skindr-widget-table-row{
  display: table-row
}

#skindrWidget.skindrWidget .skindr-widget-flow-root{
  display: flow-root
}

#skindrWidget.skindrWidget .skindr-widget-grid{
  display: grid
}

#skindrWidget.skindrWidget .skindr-widget-inline-grid{
  display: inline-grid
}

#skindrWidget.skindrWidget .skindr-widget-contents{
  display: contents
}

#skindrWidget.skindrWidget .skindr-widget-list-item{
  display: list-item
}

#skindrWidget.skindrWidget .skindr-widget-hidden{
  display: none
}

#skindrWidget.skindrWidget .skindr-widget-h-0{
  height: 0px
}

#skindrWidget.skindrWidget .skindr-widget-h-1{
  height: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-h-2{
  height: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-h-3{
  height: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-h-4{
  height: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-h-5{
  height: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-h-6{
  height: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-h-7{
  height: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-h-8{
  height: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-h-9{
  height: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-h-10{
  height: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-h-11{
  height: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-h-12{
  height: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-h-14{
  height: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-h-16{
  height: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-h-20{
  height: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-h-24{
  height: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-h-28{
  height: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-h-32{
  height: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-h-36{
  height: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-h-40{
  height: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-h-44{
  height: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-h-48{
  height: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-h-52{
  height: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-h-56{
  height: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-h-60{
  height: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-h-64{
  height: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-h-72{
  height: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-h-80{
  height: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-h-96{
  height: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-h-auto{
  height: auto
}

#skindrWidget.skindrWidget .skindr-widget-h-px{
  height: 1px
}

#skindrWidget.skindrWidget .skindr-widget-h-0\.5{
  height: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-h-1\.5{
  height: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-h-2\.5{
  height: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-h-3\.5{
  height: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-h-1\/2{
  height: 50%
}

#skindrWidget.skindrWidget .skindr-widget-h-1\/3{
  height: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-h-2\/3{
  height: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-h-1\/4{
  height: 25%
}

#skindrWidget.skindrWidget .skindr-widget-h-2\/4{
  height: 50%
}

#skindrWidget.skindrWidget .skindr-widget-h-3\/4{
  height: 75%
}

#skindrWidget.skindrWidget .skindr-widget-h-1\/5{
  height: 20%
}

#skindrWidget.skindrWidget .skindr-widget-h-2\/5{
  height: 40%
}

#skindrWidget.skindrWidget .skindr-widget-h-3\/5{
  height: 60%
}

#skindrWidget.skindrWidget .skindr-widget-h-4\/5{
  height: 80%
}

#skindrWidget.skindrWidget .skindr-widget-h-1\/6{
  height: 16.666667%
}

#skindrWidget.skindrWidget .skindr-widget-h-2\/6{
  height: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-h-3\/6{
  height: 50%
}

#skindrWidget.skindrWidget .skindr-widget-h-4\/6{
  height: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-h-5\/6{
  height: 83.333333%
}

#skindrWidget.skindrWidget .skindr-widget-h-full{
  height: 100%
}

#skindrWidget.skindrWidget .skindr-widget-h-screen{
  height: 100vh
}

#skindrWidget.skindrWidget .skindr-widget-max-h-0{
  max-height: 0px
}

#skindrWidget.skindrWidget .skindr-widget-max-h-1{
  max-height: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-2{
  max-height: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-3{
  max-height: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-4{
  max-height: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-5{
  max-height: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-6{
  max-height: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-7{
  max-height: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-8{
  max-height: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-9{
  max-height: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-10{
  max-height: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-11{
  max-height: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-12{
  max-height: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-14{
  max-height: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-16{
  max-height: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-20{
  max-height: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-24{
  max-height: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-28{
  max-height: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-32{
  max-height: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-36{
  max-height: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-40{
  max-height: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-44{
  max-height: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-48{
  max-height: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-52{
  max-height: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-56{
  max-height: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-60{
  max-height: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-64{
  max-height: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-72{
  max-height: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-80{
  max-height: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-96{
  max-height: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-px{
  max-height: 1px
}

#skindrWidget.skindrWidget .skindr-widget-max-h-0\.5{
  max-height: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-1\.5{
  max-height: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-2\.5{
  max-height: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-3\.5{
  max-height: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-max-h-full{
  max-height: 100%
}

#skindrWidget.skindrWidget .skindr-widget-max-h-screen{
  max-height: 100vh
}

#skindrWidget.skindrWidget .skindr-widget-min-h-0{
  min-height: 0px
}

#skindrWidget.skindrWidget .skindr-widget-min-h-full{
  min-height: 100%
}

#skindrWidget.skindrWidget .skindr-widget-min-h-screen{
  min-height: 100vh
}

#skindrWidget.skindrWidget .skindr-widget-w-0{
  width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-w-1{
  width: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-w-2{
  width: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-w-3{
  width: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-w-4{
  width: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-w-5{
  width: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-w-6{
  width: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-w-7{
  width: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-w-8{
  width: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-w-9{
  width: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-w-10{
  width: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-w-11{
  width: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-w-12{
  width: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-w-14{
  width: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-w-16{
  width: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-w-20{
  width: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-w-24{
  width: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-w-28{
  width: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-w-32{
  width: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-w-36{
  width: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-w-40{
  width: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-w-44{
  width: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-w-48{
  width: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-w-52{
  width: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-w-56{
  width: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-w-60{
  width: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-w-64{
  width: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-w-72{
  width: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-w-80{
  width: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-w-96{
  width: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-w-auto{
  width: auto
}

#skindrWidget.skindrWidget .skindr-widget-w-px{
  width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-w-0\.5{
  width: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-w-1\.5{
  width: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-w-2\.5{
  width: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-w-3\.5{
  width: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-w-1\/2{
  width: 50%
}

#skindrWidget.skindrWidget .skindr-widget-w-1\/3{
  width: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-2\/3{
  width: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-1\/4{
  width: 25%
}

#skindrWidget.skindrWidget .skindr-widget-w-2\/4{
  width: 50%
}

#skindrWidget.skindrWidget .skindr-widget-w-3\/4{
  width: 75%
}

#skindrWidget.skindrWidget .skindr-widget-w-1\/5{
  width: 20%
}

#skindrWidget.skindrWidget .skindr-widget-w-2\/5{
  width: 40%
}

#skindrWidget.skindrWidget .skindr-widget-w-3\/5{
  width: 60%
}

#skindrWidget.skindrWidget .skindr-widget-w-4\/5{
  width: 80%
}

#skindrWidget.skindrWidget .skindr-widget-w-1\/6{
  width: 16.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-2\/6{
  width: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-3\/6{
  width: 50%
}

#skindrWidget.skindrWidget .skindr-widget-w-4\/6{
  width: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-5\/6{
  width: 83.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-1\/12{
  width: 8.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-2\/12{
  width: 16.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-3\/12{
  width: 25%
}

#skindrWidget.skindrWidget .skindr-widget-w-4\/12{
  width: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-5\/12{
  width: 41.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-6\/12{
  width: 50%
}

#skindrWidget.skindrWidget .skindr-widget-w-7\/12{
  width: 58.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-8\/12{
  width: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-9\/12{
  width: 75%
}

#skindrWidget.skindrWidget .skindr-widget-w-10\/12{
  width: 83.333333%
}

#skindrWidget.skindrWidget .skindr-widget-w-11\/12{
  width: 91.666667%
}

#skindrWidget.skindrWidget .skindr-widget-w-full{
  width: 100%
}

#skindrWidget.skindrWidget .skindr-widget-w-screen{
  width: 100vw
}

#skindrWidget.skindrWidget .skindr-widget-w-min{
  width: min-content
}

#skindrWidget.skindrWidget .skindr-widget-w-max{
  width: max-content
}

#skindrWidget.skindrWidget .skindr-widget-min-w-0{
  min-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-min-w-full{
  min-width: 100%
}

#skindrWidget.skindrWidget .skindr-widget-min-w-min{
  min-width: min-content
}

#skindrWidget.skindrWidget .skindr-widget-min-w-max{
  min-width: max-content
}

#skindrWidget.skindrWidget .skindr-widget-max-w-0{
  max-width: 0rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-none{
  max-width: none
}

#skindrWidget.skindrWidget .skindr-widget-max-w-xs{
  max-width: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-sm{
  max-width: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-md{
  max-width: 28rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-lg{
  max-width: 32rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-xl{
  max-width: 36rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-2xl{
  max-width: 42rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-3xl{
  max-width: 48rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-4xl{
  max-width: 56rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-5xl{
  max-width: 64rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-6xl{
  max-width: 72rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-7xl{
  max-width: 80rem
}

#skindrWidget.skindrWidget .skindr-widget-max-w-full{
  max-width: 100%
}

#skindrWidget.skindrWidget .skindr-widget-max-w-min{
  max-width: min-content
}

#skindrWidget.skindrWidget .skindr-widget-max-w-max{
  max-width: max-content
}

#skindrWidget.skindrWidget .skindr-widget-max-w-prose{
  max-width: 65ch
}

#skindrWidget.skindrWidget .skindr-widget-max-w-screen-sm{
  max-width: 640px
}

#skindrWidget.skindrWidget .skindr-widget-max-w-screen-md{
  max-width: 768px
}

#skindrWidget.skindrWidget .skindr-widget-max-w-screen-lg{
  max-width: 1024px
}

#skindrWidget.skindrWidget .skindr-widget-max-w-screen-xl{
  max-width: 1280px
}

#skindrWidget.skindrWidget .skindr-widget-max-w-screen-2xl{
  max-width: 1536px
}

#skindrWidget.skindrWidget .skindr-widget-flex-1{
  flex: 1 1 0%
}

#skindrWidget.skindrWidget .skindr-widget-flex-auto{
  flex: 1 1 auto
}

#skindrWidget.skindrWidget .skindr-widget-flex-initial{
  flex: 0 1 auto
}

#skindrWidget.skindrWidget .skindr-widget-flex-none{
  flex: none
}

#skindrWidget.skindrWidget .skindr-widget-flex-shrink-0{
  flex-shrink: 0
}

#skindrWidget.skindrWidget .skindr-widget-flex-shrink{
  flex-shrink: 1
}

#skindrWidget.skindrWidget .skindr-widget-flex-grow-0{
  flex-grow: 0
}

#skindrWidget.skindrWidget .skindr-widget-flex-grow{
  flex-grow: 1
}

#skindrWidget.skindrWidget .skindr-widget-table-auto{
  table-layout: auto
}

#skindrWidget.skindrWidget .skindr-widget-table-fixed{
  table-layout: fixed
}

#skindrWidget.skindrWidget .skindr-widget-border-collapse{
  border-collapse: collapse
}

#skindrWidget.skindrWidget .skindr-widget-border-separate{
  border-collapse: separate
}

#skindrWidget.skindrWidget .skindr-widget-origin-center{
  transform-origin: center
}

#skindrWidget.skindrWidget .skindr-widget-origin-top{
  transform-origin: top
}

#skindrWidget.skindrWidget .skindr-widget-origin-top-right{
  transform-origin: top right
}

#skindrWidget.skindrWidget .skindr-widget-origin-right{
  transform-origin: right
}

#skindrWidget.skindrWidget .skindr-widget-origin-bottom-right{
  transform-origin: bottom right
}

#skindrWidget.skindrWidget .skindr-widget-origin-bottom{
  transform-origin: bottom
}

#skindrWidget.skindrWidget .skindr-widget-origin-bottom-left{
  transform-origin: bottom left
}

#skindrWidget.skindrWidget .skindr-widget-origin-left{
  transform-origin: left
}

#skindrWidget.skindrWidget .skindr-widget-origin-top-left{
  transform-origin: top left
}

#skindrWidget.skindrWidget .skindr-widget-transform{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

#skindrWidget.skindrWidget .skindr-widget-transform-gpu{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

#skindrWidget.skindrWidget .skindr-widget-transform-none{
  transform: none
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-0{
  --tw-translate-x: 0px
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-1{
  --tw-translate-x: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-2{
  --tw-translate-x: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-3{
  --tw-translate-x: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-4{
  --tw-translate-x: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-5{
  --tw-translate-x: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-6{
  --tw-translate-x: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-7{
  --tw-translate-x: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-8{
  --tw-translate-x: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-9{
  --tw-translate-x: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-10{
  --tw-translate-x: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-11{
  --tw-translate-x: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-12{
  --tw-translate-x: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-14{
  --tw-translate-x: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-16{
  --tw-translate-x: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-20{
  --tw-translate-x: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-24{
  --tw-translate-x: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-28{
  --tw-translate-x: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-32{
  --tw-translate-x: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-36{
  --tw-translate-x: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-40{
  --tw-translate-x: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-44{
  --tw-translate-x: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-48{
  --tw-translate-x: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-52{
  --tw-translate-x: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-56{
  --tw-translate-x: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-60{
  --tw-translate-x: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-64{
  --tw-translate-x: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-72{
  --tw-translate-x: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-80{
  --tw-translate-x: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-96{
  --tw-translate-x: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-px{
  --tw-translate-x: 1px
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-0\.5{
  --tw-translate-x: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-1\.5{
  --tw-translate-x: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-2\.5{
  --tw-translate-x: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-3\.5{
  --tw-translate-x: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-0{
  --tw-translate-x: 0px
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-1{
  --tw-translate-x: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-2{
  --tw-translate-x: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-3{
  --tw-translate-x: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-4{
  --tw-translate-x: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-5{
  --tw-translate-x: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-6{
  --tw-translate-x: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-7{
  --tw-translate-x: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-8{
  --tw-translate-x: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-9{
  --tw-translate-x: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-10{
  --tw-translate-x: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-11{
  --tw-translate-x: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-12{
  --tw-translate-x: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-14{
  --tw-translate-x: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-16{
  --tw-translate-x: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-20{
  --tw-translate-x: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-24{
  --tw-translate-x: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-28{
  --tw-translate-x: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-32{
  --tw-translate-x: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-36{
  --tw-translate-x: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-40{
  --tw-translate-x: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-44{
  --tw-translate-x: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-48{
  --tw-translate-x: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-52{
  --tw-translate-x: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-56{
  --tw-translate-x: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-60{
  --tw-translate-x: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-64{
  --tw-translate-x: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-72{
  --tw-translate-x: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-80{
  --tw-translate-x: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-96{
  --tw-translate-x: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-px{
  --tw-translate-x: -1px
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-0\.5{
  --tw-translate-x: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-1\.5{
  --tw-translate-x: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-2\.5{
  --tw-translate-x: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-3\.5{
  --tw-translate-x: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-1\/2{
  --tw-translate-x: 50%
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-1\/3{
  --tw-translate-x: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-2\/3{
  --tw-translate-x: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-1\/4{
  --tw-translate-x: 25%
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-2\/4{
  --tw-translate-x: 50%
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-3\/4{
  --tw-translate-x: 75%
}

#skindrWidget.skindrWidget .skindr-widget-translate-x-full{
  --tw-translate-x: 100%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-1\/2{
  --tw-translate-x: -50%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-1\/3{
  --tw-translate-x: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-2\/3{
  --tw-translate-x: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-1\/4{
  --tw-translate-x: -25%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-2\/4{
  --tw-translate-x: -50%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-3\/4{
  --tw-translate-x: -75%
}

#skindrWidget.skindrWidget .skindr-widget--translate-x-full{
  --tw-translate-x: -100%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-0{
  --tw-translate-y: 0px
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-1{
  --tw-translate-y: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-2{
  --tw-translate-y: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-3{
  --tw-translate-y: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-4{
  --tw-translate-y: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-5{
  --tw-translate-y: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-6{
  --tw-translate-y: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-7{
  --tw-translate-y: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-8{
  --tw-translate-y: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-9{
  --tw-translate-y: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-10{
  --tw-translate-y: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-11{
  --tw-translate-y: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-12{
  --tw-translate-y: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-14{
  --tw-translate-y: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-16{
  --tw-translate-y: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-20{
  --tw-translate-y: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-24{
  --tw-translate-y: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-28{
  --tw-translate-y: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-32{
  --tw-translate-y: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-36{
  --tw-translate-y: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-40{
  --tw-translate-y: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-44{
  --tw-translate-y: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-48{
  --tw-translate-y: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-52{
  --tw-translate-y: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-56{
  --tw-translate-y: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-60{
  --tw-translate-y: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-64{
  --tw-translate-y: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-72{
  --tw-translate-y: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-80{
  --tw-translate-y: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-96{
  --tw-translate-y: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-px{
  --tw-translate-y: 1px
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-0\.5{
  --tw-translate-y: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-1\.5{
  --tw-translate-y: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-2\.5{
  --tw-translate-y: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-3\.5{
  --tw-translate-y: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-0{
  --tw-translate-y: 0px
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-1{
  --tw-translate-y: -0.25rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-2{
  --tw-translate-y: -0.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-3{
  --tw-translate-y: -0.75rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-4{
  --tw-translate-y: -1rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-5{
  --tw-translate-y: -1.25rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-6{
  --tw-translate-y: -1.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-7{
  --tw-translate-y: -1.75rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-8{
  --tw-translate-y: -2rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-9{
  --tw-translate-y: -2.25rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-10{
  --tw-translate-y: -2.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-11{
  --tw-translate-y: -2.75rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-12{
  --tw-translate-y: -3rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-14{
  --tw-translate-y: -3.5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-16{
  --tw-translate-y: -4rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-20{
  --tw-translate-y: -5rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-24{
  --tw-translate-y: -6rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-28{
  --tw-translate-y: -7rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-32{
  --tw-translate-y: -8rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-36{
  --tw-translate-y: -9rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-40{
  --tw-translate-y: -10rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-44{
  --tw-translate-y: -11rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-48{
  --tw-translate-y: -12rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-52{
  --tw-translate-y: -13rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-56{
  --tw-translate-y: -14rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-60{
  --tw-translate-y: -15rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-64{
  --tw-translate-y: -16rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-72{
  --tw-translate-y: -18rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-80{
  --tw-translate-y: -20rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-96{
  --tw-translate-y: -24rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-px{
  --tw-translate-y: -1px
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-0\.5{
  --tw-translate-y: -0.125rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-1\.5{
  --tw-translate-y: -0.375rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-2\.5{
  --tw-translate-y: -0.625rem
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-3\.5{
  --tw-translate-y: -0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-1\/2{
  --tw-translate-y: 50%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-1\/3{
  --tw-translate-y: 33.333333%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-2\/3{
  --tw-translate-y: 66.666667%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-1\/4{
  --tw-translate-y: 25%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-2\/4{
  --tw-translate-y: 50%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-3\/4{
  --tw-translate-y: 75%
}

#skindrWidget.skindrWidget .skindr-widget-translate-y-full{
  --tw-translate-y: 100%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-1\/2{
  --tw-translate-y: -50%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-1\/3{
  --tw-translate-y: -33.333333%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-2\/3{
  --tw-translate-y: -66.666667%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-1\/4{
  --tw-translate-y: -25%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-2\/4{
  --tw-translate-y: -50%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-3\/4{
  --tw-translate-y: -75%
}

#skindrWidget.skindrWidget .skindr-widget--translate-y-full{
  --tw-translate-y: -100%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-0:hover{
  --tw-translate-x: 0px
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-1:hover{
  --tw-translate-x: 0.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-2:hover{
  --tw-translate-x: 0.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-3:hover{
  --tw-translate-x: 0.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-4:hover{
  --tw-translate-x: 1rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-5:hover{
  --tw-translate-x: 1.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-6:hover{
  --tw-translate-x: 1.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-7:hover{
  --tw-translate-x: 1.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-8:hover{
  --tw-translate-x: 2rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-9:hover{
  --tw-translate-x: 2.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-10:hover{
  --tw-translate-x: 2.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-11:hover{
  --tw-translate-x: 2.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-12:hover{
  --tw-translate-x: 3rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-14:hover{
  --tw-translate-x: 3.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-16:hover{
  --tw-translate-x: 4rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-20:hover{
  --tw-translate-x: 5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-24:hover{
  --tw-translate-x: 6rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-28:hover{
  --tw-translate-x: 7rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-32:hover{
  --tw-translate-x: 8rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-36:hover{
  --tw-translate-x: 9rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-40:hover{
  --tw-translate-x: 10rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-44:hover{
  --tw-translate-x: 11rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-48:hover{
  --tw-translate-x: 12rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-52:hover{
  --tw-translate-x: 13rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-56:hover{
  --tw-translate-x: 14rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-60:hover{
  --tw-translate-x: 15rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-64:hover{
  --tw-translate-x: 16rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-72:hover{
  --tw-translate-x: 18rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-80:hover{
  --tw-translate-x: 20rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-96:hover{
  --tw-translate-x: 24rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-px:hover{
  --tw-translate-x: 1px
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-0\.5:hover{
  --tw-translate-x: 0.125rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-1\.5:hover{
  --tw-translate-x: 0.375rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-2\.5:hover{
  --tw-translate-x: 0.625rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-3\.5:hover{
  --tw-translate-x: 0.875rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-0:hover{
  --tw-translate-x: 0px
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-1:hover{
  --tw-translate-x: -0.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-2:hover{
  --tw-translate-x: -0.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-3:hover{
  --tw-translate-x: -0.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-4:hover{
  --tw-translate-x: -1rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-5:hover{
  --tw-translate-x: -1.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-6:hover{
  --tw-translate-x: -1.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-7:hover{
  --tw-translate-x: -1.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-8:hover{
  --tw-translate-x: -2rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-9:hover{
  --tw-translate-x: -2.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-10:hover{
  --tw-translate-x: -2.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-11:hover{
  --tw-translate-x: -2.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-12:hover{
  --tw-translate-x: -3rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-14:hover{
  --tw-translate-x: -3.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-16:hover{
  --tw-translate-x: -4rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-20:hover{
  --tw-translate-x: -5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-24:hover{
  --tw-translate-x: -6rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-28:hover{
  --tw-translate-x: -7rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-32:hover{
  --tw-translate-x: -8rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-36:hover{
  --tw-translate-x: -9rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-40:hover{
  --tw-translate-x: -10rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-44:hover{
  --tw-translate-x: -11rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-48:hover{
  --tw-translate-x: -12rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-52:hover{
  --tw-translate-x: -13rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-56:hover{
  --tw-translate-x: -14rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-60:hover{
  --tw-translate-x: -15rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-64:hover{
  --tw-translate-x: -16rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-72:hover{
  --tw-translate-x: -18rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-80:hover{
  --tw-translate-x: -20rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-96:hover{
  --tw-translate-x: -24rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-px:hover{
  --tw-translate-x: -1px
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-0\.5:hover{
  --tw-translate-x: -0.125rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-1\.5:hover{
  --tw-translate-x: -0.375rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-2\.5:hover{
  --tw-translate-x: -0.625rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-3\.5:hover{
  --tw-translate-x: -0.875rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-1\/2:hover{
  --tw-translate-x: 50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-1\/3:hover{
  --tw-translate-x: 33.333333%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-2\/3:hover{
  --tw-translate-x: 66.666667%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-1\/4:hover{
  --tw-translate-x: 25%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-2\/4:hover{
  --tw-translate-x: 50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-3\/4:hover{
  --tw-translate-x: 75%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-x-full:hover{
  --tw-translate-x: 100%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-1\/2:hover{
  --tw-translate-x: -50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-1\/3:hover{
  --tw-translate-x: -33.333333%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-2\/3:hover{
  --tw-translate-x: -66.666667%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-1\/4:hover{
  --tw-translate-x: -25%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-2\/4:hover{
  --tw-translate-x: -50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-3\/4:hover{
  --tw-translate-x: -75%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-x-full:hover{
  --tw-translate-x: -100%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-0:hover{
  --tw-translate-y: 0px
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-1:hover{
  --tw-translate-y: 0.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-2:hover{
  --tw-translate-y: 0.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-3:hover{
  --tw-translate-y: 0.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-4:hover{
  --tw-translate-y: 1rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-5:hover{
  --tw-translate-y: 1.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-6:hover{
  --tw-translate-y: 1.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-7:hover{
  --tw-translate-y: 1.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-8:hover{
  --tw-translate-y: 2rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-9:hover{
  --tw-translate-y: 2.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-10:hover{
  --tw-translate-y: 2.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-11:hover{
  --tw-translate-y: 2.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-12:hover{
  --tw-translate-y: 3rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-14:hover{
  --tw-translate-y: 3.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-16:hover{
  --tw-translate-y: 4rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-20:hover{
  --tw-translate-y: 5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-24:hover{
  --tw-translate-y: 6rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-28:hover{
  --tw-translate-y: 7rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-32:hover{
  --tw-translate-y: 8rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-36:hover{
  --tw-translate-y: 9rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-40:hover{
  --tw-translate-y: 10rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-44:hover{
  --tw-translate-y: 11rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-48:hover{
  --tw-translate-y: 12rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-52:hover{
  --tw-translate-y: 13rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-56:hover{
  --tw-translate-y: 14rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-60:hover{
  --tw-translate-y: 15rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-64:hover{
  --tw-translate-y: 16rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-72:hover{
  --tw-translate-y: 18rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-80:hover{
  --tw-translate-y: 20rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-96:hover{
  --tw-translate-y: 24rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-px:hover{
  --tw-translate-y: 1px
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-0\.5:hover{
  --tw-translate-y: 0.125rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-1\.5:hover{
  --tw-translate-y: 0.375rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-2\.5:hover{
  --tw-translate-y: 0.625rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-3\.5:hover{
  --tw-translate-y: 0.875rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-0:hover{
  --tw-translate-y: 0px
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-1:hover{
  --tw-translate-y: -0.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-2:hover{
  --tw-translate-y: -0.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-3:hover{
  --tw-translate-y: -0.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-4:hover{
  --tw-translate-y: -1rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-5:hover{
  --tw-translate-y: -1.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-6:hover{
  --tw-translate-y: -1.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-7:hover{
  --tw-translate-y: -1.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-8:hover{
  --tw-translate-y: -2rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-9:hover{
  --tw-translate-y: -2.25rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-10:hover{
  --tw-translate-y: -2.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-11:hover{
  --tw-translate-y: -2.75rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-12:hover{
  --tw-translate-y: -3rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-14:hover{
  --tw-translate-y: -3.5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-16:hover{
  --tw-translate-y: -4rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-20:hover{
  --tw-translate-y: -5rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-24:hover{
  --tw-translate-y: -6rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-28:hover{
  --tw-translate-y: -7rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-32:hover{
  --tw-translate-y: -8rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-36:hover{
  --tw-translate-y: -9rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-40:hover{
  --tw-translate-y: -10rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-44:hover{
  --tw-translate-y: -11rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-48:hover{
  --tw-translate-y: -12rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-52:hover{
  --tw-translate-y: -13rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-56:hover{
  --tw-translate-y: -14rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-60:hover{
  --tw-translate-y: -15rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-64:hover{
  --tw-translate-y: -16rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-72:hover{
  --tw-translate-y: -18rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-80:hover{
  --tw-translate-y: -20rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-96:hover{
  --tw-translate-y: -24rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-px:hover{
  --tw-translate-y: -1px
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-0\.5:hover{
  --tw-translate-y: -0.125rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-1\.5:hover{
  --tw-translate-y: -0.375rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-2\.5:hover{
  --tw-translate-y: -0.625rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-3\.5:hover{
  --tw-translate-y: -0.875rem
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-1\/2:hover{
  --tw-translate-y: 50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-1\/3:hover{
  --tw-translate-y: 33.333333%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-2\/3:hover{
  --tw-translate-y: 66.666667%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-1\/4:hover{
  --tw-translate-y: 25%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-2\/4:hover{
  --tw-translate-y: 50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-3\/4:hover{
  --tw-translate-y: 75%
}

#skindrWidget.skindrWidget .hover\:skindr-widget-translate-y-full:hover{
  --tw-translate-y: 100%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-1\/2:hover{
  --tw-translate-y: -50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-1\/3:hover{
  --tw-translate-y: -33.333333%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-2\/3:hover{
  --tw-translate-y: -66.666667%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-1\/4:hover{
  --tw-translate-y: -25%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-2\/4:hover{
  --tw-translate-y: -50%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-3\/4:hover{
  --tw-translate-y: -75%
}

#skindrWidget.skindrWidget .hover\:skindr-widget--translate-y-full:hover{
  --tw-translate-y: -100%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-0:focus{
  --tw-translate-x: 0px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-1:focus{
  --tw-translate-x: 0.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-2:focus{
  --tw-translate-x: 0.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-3:focus{
  --tw-translate-x: 0.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-4:focus{
  --tw-translate-x: 1rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-5:focus{
  --tw-translate-x: 1.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-6:focus{
  --tw-translate-x: 1.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-7:focus{
  --tw-translate-x: 1.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-8:focus{
  --tw-translate-x: 2rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-9:focus{
  --tw-translate-x: 2.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-10:focus{
  --tw-translate-x: 2.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-11:focus{
  --tw-translate-x: 2.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-12:focus{
  --tw-translate-x: 3rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-14:focus{
  --tw-translate-x: 3.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-16:focus{
  --tw-translate-x: 4rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-20:focus{
  --tw-translate-x: 5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-24:focus{
  --tw-translate-x: 6rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-28:focus{
  --tw-translate-x: 7rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-32:focus{
  --tw-translate-x: 8rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-36:focus{
  --tw-translate-x: 9rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-40:focus{
  --tw-translate-x: 10rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-44:focus{
  --tw-translate-x: 11rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-48:focus{
  --tw-translate-x: 12rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-52:focus{
  --tw-translate-x: 13rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-56:focus{
  --tw-translate-x: 14rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-60:focus{
  --tw-translate-x: 15rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-64:focus{
  --tw-translate-x: 16rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-72:focus{
  --tw-translate-x: 18rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-80:focus{
  --tw-translate-x: 20rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-96:focus{
  --tw-translate-x: 24rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-px:focus{
  --tw-translate-x: 1px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-0\.5:focus{
  --tw-translate-x: 0.125rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-1\.5:focus{
  --tw-translate-x: 0.375rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-2\.5:focus{
  --tw-translate-x: 0.625rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-3\.5:focus{
  --tw-translate-x: 0.875rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-0:focus{
  --tw-translate-x: 0px
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-1:focus{
  --tw-translate-x: -0.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-2:focus{
  --tw-translate-x: -0.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-3:focus{
  --tw-translate-x: -0.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-4:focus{
  --tw-translate-x: -1rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-5:focus{
  --tw-translate-x: -1.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-6:focus{
  --tw-translate-x: -1.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-7:focus{
  --tw-translate-x: -1.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-8:focus{
  --tw-translate-x: -2rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-9:focus{
  --tw-translate-x: -2.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-10:focus{
  --tw-translate-x: -2.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-11:focus{
  --tw-translate-x: -2.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-12:focus{
  --tw-translate-x: -3rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-14:focus{
  --tw-translate-x: -3.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-16:focus{
  --tw-translate-x: -4rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-20:focus{
  --tw-translate-x: -5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-24:focus{
  --tw-translate-x: -6rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-28:focus{
  --tw-translate-x: -7rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-32:focus{
  --tw-translate-x: -8rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-36:focus{
  --tw-translate-x: -9rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-40:focus{
  --tw-translate-x: -10rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-44:focus{
  --tw-translate-x: -11rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-48:focus{
  --tw-translate-x: -12rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-52:focus{
  --tw-translate-x: -13rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-56:focus{
  --tw-translate-x: -14rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-60:focus{
  --tw-translate-x: -15rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-64:focus{
  --tw-translate-x: -16rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-72:focus{
  --tw-translate-x: -18rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-80:focus{
  --tw-translate-x: -20rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-96:focus{
  --tw-translate-x: -24rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-px:focus{
  --tw-translate-x: -1px
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-0\.5:focus{
  --tw-translate-x: -0.125rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-1\.5:focus{
  --tw-translate-x: -0.375rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-2\.5:focus{
  --tw-translate-x: -0.625rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-3\.5:focus{
  --tw-translate-x: -0.875rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-1\/2:focus{
  --tw-translate-x: 50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-1\/3:focus{
  --tw-translate-x: 33.333333%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-2\/3:focus{
  --tw-translate-x: 66.666667%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-1\/4:focus{
  --tw-translate-x: 25%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-2\/4:focus{
  --tw-translate-x: 50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-3\/4:focus{
  --tw-translate-x: 75%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-x-full:focus{
  --tw-translate-x: 100%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-1\/2:focus{
  --tw-translate-x: -50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-1\/3:focus{
  --tw-translate-x: -33.333333%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-2\/3:focus{
  --tw-translate-x: -66.666667%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-1\/4:focus{
  --tw-translate-x: -25%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-2\/4:focus{
  --tw-translate-x: -50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-3\/4:focus{
  --tw-translate-x: -75%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-x-full:focus{
  --tw-translate-x: -100%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-0:focus{
  --tw-translate-y: 0px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-1:focus{
  --tw-translate-y: 0.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-2:focus{
  --tw-translate-y: 0.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-3:focus{
  --tw-translate-y: 0.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-4:focus{
  --tw-translate-y: 1rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-5:focus{
  --tw-translate-y: 1.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-6:focus{
  --tw-translate-y: 1.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-7:focus{
  --tw-translate-y: 1.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-8:focus{
  --tw-translate-y: 2rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-9:focus{
  --tw-translate-y: 2.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-10:focus{
  --tw-translate-y: 2.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-11:focus{
  --tw-translate-y: 2.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-12:focus{
  --tw-translate-y: 3rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-14:focus{
  --tw-translate-y: 3.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-16:focus{
  --tw-translate-y: 4rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-20:focus{
  --tw-translate-y: 5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-24:focus{
  --tw-translate-y: 6rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-28:focus{
  --tw-translate-y: 7rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-32:focus{
  --tw-translate-y: 8rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-36:focus{
  --tw-translate-y: 9rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-40:focus{
  --tw-translate-y: 10rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-44:focus{
  --tw-translate-y: 11rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-48:focus{
  --tw-translate-y: 12rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-52:focus{
  --tw-translate-y: 13rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-56:focus{
  --tw-translate-y: 14rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-60:focus{
  --tw-translate-y: 15rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-64:focus{
  --tw-translate-y: 16rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-72:focus{
  --tw-translate-y: 18rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-80:focus{
  --tw-translate-y: 20rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-96:focus{
  --tw-translate-y: 24rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-px:focus{
  --tw-translate-y: 1px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-0\.5:focus{
  --tw-translate-y: 0.125rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-1\.5:focus{
  --tw-translate-y: 0.375rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-2\.5:focus{
  --tw-translate-y: 0.625rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-3\.5:focus{
  --tw-translate-y: 0.875rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-0:focus{
  --tw-translate-y: 0px
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-1:focus{
  --tw-translate-y: -0.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-2:focus{
  --tw-translate-y: -0.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-3:focus{
  --tw-translate-y: -0.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-4:focus{
  --tw-translate-y: -1rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-5:focus{
  --tw-translate-y: -1.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-6:focus{
  --tw-translate-y: -1.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-7:focus{
  --tw-translate-y: -1.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-8:focus{
  --tw-translate-y: -2rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-9:focus{
  --tw-translate-y: -2.25rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-10:focus{
  --tw-translate-y: -2.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-11:focus{
  --tw-translate-y: -2.75rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-12:focus{
  --tw-translate-y: -3rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-14:focus{
  --tw-translate-y: -3.5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-16:focus{
  --tw-translate-y: -4rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-20:focus{
  --tw-translate-y: -5rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-24:focus{
  --tw-translate-y: -6rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-28:focus{
  --tw-translate-y: -7rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-32:focus{
  --tw-translate-y: -8rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-36:focus{
  --tw-translate-y: -9rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-40:focus{
  --tw-translate-y: -10rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-44:focus{
  --tw-translate-y: -11rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-48:focus{
  --tw-translate-y: -12rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-52:focus{
  --tw-translate-y: -13rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-56:focus{
  --tw-translate-y: -14rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-60:focus{
  --tw-translate-y: -15rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-64:focus{
  --tw-translate-y: -16rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-72:focus{
  --tw-translate-y: -18rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-80:focus{
  --tw-translate-y: -20rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-96:focus{
  --tw-translate-y: -24rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-px:focus{
  --tw-translate-y: -1px
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-0\.5:focus{
  --tw-translate-y: -0.125rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-1\.5:focus{
  --tw-translate-y: -0.375rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-2\.5:focus{
  --tw-translate-y: -0.625rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-3\.5:focus{
  --tw-translate-y: -0.875rem
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-1\/2:focus{
  --tw-translate-y: 50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-1\/3:focus{
  --tw-translate-y: 33.333333%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-2\/3:focus{
  --tw-translate-y: 66.666667%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-1\/4:focus{
  --tw-translate-y: 25%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-2\/4:focus{
  --tw-translate-y: 50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-3\/4:focus{
  --tw-translate-y: 75%
}

#skindrWidget.skindrWidget .focus\:skindr-widget-translate-y-full:focus{
  --tw-translate-y: 100%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-1\/2:focus{
  --tw-translate-y: -50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-1\/3:focus{
  --tw-translate-y: -33.333333%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-2\/3:focus{
  --tw-translate-y: -66.666667%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-1\/4:focus{
  --tw-translate-y: -25%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-2\/4:focus{
  --tw-translate-y: -50%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-3\/4:focus{
  --tw-translate-y: -75%
}

#skindrWidget.skindrWidget .focus\:skindr-widget--translate-y-full:focus{
  --tw-translate-y: -100%
}

#skindrWidget.skindrWidget .skindr-widget-rotate-0{
  --tw-rotate: 0deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-1{
  --tw-rotate: 1deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-2{
  --tw-rotate: 2deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-3{
  --tw-rotate: 3deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-6{
  --tw-rotate: 6deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-12{
  --tw-rotate: 12deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-45{
  --tw-rotate: 45deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-90{
  --tw-rotate: 90deg
}

#skindrWidget.skindrWidget .skindr-widget-rotate-180{
  --tw-rotate: 180deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-180{
  --tw-rotate: -180deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-90{
  --tw-rotate: -90deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-45{
  --tw-rotate: -45deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-12{
  --tw-rotate: -12deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-6{
  --tw-rotate: -6deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-3{
  --tw-rotate: -3deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-2{
  --tw-rotate: -2deg
}

#skindrWidget.skindrWidget .skindr-widget--rotate-1{
  --tw-rotate: -1deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-0:hover{
  --tw-rotate: 0deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-1:hover{
  --tw-rotate: 1deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-2:hover{
  --tw-rotate: 2deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-3:hover{
  --tw-rotate: 3deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-6:hover{
  --tw-rotate: 6deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-12:hover{
  --tw-rotate: 12deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-45:hover{
  --tw-rotate: 45deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-90:hover{
  --tw-rotate: 90deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-rotate-180:hover{
  --tw-rotate: 180deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-180:hover{
  --tw-rotate: -180deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-90:hover{
  --tw-rotate: -90deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-45:hover{
  --tw-rotate: -45deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-12:hover{
  --tw-rotate: -12deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-6:hover{
  --tw-rotate: -6deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-3:hover{
  --tw-rotate: -3deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-2:hover{
  --tw-rotate: -2deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--rotate-1:hover{
  --tw-rotate: -1deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-0:focus{
  --tw-rotate: 0deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-1:focus{
  --tw-rotate: 1deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-2:focus{
  --tw-rotate: 2deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-3:focus{
  --tw-rotate: 3deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-6:focus{
  --tw-rotate: 6deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-12:focus{
  --tw-rotate: 12deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-45:focus{
  --tw-rotate: 45deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-90:focus{
  --tw-rotate: 90deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-rotate-180:focus{
  --tw-rotate: 180deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-180:focus{
  --tw-rotate: -180deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-90:focus{
  --tw-rotate: -90deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-45:focus{
  --tw-rotate: -45deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-12:focus{
  --tw-rotate: -12deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-6:focus{
  --tw-rotate: -6deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-3:focus{
  --tw-rotate: -3deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-2:focus{
  --tw-rotate: -2deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--rotate-1:focus{
  --tw-rotate: -1deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-x-0{
  --tw-skew-x: 0deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-x-1{
  --tw-skew-x: 1deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-x-2{
  --tw-skew-x: 2deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-x-3{
  --tw-skew-x: 3deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-x-6{
  --tw-skew-x: 6deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-x-12{
  --tw-skew-x: 12deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-x-12{
  --tw-skew-x: -12deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-x-6{
  --tw-skew-x: -6deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-x-3{
  --tw-skew-x: -3deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-x-2{
  --tw-skew-x: -2deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-x-1{
  --tw-skew-x: -1deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-y-0{
  --tw-skew-y: 0deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-y-1{
  --tw-skew-y: 1deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-y-2{
  --tw-skew-y: 2deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-y-3{
  --tw-skew-y: 3deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-y-6{
  --tw-skew-y: 6deg
}

#skindrWidget.skindrWidget .skindr-widget-skew-y-12{
  --tw-skew-y: 12deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-y-12{
  --tw-skew-y: -12deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-y-6{
  --tw-skew-y: -6deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-y-3{
  --tw-skew-y: -3deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-y-2{
  --tw-skew-y: -2deg
}

#skindrWidget.skindrWidget .skindr-widget--skew-y-1{
  --tw-skew-y: -1deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-x-0:hover{
  --tw-skew-x: 0deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-x-1:hover{
  --tw-skew-x: 1deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-x-2:hover{
  --tw-skew-x: 2deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-x-3:hover{
  --tw-skew-x: 3deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-x-6:hover{
  --tw-skew-x: 6deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-x-12:hover{
  --tw-skew-x: 12deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-x-12:hover{
  --tw-skew-x: -12deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-x-6:hover{
  --tw-skew-x: -6deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-x-3:hover{
  --tw-skew-x: -3deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-x-2:hover{
  --tw-skew-x: -2deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-x-1:hover{
  --tw-skew-x: -1deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-y-0:hover{
  --tw-skew-y: 0deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-y-1:hover{
  --tw-skew-y: 1deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-y-2:hover{
  --tw-skew-y: 2deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-y-3:hover{
  --tw-skew-y: 3deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-y-6:hover{
  --tw-skew-y: 6deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget-skew-y-12:hover{
  --tw-skew-y: 12deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-y-12:hover{
  --tw-skew-y: -12deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-y-6:hover{
  --tw-skew-y: -6deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-y-3:hover{
  --tw-skew-y: -3deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-y-2:hover{
  --tw-skew-y: -2deg
}

#skindrWidget.skindrWidget .hover\:skindr-widget--skew-y-1:hover{
  --tw-skew-y: -1deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-x-0:focus{
  --tw-skew-x: 0deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-x-1:focus{
  --tw-skew-x: 1deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-x-2:focus{
  --tw-skew-x: 2deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-x-3:focus{
  --tw-skew-x: 3deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-x-6:focus{
  --tw-skew-x: 6deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-x-12:focus{
  --tw-skew-x: 12deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-x-12:focus{
  --tw-skew-x: -12deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-x-6:focus{
  --tw-skew-x: -6deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-x-3:focus{
  --tw-skew-x: -3deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-x-2:focus{
  --tw-skew-x: -2deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-x-1:focus{
  --tw-skew-x: -1deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-y-0:focus{
  --tw-skew-y: 0deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-y-1:focus{
  --tw-skew-y: 1deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-y-2:focus{
  --tw-skew-y: 2deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-y-3:focus{
  --tw-skew-y: 3deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-y-6:focus{
  --tw-skew-y: 6deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget-skew-y-12:focus{
  --tw-skew-y: 12deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-y-12:focus{
  --tw-skew-y: -12deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-y-6:focus{
  --tw-skew-y: -6deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-y-3:focus{
  --tw-skew-y: -3deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-y-2:focus{
  --tw-skew-y: -2deg
}

#skindrWidget.skindrWidget .focus\:skindr-widget--skew-y-1:focus{
  --tw-skew-y: -1deg
}

#skindrWidget.skindrWidget .skindr-widget-scale-0{
  --tw-scale-x: 0;
  --tw-scale-y: 0
}

#skindrWidget.skindrWidget .skindr-widget-scale-50{
  --tw-scale-x: .5;
  --tw-scale-y: .5
}

#skindrWidget.skindrWidget .skindr-widget-scale-75{
  --tw-scale-x: .75;
  --tw-scale-y: .75
}

#skindrWidget.skindrWidget .skindr-widget-scale-90{
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

#skindrWidget.skindrWidget .skindr-widget-scale-95{
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

#skindrWidget.skindrWidget .skindr-widget-scale-100{
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

#skindrWidget.skindrWidget .skindr-widget-scale-105{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

#skindrWidget.skindrWidget .skindr-widget-scale-110{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

#skindrWidget.skindrWidget .skindr-widget-scale-125{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25
}

#skindrWidget.skindrWidget .skindr-widget-scale-150{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-0:hover{
  --tw-scale-x: 0;
  --tw-scale-y: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-50:hover{
  --tw-scale-x: .5;
  --tw-scale-y: .5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-75:hover{
  --tw-scale-x: .75;
  --tw-scale-y: .75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-90:hover{
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-95:hover{
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-100:hover{
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-105:hover{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-110:hover{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-125:hover{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-150:hover{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-0:focus{
  --tw-scale-x: 0;
  --tw-scale-y: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-50:focus{
  --tw-scale-x: .5;
  --tw-scale-y: .5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-75:focus{
  --tw-scale-x: .75;
  --tw-scale-y: .75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-90:focus{
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-95:focus{
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-100:focus{
  --tw-scale-x: 1;
  --tw-scale-y: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-105:focus{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-110:focus{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-125:focus{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-150:focus{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-0{
  --tw-scale-x: 0
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-50{
  --tw-scale-x: .5
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-75{
  --tw-scale-x: .75
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-90{
  --tw-scale-x: .9
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-95{
  --tw-scale-x: .95
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-100{
  --tw-scale-x: 1
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-105{
  --tw-scale-x: 1.05
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-110{
  --tw-scale-x: 1.1
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-125{
  --tw-scale-x: 1.25
}

#skindrWidget.skindrWidget .skindr-widget-scale-x-150{
  --tw-scale-x: 1.5
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-0{
  --tw-scale-y: 0
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-50{
  --tw-scale-y: .5
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-75{
  --tw-scale-y: .75
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-90{
  --tw-scale-y: .9
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-95{
  --tw-scale-y: .95
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-100{
  --tw-scale-y: 1
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-105{
  --tw-scale-y: 1.05
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-110{
  --tw-scale-y: 1.1
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-125{
  --tw-scale-y: 1.25
}

#skindrWidget.skindrWidget .skindr-widget-scale-y-150{
  --tw-scale-y: 1.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-0:hover{
  --tw-scale-x: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-50:hover{
  --tw-scale-x: .5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-75:hover{
  --tw-scale-x: .75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-90:hover{
  --tw-scale-x: .9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-95:hover{
  --tw-scale-x: .95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-100:hover{
  --tw-scale-x: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-105:hover{
  --tw-scale-x: 1.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-110:hover{
  --tw-scale-x: 1.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-125:hover{
  --tw-scale-x: 1.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-x-150:hover{
  --tw-scale-x: 1.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-0:hover{
  --tw-scale-y: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-50:hover{
  --tw-scale-y: .5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-75:hover{
  --tw-scale-y: .75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-90:hover{
  --tw-scale-y: .9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-95:hover{
  --tw-scale-y: .95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-100:hover{
  --tw-scale-y: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-105:hover{
  --tw-scale-y: 1.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-110:hover{
  --tw-scale-y: 1.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-125:hover{
  --tw-scale-y: 1.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-scale-y-150:hover{
  --tw-scale-y: 1.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-0:focus{
  --tw-scale-x: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-50:focus{
  --tw-scale-x: .5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-75:focus{
  --tw-scale-x: .75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-90:focus{
  --tw-scale-x: .9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-95:focus{
  --tw-scale-x: .95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-100:focus{
  --tw-scale-x: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-105:focus{
  --tw-scale-x: 1.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-110:focus{
  --tw-scale-x: 1.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-125:focus{
  --tw-scale-x: 1.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-x-150:focus{
  --tw-scale-x: 1.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-0:focus{
  --tw-scale-y: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-50:focus{
  --tw-scale-y: .5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-75:focus{
  --tw-scale-y: .75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-90:focus{
  --tw-scale-y: .9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-95:focus{
  --tw-scale-y: .95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-100:focus{
  --tw-scale-y: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-105:focus{
  --tw-scale-y: 1.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-110:focus{
  --tw-scale-y: 1.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-125:focus{
  --tw-scale-y: 1.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-scale-y-150:focus{
  --tw-scale-y: 1.5
}

@keyframes skindr-widget-spin{
  to{
    transform: rotate(360deg)
  }
}

@keyframes skindr-widget-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0
  }
}

@keyframes skindr-widget-pulse{
  50%{
    opacity: .5
  }
}

@keyframes skindr-widget-bounce{
  0%, 100%{
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

#skindrWidget.skindrWidget .skindr-widget-animate-none{
  animation: none
}

#skindrWidget.skindrWidget .skindr-widget-animate-spin{
  animation: skindr-widget-spin 1s linear infinite
}

#skindrWidget.skindrWidget .skindr-widget-animate-ping{
  animation: skindr-widget-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

#skindrWidget.skindrWidget .skindr-widget-animate-pulse{
  animation: skindr-widget-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

#skindrWidget.skindrWidget .skindr-widget-animate-bounce{
  animation: skindr-widget-bounce 1s infinite
}

#skindrWidget.skindrWidget .skindr-widget-cursor-auto{
  cursor: auto
}

#skindrWidget.skindrWidget .skindr-widget-cursor-default{
  cursor: default
}

#skindrWidget.skindrWidget .skindr-widget-cursor-pointer{
  cursor: pointer
}

#skindrWidget.skindrWidget .skindr-widget-cursor-wait{
  cursor: wait
}

#skindrWidget.skindrWidget .skindr-widget-cursor-text{
  cursor: text
}

#skindrWidget.skindrWidget .skindr-widget-cursor-move{
  cursor: move
}

#skindrWidget.skindrWidget .skindr-widget-cursor-help{
  cursor: help
}

#skindrWidget.skindrWidget .skindr-widget-cursor-not-allowed{
  cursor: not-allowed
}

#skindrWidget.skindrWidget .skindr-widget-select-none{
  -webkit-user-select: none;
          user-select: none
}

#skindrWidget.skindrWidget .skindr-widget-select-text{
  -webkit-user-select: text;
          user-select: text
}

#skindrWidget.skindrWidget .skindr-widget-select-all{
  -webkit-user-select: all;
          user-select: all
}

#skindrWidget.skindrWidget .skindr-widget-select-auto{
  -webkit-user-select: auto;
          user-select: auto
}

#skindrWidget.skindrWidget .skindr-widget-resize-none{
  resize: none
}

#skindrWidget.skindrWidget .skindr-widget-resize-y{
  resize: vertical
}

#skindrWidget.skindrWidget .skindr-widget-resize-x{
  resize: horizontal
}

#skindrWidget.skindrWidget .skindr-widget-resize{
  resize: both
}

#skindrWidget.skindrWidget .skindr-widget-list-inside{
  list-style-position: inside
}

#skindrWidget.skindrWidget .skindr-widget-list-outside{
  list-style-position: outside
}

#skindrWidget.skindrWidget .skindr-widget-list-none{
  list-style-type: none
}

#skindrWidget.skindrWidget .skindr-widget-list-disc{
  list-style-type: disc
}

#skindrWidget.skindrWidget .skindr-widget-list-decimal{
  list-style-type: decimal
}

#skindrWidget.skindrWidget .skindr-widget-appearance-none{
  -webkit-appearance: none;
          appearance: none
}

#skindrWidget.skindrWidget .skindr-widget-auto-cols-auto{
  grid-auto-columns: auto
}

#skindrWidget.skindrWidget .skindr-widget-auto-cols-min{
  grid-auto-columns: min-content
}

#skindrWidget.skindrWidget .skindr-widget-auto-cols-max{
  grid-auto-columns: max-content
}

#skindrWidget.skindrWidget .skindr-widget-auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr)
}

#skindrWidget.skindrWidget .skindr-widget-grid-flow-row{
  grid-auto-flow: row
}

#skindrWidget.skindrWidget .skindr-widget-grid-flow-col{
  grid-auto-flow: column
}

#skindrWidget.skindrWidget .skindr-widget-grid-flow-row-dense{
  grid-auto-flow: row dense
}

#skindrWidget.skindrWidget .skindr-widget-grid-flow-col-dense{
  grid-auto-flow: column dense
}

#skindrWidget.skindrWidget .skindr-widget-auto-rows-auto{
  grid-auto-rows: auto
}

#skindrWidget.skindrWidget .skindr-widget-auto-rows-min{
  grid-auto-rows: min-content
}

#skindrWidget.skindrWidget .skindr-widget-auto-rows-max{
  grid-auto-rows: max-content
}

#skindrWidget.skindrWidget .skindr-widget-auto-rows-fr{
  grid-auto-rows: minmax(0, 1fr)
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-7{
  grid-template-columns: repeat(7, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-8{
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-9{
  grid-template-columns: repeat(9, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-cols-none{
  grid-template-columns: none
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-2{
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-4{
  grid-template-rows: repeat(4, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-5{
  grid-template-rows: repeat(5, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-6{
  grid-template-rows: repeat(6, minmax(0, 1fr))
}

#skindrWidget.skindrWidget .skindr-widget-grid-rows-none{
  grid-template-rows: none
}

#skindrWidget.skindrWidget .skindr-widget-flex-row{
  flex-direction: row
}

#skindrWidget.skindrWidget .skindr-widget-flex-row-reverse{
  flex-direction: row-reverse
}

#skindrWidget.skindrWidget .skindr-widget-flex-col{
  flex-direction: column
}

#skindrWidget.skindrWidget .skindr-widget-flex-col-reverse{
  flex-direction: column-reverse
}

#skindrWidget.skindrWidget .skindr-widget-flex-wrap{
  flex-wrap: wrap
}

#skindrWidget.skindrWidget .skindr-widget-flex-wrap-reverse{
  flex-wrap: wrap-reverse
}

#skindrWidget.skindrWidget .skindr-widget-flex-nowrap{
  flex-wrap: nowrap
}

#skindrWidget.skindrWidget .skindr-widget-place-content-center{
  place-content: center
}

#skindrWidget.skindrWidget .skindr-widget-place-content-start{
  place-content: start
}

#skindrWidget.skindrWidget .skindr-widget-place-content-end{
  place-content: end
}

#skindrWidget.skindrWidget .skindr-widget-place-content-between{
  place-content: space-between
}

#skindrWidget.skindrWidget .skindr-widget-place-content-around{
  place-content: space-around
}

#skindrWidget.skindrWidget .skindr-widget-place-content-evenly{
  place-content: space-evenly
}

#skindrWidget.skindrWidget .skindr-widget-place-content-stretch{
  place-content: stretch
}

#skindrWidget.skindrWidget .skindr-widget-place-items-start{
  place-items: start
}

#skindrWidget.skindrWidget .skindr-widget-place-items-end{
  place-items: end
}

#skindrWidget.skindrWidget .skindr-widget-place-items-center{
  place-items: center
}

#skindrWidget.skindrWidget .skindr-widget-place-items-stretch{
  place-items: stretch
}

#skindrWidget.skindrWidget .skindr-widget-content-center{
  align-content: center
}

#skindrWidget.skindrWidget .skindr-widget-content-start{
  align-content: flex-start
}

#skindrWidget.skindrWidget .skindr-widget-content-end{
  align-content: flex-end
}

#skindrWidget.skindrWidget .skindr-widget-content-between{
  align-content: space-between
}

#skindrWidget.skindrWidget .skindr-widget-content-around{
  align-content: space-around
}

#skindrWidget.skindrWidget .skindr-widget-content-evenly{
  align-content: space-evenly
}

#skindrWidget.skindrWidget .skindr-widget-items-start{
  align-items: flex-start
}

#skindrWidget.skindrWidget .skindr-widget-items-end{
  align-items: flex-end
}

#skindrWidget.skindrWidget .skindr-widget-items-center{
  align-items: center
}

#skindrWidget.skindrWidget .skindr-widget-items-baseline{
  align-items: baseline
}

#skindrWidget.skindrWidget .skindr-widget-items-stretch{
  align-items: stretch
}

#skindrWidget.skindrWidget .skindr-widget-justify-start{
  justify-content: flex-start
}

#skindrWidget.skindrWidget .skindr-widget-justify-end{
  justify-content: flex-end
}

#skindrWidget.skindrWidget .skindr-widget-justify-center{
  justify-content: center
}

#skindrWidget.skindrWidget .skindr-widget-justify-between{
  justify-content: space-between
}

#skindrWidget.skindrWidget .skindr-widget-justify-around{
  justify-content: space-around
}

#skindrWidget.skindrWidget .skindr-widget-justify-evenly{
  justify-content: space-evenly
}

#skindrWidget.skindrWidget .skindr-widget-justify-items-start{
  justify-items: start
}

#skindrWidget.skindrWidget .skindr-widget-justify-items-end{
  justify-items: end
}

#skindrWidget.skindrWidget .skindr-widget-justify-items-center{
  justify-items: center
}

#skindrWidget.skindrWidget .skindr-widget-justify-items-stretch{
  justify-items: stretch
}

#skindrWidget.skindrWidget .skindr-widget-gap-0{
  gap: 0px
}

#skindrWidget.skindrWidget .skindr-widget-gap-1{
  gap: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-2{
  gap: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-3{
  gap: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-4{
  gap: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-5{
  gap: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-6{
  gap: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-7{
  gap: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-8{
  gap: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-9{
  gap: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-10{
  gap: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-11{
  gap: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-12{
  gap: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-14{
  gap: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-16{
  gap: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-20{
  gap: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-24{
  gap: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-28{
  gap: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-32{
  gap: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-36{
  gap: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-40{
  gap: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-44{
  gap: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-48{
  gap: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-52{
  gap: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-56{
  gap: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-60{
  gap: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-64{
  gap: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-72{
  gap: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-80{
  gap: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-96{
  gap: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-px{
  gap: 1px
}

#skindrWidget.skindrWidget .skindr-widget-gap-0\.5{
  gap: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-1\.5{
  gap: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-2\.5{
  gap: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-3\.5{
  gap: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-0{
  column-gap: 0px
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-1{
  column-gap: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-2{
  column-gap: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-3{
  column-gap: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-4{
  column-gap: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-5{
  column-gap: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-6{
  column-gap: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-7{
  column-gap: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-8{
  column-gap: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-9{
  column-gap: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-10{
  column-gap: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-11{
  column-gap: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-12{
  column-gap: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-14{
  column-gap: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-16{
  column-gap: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-20{
  column-gap: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-24{
  column-gap: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-28{
  column-gap: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-32{
  column-gap: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-36{
  column-gap: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-40{
  column-gap: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-44{
  column-gap: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-48{
  column-gap: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-52{
  column-gap: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-56{
  column-gap: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-60{
  column-gap: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-64{
  column-gap: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-72{
  column-gap: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-80{
  column-gap: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-96{
  column-gap: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-px{
  column-gap: 1px
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-0\.5{
  column-gap: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-1\.5{
  column-gap: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-2\.5{
  column-gap: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-x-3\.5{
  column-gap: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-0{
  row-gap: 0px
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-1{
  row-gap: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-2{
  row-gap: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-3{
  row-gap: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-4{
  row-gap: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-5{
  row-gap: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-6{
  row-gap: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-7{
  row-gap: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-8{
  row-gap: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-9{
  row-gap: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-10{
  row-gap: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-11{
  row-gap: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-12{
  row-gap: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-14{
  row-gap: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-16{
  row-gap: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-20{
  row-gap: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-24{
  row-gap: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-28{
  row-gap: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-32{
  row-gap: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-36{
  row-gap: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-40{
  row-gap: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-44{
  row-gap: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-48{
  row-gap: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-52{
  row-gap: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-56{
  row-gap: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-60{
  row-gap: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-64{
  row-gap: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-72{
  row-gap: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-80{
  row-gap: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-96{
  row-gap: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-px{
  row-gap: 1px
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-0\.5{
  row-gap: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-1\.5{
  row-gap: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-2\.5{
  row-gap: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-gap-y-3\.5{
  row-gap: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-space-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 1
}

#skindrWidget.skindrWidget .skindr-widget-space-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 1
}

#skindrWidget.skindrWidget .skindr-widget-divide-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-divide-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-divide-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-divide-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-divide-x > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

#skindrWidget.skindrWidget .skindr-widget-divide-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-divide-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-divide-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-divide-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

#skindrWidget.skindrWidget .skindr-widget-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 1
}

#skindrWidget.skindrWidget .skindr-widget-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 1
}

#skindrWidget.skindrWidget .skindr-widget-divide-solid > :not([hidden]) ~ :not([hidden]){
  border-style: solid
}

#skindrWidget.skindrWidget .skindr-widget-divide-dashed > :not([hidden]) ~ :not([hidden]){
  border-style: dashed
}

#skindrWidget.skindrWidget .skindr-widget-divide-dotted > :not([hidden]) ~ :not([hidden]){
  border-style: dotted
}

#skindrWidget.skindrWidget .skindr-widget-divide-double > :not([hidden]) ~ :not([hidden]){
  border-style: double
}

#skindrWidget.skindrWidget .skindr-widget-divide-none > :not([hidden]) ~ :not([hidden]){
  border-style: none
}

#skindrWidget.skindrWidget .skindr-widget-divide-transparent > :not([hidden]) ~ :not([hidden]){
  border-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-divide-current > :not([hidden]) ~ :not([hidden]){
  border-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-divide-black > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-white > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-red-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-green-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-place-self-auto{
  place-self: auto
}

#skindrWidget.skindrWidget .skindr-widget-place-self-start{
  place-self: start
}

#skindrWidget.skindrWidget .skindr-widget-place-self-end{
  place-self: end
}

#skindrWidget.skindrWidget .skindr-widget-place-self-center{
  place-self: center
}

#skindrWidget.skindrWidget .skindr-widget-place-self-stretch{
  place-self: stretch
}

#skindrWidget.skindrWidget .skindr-widget-self-auto{
  align-self: auto
}

#skindrWidget.skindrWidget .skindr-widget-self-start{
  align-self: flex-start
}

#skindrWidget.skindrWidget .skindr-widget-self-end{
  align-self: flex-end
}

#skindrWidget.skindrWidget .skindr-widget-self-center{
  align-self: center
}

#skindrWidget.skindrWidget .skindr-widget-self-stretch{
  align-self: stretch
}

#skindrWidget.skindrWidget .skindr-widget-self-baseline{
  align-self: baseline
}

#skindrWidget.skindrWidget .skindr-widget-justify-self-auto{
  justify-self: auto
}

#skindrWidget.skindrWidget .skindr-widget-justify-self-start{
  justify-self: start
}

#skindrWidget.skindrWidget .skindr-widget-justify-self-end{
  justify-self: end
}

#skindrWidget.skindrWidget .skindr-widget-justify-self-center{
  justify-self: center
}

#skindrWidget.skindrWidget .skindr-widget-justify-self-stretch{
  justify-self: stretch
}

#skindrWidget.skindrWidget .skindr-widget-overflow-auto{
  overflow: auto
}

#skindrWidget.skindrWidget .skindr-widget-overflow-hidden{
  overflow: hidden
}

#skindrWidget.skindrWidget .skindr-widget-overflow-visible{
  overflow: visible
}

#skindrWidget.skindrWidget .skindr-widget-overflow-scroll{
  overflow: scroll
}

#skindrWidget.skindrWidget .skindr-widget-overflow-x-auto{
  overflow-x: auto
}

#skindrWidget.skindrWidget .skindr-widget-overflow-y-auto{
  overflow-y: auto
}

#skindrWidget.skindrWidget .skindr-widget-overflow-x-hidden{
  overflow-x: hidden
}

#skindrWidget.skindrWidget .skindr-widget-overflow-y-hidden{
  overflow-y: hidden
}

#skindrWidget.skindrWidget .skindr-widget-overflow-x-visible{
  overflow-x: visible
}

#skindrWidget.skindrWidget .skindr-widget-overflow-y-visible{
  overflow-y: visible
}

#skindrWidget.skindrWidget .skindr-widget-overflow-x-scroll{
  overflow-x: scroll
}

#skindrWidget.skindrWidget .skindr-widget-overflow-y-scroll{
  overflow-y: scroll
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-auto{
  overscroll-behavior: auto
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-contain{
  overscroll-behavior: contain
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-none{
  overscroll-behavior: none
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-y-auto{
  overscroll-behavior-y: auto
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-y-contain{
  overscroll-behavior-y: contain
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-y-none{
  overscroll-behavior-y: none
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-x-auto{
  overscroll-behavior-x: auto
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-x-contain{
  overscroll-behavior-x: contain
}

#skindrWidget.skindrWidget .skindr-widget-overscroll-x-none{
  overscroll-behavior-x: none
}

#skindrWidget.skindrWidget .skindr-widget-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

#skindrWidget.skindrWidget .skindr-widget-overflow-ellipsis{
  text-overflow: ellipsis
}

#skindrWidget.skindrWidget .skindr-widget-overflow-clip{
  text-overflow: clip
}

#skindrWidget.skindrWidget .skindr-widget-whitespace-normal{
  white-space: normal
}

#skindrWidget.skindrWidget .skindr-widget-whitespace-nowrap{
  white-space: nowrap
}

#skindrWidget.skindrWidget .skindr-widget-whitespace-pre{
  white-space: pre
}

#skindrWidget.skindrWidget .skindr-widget-whitespace-pre-line{
  white-space: pre-line
}

#skindrWidget.skindrWidget .skindr-widget-whitespace-pre-wrap{
  white-space: pre-wrap
}

#skindrWidget.skindrWidget .skindr-widget-break-normal{
  overflow-wrap: normal;
  word-break: normal
}

#skindrWidget.skindrWidget .skindr-widget-break-words{
  overflow-wrap: break-word
}

#skindrWidget.skindrWidget .skindr-widget-break-all{
  word-break: break-all
}

#skindrWidget.skindrWidget .skindr-widget-rounded-none{
  border-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-sm{
  border-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded{
  border-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-md{
  border-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-lg{
  border-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-xl{
  border-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-2xl{
  border-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-3xl{
  border-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-full{
  border-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-none{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-sm{
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-md{
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-lg{
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-xl{
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-2xl{
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-3xl{
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-t-full{
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-none{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-sm{
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-md{
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-lg{
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-xl{
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-2xl{
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-3xl{
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-r-full{
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-none{
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-sm{
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-md{
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-lg{
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-xl{
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-2xl{
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-3xl{
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-b-full{
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-none{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-sm{
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l{
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-md{
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-lg{
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-xl{
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-2xl{
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-3xl{
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-l-full{
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-none{
  border-top-left-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-sm{
  border-top-left-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl{
  border-top-left-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-md{
  border-top-left-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-lg{
  border-top-left-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-xl{
  border-top-left-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-2xl{
  border-top-left-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-3xl{
  border-top-left-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tl-full{
  border-top-left-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-none{
  border-top-right-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-sm{
  border-top-right-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr{
  border-top-right-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-md{
  border-top-right-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-lg{
  border-top-right-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-xl{
  border-top-right-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-2xl{
  border-top-right-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-3xl{
  border-top-right-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-tr-full{
  border-top-right-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-none{
  border-bottom-right-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-sm{
  border-bottom-right-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br{
  border-bottom-right-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-md{
  border-bottom-right-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-lg{
  border-bottom-right-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-xl{
  border-bottom-right-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-2xl{
  border-bottom-right-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-3xl{
  border-bottom-right-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-br-full{
  border-bottom-right-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-none{
  border-bottom-left-radius: 0px
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-sm{
  border-bottom-left-radius: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl{
  border-bottom-left-radius: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-md{
  border-bottom-left-radius: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-lg{
  border-bottom-left-radius: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-xl{
  border-bottom-left-radius: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-2xl{
  border-bottom-left-radius: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-3xl{
  border-bottom-left-radius: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-rounded-bl-full{
  border-bottom-left-radius: 9999px
}

#skindrWidget.skindrWidget .skindr-widget-border-0{
  border-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-border-2{
  border-width: 2px
}

#skindrWidget.skindrWidget .skindr-widget-border-4{
  border-width: 4px
}

#skindrWidget.skindrWidget .skindr-widget-border-8{
  border-width: 8px
}

#skindrWidget.skindrWidget .skindr-widget-border{
  border-width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-border-t-0{
  border-top-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-border-t-2{
  border-top-width: 2px
}

#skindrWidget.skindrWidget .skindr-widget-border-t-4{
  border-top-width: 4px
}

#skindrWidget.skindrWidget .skindr-widget-border-t-8{
  border-top-width: 8px
}

#skindrWidget.skindrWidget .skindr-widget-border-t{
  border-top-width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-border-r-0{
  border-right-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-border-r-2{
  border-right-width: 2px
}

#skindrWidget.skindrWidget .skindr-widget-border-r-4{
  border-right-width: 4px
}

#skindrWidget.skindrWidget .skindr-widget-border-r-8{
  border-right-width: 8px
}

#skindrWidget.skindrWidget .skindr-widget-border-r{
  border-right-width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-border-b-0{
  border-bottom-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-border-b-2{
  border-bottom-width: 2px
}

#skindrWidget.skindrWidget .skindr-widget-border-b-4{
  border-bottom-width: 4px
}

#skindrWidget.skindrWidget .skindr-widget-border-b-8{
  border-bottom-width: 8px
}

#skindrWidget.skindrWidget .skindr-widget-border-b{
  border-bottom-width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-border-l-0{
  border-left-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-border-l-2{
  border-left-width: 2px
}

#skindrWidget.skindrWidget .skindr-widget-border-l-4{
  border-left-width: 4px
}

#skindrWidget.skindrWidget .skindr-widget-border-l-8{
  border-left-width: 8px
}

#skindrWidget.skindrWidget .skindr-widget-border-l{
  border-left-width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-border-solid{
  border-style: solid
}

#skindrWidget.skindrWidget .skindr-widget-border-dashed{
  border-style: dashed
}

#skindrWidget.skindrWidget .skindr-widget-border-dotted{
  border-style: dotted
}

#skindrWidget.skindrWidget .skindr-widget-border-double{
  border-style: double
}

#skindrWidget.skindrWidget .skindr-widget-border-none{
  border-style: none
}

#skindrWidget.skindrWidget .skindr-widget-border-transparent{
  border-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-border-current{
  border-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-border-black{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-white{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-50{
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-100{
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-200{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-400{
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-500{
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-600{
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-700{
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-800{
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-gray-900{
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-50{
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-100{
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-200{
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-300{
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-400{
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-500{
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-600{
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-700{
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-800{
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-red-900{
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-50{
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-100{
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-200{
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-300{
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-400{
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-500{
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-600{
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-700{
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-800{
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-yellow-900{
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-50{
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-100{
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-200{
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-300{
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-400{
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-500{
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-600{
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-700{
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-800{
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-green-900{
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-50{
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-100{
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-200{
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-300{
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-400{
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-500{
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-600{
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-700{
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-800{
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-blue-900{
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-50{
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-100{
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-200{
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-300{
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-400{
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-500{
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-600{
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-700{
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-800{
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-indigo-900{
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-50{
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-100{
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-200{
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-300{
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-400{
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-500{
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-600{
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-700{
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-800{
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-purple-900{
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-50{
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-100{
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-200{
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-300{
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-400{
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-500{
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-600{
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-700{
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-800{
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-pink-900{
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-transparent{
  border-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-current{
  border-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-black{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-white{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-50{
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-100{
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-200{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-400{
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-500{
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-600{
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-700{
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-800{
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-gray-900{
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-50{
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-100{
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-200{
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-300{
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-400{
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-500{
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-600{
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-700{
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-800{
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-red-900{
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-50{
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-100{
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-200{
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-300{
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-400{
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-500{
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-600{
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-700{
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-800{
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-yellow-900{
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-50{
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-100{
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-200{
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-300{
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-400{
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-500{
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-600{
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-700{
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-800{
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-green-900{
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-50{
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-100{
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-200{
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-300{
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-400{
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-500{
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-600{
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-700{
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-800{
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-blue-900{
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-50{
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-100{
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-200{
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-300{
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-400{
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-500{
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-600{
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-700{
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-800{
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-indigo-900{
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-50{
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-100{
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-200{
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-300{
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-400{
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-500{
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-600{
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-700{
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-800{
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-purple-900{
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-50{
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-100{
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-200{
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-300{
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-400{
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-500{
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-600{
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-700{
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-800{
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-pink-900{
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-transparent:focus-within{
  border-color: transparent
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-current:focus-within{
  border-color: currentColor
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-black:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-white:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-gray-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-red-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-yellow-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-green-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-blue-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-indigo-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-purple-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-50:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-100:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-200:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-300:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-400:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-500:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-600:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-700:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-800:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-pink-900:focus-within{
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-transparent:hover{
  border-color: transparent
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-current:hover{
  border-color: currentColor
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-black:hover{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-white:hover{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-gray-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-red-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-yellow-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-green-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-blue-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-indigo-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-purple-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-50:hover{
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-100:hover{
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-200:hover{
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-300:hover{
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-400:hover{
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-500:hover{
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-600:hover{
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-700:hover{
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-800:hover{
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-pink-900:hover{
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-transparent:focus{
  border-color: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-current:focus{
  border-color: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-black:focus{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-white:focus{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-gray-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-red-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-yellow-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-green-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-blue-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-indigo-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-purple-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-50:focus{
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-100:focus{
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-200:focus{
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-300:focus{
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-400:focus{
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-500:focus{
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-600:focus{
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-700:focus{
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-800:focus{
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-pink-900:focus{
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-0{
  --tw-border-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-5{
  --tw-border-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-10{
  --tw-border-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-20{
  --tw-border-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-25{
  --tw-border-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-30{
  --tw-border-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-40{
  --tw-border-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-50{
  --tw-border-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-60{
  --tw-border-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-70{
  --tw-border-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-75{
  --tw-border-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-80{
  --tw-border-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-90{
  --tw-border-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-95{
  --tw-border-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-border-opacity-100{
  --tw-border-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-0{
  --tw-border-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-5{
  --tw-border-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-10{
  --tw-border-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-20{
  --tw-border-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-25{
  --tw-border-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-30{
  --tw-border-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-40{
  --tw-border-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-50{
  --tw-border-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-60{
  --tw-border-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-70{
  --tw-border-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-75{
  --tw-border-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-80{
  --tw-border-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-90{
  --tw-border-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-95{
  --tw-border-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-border-opacity-100{
  --tw-border-opacity: 1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-0:focus-within{
  --tw-border-opacity: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-5:focus-within{
  --tw-border-opacity: 0.05
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-10:focus-within{
  --tw-border-opacity: 0.1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-20:focus-within{
  --tw-border-opacity: 0.2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-25:focus-within{
  --tw-border-opacity: 0.25
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-30:focus-within{
  --tw-border-opacity: 0.3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-40:focus-within{
  --tw-border-opacity: 0.4
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-50:focus-within{
  --tw-border-opacity: 0.5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-60:focus-within{
  --tw-border-opacity: 0.6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-70:focus-within{
  --tw-border-opacity: 0.7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-75:focus-within{
  --tw-border-opacity: 0.75
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-80:focus-within{
  --tw-border-opacity: 0.8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-90:focus-within{
  --tw-border-opacity: 0.9
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-95:focus-within{
  --tw-border-opacity: 0.95
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-border-opacity-100:focus-within{
  --tw-border-opacity: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-0:hover{
  --tw-border-opacity: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-5:hover{
  --tw-border-opacity: 0.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-10:hover{
  --tw-border-opacity: 0.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-20:hover{
  --tw-border-opacity: 0.2
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-25:hover{
  --tw-border-opacity: 0.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-30:hover{
  --tw-border-opacity: 0.3
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-40:hover{
  --tw-border-opacity: 0.4
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-50:hover{
  --tw-border-opacity: 0.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-60:hover{
  --tw-border-opacity: 0.6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-70:hover{
  --tw-border-opacity: 0.7
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-75:hover{
  --tw-border-opacity: 0.75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-80:hover{
  --tw-border-opacity: 0.8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-90:hover{
  --tw-border-opacity: 0.9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-95:hover{
  --tw-border-opacity: 0.95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-border-opacity-100:hover{
  --tw-border-opacity: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-0:focus{
  --tw-border-opacity: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-5:focus{
  --tw-border-opacity: 0.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-10:focus{
  --tw-border-opacity: 0.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-20:focus{
  --tw-border-opacity: 0.2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-25:focus{
  --tw-border-opacity: 0.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-30:focus{
  --tw-border-opacity: 0.3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-40:focus{
  --tw-border-opacity: 0.4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-50:focus{
  --tw-border-opacity: 0.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-60:focus{
  --tw-border-opacity: 0.6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-70:focus{
  --tw-border-opacity: 0.7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-75:focus{
  --tw-border-opacity: 0.75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-80:focus{
  --tw-border-opacity: 0.8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-90:focus{
  --tw-border-opacity: 0.9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-95:focus{
  --tw-border-opacity: 0.95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-border-opacity-100:focus{
  --tw-border-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-bg-transparent{
  background-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-bg-current{
  background-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-bg-black{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-white{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-50{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-200{
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-400{
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-500{
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-600{
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-700{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-800{
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gray-900{
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-50{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-100{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-200{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-300{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-400{
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-500{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-600{
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-700{
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-800{
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-red-900{
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-50{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-100{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-200{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-300{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-400{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-500{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-600{
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-700{
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-800{
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-yellow-900{
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-50{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-100{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-200{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-300{
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-400{
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-500{
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-600{
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-700{
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-800{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-green-900{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-50{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-100{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-200{
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-300{
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-400{
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-500{
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-600{
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-700{
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-800{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-blue-900{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-50{
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-100{
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-200{
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-300{
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-400{
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-500{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-600{
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-700{
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-800{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-indigo-900{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-50{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-100{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-200{
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-300{
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-400{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-500{
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-600{
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-700{
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-800{
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-purple-900{
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-50{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-100{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-200{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-300{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-400{
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-500{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-600{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-700{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-800{
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-pink-900{
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-transparent{
  background-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-current{
  background-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-black{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-white{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-50{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-200{
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-400{
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-500{
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-600{
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-700{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-800{
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-gray-900{
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-50{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-100{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-200{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-300{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-400{
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-500{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-600{
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-700{
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-800{
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-red-900{
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-50{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-100{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-200{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-300{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-400{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-500{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-600{
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-700{
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-800{
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-yellow-900{
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-50{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-100{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-200{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-300{
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-400{
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-500{
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-600{
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-700{
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-800{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-green-900{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-50{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-100{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-200{
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-300{
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-400{
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-500{
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-600{
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-700{
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-800{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-blue-900{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-50{
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-100{
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-200{
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-300{
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-400{
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-500{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-600{
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-700{
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-800{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-indigo-900{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-50{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-100{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-200{
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-300{
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-400{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-500{
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-600{
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-700{
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-800{
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-purple-900{
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-50{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-100{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-200{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-300{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-400{
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-500{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-600{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-700{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-800{
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-pink-900{
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-transparent:focus-within{
  background-color: transparent
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-current:focus-within{
  background-color: currentColor
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-black:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-white:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-gray-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-red-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-yellow-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-green-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-blue-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-indigo-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-purple-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-50:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-100:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-200:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-300:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-400:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-500:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-600:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-700:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-800:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-pink-900:focus-within{
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-transparent:hover{
  background-color: transparent
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-current:hover{
  background-color: currentColor
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-black:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-white:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-gray-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-red-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-yellow-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-green-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-blue-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-indigo-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-purple-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-300:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-500:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-600:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-800:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-pink-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-transparent:focus{
  background-color: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-current:focus{
  background-color: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-black:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-white:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-gray-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-red-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-yellow-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-green-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-blue-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-indigo-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-purple-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-50:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-100:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-200:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-300:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-400:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-500:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-600:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-700:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-800:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-pink-900:focus{
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-0{
  --tw-bg-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-5{
  --tw-bg-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-10{
  --tw-bg-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-20{
  --tw-bg-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-25{
  --tw-bg-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-30{
  --tw-bg-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-40{
  --tw-bg-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-50{
  --tw-bg-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-60{
  --tw-bg-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-70{
  --tw-bg-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-75{
  --tw-bg-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-80{
  --tw-bg-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-90{
  --tw-bg-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-95{
  --tw-bg-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-bg-opacity-100{
  --tw-bg-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-0{
  --tw-bg-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-5{
  --tw-bg-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-10{
  --tw-bg-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-20{
  --tw-bg-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-25{
  --tw-bg-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-30{
  --tw-bg-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-40{
  --tw-bg-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-50{
  --tw-bg-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-60{
  --tw-bg-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-70{
  --tw-bg-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-75{
  --tw-bg-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-80{
  --tw-bg-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-90{
  --tw-bg-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-95{
  --tw-bg-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-bg-opacity-100{
  --tw-bg-opacity: 1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-0:focus-within{
  --tw-bg-opacity: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-5:focus-within{
  --tw-bg-opacity: 0.05
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-10:focus-within{
  --tw-bg-opacity: 0.1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-20:focus-within{
  --tw-bg-opacity: 0.2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-25:focus-within{
  --tw-bg-opacity: 0.25
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-30:focus-within{
  --tw-bg-opacity: 0.3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-40:focus-within{
  --tw-bg-opacity: 0.4
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-50:focus-within{
  --tw-bg-opacity: 0.5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-60:focus-within{
  --tw-bg-opacity: 0.6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-70:focus-within{
  --tw-bg-opacity: 0.7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-75:focus-within{
  --tw-bg-opacity: 0.75
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-80:focus-within{
  --tw-bg-opacity: 0.8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-90:focus-within{
  --tw-bg-opacity: 0.9
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-95:focus-within{
  --tw-bg-opacity: 0.95
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-bg-opacity-100:focus-within{
  --tw-bg-opacity: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-0:hover{
  --tw-bg-opacity: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-5:hover{
  --tw-bg-opacity: 0.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-10:hover{
  --tw-bg-opacity: 0.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-20:hover{
  --tw-bg-opacity: 0.2
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-25:hover{
  --tw-bg-opacity: 0.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-30:hover{
  --tw-bg-opacity: 0.3
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-40:hover{
  --tw-bg-opacity: 0.4
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-50:hover{
  --tw-bg-opacity: 0.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-60:hover{
  --tw-bg-opacity: 0.6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-70:hover{
  --tw-bg-opacity: 0.7
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-75:hover{
  --tw-bg-opacity: 0.75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-80:hover{
  --tw-bg-opacity: 0.8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-90:hover{
  --tw-bg-opacity: 0.9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-95:hover{
  --tw-bg-opacity: 0.95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-bg-opacity-100:hover{
  --tw-bg-opacity: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-0:focus{
  --tw-bg-opacity: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-5:focus{
  --tw-bg-opacity: 0.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-10:focus{
  --tw-bg-opacity: 0.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-20:focus{
  --tw-bg-opacity: 0.2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-25:focus{
  --tw-bg-opacity: 0.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-30:focus{
  --tw-bg-opacity: 0.3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-40:focus{
  --tw-bg-opacity: 0.4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-50:focus{
  --tw-bg-opacity: 0.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-60:focus{
  --tw-bg-opacity: 0.6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-70:focus{
  --tw-bg-opacity: 0.7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-75:focus{
  --tw-bg-opacity: 0.75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-80:focus{
  --tw-bg-opacity: 0.8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-90:focus{
  --tw-bg-opacity: 0.9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-95:focus{
  --tw-bg-opacity: 0.95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-bg-opacity-100:focus{
  --tw-bg-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-bg-none{
  background-image: none
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-t{
  background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-tr{
  background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-b{
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-bl{
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-l{
  background-image: linear-gradient(to left, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-bg-gradient-to-tl{
  background-image: linear-gradient(to top left, var(--tw-gradient-stops))
}

#skindrWidget.skindrWidget .skindr-widget-from-transparent{
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-current{
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-black{
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-white{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-50{
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-100{
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-200{
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-300{
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-400{
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-500{
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-600{
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-700{
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-800{
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-gray-900{
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-50{
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-100{
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-200{
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-300{
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-400{
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-500{
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-600{
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-700{
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-800{
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-red-900{
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-50{
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-100{
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-200{
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-300{
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-400{
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-500{
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-600{
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-700{
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-800{
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-yellow-900{
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-50{
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-100{
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-200{
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-300{
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-400{
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-500{
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-600{
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-700{
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-800{
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-green-900{
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-50{
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-100{
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-200{
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-300{
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-400{
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-500{
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-600{
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-700{
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-800{
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-blue-900{
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-50{
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-100{
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-200{
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-300{
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-400{
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-500{
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-600{
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-700{
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-800{
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-indigo-900{
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-50{
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-100{
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-200{
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-300{
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-400{
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-500{
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-600{
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-700{
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-800{
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-purple-900{
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-50{
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-100{
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-200{
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-300{
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-400{
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-500{
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-600{
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-700{
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-800{
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

#skindrWidget.skindrWidget .skindr-widget-from-pink-900{
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-transparent:hover{
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-current:hover{
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-black:hover{
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-white:hover{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-50:hover{
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-100:hover{
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-200:hover{
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-300:hover{
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-400:hover{
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-500:hover{
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-600:hover{
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-700:hover{
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-800:hover{
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-gray-900:hover{
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-50:hover{
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-100:hover{
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-200:hover{
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-300:hover{
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-400:hover{
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-500:hover{
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-600:hover{
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-700:hover{
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-800:hover{
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-red-900:hover{
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-50:hover{
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-100:hover{
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-200:hover{
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-300:hover{
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-400:hover{
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-500:hover{
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-600:hover{
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-700:hover{
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-800:hover{
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-yellow-900:hover{
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-50:hover{
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-100:hover{
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-200:hover{
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-300:hover{
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-400:hover{
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-500:hover{
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-600:hover{
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-700:hover{
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-800:hover{
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-green-900:hover{
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-50:hover{
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-100:hover{
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-200:hover{
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-300:hover{
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-400:hover{
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-500:hover{
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-600:hover{
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-700:hover{
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-800:hover{
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-blue-900:hover{
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-50:hover{
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-100:hover{
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-200:hover{
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-300:hover{
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-400:hover{
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-500:hover{
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-600:hover{
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-700:hover{
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-800:hover{
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-indigo-900:hover{
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-50:hover{
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-100:hover{
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-200:hover{
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-300:hover{
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-400:hover{
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-500:hover{
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-600:hover{
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-700:hover{
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-800:hover{
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-purple-900:hover{
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-50:hover{
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-100:hover{
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-200:hover{
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-300:hover{
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-400:hover{
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-500:hover{
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-600:hover{
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-700:hover{
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-800:hover{
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-from-pink-900:hover{
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-transparent:focus{
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-current:focus{
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-black:focus{
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-white:focus{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-50:focus{
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-100:focus{
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-200:focus{
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-300:focus{
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-400:focus{
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-500:focus{
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-600:focus{
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-700:focus{
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-800:focus{
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-gray-900:focus{
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-50:focus{
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-100:focus{
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-200:focus{
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-300:focus{
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-400:focus{
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-500:focus{
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-600:focus{
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-700:focus{
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-800:focus{
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-red-900:focus{
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-50:focus{
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-100:focus{
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-200:focus{
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-300:focus{
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-400:focus{
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-500:focus{
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-600:focus{
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-700:focus{
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-800:focus{
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-yellow-900:focus{
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-50:focus{
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-100:focus{
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-200:focus{
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-300:focus{
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-400:focus{
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-500:focus{
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-600:focus{
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-700:focus{
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-800:focus{
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-green-900:focus{
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-50:focus{
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-100:focus{
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-200:focus{
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-300:focus{
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-400:focus{
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-500:focus{
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-600:focus{
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-700:focus{
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-800:focus{
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-blue-900:focus{
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-50:focus{
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-100:focus{
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-200:focus{
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-300:focus{
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-400:focus{
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-500:focus{
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-600:focus{
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-700:focus{
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-800:focus{
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-indigo-900:focus{
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-50:focus{
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-100:focus{
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-200:focus{
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-300:focus{
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-400:focus{
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-500:focus{
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-600:focus{
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-700:focus{
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-800:focus{
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-purple-900:focus{
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-50:focus{
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-100:focus{
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-200:focus{
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-300:focus{
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-400:focus{
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-500:focus{
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-600:focus{
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-700:focus{
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-800:focus{
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-from-pink-900:focus{
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-transparent{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-current{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-black{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-white{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-50{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-100{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-200{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-300{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-400{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-500{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-600{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-700{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-800{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-gray-900{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-300{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-400{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-500{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-600{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-700{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-800{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-red-900{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-300{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-400{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-500{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-600{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-700{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-800{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-yellow-900{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-50{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-100{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-200{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-300{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-400{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-500{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-600{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-700{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-800{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-green-900{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-50{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-100{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-200{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-300{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-400{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-500{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-600{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-700{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-800{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-blue-900{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-50{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-100{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-200{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-300{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-400{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-500{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-600{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-700{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-800{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-indigo-900{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-50{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-100{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-200{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-300{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-400{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-500{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-600{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-700{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-800{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-purple-900{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-300{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-400{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-500{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-600{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-700{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-800{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

#skindrWidget.skindrWidget .skindr-widget-via-pink-900{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-transparent:hover{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-current:hover{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-black:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-white:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-gray-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-red-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-yellow-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-green-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-blue-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-indigo-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-purple-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-via-pink-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-transparent:focus{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-current:focus{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-black:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-white:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-gray-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-red-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-yellow-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-green-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-blue-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-indigo-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-purple-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-via-pink-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
}

#skindrWidget.skindrWidget .skindr-widget-to-transparent{
  --tw-gradient-to: transparent
}

#skindrWidget.skindrWidget .skindr-widget-to-current{
  --tw-gradient-to: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-to-black{
  --tw-gradient-to: #000
}

#skindrWidget.skindrWidget .skindr-widget-to-white{
  --tw-gradient-to: #fff
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-50{
  --tw-gradient-to: #f9fafb
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-100{
  --tw-gradient-to: #f3f4f6
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-200{
  --tw-gradient-to: #e5e7eb
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-300{
  --tw-gradient-to: #d1d5db
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-400{
  --tw-gradient-to: #9ca3af
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-500{
  --tw-gradient-to: #6b7280
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-600{
  --tw-gradient-to: #4b5563
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-700{
  --tw-gradient-to: #374151
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-800{
  --tw-gradient-to: #1f2937
}

#skindrWidget.skindrWidget .skindr-widget-to-gray-900{
  --tw-gradient-to: #111827
}

#skindrWidget.skindrWidget .skindr-widget-to-red-50{
  --tw-gradient-to: #fef2f2
}

#skindrWidget.skindrWidget .skindr-widget-to-red-100{
  --tw-gradient-to: #fee2e2
}

#skindrWidget.skindrWidget .skindr-widget-to-red-200{
  --tw-gradient-to: #fecaca
}

#skindrWidget.skindrWidget .skindr-widget-to-red-300{
  --tw-gradient-to: #fca5a5
}

#skindrWidget.skindrWidget .skindr-widget-to-red-400{
  --tw-gradient-to: #f87171
}

#skindrWidget.skindrWidget .skindr-widget-to-red-500{
  --tw-gradient-to: #ef4444
}

#skindrWidget.skindrWidget .skindr-widget-to-red-600{
  --tw-gradient-to: #dc2626
}

#skindrWidget.skindrWidget .skindr-widget-to-red-700{
  --tw-gradient-to: #b91c1c
}

#skindrWidget.skindrWidget .skindr-widget-to-red-800{
  --tw-gradient-to: #991b1b
}

#skindrWidget.skindrWidget .skindr-widget-to-red-900{
  --tw-gradient-to: #7f1d1d
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-50{
  --tw-gradient-to: #fffbeb
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-100{
  --tw-gradient-to: #fef3c7
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-200{
  --tw-gradient-to: #fde68a
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-300{
  --tw-gradient-to: #fcd34d
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-400{
  --tw-gradient-to: #fbbf24
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-500{
  --tw-gradient-to: #f59e0b
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-600{
  --tw-gradient-to: #d97706
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-700{
  --tw-gradient-to: #b45309
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-800{
  --tw-gradient-to: #92400e
}

#skindrWidget.skindrWidget .skindr-widget-to-yellow-900{
  --tw-gradient-to: #78350f
}

#skindrWidget.skindrWidget .skindr-widget-to-green-50{
  --tw-gradient-to: #ecfdf5
}

#skindrWidget.skindrWidget .skindr-widget-to-green-100{
  --tw-gradient-to: #d1fae5
}

#skindrWidget.skindrWidget .skindr-widget-to-green-200{
  --tw-gradient-to: #a7f3d0
}

#skindrWidget.skindrWidget .skindr-widget-to-green-300{
  --tw-gradient-to: #6ee7b7
}

#skindrWidget.skindrWidget .skindr-widget-to-green-400{
  --tw-gradient-to: #34d399
}

#skindrWidget.skindrWidget .skindr-widget-to-green-500{
  --tw-gradient-to: #10b981
}

#skindrWidget.skindrWidget .skindr-widget-to-green-600{
  --tw-gradient-to: #059669
}

#skindrWidget.skindrWidget .skindr-widget-to-green-700{
  --tw-gradient-to: #047857
}

#skindrWidget.skindrWidget .skindr-widget-to-green-800{
  --tw-gradient-to: #065f46
}

#skindrWidget.skindrWidget .skindr-widget-to-green-900{
  --tw-gradient-to: #064e3b
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-50{
  --tw-gradient-to: #eff6ff
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-100{
  --tw-gradient-to: #dbeafe
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-200{
  --tw-gradient-to: #bfdbfe
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-300{
  --tw-gradient-to: #93c5fd
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-400{
  --tw-gradient-to: #60a5fa
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-500{
  --tw-gradient-to: #3b82f6
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-600{
  --tw-gradient-to: #2563eb
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-700{
  --tw-gradient-to: #1d4ed8
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-800{
  --tw-gradient-to: #1e40af
}

#skindrWidget.skindrWidget .skindr-widget-to-blue-900{
  --tw-gradient-to: #1e3a8a
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-50{
  --tw-gradient-to: #eef2ff
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-100{
  --tw-gradient-to: #e0e7ff
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-200{
  --tw-gradient-to: #c7d2fe
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-300{
  --tw-gradient-to: #a5b4fc
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-400{
  --tw-gradient-to: #818cf8
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-500{
  --tw-gradient-to: #6366f1
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-600{
  --tw-gradient-to: #4f46e5
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-700{
  --tw-gradient-to: #4338ca
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-800{
  --tw-gradient-to: #3730a3
}

#skindrWidget.skindrWidget .skindr-widget-to-indigo-900{
  --tw-gradient-to: #312e81
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-50{
  --tw-gradient-to: #f5f3ff
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-100{
  --tw-gradient-to: #ede9fe
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-200{
  --tw-gradient-to: #ddd6fe
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-300{
  --tw-gradient-to: #c4b5fd
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-400{
  --tw-gradient-to: #a78bfa
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-500{
  --tw-gradient-to: #8b5cf6
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-600{
  --tw-gradient-to: #7c3aed
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-700{
  --tw-gradient-to: #6d28d9
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-800{
  --tw-gradient-to: #5b21b6
}

#skindrWidget.skindrWidget .skindr-widget-to-purple-900{
  --tw-gradient-to: #4c1d95
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-50{
  --tw-gradient-to: #fdf2f8
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-100{
  --tw-gradient-to: #fce7f3
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-200{
  --tw-gradient-to: #fbcfe8
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-300{
  --tw-gradient-to: #f9a8d4
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-400{
  --tw-gradient-to: #f472b6
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-500{
  --tw-gradient-to: #ec4899
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-600{
  --tw-gradient-to: #db2777
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-700{
  --tw-gradient-to: #be185d
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-800{
  --tw-gradient-to: #9d174d
}

#skindrWidget.skindrWidget .skindr-widget-to-pink-900{
  --tw-gradient-to: #831843
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-transparent:hover{
  --tw-gradient-to: transparent
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-current:hover{
  --tw-gradient-to: currentColor
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-black:hover{
  --tw-gradient-to: #000
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-white:hover{
  --tw-gradient-to: #fff
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-50:hover{
  --tw-gradient-to: #f9fafb
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-100:hover{
  --tw-gradient-to: #f3f4f6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-200:hover{
  --tw-gradient-to: #e5e7eb
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-300:hover{
  --tw-gradient-to: #d1d5db
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-400:hover{
  --tw-gradient-to: #9ca3af
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-500:hover{
  --tw-gradient-to: #6b7280
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-600:hover{
  --tw-gradient-to: #4b5563
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-700:hover{
  --tw-gradient-to: #374151
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-800:hover{
  --tw-gradient-to: #1f2937
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-gray-900:hover{
  --tw-gradient-to: #111827
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-50:hover{
  --tw-gradient-to: #fef2f2
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-100:hover{
  --tw-gradient-to: #fee2e2
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-200:hover{
  --tw-gradient-to: #fecaca
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-300:hover{
  --tw-gradient-to: #fca5a5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-400:hover{
  --tw-gradient-to: #f87171
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-500:hover{
  --tw-gradient-to: #ef4444
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-600:hover{
  --tw-gradient-to: #dc2626
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-700:hover{
  --tw-gradient-to: #b91c1c
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-800:hover{
  --tw-gradient-to: #991b1b
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-red-900:hover{
  --tw-gradient-to: #7f1d1d
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-50:hover{
  --tw-gradient-to: #fffbeb
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-100:hover{
  --tw-gradient-to: #fef3c7
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-200:hover{
  --tw-gradient-to: #fde68a
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-300:hover{
  --tw-gradient-to: #fcd34d
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-400:hover{
  --tw-gradient-to: #fbbf24
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-500:hover{
  --tw-gradient-to: #f59e0b
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-600:hover{
  --tw-gradient-to: #d97706
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-700:hover{
  --tw-gradient-to: #b45309
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-800:hover{
  --tw-gradient-to: #92400e
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-yellow-900:hover{
  --tw-gradient-to: #78350f
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-50:hover{
  --tw-gradient-to: #ecfdf5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-100:hover{
  --tw-gradient-to: #d1fae5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-200:hover{
  --tw-gradient-to: #a7f3d0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-300:hover{
  --tw-gradient-to: #6ee7b7
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-400:hover{
  --tw-gradient-to: #34d399
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-500:hover{
  --tw-gradient-to: #10b981
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-600:hover{
  --tw-gradient-to: #059669
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-700:hover{
  --tw-gradient-to: #047857
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-800:hover{
  --tw-gradient-to: #065f46
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-green-900:hover{
  --tw-gradient-to: #064e3b
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-50:hover{
  --tw-gradient-to: #eff6ff
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-100:hover{
  --tw-gradient-to: #dbeafe
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-200:hover{
  --tw-gradient-to: #bfdbfe
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-300:hover{
  --tw-gradient-to: #93c5fd
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-400:hover{
  --tw-gradient-to: #60a5fa
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-500:hover{
  --tw-gradient-to: #3b82f6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-600:hover{
  --tw-gradient-to: #2563eb
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-700:hover{
  --tw-gradient-to: #1d4ed8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-800:hover{
  --tw-gradient-to: #1e40af
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-blue-900:hover{
  --tw-gradient-to: #1e3a8a
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-50:hover{
  --tw-gradient-to: #eef2ff
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-100:hover{
  --tw-gradient-to: #e0e7ff
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-200:hover{
  --tw-gradient-to: #c7d2fe
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-300:hover{
  --tw-gradient-to: #a5b4fc
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-400:hover{
  --tw-gradient-to: #818cf8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-500:hover{
  --tw-gradient-to: #6366f1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-600:hover{
  --tw-gradient-to: #4f46e5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-700:hover{
  --tw-gradient-to: #4338ca
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-800:hover{
  --tw-gradient-to: #3730a3
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-indigo-900:hover{
  --tw-gradient-to: #312e81
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-50:hover{
  --tw-gradient-to: #f5f3ff
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-100:hover{
  --tw-gradient-to: #ede9fe
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-200:hover{
  --tw-gradient-to: #ddd6fe
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-300:hover{
  --tw-gradient-to: #c4b5fd
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-400:hover{
  --tw-gradient-to: #a78bfa
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-500:hover{
  --tw-gradient-to: #8b5cf6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-600:hover{
  --tw-gradient-to: #7c3aed
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-700:hover{
  --tw-gradient-to: #6d28d9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-800:hover{
  --tw-gradient-to: #5b21b6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-purple-900:hover{
  --tw-gradient-to: #4c1d95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-50:hover{
  --tw-gradient-to: #fdf2f8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-100:hover{
  --tw-gradient-to: #fce7f3
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-200:hover{
  --tw-gradient-to: #fbcfe8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-300:hover{
  --tw-gradient-to: #f9a8d4
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-400:hover{
  --tw-gradient-to: #f472b6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-500:hover{
  --tw-gradient-to: #ec4899
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-600:hover{
  --tw-gradient-to: #db2777
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-700:hover{
  --tw-gradient-to: #be185d
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-800:hover{
  --tw-gradient-to: #9d174d
}

#skindrWidget.skindrWidget .hover\:skindr-widget-to-pink-900:hover{
  --tw-gradient-to: #831843
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-transparent:focus{
  --tw-gradient-to: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-current:focus{
  --tw-gradient-to: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-black:focus{
  --tw-gradient-to: #000
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-white:focus{
  --tw-gradient-to: #fff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-50:focus{
  --tw-gradient-to: #f9fafb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-100:focus{
  --tw-gradient-to: #f3f4f6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-200:focus{
  --tw-gradient-to: #e5e7eb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-300:focus{
  --tw-gradient-to: #d1d5db
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-400:focus{
  --tw-gradient-to: #9ca3af
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-500:focus{
  --tw-gradient-to: #6b7280
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-600:focus{
  --tw-gradient-to: #4b5563
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-700:focus{
  --tw-gradient-to: #374151
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-800:focus{
  --tw-gradient-to: #1f2937
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-gray-900:focus{
  --tw-gradient-to: #111827
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-50:focus{
  --tw-gradient-to: #fef2f2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-100:focus{
  --tw-gradient-to: #fee2e2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-200:focus{
  --tw-gradient-to: #fecaca
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-300:focus{
  --tw-gradient-to: #fca5a5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-400:focus{
  --tw-gradient-to: #f87171
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-500:focus{
  --tw-gradient-to: #ef4444
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-600:focus{
  --tw-gradient-to: #dc2626
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-700:focus{
  --tw-gradient-to: #b91c1c
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-800:focus{
  --tw-gradient-to: #991b1b
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-red-900:focus{
  --tw-gradient-to: #7f1d1d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-50:focus{
  --tw-gradient-to: #fffbeb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-100:focus{
  --tw-gradient-to: #fef3c7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-200:focus{
  --tw-gradient-to: #fde68a
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-300:focus{
  --tw-gradient-to: #fcd34d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-400:focus{
  --tw-gradient-to: #fbbf24
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-500:focus{
  --tw-gradient-to: #f59e0b
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-600:focus{
  --tw-gradient-to: #d97706
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-700:focus{
  --tw-gradient-to: #b45309
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-800:focus{
  --tw-gradient-to: #92400e
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-yellow-900:focus{
  --tw-gradient-to: #78350f
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-50:focus{
  --tw-gradient-to: #ecfdf5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-100:focus{
  --tw-gradient-to: #d1fae5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-200:focus{
  --tw-gradient-to: #a7f3d0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-300:focus{
  --tw-gradient-to: #6ee7b7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-400:focus{
  --tw-gradient-to: #34d399
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-500:focus{
  --tw-gradient-to: #10b981
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-600:focus{
  --tw-gradient-to: #059669
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-700:focus{
  --tw-gradient-to: #047857
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-800:focus{
  --tw-gradient-to: #065f46
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-green-900:focus{
  --tw-gradient-to: #064e3b
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-50:focus{
  --tw-gradient-to: #eff6ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-100:focus{
  --tw-gradient-to: #dbeafe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-200:focus{
  --tw-gradient-to: #bfdbfe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-300:focus{
  --tw-gradient-to: #93c5fd
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-400:focus{
  --tw-gradient-to: #60a5fa
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-500:focus{
  --tw-gradient-to: #3b82f6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-600:focus{
  --tw-gradient-to: #2563eb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-700:focus{
  --tw-gradient-to: #1d4ed8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-800:focus{
  --tw-gradient-to: #1e40af
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-blue-900:focus{
  --tw-gradient-to: #1e3a8a
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-50:focus{
  --tw-gradient-to: #eef2ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-100:focus{
  --tw-gradient-to: #e0e7ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-200:focus{
  --tw-gradient-to: #c7d2fe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-300:focus{
  --tw-gradient-to: #a5b4fc
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-400:focus{
  --tw-gradient-to: #818cf8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-500:focus{
  --tw-gradient-to: #6366f1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-600:focus{
  --tw-gradient-to: #4f46e5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-700:focus{
  --tw-gradient-to: #4338ca
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-800:focus{
  --tw-gradient-to: #3730a3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-indigo-900:focus{
  --tw-gradient-to: #312e81
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-50:focus{
  --tw-gradient-to: #f5f3ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-100:focus{
  --tw-gradient-to: #ede9fe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-200:focus{
  --tw-gradient-to: #ddd6fe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-300:focus{
  --tw-gradient-to: #c4b5fd
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-400:focus{
  --tw-gradient-to: #a78bfa
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-500:focus{
  --tw-gradient-to: #8b5cf6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-600:focus{
  --tw-gradient-to: #7c3aed
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-700:focus{
  --tw-gradient-to: #6d28d9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-800:focus{
  --tw-gradient-to: #5b21b6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-purple-900:focus{
  --tw-gradient-to: #4c1d95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-50:focus{
  --tw-gradient-to: #fdf2f8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-100:focus{
  --tw-gradient-to: #fce7f3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-200:focus{
  --tw-gradient-to: #fbcfe8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-300:focus{
  --tw-gradient-to: #f9a8d4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-400:focus{
  --tw-gradient-to: #f472b6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-500:focus{
  --tw-gradient-to: #ec4899
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-600:focus{
  --tw-gradient-to: #db2777
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-700:focus{
  --tw-gradient-to: #be185d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-800:focus{
  --tw-gradient-to: #9d174d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-to-pink-900:focus{
  --tw-gradient-to: #831843
}

#skindrWidget.skindrWidget .skindr-widget-decoration-slice{
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice
}

#skindrWidget.skindrWidget .skindr-widget-decoration-clone{
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone
}

#skindrWidget.skindrWidget .skindr-widget-bg-auto{
  background-size: auto
}

#skindrWidget.skindrWidget .skindr-widget-bg-cover{
  background-size: cover
}

#skindrWidget.skindrWidget .skindr-widget-bg-contain{
  background-size: contain
}

#skindrWidget.skindrWidget .skindr-widget-bg-fixed{
  background-attachment: fixed
}

#skindrWidget.skindrWidget .skindr-widget-bg-local{
  background-attachment: local
}

#skindrWidget.skindrWidget .skindr-widget-bg-scroll{
  background-attachment: scroll
}

#skindrWidget.skindrWidget .skindr-widget-bg-clip-border{
  background-clip: border-box
}

#skindrWidget.skindrWidget .skindr-widget-bg-clip-padding{
  background-clip: padding-box
}

#skindrWidget.skindrWidget .skindr-widget-bg-clip-content{
  background-clip: content-box
}

#skindrWidget.skindrWidget .skindr-widget-bg-clip-text{
  -webkit-background-clip: text;
          background-clip: text
}

#skindrWidget.skindrWidget .skindr-widget-bg-bottom{
  background-position: bottom
}

#skindrWidget.skindrWidget .skindr-widget-bg-center{
  background-position: center
}

#skindrWidget.skindrWidget .skindr-widget-bg-left{
  background-position: left
}

#skindrWidget.skindrWidget .skindr-widget-bg-left-bottom{
  background-position: left bottom
}

#skindrWidget.skindrWidget .skindr-widget-bg-left-top{
  background-position: left top
}

#skindrWidget.skindrWidget .skindr-widget-bg-right{
  background-position: right
}

#skindrWidget.skindrWidget .skindr-widget-bg-right-bottom{
  background-position: right bottom
}

#skindrWidget.skindrWidget .skindr-widget-bg-right-top{
  background-position: right top
}

#skindrWidget.skindrWidget .skindr-widget-bg-top{
  background-position: top
}

#skindrWidget.skindrWidget .skindr-widget-bg-repeat{
  background-repeat: repeat
}

#skindrWidget.skindrWidget .skindr-widget-bg-no-repeat{
  background-repeat: no-repeat
}

#skindrWidget.skindrWidget .skindr-widget-bg-repeat-x{
  background-repeat: repeat-x
}

#skindrWidget.skindrWidget .skindr-widget-bg-repeat-y{
  background-repeat: repeat-y
}

#skindrWidget.skindrWidget .skindr-widget-bg-repeat-round{
  background-repeat: round
}

#skindrWidget.skindrWidget .skindr-widget-bg-repeat-space{
  background-repeat: space
}

#skindrWidget.skindrWidget .skindr-widget-bg-origin-border{
  background-origin: border-box
}

#skindrWidget.skindrWidget .skindr-widget-bg-origin-padding{
  background-origin: padding-box
}

#skindrWidget.skindrWidget .skindr-widget-bg-origin-content{
  background-origin: content-box
}

#skindrWidget.skindrWidget .skindr-widget-fill-current{
  fill: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-stroke-current{
  stroke: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-stroke-0{
  stroke-width: 0
}

#skindrWidget.skindrWidget .skindr-widget-stroke-1{
  stroke-width: 1
}

#skindrWidget.skindrWidget .skindr-widget-stroke-2{
  stroke-width: 2
}

#skindrWidget.skindrWidget .skindr-widget-object-contain{
  object-fit: contain
}

#skindrWidget.skindrWidget .skindr-widget-object-cover{
  object-fit: cover
}

#skindrWidget.skindrWidget .skindr-widget-object-fill{
  object-fit: fill
}

#skindrWidget.skindrWidget .skindr-widget-object-none{
  object-fit: none
}

#skindrWidget.skindrWidget .skindr-widget-object-scale-down{
  object-fit: scale-down
}

#skindrWidget.skindrWidget .skindr-widget-object-bottom{
  object-position: bottom
}

#skindrWidget.skindrWidget .skindr-widget-object-center{
  object-position: center
}

#skindrWidget.skindrWidget .skindr-widget-object-left{
  object-position: left
}

#skindrWidget.skindrWidget .skindr-widget-object-left-bottom{
  object-position: left bottom
}

#skindrWidget.skindrWidget .skindr-widget-object-left-top{
  object-position: left top
}

#skindrWidget.skindrWidget .skindr-widget-object-right{
  object-position: right
}

#skindrWidget.skindrWidget .skindr-widget-object-right-bottom{
  object-position: right bottom
}

#skindrWidget.skindrWidget .skindr-widget-object-right-top{
  object-position: right top
}

#skindrWidget.skindrWidget .skindr-widget-object-top{
  object-position: top
}

#skindrWidget.skindrWidget .skindr-widget-p-0{
  padding: 0px
}

#skindrWidget.skindrWidget .skindr-widget-p-1{
  padding: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-p-2{
  padding: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-p-3{
  padding: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-p-4{
  padding: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-p-5{
  padding: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-p-6{
  padding: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-p-7{
  padding: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-p-8{
  padding: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-p-9{
  padding: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-p-10{
  padding: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-p-11{
  padding: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-p-12{
  padding: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-p-14{
  padding: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-p-16{
  padding: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-p-20{
  padding: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-p-24{
  padding: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-p-28{
  padding: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-p-32{
  padding: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-p-36{
  padding: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-p-40{
  padding: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-p-44{
  padding: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-p-48{
  padding: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-p-52{
  padding: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-p-56{
  padding: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-p-60{
  padding: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-p-64{
  padding: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-p-72{
  padding: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-p-80{
  padding: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-p-96{
  padding: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-p-px{
  padding: 1px
}

#skindrWidget.skindrWidget .skindr-widget-p-0\.5{
  padding: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-p-1\.5{
  padding: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-p-2\.5{
  padding: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-p-3\.5{
  padding: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-px-0{
  padding-left: 0px;
  padding-right: 0px
}

#skindrWidget.skindrWidget .skindr-widget-px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-px-4{
  padding-left: 1rem;
  padding-right: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-px-7{
  padding-left: 1.75rem;
  padding-right: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-px-8{
  padding-left: 2rem;
  padding-right: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-px-9{
  padding-left: 2.25rem;
  padding-right: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-px-11{
  padding-left: 2.75rem;
  padding-right: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-px-12{
  padding-left: 3rem;
  padding-right: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-px-14{
  padding-left: 3.5rem;
  padding-right: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-px-16{
  padding-left: 4rem;
  padding-right: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-px-20{
  padding-left: 5rem;
  padding-right: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-px-24{
  padding-left: 6rem;
  padding-right: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-px-28{
  padding-left: 7rem;
  padding-right: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-px-32{
  padding-left: 8rem;
  padding-right: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-px-36{
  padding-left: 9rem;
  padding-right: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-px-40{
  padding-left: 10rem;
  padding-right: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-px-44{
  padding-left: 11rem;
  padding-right: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-px-48{
  padding-left: 12rem;
  padding-right: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-px-52{
  padding-left: 13rem;
  padding-right: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-px-56{
  padding-left: 14rem;
  padding-right: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-px-60{
  padding-left: 15rem;
  padding-right: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-px-64{
  padding-left: 16rem;
  padding-right: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-px-72{
  padding-left: 18rem;
  padding-right: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-px-80{
  padding-left: 20rem;
  padding-right: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-px-96{
  padding-left: 24rem;
  padding-right: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-px-px{
  padding-left: 1px;
  padding-right: 1px
}

#skindrWidget.skindrWidget .skindr-widget-px-0\.5{
  padding-left: 0.125rem;
  padding-right: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-px-1\.5{
  padding-left: 0.375rem;
  padding-right: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-px-2\.5{
  padding-left: 0.625rem;
  padding-right: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-px-3\.5{
  padding-left: 0.875rem;
  padding-right: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-py-0{
  padding-top: 0px;
  padding-bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget-py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-py-4{
  padding-top: 1rem;
  padding-bottom: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-py-7{
  padding-top: 1.75rem;
  padding-bottom: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-py-8{
  padding-top: 2rem;
  padding-bottom: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-py-9{
  padding-top: 2.25rem;
  padding-bottom: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-py-11{
  padding-top: 2.75rem;
  padding-bottom: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-py-12{
  padding-top: 3rem;
  padding-bottom: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-py-14{
  padding-top: 3.5rem;
  padding-bottom: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-py-16{
  padding-top: 4rem;
  padding-bottom: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-py-20{
  padding-top: 5rem;
  padding-bottom: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-py-24{
  padding-top: 6rem;
  padding-bottom: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-py-28{
  padding-top: 7rem;
  padding-bottom: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-py-32{
  padding-top: 8rem;
  padding-bottom: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-py-36{
  padding-top: 9rem;
  padding-bottom: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-py-40{
  padding-top: 10rem;
  padding-bottom: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-py-44{
  padding-top: 11rem;
  padding-bottom: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-py-48{
  padding-top: 12rem;
  padding-bottom: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-py-52{
  padding-top: 13rem;
  padding-bottom: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-py-56{
  padding-top: 14rem;
  padding-bottom: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-py-60{
  padding-top: 15rem;
  padding-bottom: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-py-64{
  padding-top: 16rem;
  padding-bottom: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-py-72{
  padding-top: 18rem;
  padding-bottom: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-py-80{
  padding-top: 20rem;
  padding-bottom: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-py-96{
  padding-top: 24rem;
  padding-bottom: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-py-px{
  padding-top: 1px;
  padding-bottom: 1px
}

#skindrWidget.skindrWidget .skindr-widget-py-0\.5{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-py-1\.5{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-py-2\.5{
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-py-3\.5{
  padding-top: 0.875rem;
  padding-bottom: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-0{
  padding-top: 0px
}

#skindrWidget.skindrWidget .skindr-widget-pt-1{
  padding-top: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-2{
  padding-top: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-3{
  padding-top: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-4{
  padding-top: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-5{
  padding-top: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-6{
  padding-top: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-7{
  padding-top: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-8{
  padding-top: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-9{
  padding-top: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-10{
  padding-top: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-11{
  padding-top: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-12{
  padding-top: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-14{
  padding-top: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-16{
  padding-top: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-20{
  padding-top: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-24{
  padding-top: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-28{
  padding-top: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-32{
  padding-top: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-36{
  padding-top: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-40{
  padding-top: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-44{
  padding-top: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-48{
  padding-top: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-52{
  padding-top: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-56{
  padding-top: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-60{
  padding-top: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-64{
  padding-top: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-72{
  padding-top: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-80{
  padding-top: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-96{
  padding-top: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-px{
  padding-top: 1px
}

#skindrWidget.skindrWidget .skindr-widget-pt-0\.5{
  padding-top: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-1\.5{
  padding-top: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-2\.5{
  padding-top: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-pt-3\.5{
  padding-top: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-0{
  padding-right: 0px
}

#skindrWidget.skindrWidget .skindr-widget-pr-1{
  padding-right: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-2{
  padding-right: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-3{
  padding-right: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-4{
  padding-right: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-5{
  padding-right: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-6{
  padding-right: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-7{
  padding-right: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-8{
  padding-right: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-9{
  padding-right: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-10{
  padding-right: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-11{
  padding-right: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-12{
  padding-right: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-14{
  padding-right: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-16{
  padding-right: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-20{
  padding-right: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-24{
  padding-right: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-28{
  padding-right: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-32{
  padding-right: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-36{
  padding-right: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-40{
  padding-right: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-44{
  padding-right: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-48{
  padding-right: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-52{
  padding-right: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-56{
  padding-right: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-60{
  padding-right: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-64{
  padding-right: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-72{
  padding-right: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-80{
  padding-right: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-96{
  padding-right: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-px{
  padding-right: 1px
}

#skindrWidget.skindrWidget .skindr-widget-pr-0\.5{
  padding-right: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-1\.5{
  padding-right: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-2\.5{
  padding-right: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-pr-3\.5{
  padding-right: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-0{
  padding-bottom: 0px
}

#skindrWidget.skindrWidget .skindr-widget-pb-1{
  padding-bottom: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-2{
  padding-bottom: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-3{
  padding-bottom: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-4{
  padding-bottom: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-5{
  padding-bottom: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-6{
  padding-bottom: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-7{
  padding-bottom: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-8{
  padding-bottom: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-9{
  padding-bottom: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-10{
  padding-bottom: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-11{
  padding-bottom: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-12{
  padding-bottom: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-14{
  padding-bottom: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-16{
  padding-bottom: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-20{
  padding-bottom: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-24{
  padding-bottom: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-28{
  padding-bottom: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-32{
  padding-bottom: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-36{
  padding-bottom: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-40{
  padding-bottom: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-44{
  padding-bottom: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-48{
  padding-bottom: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-52{
  padding-bottom: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-56{
  padding-bottom: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-60{
  padding-bottom: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-64{
  padding-bottom: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-72{
  padding-bottom: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-80{
  padding-bottom: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-96{
  padding-bottom: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-px{
  padding-bottom: 1px
}

#skindrWidget.skindrWidget .skindr-widget-pb-0\.5{
  padding-bottom: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-1\.5{
  padding-bottom: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-2\.5{
  padding-bottom: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-pb-3\.5{
  padding-bottom: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-0{
  padding-left: 0px
}

#skindrWidget.skindrWidget .skindr-widget-pl-1{
  padding-left: 0.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-2{
  padding-left: 0.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-3{
  padding-left: 0.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-4{
  padding-left: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-5{
  padding-left: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-6{
  padding-left: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-7{
  padding-left: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-8{
  padding-left: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-9{
  padding-left: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-10{
  padding-left: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-11{
  padding-left: 2.75rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-12{
  padding-left: 3rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-14{
  padding-left: 3.5rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-16{
  padding-left: 4rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-20{
  padding-left: 5rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-24{
  padding-left: 6rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-28{
  padding-left: 7rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-32{
  padding-left: 8rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-36{
  padding-left: 9rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-40{
  padding-left: 10rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-44{
  padding-left: 11rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-48{
  padding-left: 12rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-52{
  padding-left: 13rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-56{
  padding-left: 14rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-60{
  padding-left: 15rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-64{
  padding-left: 16rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-72{
  padding-left: 18rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-80{
  padding-left: 20rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-96{
  padding-left: 24rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-px{
  padding-left: 1px
}

#skindrWidget.skindrWidget .skindr-widget-pl-0\.5{
  padding-left: 0.125rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-1\.5{
  padding-left: 0.375rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-2\.5{
  padding-left: 0.625rem
}

#skindrWidget.skindrWidget .skindr-widget-pl-3\.5{
  padding-left: 0.875rem
}

#skindrWidget.skindrWidget .skindr-widget-text-left{
  text-align: left
}

#skindrWidget.skindrWidget .skindr-widget-text-center{
  text-align: center
}

#skindrWidget.skindrWidget .skindr-widget-text-right{
  text-align: right
}

#skindrWidget.skindrWidget .skindr-widget-text-justify{
  text-align: justify
}

#skindrWidget.skindrWidget .skindr-widget-align-baseline{
  vertical-align: baseline
}

#skindrWidget.skindrWidget .skindr-widget-align-top{
  vertical-align: top
}

#skindrWidget.skindrWidget .skindr-widget-align-middle{
  vertical-align: middle
}

#skindrWidget.skindrWidget .skindr-widget-align-bottom{
  vertical-align: bottom
}

#skindrWidget.skindrWidget .skindr-widget-align-text-top{
  vertical-align: text-top
}

#skindrWidget.skindrWidget .skindr-widget-align-text-bottom{
  vertical-align: text-bottom
}

#skindrWidget.skindrWidget .skindr-widget-font-sans{
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

#skindrWidget.skindrWidget .skindr-widget-font-serif{
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
}

#skindrWidget.skindrWidget .skindr-widget-font-mono{
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

#skindrWidget.skindrWidget .skindr-widget-text-xs{
  font-size: 0.75rem;
  line-height: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-text-base{
  font-size: 1rem;
  line-height: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-text-xl{
  font-size: 1.25rem;
  line-height: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-text-2xl{
  font-size: 1.5rem;
  line-height: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-text-4xl{
  font-size: 2.25rem;
  line-height: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-text-5xl{
  font-size: 3rem;
  line-height: 1
}

#skindrWidget.skindrWidget .skindr-widget-text-6xl{
  font-size: 3.75rem;
  line-height: 1
}

#skindrWidget.skindrWidget .skindr-widget-text-7xl{
  font-size: 4.5rem;
  line-height: 1
}

#skindrWidget.skindrWidget .skindr-widget-text-8xl{
  font-size: 6rem;
  line-height: 1
}

#skindrWidget.skindrWidget .skindr-widget-text-9xl{
  font-size: 8rem;
  line-height: 1
}

#skindrWidget.skindrWidget .skindr-widget-font-thin{
  font-weight: 100
}

#skindrWidget.skindrWidget .skindr-widget-font-extralight{
  font-weight: 200
}

#skindrWidget.skindrWidget .skindr-widget-font-light{
  font-weight: 300
}

#skindrWidget.skindrWidget .skindr-widget-font-normal{
  font-weight: 400
}

#skindrWidget.skindrWidget .skindr-widget-font-medium{
  font-weight: 500
}

#skindrWidget.skindrWidget .skindr-widget-font-semibold{
  font-weight: 600
}

#skindrWidget.skindrWidget .skindr-widget-font-bold{
  font-weight: 700
}

#skindrWidget.skindrWidget .skindr-widget-font-extrabold{
  font-weight: 800
}

#skindrWidget.skindrWidget .skindr-widget-font-black{
  font-weight: 900
}

#skindrWidget.skindrWidget .skindr-widget-uppercase{
  text-transform: uppercase
}

#skindrWidget.skindrWidget .skindr-widget-lowercase{
  text-transform: lowercase
}

#skindrWidget.skindrWidget .skindr-widget-capitalize{
  text-transform: capitalize
}

#skindrWidget.skindrWidget .skindr-widget-normal-case{
  text-transform: none
}

#skindrWidget.skindrWidget .skindr-widget-italic{
  font-style: italic
}

#skindrWidget.skindrWidget .skindr-widget-not-italic{
  font-style: normal
}

#skindrWidget.skindrWidget .skindr-widget-ordinal, #skindrWidget.skindrWidget .skindr-widget-slashed-zero, #skindrWidget.skindrWidget .skindr-widget-lining-nums, #skindrWidget.skindrWidget .skindr-widget-oldstyle-nums, #skindrWidget.skindrWidget .skindr-widget-proportional-nums, #skindrWidget.skindrWidget .skindr-widget-tabular-nums, #skindrWidget.skindrWidget .skindr-widget-diagonal-fractions, #skindrWidget.skindrWidget .skindr-widget-stacked-fractions{
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

#skindrWidget.skindrWidget .skindr-widget-normal-nums{
  font-variant-numeric: normal
}

#skindrWidget.skindrWidget .skindr-widget-ordinal{
  --tw-ordinal: ordinal
}

#skindrWidget.skindrWidget .skindr-widget-slashed-zero{
  --tw-slashed-zero: slashed-zero
}

#skindrWidget.skindrWidget .skindr-widget-lining-nums{
  --tw-numeric-figure: lining-nums
}

#skindrWidget.skindrWidget .skindr-widget-oldstyle-nums{
  --tw-numeric-figure: oldstyle-nums
}

#skindrWidget.skindrWidget .skindr-widget-proportional-nums{
  --tw-numeric-spacing: proportional-nums
}

#skindrWidget.skindrWidget .skindr-widget-tabular-nums{
  --tw-numeric-spacing: tabular-nums
}

#skindrWidget.skindrWidget .skindr-widget-diagonal-fractions{
  --tw-numeric-fraction: diagonal-fractions
}

#skindrWidget.skindrWidget .skindr-widget-stacked-fractions{
  --tw-numeric-fraction: stacked-fractions
}

#skindrWidget.skindrWidget .skindr-widget-leading-3{
  line-height: .75rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-4{
  line-height: 1rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-5{
  line-height: 1.25rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-6{
  line-height: 1.5rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-7{
  line-height: 1.75rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-8{
  line-height: 2rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-9{
  line-height: 2.25rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-10{
  line-height: 2.5rem
}

#skindrWidget.skindrWidget .skindr-widget-leading-none{
  line-height: 1
}

#skindrWidget.skindrWidget .skindr-widget-leading-tight{
  line-height: 1.25
}

#skindrWidget.skindrWidget .skindr-widget-leading-snug{
  line-height: 1.375
}

#skindrWidget.skindrWidget .skindr-widget-leading-normal{
  line-height: 1.5
}

#skindrWidget.skindrWidget .skindr-widget-leading-relaxed{
  line-height: 1.625
}

#skindrWidget.skindrWidget .skindr-widget-leading-loose{
  line-height: 2
}

#skindrWidget.skindrWidget .skindr-widget-tracking-tighter{
  letter-spacing: -0.05em
}

#skindrWidget.skindrWidget .skindr-widget-tracking-tight{
  letter-spacing: -0.025em
}

#skindrWidget.skindrWidget .skindr-widget-tracking-normal{
  letter-spacing: 0em
}

#skindrWidget.skindrWidget .skindr-widget-tracking-wide{
  letter-spacing: 0.025em
}

#skindrWidget.skindrWidget .skindr-widget-tracking-wider{
  letter-spacing: 0.05em
}

#skindrWidget.skindrWidget .skindr-widget-tracking-widest{
  letter-spacing: 0.1em
}

#skindrWidget.skindrWidget .skindr-widget-text-transparent{
  color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-text-current{
  color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-text-black{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-white{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-50{
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-100{
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-200{
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-300{
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-400{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-500{
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-600{
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-700{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-800{
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-gray-900{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-50{
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-100{
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-200{
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-300{
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-400{
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-500{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-600{
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-700{
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-800{
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-red-900{
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-50{
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-100{
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-200{
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-300{
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-400{
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-500{
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-600{
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-700{
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-800{
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-yellow-900{
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-50{
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-100{
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-200{
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-300{
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-400{
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-500{
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-600{
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-700{
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-800{
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-green-900{
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-50{
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-100{
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-200{
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-300{
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-400{
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-500{
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-600{
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-700{
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-800{
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-blue-900{
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-50{
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-100{
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-200{
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-300{
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-400{
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-500{
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-600{
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-700{
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-800{
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-indigo-900{
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-50{
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-100{
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-200{
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-300{
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-400{
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-500{
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-600{
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-700{
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-800{
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-purple-900{
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-50{
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-100{
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-200{
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-300{
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-400{
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-500{
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-600{
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-700{
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-800{
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-pink-900{
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-transparent{
  color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-current{
  color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-black{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-white{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-50{
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-100{
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-200{
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-300{
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-400{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-500{
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-600{
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-700{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-800{
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-gray-900{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-50{
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-100{
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-200{
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-300{
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-400{
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-500{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-600{
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-700{
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-800{
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-red-900{
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-50{
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-100{
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-200{
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-300{
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-400{
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-500{
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-600{
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-700{
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-800{
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-yellow-900{
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-50{
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-100{
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-200{
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-300{
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-400{
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-500{
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-600{
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-700{
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-800{
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-green-900{
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-50{
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-100{
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-200{
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-300{
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-400{
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-500{
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-600{
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-700{
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-800{
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-blue-900{
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-50{
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-100{
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-200{
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-300{
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-400{
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-500{
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-600{
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-700{
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-800{
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-indigo-900{
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-50{
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-100{
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-200{
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-300{
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-400{
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-500{
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-600{
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-700{
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-800{
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-purple-900{
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-50{
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-100{
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-200{
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-300{
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-400{
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-500{
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-600{
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-700{
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-800{
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-pink-900{
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-transparent:focus-within{
  color: transparent
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-current:focus-within{
  color: currentColor
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-black:focus-within{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-white:focus-within{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-gray-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-red-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-yellow-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-green-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-blue-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-indigo-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-purple-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-50:focus-within{
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-100:focus-within{
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-200:focus-within{
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-300:focus-within{
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-400:focus-within{
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-500:focus-within{
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-600:focus-within{
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-700:focus-within{
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-800:focus-within{
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-pink-900:focus-within{
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-transparent:hover{
  color: transparent
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-current:hover{
  color: currentColor
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-black:hover{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-white:hover{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-50:hover{
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-100:hover{
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-200:hover{
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-300:hover{
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-400:hover{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-500:hover{
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-600:hover{
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-700:hover{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-800:hover{
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-gray-900:hover{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-50:hover{
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-100:hover{
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-200:hover{
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-300:hover{
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-400:hover{
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-500:hover{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-600:hover{
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-700:hover{
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-800:hover{
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-red-900:hover{
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-50:hover{
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-100:hover{
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-200:hover{
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-300:hover{
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-400:hover{
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-500:hover{
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-600:hover{
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-700:hover{
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-800:hover{
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-yellow-900:hover{
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-50:hover{
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-100:hover{
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-200:hover{
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-300:hover{
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-400:hover{
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-500:hover{
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-600:hover{
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-700:hover{
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-800:hover{
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-green-900:hover{
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-50:hover{
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-100:hover{
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-200:hover{
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-300:hover{
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-400:hover{
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-500:hover{
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-600:hover{
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-700:hover{
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-800:hover{
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-blue-900:hover{
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-50:hover{
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-100:hover{
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-200:hover{
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-300:hover{
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-400:hover{
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-500:hover{
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-600:hover{
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-700:hover{
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-800:hover{
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-indigo-900:hover{
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-50:hover{
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-100:hover{
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-200:hover{
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-300:hover{
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-400:hover{
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-500:hover{
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-600:hover{
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-700:hover{
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-800:hover{
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-purple-900:hover{
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-50:hover{
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-100:hover{
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-200:hover{
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-300:hover{
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-400:hover{
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-500:hover{
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-600:hover{
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-700:hover{
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-800:hover{
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-pink-900:hover{
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-transparent:focus{
  color: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-current:focus{
  color: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-black:focus{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-white:focus{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-50:focus{
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-100:focus{
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-200:focus{
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-300:focus{
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-400:focus{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-500:focus{
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-600:focus{
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-700:focus{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-800:focus{
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-gray-900:focus{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-50:focus{
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-100:focus{
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-200:focus{
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-300:focus{
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-400:focus{
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-500:focus{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-600:focus{
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-700:focus{
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-800:focus{
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-red-900:focus{
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-50:focus{
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-100:focus{
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-200:focus{
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-300:focus{
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-400:focus{
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-500:focus{
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-600:focus{
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-700:focus{
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-800:focus{
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-yellow-900:focus{
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-50:focus{
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-100:focus{
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-200:focus{
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-300:focus{
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-400:focus{
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-500:focus{
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-600:focus{
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-700:focus{
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-800:focus{
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-green-900:focus{
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-50:focus{
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-100:focus{
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-200:focus{
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-300:focus{
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-400:focus{
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-500:focus{
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-600:focus{
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-700:focus{
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-800:focus{
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-blue-900:focus{
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-50:focus{
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-100:focus{
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-200:focus{
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-300:focus{
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-400:focus{
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-500:focus{
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-600:focus{
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-700:focus{
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-800:focus{
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-indigo-900:focus{
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-50:focus{
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-100:focus{
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-200:focus{
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-300:focus{
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-400:focus{
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-500:focus{
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-600:focus{
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-700:focus{
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-800:focus{
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-purple-900:focus{
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-50:focus{
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-100:focus{
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-200:focus{
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-300:focus{
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-400:focus{
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-500:focus{
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-600:focus{
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-700:focus{
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-800:focus{
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-pink-900:focus{
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-0{
  --tw-text-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-5{
  --tw-text-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-10{
  --tw-text-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-20{
  --tw-text-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-25{
  --tw-text-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-30{
  --tw-text-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-40{
  --tw-text-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-50{
  --tw-text-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-60{
  --tw-text-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-70{
  --tw-text-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-75{
  --tw-text-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-80{
  --tw-text-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-90{
  --tw-text-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-95{
  --tw-text-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-text-opacity-100{
  --tw-text-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-0{
  --tw-text-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-5{
  --tw-text-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-10{
  --tw-text-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-20{
  --tw-text-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-25{
  --tw-text-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-30{
  --tw-text-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-40{
  --tw-text-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-50{
  --tw-text-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-60{
  --tw-text-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-70{
  --tw-text-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-75{
  --tw-text-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-80{
  --tw-text-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-90{
  --tw-text-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-95{
  --tw-text-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-text-opacity-100{
  --tw-text-opacity: 1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-0:focus-within{
  --tw-text-opacity: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-5:focus-within{
  --tw-text-opacity: 0.05
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-10:focus-within{
  --tw-text-opacity: 0.1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-20:focus-within{
  --tw-text-opacity: 0.2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-25:focus-within{
  --tw-text-opacity: 0.25
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-30:focus-within{
  --tw-text-opacity: 0.3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-40:focus-within{
  --tw-text-opacity: 0.4
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-50:focus-within{
  --tw-text-opacity: 0.5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-60:focus-within{
  --tw-text-opacity: 0.6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-70:focus-within{
  --tw-text-opacity: 0.7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-75:focus-within{
  --tw-text-opacity: 0.75
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-80:focus-within{
  --tw-text-opacity: 0.8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-90:focus-within{
  --tw-text-opacity: 0.9
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-95:focus-within{
  --tw-text-opacity: 0.95
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-text-opacity-100:focus-within{
  --tw-text-opacity: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-0:hover{
  --tw-text-opacity: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-5:hover{
  --tw-text-opacity: 0.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-10:hover{
  --tw-text-opacity: 0.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-20:hover{
  --tw-text-opacity: 0.2
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-25:hover{
  --tw-text-opacity: 0.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-30:hover{
  --tw-text-opacity: 0.3
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-40:hover{
  --tw-text-opacity: 0.4
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-50:hover{
  --tw-text-opacity: 0.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-60:hover{
  --tw-text-opacity: 0.6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-70:hover{
  --tw-text-opacity: 0.7
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-75:hover{
  --tw-text-opacity: 0.75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-80:hover{
  --tw-text-opacity: 0.8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-90:hover{
  --tw-text-opacity: 0.9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-95:hover{
  --tw-text-opacity: 0.95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-text-opacity-100:hover{
  --tw-text-opacity: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-0:focus{
  --tw-text-opacity: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-5:focus{
  --tw-text-opacity: 0.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-10:focus{
  --tw-text-opacity: 0.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-20:focus{
  --tw-text-opacity: 0.2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-25:focus{
  --tw-text-opacity: 0.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-30:focus{
  --tw-text-opacity: 0.3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-40:focus{
  --tw-text-opacity: 0.4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-50:focus{
  --tw-text-opacity: 0.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-60:focus{
  --tw-text-opacity: 0.6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-70:focus{
  --tw-text-opacity: 0.7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-75:focus{
  --tw-text-opacity: 0.75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-80:focus{
  --tw-text-opacity: 0.8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-90:focus{
  --tw-text-opacity: 0.9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-95:focus{
  --tw-text-opacity: 0.95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-text-opacity-100:focus{
  --tw-text-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-underline{
  text-decoration: underline
}

#skindrWidget.skindrWidget .skindr-widget-line-through{
  text-decoration: line-through
}

#skindrWidget.skindrWidget .skindr-widget-no-underline{
  text-decoration: none
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-underline{
  text-decoration: underline
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-line-through{
  text-decoration: line-through
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-no-underline{
  text-decoration: none
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-underline:focus-within{
  text-decoration: underline
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-line-through:focus-within{
  text-decoration: line-through
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-no-underline:focus-within{
  text-decoration: none
}

#skindrWidget.skindrWidget .hover\:skindr-widget-underline:hover{
  text-decoration: underline
}

#skindrWidget.skindrWidget .hover\:skindr-widget-line-through:hover{
  text-decoration: line-through
}

#skindrWidget.skindrWidget .hover\:skindr-widget-no-underline:hover{
  text-decoration: none
}

#skindrWidget.skindrWidget .focus\:skindr-widget-underline:focus{
  text-decoration: underline
}

#skindrWidget.skindrWidget .focus\:skindr-widget-line-through:focus{
  text-decoration: line-through
}

#skindrWidget.skindrWidget .focus\:skindr-widget-no-underline:focus{
  text-decoration: none
}

#skindrWidget.skindrWidget .skindr-widget-antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

#skindrWidget.skindrWidget .skindr-widget-subpixel-antialiased{
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-transparent::placeholder{
  color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-current::placeholder{
  color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-black::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-white::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-gray-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-red-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-yellow-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-green-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-blue-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-indigo-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-purple-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-50::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-100::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-200::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-300::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-400::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-500::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-600::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-700::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-800::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-pink-900::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-transparent:focus::placeholder{
  color: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-current:focus::placeholder{
  color: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-black:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-white:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-gray-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-red-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-yellow-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-green-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-blue-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-indigo-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-purple-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-50:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-100:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-200:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-300:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-500:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-600:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-700:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-800:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-pink-900:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-0::placeholder{
  --tw-placeholder-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-5::placeholder{
  --tw-placeholder-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-10::placeholder{
  --tw-placeholder-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-20::placeholder{
  --tw-placeholder-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-25::placeholder{
  --tw-placeholder-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-30::placeholder{
  --tw-placeholder-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-40::placeholder{
  --tw-placeholder-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-50::placeholder{
  --tw-placeholder-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-60::placeholder{
  --tw-placeholder-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-70::placeholder{
  --tw-placeholder-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-75::placeholder{
  --tw-placeholder-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-80::placeholder{
  --tw-placeholder-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-90::placeholder{
  --tw-placeholder-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-95::placeholder{
  --tw-placeholder-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-placeholder-opacity-100::placeholder{
  --tw-placeholder-opacity: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-0:focus::placeholder{
  --tw-placeholder-opacity: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-5:focus::placeholder{
  --tw-placeholder-opacity: 0.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-10:focus::placeholder{
  --tw-placeholder-opacity: 0.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-20:focus::placeholder{
  --tw-placeholder-opacity: 0.2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-25:focus::placeholder{
  --tw-placeholder-opacity: 0.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-30:focus::placeholder{
  --tw-placeholder-opacity: 0.3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-40:focus::placeholder{
  --tw-placeholder-opacity: 0.4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-50:focus::placeholder{
  --tw-placeholder-opacity: 0.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-60:focus::placeholder{
  --tw-placeholder-opacity: 0.6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-70:focus::placeholder{
  --tw-placeholder-opacity: 0.7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-75:focus::placeholder{
  --tw-placeholder-opacity: 0.75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-80:focus::placeholder{
  --tw-placeholder-opacity: 0.8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-90:focus::placeholder{
  --tw-placeholder-opacity: 0.9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-95:focus::placeholder{
  --tw-placeholder-opacity: 0.95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-placeholder-opacity-100:focus::placeholder{
  --tw-placeholder-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-opacity-0{
  opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-opacity-5{
  opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-opacity-10{
  opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-opacity-20{
  opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-opacity-25{
  opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-opacity-30{
  opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-opacity-40{
  opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-opacity-50{
  opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-opacity-60{
  opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-opacity-70{
  opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-opacity-75{
  opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-opacity-80{
  opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-opacity-90{
  opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-opacity-95{
  opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-opacity-100{
  opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-0{
  opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-5{
  opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-10{
  opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-20{
  opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-25{
  opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-30{
  opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-40{
  opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-50{
  opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-60{
  opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-70{
  opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-75{
  opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-80{
  opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-90{
  opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-95{
  opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-opacity-100{
  opacity: 1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-0:focus-within{
  opacity: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-5:focus-within{
  opacity: 0.05
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-10:focus-within{
  opacity: 0.1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-20:focus-within{
  opacity: 0.2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-25:focus-within{
  opacity: 0.25
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-30:focus-within{
  opacity: 0.3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-40:focus-within{
  opacity: 0.4
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-50:focus-within{
  opacity: 0.5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-60:focus-within{
  opacity: 0.6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-70:focus-within{
  opacity: 0.7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-75:focus-within{
  opacity: 0.75
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-80:focus-within{
  opacity: 0.8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-90:focus-within{
  opacity: 0.9
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-95:focus-within{
  opacity: 0.95
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-opacity-100:focus-within{
  opacity: 1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-0:hover{
  opacity: 0
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-5:hover{
  opacity: 0.05
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-10:hover{
  opacity: 0.1
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-20:hover{
  opacity: 0.2
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-25:hover{
  opacity: 0.25
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-30:hover{
  opacity: 0.3
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-40:hover{
  opacity: 0.4
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-50:hover{
  opacity: 0.5
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-60:hover{
  opacity: 0.6
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-70:hover{
  opacity: 0.7
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-75:hover{
  opacity: 0.75
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-80:hover{
  opacity: 0.8
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-90:hover{
  opacity: 0.9
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-95:hover{
  opacity: 0.95
}

#skindrWidget.skindrWidget .hover\:skindr-widget-opacity-100:hover{
  opacity: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-0:focus{
  opacity: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-5:focus{
  opacity: 0.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-10:focus{
  opacity: 0.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-20:focus{
  opacity: 0.2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-25:focus{
  opacity: 0.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-30:focus{
  opacity: 0.3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-40:focus{
  opacity: 0.4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-50:focus{
  opacity: 0.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-60:focus{
  opacity: 0.6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-70:focus{
  opacity: 0.7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-75:focus{
  opacity: 0.75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-80:focus{
  opacity: 0.8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-90:focus{
  opacity: 0.9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-95:focus{
  opacity: 0.95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-opacity-100:focus{
  opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-normal{
  background-blend-mode: normal
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-multiply{
  background-blend-mode: multiply
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-screen{
  background-blend-mode: screen
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-overlay{
  background-blend-mode: overlay
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-darken{
  background-blend-mode: darken
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-lighten{
  background-blend-mode: lighten
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-color-dodge{
  background-blend-mode: color-dodge
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-color-burn{
  background-blend-mode: color-burn
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-hard-light{
  background-blend-mode: hard-light
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-soft-light{
  background-blend-mode: soft-light
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-difference{
  background-blend-mode: difference
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-exclusion{
  background-blend-mode: exclusion
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-hue{
  background-blend-mode: hue
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-saturation{
  background-blend-mode: saturation
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-color{
  background-blend-mode: color
}

#skindrWidget.skindrWidget .skindr-widget-bg-blend-luminosity{
  background-blend-mode: luminosity
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-normal{
  mix-blend-mode: normal
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-multiply{
  mix-blend-mode: multiply
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-screen{
  mix-blend-mode: screen
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-overlay{
  mix-blend-mode: overlay
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-darken{
  mix-blend-mode: darken
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-lighten{
  mix-blend-mode: lighten
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-color-dodge{
  mix-blend-mode: color-dodge
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-color-burn{
  mix-blend-mode: color-burn
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-hard-light{
  mix-blend-mode: hard-light
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-soft-light{
  mix-blend-mode: soft-light
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-difference{
  mix-blend-mode: difference
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-exclusion{
  mix-blend-mode: exclusion
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-hue{
  mix-blend-mode: hue
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-saturation{
  mix-blend-mode: saturation
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-color{
  mix-blend-mode: color
}

#skindrWidget.skindrWidget .skindr-widget-mix-blend-luminosity{
  mix-blend-mode: luminosity
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000
}

#skindrWidget.skindrWidget .skindr-widget-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-shadow-none{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-group:hover .group-hover\:skindr-widget-shadow-none{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-sm:focus-within{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow:focus-within{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-md:focus-within{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-lg:focus-within{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-xl:focus-within{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-2xl:focus-within{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-inner:focus-within{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-shadow-none:focus-within{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-sm:hover{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow:hover{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-md:hover{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-lg:hover{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-xl:hover{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-2xl:hover{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-inner:hover{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .hover\:skindr-widget-shadow-none:hover{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-sm:focus{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow:focus{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-md:focus{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-lg:focus{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-xl:focus{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-2xl:focus{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-inner:focus{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-shadow-none:focus{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .skindr-widget-outline-white{
  outline: 2px dotted white;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .skindr-widget-outline-black{
  outline: 2px dotted black;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-outline-none:focus-within{
  outline: 2px solid transparent;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-outline-white:focus-within{
  outline: 2px dotted white;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-outline-black:focus-within{
  outline: 2px dotted black;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-outline-white:focus{
  outline: 2px dotted white;
  outline-offset: 2px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-outline-black:focus{
  outline: 2px dotted black;
  outline-offset: 2px
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

#skindrWidget.skindrWidget .skindr-widget-ring-0{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-ring-2{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-ring-4{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-ring-8{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-ring{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-0:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-1:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-2:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-4:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-8:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-1:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-4:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-8:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-ring-inset{
  --tw-ring-inset: inset
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-inset:focus-within{
  --tw-ring-inset: inset
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-inset:focus{
  --tw-ring-inset: inset
}

#skindrWidget.skindrWidget .skindr-widget-ring-transparent{
  --tw-ring-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-ring-current{
  --tw-ring-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-ring-black{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-white{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-gray-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-red-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-yellow-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-green-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-blue-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-indigo-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-purple-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-50{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-100{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-200{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-600{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-700{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-800{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-pink-900{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-transparent:focus-within{
  --tw-ring-color: transparent
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-current:focus-within{
  --tw-ring-color: currentColor
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-black:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-white:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-gray-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-red-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-yellow-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-green-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-blue-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-indigo-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-purple-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-50:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-100:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-200:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-300:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-400:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-700:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-800:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-pink-900:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-transparent:focus{
  --tw-ring-color: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-current:focus{
  --tw-ring-color: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-black:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-white:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-gray-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-red-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-yellow-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-green-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-blue-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-indigo-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-purple-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-50:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-100:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-200:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-400:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-700:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-800:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-pink-900:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-0{
  --tw-ring-opacity: 0
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-5{
  --tw-ring-opacity: 0.05
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-10{
  --tw-ring-opacity: 0.1
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-20{
  --tw-ring-opacity: 0.2
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-25{
  --tw-ring-opacity: 0.25
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-30{
  --tw-ring-opacity: 0.3
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-40{
  --tw-ring-opacity: 0.4
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-50{
  --tw-ring-opacity: 0.5
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-60{
  --tw-ring-opacity: 0.6
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-70{
  --tw-ring-opacity: 0.7
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-75{
  --tw-ring-opacity: 0.75
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-80{
  --tw-ring-opacity: 0.8
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-90{
  --tw-ring-opacity: 0.9
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-95{
  --tw-ring-opacity: 0.95
}

#skindrWidget.skindrWidget .skindr-widget-ring-opacity-100{
  --tw-ring-opacity: 1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-0:focus-within{
  --tw-ring-opacity: 0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-5:focus-within{
  --tw-ring-opacity: 0.05
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-10:focus-within{
  --tw-ring-opacity: 0.1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-20:focus-within{
  --tw-ring-opacity: 0.2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-25:focus-within{
  --tw-ring-opacity: 0.25
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-30:focus-within{
  --tw-ring-opacity: 0.3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-40:focus-within{
  --tw-ring-opacity: 0.4
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-50:focus-within{
  --tw-ring-opacity: 0.5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-60:focus-within{
  --tw-ring-opacity: 0.6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-70:focus-within{
  --tw-ring-opacity: 0.7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-75:focus-within{
  --tw-ring-opacity: 0.75
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-80:focus-within{
  --tw-ring-opacity: 0.8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-90:focus-within{
  --tw-ring-opacity: 0.9
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-95:focus-within{
  --tw-ring-opacity: 0.95
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-opacity-100:focus-within{
  --tw-ring-opacity: 1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-0:focus{
  --tw-ring-opacity: 0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-5:focus{
  --tw-ring-opacity: 0.05
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-10:focus{
  --tw-ring-opacity: 0.1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-20:focus{
  --tw-ring-opacity: 0.2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-25:focus{
  --tw-ring-opacity: 0.25
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-30:focus{
  --tw-ring-opacity: 0.3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-40:focus{
  --tw-ring-opacity: 0.4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-50:focus{
  --tw-ring-opacity: 0.5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-60:focus{
  --tw-ring-opacity: 0.6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-70:focus{
  --tw-ring-opacity: 0.7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-75:focus{
  --tw-ring-opacity: 0.75
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-80:focus{
  --tw-ring-opacity: 0.8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-90:focus{
  --tw-ring-opacity: 0.9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-95:focus{
  --tw-ring-opacity: 0.95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-opacity-100:focus{
  --tw-ring-opacity: 1
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-0{
  --tw-ring-offset-width: 0px
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-1{
  --tw-ring-offset-width: 1px
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-2{
  --tw-ring-offset-width: 2px
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-4{
  --tw-ring-offset-width: 4px
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-8{
  --tw-ring-offset-width: 8px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-0:focus-within{
  --tw-ring-offset-width: 0px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-1:focus-within{
  --tw-ring-offset-width: 1px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-2:focus-within{
  --tw-ring-offset-width: 2px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-4:focus-within{
  --tw-ring-offset-width: 4px
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-8:focus-within{
  --tw-ring-offset-width: 8px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-0:focus{
  --tw-ring-offset-width: 0px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-1:focus{
  --tw-ring-offset-width: 1px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-2:focus{
  --tw-ring-offset-width: 2px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-4:focus{
  --tw-ring-offset-width: 4px
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-8:focus{
  --tw-ring-offset-width: 8px
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-transparent{
  --tw-ring-offset-color: transparent
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-current{
  --tw-ring-offset-color: currentColor
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-black{
  --tw-ring-offset-color: #000
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-white{
  --tw-ring-offset-color: #fff
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-50{
  --tw-ring-offset-color: #f9fafb
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-100{
  --tw-ring-offset-color: #f3f4f6
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-200{
  --tw-ring-offset-color: #e5e7eb
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-300{
  --tw-ring-offset-color: #d1d5db
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-400{
  --tw-ring-offset-color: #9ca3af
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-500{
  --tw-ring-offset-color: #6b7280
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-600{
  --tw-ring-offset-color: #4b5563
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-700{
  --tw-ring-offset-color: #374151
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-800{
  --tw-ring-offset-color: #1f2937
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-gray-900{
  --tw-ring-offset-color: #111827
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-50{
  --tw-ring-offset-color: #fef2f2
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-100{
  --tw-ring-offset-color: #fee2e2
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-200{
  --tw-ring-offset-color: #fecaca
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-300{
  --tw-ring-offset-color: #fca5a5
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-400{
  --tw-ring-offset-color: #f87171
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-500{
  --tw-ring-offset-color: #ef4444
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-600{
  --tw-ring-offset-color: #dc2626
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-700{
  --tw-ring-offset-color: #b91c1c
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-800{
  --tw-ring-offset-color: #991b1b
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-red-900{
  --tw-ring-offset-color: #7f1d1d
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-50{
  --tw-ring-offset-color: #fffbeb
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-100{
  --tw-ring-offset-color: #fef3c7
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-200{
  --tw-ring-offset-color: #fde68a
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-300{
  --tw-ring-offset-color: #fcd34d
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-400{
  --tw-ring-offset-color: #fbbf24
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-500{
  --tw-ring-offset-color: #f59e0b
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-600{
  --tw-ring-offset-color: #d97706
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-700{
  --tw-ring-offset-color: #b45309
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-800{
  --tw-ring-offset-color: #92400e
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-yellow-900{
  --tw-ring-offset-color: #78350f
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-50{
  --tw-ring-offset-color: #ecfdf5
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-100{
  --tw-ring-offset-color: #d1fae5
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-200{
  --tw-ring-offset-color: #a7f3d0
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-300{
  --tw-ring-offset-color: #6ee7b7
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-400{
  --tw-ring-offset-color: #34d399
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-500{
  --tw-ring-offset-color: #10b981
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-600{
  --tw-ring-offset-color: #059669
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-700{
  --tw-ring-offset-color: #047857
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-800{
  --tw-ring-offset-color: #065f46
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-green-900{
  --tw-ring-offset-color: #064e3b
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-50{
  --tw-ring-offset-color: #eff6ff
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-100{
  --tw-ring-offset-color: #dbeafe
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-200{
  --tw-ring-offset-color: #bfdbfe
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-300{
  --tw-ring-offset-color: #93c5fd
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-400{
  --tw-ring-offset-color: #60a5fa
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-500{
  --tw-ring-offset-color: #3b82f6
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-600{
  --tw-ring-offset-color: #2563eb
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-700{
  --tw-ring-offset-color: #1d4ed8
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-800{
  --tw-ring-offset-color: #1e40af
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-blue-900{
  --tw-ring-offset-color: #1e3a8a
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-50{
  --tw-ring-offset-color: #eef2ff
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-100{
  --tw-ring-offset-color: #e0e7ff
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-200{
  --tw-ring-offset-color: #c7d2fe
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-300{
  --tw-ring-offset-color: #a5b4fc
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-400{
  --tw-ring-offset-color: #818cf8
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-500{
  --tw-ring-offset-color: #6366f1
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-600{
  --tw-ring-offset-color: #4f46e5
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-700{
  --tw-ring-offset-color: #4338ca
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-800{
  --tw-ring-offset-color: #3730a3
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-indigo-900{
  --tw-ring-offset-color: #312e81
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-50{
  --tw-ring-offset-color: #f5f3ff
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-100{
  --tw-ring-offset-color: #ede9fe
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-200{
  --tw-ring-offset-color: #ddd6fe
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-300{
  --tw-ring-offset-color: #c4b5fd
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-400{
  --tw-ring-offset-color: #a78bfa
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-500{
  --tw-ring-offset-color: #8b5cf6
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-600{
  --tw-ring-offset-color: #7c3aed
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-700{
  --tw-ring-offset-color: #6d28d9
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-800{
  --tw-ring-offset-color: #5b21b6
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-purple-900{
  --tw-ring-offset-color: #4c1d95
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-50{
  --tw-ring-offset-color: #fdf2f8
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-100{
  --tw-ring-offset-color: #fce7f3
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-200{
  --tw-ring-offset-color: #fbcfe8
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-300{
  --tw-ring-offset-color: #f9a8d4
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-400{
  --tw-ring-offset-color: #f472b6
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-500{
  --tw-ring-offset-color: #ec4899
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-600{
  --tw-ring-offset-color: #db2777
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-700{
  --tw-ring-offset-color: #be185d
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-800{
  --tw-ring-offset-color: #9d174d
}

#skindrWidget.skindrWidget .skindr-widget-ring-offset-pink-900{
  --tw-ring-offset-color: #831843
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-transparent:focus-within{
  --tw-ring-offset-color: transparent
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-current:focus-within{
  --tw-ring-offset-color: currentColor
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-black:focus-within{
  --tw-ring-offset-color: #000
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-white:focus-within{
  --tw-ring-offset-color: #fff
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-50:focus-within{
  --tw-ring-offset-color: #f9fafb
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-100:focus-within{
  --tw-ring-offset-color: #f3f4f6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-200:focus-within{
  --tw-ring-offset-color: #e5e7eb
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-300:focus-within{
  --tw-ring-offset-color: #d1d5db
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-400:focus-within{
  --tw-ring-offset-color: #9ca3af
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-500:focus-within{
  --tw-ring-offset-color: #6b7280
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-600:focus-within{
  --tw-ring-offset-color: #4b5563
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-700:focus-within{
  --tw-ring-offset-color: #374151
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-800:focus-within{
  --tw-ring-offset-color: #1f2937
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-gray-900:focus-within{
  --tw-ring-offset-color: #111827
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-50:focus-within{
  --tw-ring-offset-color: #fef2f2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-100:focus-within{
  --tw-ring-offset-color: #fee2e2
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-200:focus-within{
  --tw-ring-offset-color: #fecaca
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-300:focus-within{
  --tw-ring-offset-color: #fca5a5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-400:focus-within{
  --tw-ring-offset-color: #f87171
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-500:focus-within{
  --tw-ring-offset-color: #ef4444
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-600:focus-within{
  --tw-ring-offset-color: #dc2626
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-700:focus-within{
  --tw-ring-offset-color: #b91c1c
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-800:focus-within{
  --tw-ring-offset-color: #991b1b
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-red-900:focus-within{
  --tw-ring-offset-color: #7f1d1d
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-50:focus-within{
  --tw-ring-offset-color: #fffbeb
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-100:focus-within{
  --tw-ring-offset-color: #fef3c7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-200:focus-within{
  --tw-ring-offset-color: #fde68a
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-300:focus-within{
  --tw-ring-offset-color: #fcd34d
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-400:focus-within{
  --tw-ring-offset-color: #fbbf24
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-500:focus-within{
  --tw-ring-offset-color: #f59e0b
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-600:focus-within{
  --tw-ring-offset-color: #d97706
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-700:focus-within{
  --tw-ring-offset-color: #b45309
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-800:focus-within{
  --tw-ring-offset-color: #92400e
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-yellow-900:focus-within{
  --tw-ring-offset-color: #78350f
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-50:focus-within{
  --tw-ring-offset-color: #ecfdf5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-100:focus-within{
  --tw-ring-offset-color: #d1fae5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-200:focus-within{
  --tw-ring-offset-color: #a7f3d0
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-300:focus-within{
  --tw-ring-offset-color: #6ee7b7
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-400:focus-within{
  --tw-ring-offset-color: #34d399
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-500:focus-within{
  --tw-ring-offset-color: #10b981
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-600:focus-within{
  --tw-ring-offset-color: #059669
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-700:focus-within{
  --tw-ring-offset-color: #047857
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-800:focus-within{
  --tw-ring-offset-color: #065f46
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-green-900:focus-within{
  --tw-ring-offset-color: #064e3b
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-50:focus-within{
  --tw-ring-offset-color: #eff6ff
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-100:focus-within{
  --tw-ring-offset-color: #dbeafe
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-200:focus-within{
  --tw-ring-offset-color: #bfdbfe
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-300:focus-within{
  --tw-ring-offset-color: #93c5fd
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-400:focus-within{
  --tw-ring-offset-color: #60a5fa
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-500:focus-within{
  --tw-ring-offset-color: #3b82f6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-600:focus-within{
  --tw-ring-offset-color: #2563eb
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-700:focus-within{
  --tw-ring-offset-color: #1d4ed8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-800:focus-within{
  --tw-ring-offset-color: #1e40af
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-blue-900:focus-within{
  --tw-ring-offset-color: #1e3a8a
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-50:focus-within{
  --tw-ring-offset-color: #eef2ff
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-100:focus-within{
  --tw-ring-offset-color: #e0e7ff
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-200:focus-within{
  --tw-ring-offset-color: #c7d2fe
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-300:focus-within{
  --tw-ring-offset-color: #a5b4fc
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-400:focus-within{
  --tw-ring-offset-color: #818cf8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-500:focus-within{
  --tw-ring-offset-color: #6366f1
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-600:focus-within{
  --tw-ring-offset-color: #4f46e5
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-700:focus-within{
  --tw-ring-offset-color: #4338ca
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-800:focus-within{
  --tw-ring-offset-color: #3730a3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-indigo-900:focus-within{
  --tw-ring-offset-color: #312e81
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-50:focus-within{
  --tw-ring-offset-color: #f5f3ff
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-100:focus-within{
  --tw-ring-offset-color: #ede9fe
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-200:focus-within{
  --tw-ring-offset-color: #ddd6fe
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-300:focus-within{
  --tw-ring-offset-color: #c4b5fd
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-400:focus-within{
  --tw-ring-offset-color: #a78bfa
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-500:focus-within{
  --tw-ring-offset-color: #8b5cf6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-600:focus-within{
  --tw-ring-offset-color: #7c3aed
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-700:focus-within{
  --tw-ring-offset-color: #6d28d9
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-800:focus-within{
  --tw-ring-offset-color: #5b21b6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-purple-900:focus-within{
  --tw-ring-offset-color: #4c1d95
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-50:focus-within{
  --tw-ring-offset-color: #fdf2f8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-100:focus-within{
  --tw-ring-offset-color: #fce7f3
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-200:focus-within{
  --tw-ring-offset-color: #fbcfe8
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-300:focus-within{
  --tw-ring-offset-color: #f9a8d4
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-400:focus-within{
  --tw-ring-offset-color: #f472b6
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-500:focus-within{
  --tw-ring-offset-color: #ec4899
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-600:focus-within{
  --tw-ring-offset-color: #db2777
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-700:focus-within{
  --tw-ring-offset-color: #be185d
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-800:focus-within{
  --tw-ring-offset-color: #9d174d
}

#skindrWidget.skindrWidget .focus-within\:skindr-widget-ring-offset-pink-900:focus-within{
  --tw-ring-offset-color: #831843
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-transparent:focus{
  --tw-ring-offset-color: transparent
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-current:focus{
  --tw-ring-offset-color: currentColor
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-black:focus{
  --tw-ring-offset-color: #000
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-white:focus{
  --tw-ring-offset-color: #fff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-50:focus{
  --tw-ring-offset-color: #f9fafb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-100:focus{
  --tw-ring-offset-color: #f3f4f6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-200:focus{
  --tw-ring-offset-color: #e5e7eb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-300:focus{
  --tw-ring-offset-color: #d1d5db
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-400:focus{
  --tw-ring-offset-color: #9ca3af
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-500:focus{
  --tw-ring-offset-color: #6b7280
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-600:focus{
  --tw-ring-offset-color: #4b5563
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-700:focus{
  --tw-ring-offset-color: #374151
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-800:focus{
  --tw-ring-offset-color: #1f2937
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-gray-900:focus{
  --tw-ring-offset-color: #111827
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-50:focus{
  --tw-ring-offset-color: #fef2f2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-100:focus{
  --tw-ring-offset-color: #fee2e2
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-200:focus{
  --tw-ring-offset-color: #fecaca
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-300:focus{
  --tw-ring-offset-color: #fca5a5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-400:focus{
  --tw-ring-offset-color: #f87171
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-500:focus{
  --tw-ring-offset-color: #ef4444
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-600:focus{
  --tw-ring-offset-color: #dc2626
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-700:focus{
  --tw-ring-offset-color: #b91c1c
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-800:focus{
  --tw-ring-offset-color: #991b1b
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-red-900:focus{
  --tw-ring-offset-color: #7f1d1d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-50:focus{
  --tw-ring-offset-color: #fffbeb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-100:focus{
  --tw-ring-offset-color: #fef3c7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-200:focus{
  --tw-ring-offset-color: #fde68a
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-300:focus{
  --tw-ring-offset-color: #fcd34d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-400:focus{
  --tw-ring-offset-color: #fbbf24
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-500:focus{
  --tw-ring-offset-color: #f59e0b
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-600:focus{
  --tw-ring-offset-color: #d97706
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-700:focus{
  --tw-ring-offset-color: #b45309
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-800:focus{
  --tw-ring-offset-color: #92400e
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-yellow-900:focus{
  --tw-ring-offset-color: #78350f
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-50:focus{
  --tw-ring-offset-color: #ecfdf5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-100:focus{
  --tw-ring-offset-color: #d1fae5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-200:focus{
  --tw-ring-offset-color: #a7f3d0
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-300:focus{
  --tw-ring-offset-color: #6ee7b7
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-400:focus{
  --tw-ring-offset-color: #34d399
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-500:focus{
  --tw-ring-offset-color: #10b981
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-600:focus{
  --tw-ring-offset-color: #059669
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-700:focus{
  --tw-ring-offset-color: #047857
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-800:focus{
  --tw-ring-offset-color: #065f46
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-green-900:focus{
  --tw-ring-offset-color: #064e3b
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-50:focus{
  --tw-ring-offset-color: #eff6ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-100:focus{
  --tw-ring-offset-color: #dbeafe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-200:focus{
  --tw-ring-offset-color: #bfdbfe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-300:focus{
  --tw-ring-offset-color: #93c5fd
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-400:focus{
  --tw-ring-offset-color: #60a5fa
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-500:focus{
  --tw-ring-offset-color: #3b82f6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-600:focus{
  --tw-ring-offset-color: #2563eb
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-700:focus{
  --tw-ring-offset-color: #1d4ed8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-800:focus{
  --tw-ring-offset-color: #1e40af
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-blue-900:focus{
  --tw-ring-offset-color: #1e3a8a
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-50:focus{
  --tw-ring-offset-color: #eef2ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-100:focus{
  --tw-ring-offset-color: #e0e7ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-200:focus{
  --tw-ring-offset-color: #c7d2fe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-300:focus{
  --tw-ring-offset-color: #a5b4fc
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-400:focus{
  --tw-ring-offset-color: #818cf8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-500:focus{
  --tw-ring-offset-color: #6366f1
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-600:focus{
  --tw-ring-offset-color: #4f46e5
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-700:focus{
  --tw-ring-offset-color: #4338ca
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-800:focus{
  --tw-ring-offset-color: #3730a3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-indigo-900:focus{
  --tw-ring-offset-color: #312e81
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-50:focus{
  --tw-ring-offset-color: #f5f3ff
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-100:focus{
  --tw-ring-offset-color: #ede9fe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-200:focus{
  --tw-ring-offset-color: #ddd6fe
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-300:focus{
  --tw-ring-offset-color: #c4b5fd
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-400:focus{
  --tw-ring-offset-color: #a78bfa
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-500:focus{
  --tw-ring-offset-color: #8b5cf6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-600:focus{
  --tw-ring-offset-color: #7c3aed
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-700:focus{
  --tw-ring-offset-color: #6d28d9
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-800:focus{
  --tw-ring-offset-color: #5b21b6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-purple-900:focus{
  --tw-ring-offset-color: #4c1d95
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-50:focus{
  --tw-ring-offset-color: #fdf2f8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-100:focus{
  --tw-ring-offset-color: #fce7f3
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-200:focus{
  --tw-ring-offset-color: #fbcfe8
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-300:focus{
  --tw-ring-offset-color: #f9a8d4
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-400:focus{
  --tw-ring-offset-color: #f472b6
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-500:focus{
  --tw-ring-offset-color: #ec4899
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-600:focus{
  --tw-ring-offset-color: #db2777
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-700:focus{
  --tw-ring-offset-color: #be185d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-800:focus{
  --tw-ring-offset-color: #9d174d
}

#skindrWidget.skindrWidget .focus\:skindr-widget-ring-offset-pink-900:focus{
  --tw-ring-offset-color: #831843
}

#skindrWidget.skindrWidget .skindr-widget-filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

#skindrWidget.skindrWidget .skindr-widget-filter-none{
  filter: none
}

#skindrWidget.skindrWidget .skindr-widget-blur-0{
  --tw-blur: blur(0)
}

#skindrWidget.skindrWidget .skindr-widget-blur-none{
  --tw-blur: blur(0)
}

#skindrWidget.skindrWidget .skindr-widget-blur-sm{
  --tw-blur: blur(4px)
}

#skindrWidget.skindrWidget .skindr-widget-blur{
  --tw-blur: blur(8px)
}

#skindrWidget.skindrWidget .skindr-widget-blur-md{
  --tw-blur: blur(12px)
}

#skindrWidget.skindrWidget .skindr-widget-blur-lg{
  --tw-blur: blur(16px)
}

#skindrWidget.skindrWidget .skindr-widget-blur-xl{
  --tw-blur: blur(24px)
}

#skindrWidget.skindrWidget .skindr-widget-blur-2xl{
  --tw-blur: blur(40px)
}

#skindrWidget.skindrWidget .skindr-widget-blur-3xl{
  --tw-blur: blur(64px)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-0{
  --tw-brightness: brightness(0)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-50{
  --tw-brightness: brightness(.5)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-75{
  --tw-brightness: brightness(.75)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-90{
  --tw-brightness: brightness(.9)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-95{
  --tw-brightness: brightness(.95)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-100{
  --tw-brightness: brightness(1)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-105{
  --tw-brightness: brightness(1.05)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-110{
  --tw-brightness: brightness(1.1)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-125{
  --tw-brightness: brightness(1.25)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-150{
  --tw-brightness: brightness(1.5)
}

#skindrWidget.skindrWidget .skindr-widget-brightness-200{
  --tw-brightness: brightness(2)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-0{
  --tw-contrast: contrast(0)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-50{
  --tw-contrast: contrast(.5)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-75{
  --tw-contrast: contrast(.75)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-100{
  --tw-contrast: contrast(1)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-125{
  --tw-contrast: contrast(1.25)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-150{
  --tw-contrast: contrast(1.5)
}

#skindrWidget.skindrWidget .skindr-widget-contrast-200{
  --tw-contrast: contrast(2)
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow-sm{
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow-md{
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow-lg{
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow-xl{
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow-2xl{
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
}

#skindrWidget.skindrWidget .skindr-widget-drop-shadow-none{
  --tw-drop-shadow: drop-shadow(0 0 #0000)
}

#skindrWidget.skindrWidget .skindr-widget-grayscale-0{
  --tw-grayscale: grayscale(0)
}

#skindrWidget.skindrWidget .skindr-widget-grayscale{
  --tw-grayscale: grayscale(100%)
}

#skindrWidget.skindrWidget .skindr-widget-hue-rotate-0{
  --tw-hue-rotate: hue-rotate(0deg)
}

#skindrWidget.skindrWidget .skindr-widget-hue-rotate-15{
  --tw-hue-rotate: hue-rotate(15deg)
}

#skindrWidget.skindrWidget .skindr-widget-hue-rotate-30{
  --tw-hue-rotate: hue-rotate(30deg)
}

#skindrWidget.skindrWidget .skindr-widget-hue-rotate-60{
  --tw-hue-rotate: hue-rotate(60deg)
}

#skindrWidget.skindrWidget .skindr-widget-hue-rotate-90{
  --tw-hue-rotate: hue-rotate(90deg)
}

#skindrWidget.skindrWidget .skindr-widget-hue-rotate-180{
  --tw-hue-rotate: hue-rotate(180deg)
}

#skindrWidget.skindrWidget .skindr-widget--hue-rotate-180{
  --tw-hue-rotate: hue-rotate(-180deg)
}

#skindrWidget.skindrWidget .skindr-widget--hue-rotate-90{
  --tw-hue-rotate: hue-rotate(-90deg)
}

#skindrWidget.skindrWidget .skindr-widget--hue-rotate-60{
  --tw-hue-rotate: hue-rotate(-60deg)
}

#skindrWidget.skindrWidget .skindr-widget--hue-rotate-30{
  --tw-hue-rotate: hue-rotate(-30deg)
}

#skindrWidget.skindrWidget .skindr-widget--hue-rotate-15{
  --tw-hue-rotate: hue-rotate(-15deg)
}

#skindrWidget.skindrWidget .skindr-widget-invert-0{
  --tw-invert: invert(0)
}

#skindrWidget.skindrWidget .skindr-widget-invert{
  --tw-invert: invert(100%)
}

#skindrWidget.skindrWidget .skindr-widget-saturate-0{
  --tw-saturate: saturate(0)
}

#skindrWidget.skindrWidget .skindr-widget-saturate-50{
  --tw-saturate: saturate(.5)
}

#skindrWidget.skindrWidget .skindr-widget-saturate-100{
  --tw-saturate: saturate(1)
}

#skindrWidget.skindrWidget .skindr-widget-saturate-150{
  --tw-saturate: saturate(1.5)
}

#skindrWidget.skindrWidget .skindr-widget-saturate-200{
  --tw-saturate: saturate(2)
}

#skindrWidget.skindrWidget .skindr-widget-sepia-0{
  --tw-sepia: sepia(0)
}

#skindrWidget.skindrWidget .skindr-widget-sepia{
  --tw-sepia: sepia(100%)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-filter{
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-filter-none{
  -webkit-backdrop-filter: none;
          backdrop-filter: none
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-0{
  --tw-backdrop-blur: blur(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-none{
  --tw-backdrop-blur: blur(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur{
  --tw-backdrop-blur: blur(8px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-md{
  --tw-backdrop-blur: blur(12px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-lg{
  --tw-backdrop-blur: blur(16px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-xl{
  --tw-backdrop-blur: blur(24px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-2xl{
  --tw-backdrop-blur: blur(40px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-blur-3xl{
  --tw-backdrop-blur: blur(64px)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-0{
  --tw-backdrop-brightness: brightness(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-50{
  --tw-backdrop-brightness: brightness(.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-75{
  --tw-backdrop-brightness: brightness(.75)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-90{
  --tw-backdrop-brightness: brightness(.9)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-95{
  --tw-backdrop-brightness: brightness(.95)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-100{
  --tw-backdrop-brightness: brightness(1)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-105{
  --tw-backdrop-brightness: brightness(1.05)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-110{
  --tw-backdrop-brightness: brightness(1.1)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-125{
  --tw-backdrop-brightness: brightness(1.25)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-150{
  --tw-backdrop-brightness: brightness(1.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-brightness-200{
  --tw-backdrop-brightness: brightness(2)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-0{
  --tw-backdrop-contrast: contrast(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-50{
  --tw-backdrop-contrast: contrast(.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-75{
  --tw-backdrop-contrast: contrast(.75)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-100{
  --tw-backdrop-contrast: contrast(1)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-125{
  --tw-backdrop-contrast: contrast(1.25)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-150{
  --tw-backdrop-contrast: contrast(1.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-contrast-200{
  --tw-backdrop-contrast: contrast(2)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-grayscale-0{
  --tw-backdrop-grayscale: grayscale(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-grayscale{
  --tw-backdrop-grayscale: grayscale(100%)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-hue-rotate-0{
  --tw-backdrop-hue-rotate: hue-rotate(0deg)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(15deg)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(30deg)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(60deg)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(90deg)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(180deg)
}

#skindrWidget.skindrWidget .skindr-widget--backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(-180deg)
}

#skindrWidget.skindrWidget .skindr-widget--backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(-90deg)
}

#skindrWidget.skindrWidget .skindr-widget--backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(-60deg)
}

#skindrWidget.skindrWidget .skindr-widget--backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(-30deg)
}

#skindrWidget.skindrWidget .skindr-widget--backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(-15deg)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-invert-0{
  --tw-backdrop-invert: invert(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-invert{
  --tw-backdrop-invert: invert(100%)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-0{
  --tw-backdrop-opacity: opacity(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-5{
  --tw-backdrop-opacity: opacity(0.05)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-10{
  --tw-backdrop-opacity: opacity(0.1)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-20{
  --tw-backdrop-opacity: opacity(0.2)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-25{
  --tw-backdrop-opacity: opacity(0.25)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-30{
  --tw-backdrop-opacity: opacity(0.3)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-40{
  --tw-backdrop-opacity: opacity(0.4)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-50{
  --tw-backdrop-opacity: opacity(0.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-60{
  --tw-backdrop-opacity: opacity(0.6)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-70{
  --tw-backdrop-opacity: opacity(0.7)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-75{
  --tw-backdrop-opacity: opacity(0.75)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-80{
  --tw-backdrop-opacity: opacity(0.8)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-90{
  --tw-backdrop-opacity: opacity(0.9)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-95{
  --tw-backdrop-opacity: opacity(0.95)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-opacity-100{
  --tw-backdrop-opacity: opacity(1)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-saturate-0{
  --tw-backdrop-saturate: saturate(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-saturate-50{
  --tw-backdrop-saturate: saturate(.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-saturate-100{
  --tw-backdrop-saturate: saturate(1)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-saturate-150{
  --tw-backdrop-saturate: saturate(1.5)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-saturate-200{
  --tw-backdrop-saturate: saturate(2)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-sepia-0{
  --tw-backdrop-sepia: sepia(0)
}

#skindrWidget.skindrWidget .skindr-widget-backdrop-sepia{
  --tw-backdrop-sepia: sepia(100%)
}

#skindrWidget.skindrWidget .skindr-widget-transition-none{
  transition-property: none
}

#skindrWidget.skindrWidget .skindr-widget-transition-all{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-transition-colors{
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-transition-opacity{
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-transition-shadow{
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-transition-transform{
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-75{
  transition-delay: 75ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-100{
  transition-delay: 100ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-150{
  transition-delay: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-200{
  transition-delay: 200ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-300{
  transition-delay: 300ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-500{
  transition-delay: 500ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-700{
  transition-delay: 700ms
}

#skindrWidget.skindrWidget .skindr-widget-delay-1000{
  transition-delay: 1000ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-75{
  transition-duration: 75ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-100{
  transition-duration: 100ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-150{
  transition-duration: 150ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-200{
  transition-duration: 200ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-300{
  transition-duration: 300ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-500{
  transition-duration: 500ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-700{
  transition-duration: 700ms
}

#skindrWidget.skindrWidget .skindr-widget-duration-1000{
  transition-duration: 1000ms
}

#skindrWidget.skindrWidget .skindr-widget-ease-linear{
  transition-timing-function: linear
}

#skindrWidget.skindrWidget .skindr-widget-ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

#skindrWidget.skindrWidget .skindr-widget-ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

#skindrWidget.skindrWidget .skindr-widget-ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@media (min-width: 640px){
  .sm\:skindr-widget-container{
    width: 100%
  }

  @media (min-width: 640px){
    .sm\:skindr-widget-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .sm\:skindr-widget-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .sm\:skindr-widget-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .sm\:skindr-widget-container{
      max-width: 1280px
    }
  }

  @media (min-width: 1536px){
    .sm\:skindr-widget-container{
      max-width: 1536px
    }
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-sr-only:focus-within{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-not-sr-only:focus-within{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pointer-events-none{
    pointer-events: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pointer-events-auto{
    pointer-events: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-visible{
    visibility: visible
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-invisible{
    visibility: hidden
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-static{
    position: static
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-fixed{
    position: fixed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-absolute{
    position: absolute
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-relative{
    position: relative
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-sticky{
    position: sticky
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-1{
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-2{
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-3{
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-4{
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-5{
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-6{
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-7{
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-8{
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-9{
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-10{
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-11{
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-12{
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-14{
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-16{
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-20{
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-24{
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-28{
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-32{
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-36{
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-40{
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-44{
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-48{
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-52{
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-56{
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-60{
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-64{
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-72{
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-80{
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-96{
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-px{
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-0\.5{
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-1\.5{
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-2\.5{
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-3\.5{
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-1{
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-2{
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-3{
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-4{
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-5{
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-6{
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-7{
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-8{
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-9{
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-10{
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-11{
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-12{
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-14{
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-16{
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-20{
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-24{
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-28{
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-32{
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-36{
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-40{
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-44{
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-48{
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-52{
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-56{
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-60{
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-64{
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-72{
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-80{
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-96{
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-px{
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-0\.5{
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-1\.5{
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-2\.5{
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-3\.5{
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-1\/2{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-1\/3{
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-2\/3{
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-1\/4{
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-2\/4{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-3\/4{
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-full{
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-1\/2{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-1\/3{
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-2\/3{
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-1\/4{
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-2\/4{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-3\/4{
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-full{
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-1{
    left: 0.25rem;
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-2{
    left: 0.5rem;
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-3{
    left: 0.75rem;
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-4{
    left: 1rem;
    right: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-5{
    left: 1.25rem;
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-6{
    left: 1.5rem;
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-7{
    left: 1.75rem;
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-8{
    left: 2rem;
    right: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-9{
    left: 2.25rem;
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-10{
    left: 2.5rem;
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-11{
    left: 2.75rem;
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-12{
    left: 3rem;
    right: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-14{
    left: 3.5rem;
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-16{
    left: 4rem;
    right: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-20{
    left: 5rem;
    right: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-24{
    left: 6rem;
    right: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-28{
    left: 7rem;
    right: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-32{
    left: 8rem;
    right: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-36{
    left: 9rem;
    right: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-40{
    left: 10rem;
    right: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-44{
    left: 11rem;
    right: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-48{
    left: 12rem;
    right: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-52{
    left: 13rem;
    right: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-56{
    left: 14rem;
    right: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-60{
    left: 15rem;
    right: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-64{
    left: 16rem;
    right: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-72{
    left: 18rem;
    right: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-80{
    left: 20rem;
    right: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-96{
    left: 24rem;
    right: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-auto{
    left: auto;
    right: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-px{
    left: 1px;
    right: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-0\.5{
    left: 0.125rem;
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-1\.5{
    left: 0.375rem;
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-2\.5{
    left: 0.625rem;
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-3\.5{
    left: 0.875rem;
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-1{
    left: -0.25rem;
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-2{
    left: -0.5rem;
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-3{
    left: -0.75rem;
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-4{
    left: -1rem;
    right: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-5{
    left: -1.25rem;
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-6{
    left: -1.5rem;
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-7{
    left: -1.75rem;
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-8{
    left: -2rem;
    right: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-9{
    left: -2.25rem;
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-10{
    left: -2.5rem;
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-11{
    left: -2.75rem;
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-12{
    left: -3rem;
    right: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-14{
    left: -3.5rem;
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-16{
    left: -4rem;
    right: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-20{
    left: -5rem;
    right: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-24{
    left: -6rem;
    right: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-28{
    left: -7rem;
    right: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-32{
    left: -8rem;
    right: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-36{
    left: -9rem;
    right: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-40{
    left: -10rem;
    right: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-44{
    left: -11rem;
    right: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-48{
    left: -12rem;
    right: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-52{
    left: -13rem;
    right: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-56{
    left: -14rem;
    right: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-60{
    left: -15rem;
    right: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-64{
    left: -16rem;
    right: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-72{
    left: -18rem;
    right: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-80{
    left: -20rem;
    right: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-96{
    left: -24rem;
    right: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-px{
    left: -1px;
    right: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-0\.5{
    left: -0.125rem;
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-1\.5{
    left: -0.375rem;
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-2\.5{
    left: -0.625rem;
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-3\.5{
    left: -0.875rem;
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-1\/2{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-1\/3{
    left: 33.333333%;
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-2\/3{
    left: 66.666667%;
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-1\/4{
    left: 25%;
    right: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-2\/4{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-3\/4{
    left: 75%;
    right: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-x-full{
    left: 100%;
    right: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-1\/2{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-1\/3{
    left: -33.333333%;
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-2\/3{
    left: -66.666667%;
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-1\/4{
    left: -25%;
    right: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-2\/4{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-3\/4{
    left: -75%;
    right: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-x-full{
    left: -100%;
    right: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-1{
    top: 0.25rem;
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-2{
    top: 0.5rem;
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-3{
    top: 0.75rem;
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-4{
    top: 1rem;
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-5{
    top: 1.25rem;
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-6{
    top: 1.5rem;
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-7{
    top: 1.75rem;
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-8{
    top: 2rem;
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-9{
    top: 2.25rem;
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-10{
    top: 2.5rem;
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-11{
    top: 2.75rem;
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-12{
    top: 3rem;
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-14{
    top: 3.5rem;
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-16{
    top: 4rem;
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-20{
    top: 5rem;
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-24{
    top: 6rem;
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-28{
    top: 7rem;
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-32{
    top: 8rem;
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-36{
    top: 9rem;
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-40{
    top: 10rem;
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-44{
    top: 11rem;
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-48{
    top: 12rem;
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-52{
    top: 13rem;
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-56{
    top: 14rem;
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-60{
    top: 15rem;
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-64{
    top: 16rem;
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-72{
    top: 18rem;
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-80{
    top: 20rem;
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-96{
    top: 24rem;
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-auto{
    top: auto;
    bottom: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-px{
    top: 1px;
    bottom: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-0\.5{
    top: 0.125rem;
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-1\.5{
    top: 0.375rem;
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-2\.5{
    top: 0.625rem;
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-3\.5{
    top: 0.875rem;
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-1{
    top: -0.25rem;
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-2{
    top: -0.5rem;
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-3{
    top: -0.75rem;
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-4{
    top: -1rem;
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-5{
    top: -1.25rem;
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-6{
    top: -1.5rem;
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-7{
    top: -1.75rem;
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-8{
    top: -2rem;
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-9{
    top: -2.25rem;
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-10{
    top: -2.5rem;
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-11{
    top: -2.75rem;
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-12{
    top: -3rem;
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-14{
    top: -3.5rem;
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-16{
    top: -4rem;
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-20{
    top: -5rem;
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-24{
    top: -6rem;
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-28{
    top: -7rem;
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-32{
    top: -8rem;
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-36{
    top: -9rem;
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-40{
    top: -10rem;
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-44{
    top: -11rem;
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-48{
    top: -12rem;
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-52{
    top: -13rem;
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-56{
    top: -14rem;
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-60{
    top: -15rem;
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-64{
    top: -16rem;
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-72{
    top: -18rem;
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-80{
    top: -20rem;
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-96{
    top: -24rem;
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-px{
    top: -1px;
    bottom: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-0\.5{
    top: -0.125rem;
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-1\.5{
    top: -0.375rem;
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-2\.5{
    top: -0.625rem;
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-3\.5{
    top: -0.875rem;
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-1\/2{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-1\/3{
    top: 33.333333%;
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-2\/3{
    top: 66.666667%;
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-1\/4{
    top: 25%;
    bottom: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-2\/4{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-3\/4{
    top: 75%;
    bottom: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inset-y-full{
    top: 100%;
    bottom: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-1\/2{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-1\/3{
    top: -33.333333%;
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-2\/3{
    top: -66.666667%;
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-1\/4{
    top: -25%;
    bottom: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-2\/4{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-3\/4{
    top: -75%;
    bottom: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--inset-y-full{
    top: -100%;
    bottom: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-1{
    top: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-2{
    top: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-3{
    top: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-4{
    top: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-5{
    top: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-6{
    top: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-7{
    top: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-8{
    top: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-9{
    top: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-10{
    top: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-11{
    top: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-12{
    top: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-14{
    top: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-16{
    top: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-20{
    top: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-24{
    top: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-28{
    top: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-32{
    top: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-36{
    top: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-40{
    top: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-44{
    top: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-48{
    top: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-52{
    top: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-56{
    top: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-60{
    top: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-64{
    top: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-72{
    top: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-80{
    top: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-96{
    top: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-auto{
    top: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-px{
    top: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-0\.5{
    top: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-1\.5{
    top: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-2\.5{
    top: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-3\.5{
    top: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-1{
    top: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-2{
    top: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-3{
    top: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-4{
    top: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-5{
    top: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-6{
    top: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-7{
    top: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-8{
    top: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-9{
    top: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-10{
    top: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-11{
    top: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-12{
    top: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-14{
    top: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-16{
    top: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-20{
    top: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-24{
    top: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-28{
    top: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-32{
    top: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-36{
    top: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-40{
    top: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-44{
    top: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-48{
    top: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-52{
    top: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-56{
    top: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-60{
    top: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-64{
    top: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-72{
    top: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-80{
    top: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-96{
    top: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-px{
    top: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-0\.5{
    top: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-1\.5{
    top: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-2\.5{
    top: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-3\.5{
    top: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-1\/2{
    top: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-1\/3{
    top: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-2\/3{
    top: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-1\/4{
    top: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-2\/4{
    top: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-3\/4{
    top: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-top-full{
    top: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-1\/2{
    top: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-1\/3{
    top: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-2\/3{
    top: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-1\/4{
    top: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-2\/4{
    top: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-3\/4{
    top: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--top-full{
    top: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-1{
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-2{
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-3{
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-4{
    right: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-5{
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-6{
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-7{
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-8{
    right: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-9{
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-10{
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-11{
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-12{
    right: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-14{
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-16{
    right: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-20{
    right: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-24{
    right: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-28{
    right: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-32{
    right: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-36{
    right: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-40{
    right: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-44{
    right: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-48{
    right: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-52{
    right: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-56{
    right: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-60{
    right: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-64{
    right: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-72{
    right: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-80{
    right: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-96{
    right: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-auto{
    right: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-px{
    right: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-0\.5{
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-1\.5{
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-2\.5{
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-3\.5{
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-1{
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-2{
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-3{
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-4{
    right: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-5{
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-6{
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-7{
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-8{
    right: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-9{
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-10{
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-11{
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-12{
    right: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-14{
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-16{
    right: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-20{
    right: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-24{
    right: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-28{
    right: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-32{
    right: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-36{
    right: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-40{
    right: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-44{
    right: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-48{
    right: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-52{
    right: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-56{
    right: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-60{
    right: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-64{
    right: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-72{
    right: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-80{
    right: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-96{
    right: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-px{
    right: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-0\.5{
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-1\.5{
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-2\.5{
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-3\.5{
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-1\/2{
    right: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-1\/3{
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-2\/3{
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-1\/4{
    right: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-2\/4{
    right: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-3\/4{
    right: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-right-full{
    right: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-1\/2{
    right: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-1\/3{
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-2\/3{
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-1\/4{
    right: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-2\/4{
    right: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-3\/4{
    right: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--right-full{
    right: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-1{
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-2{
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-3{
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-4{
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-5{
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-6{
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-7{
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-8{
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-9{
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-10{
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-11{
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-12{
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-14{
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-16{
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-20{
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-24{
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-28{
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-32{
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-36{
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-40{
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-44{
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-48{
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-52{
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-56{
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-60{
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-64{
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-72{
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-80{
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-96{
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-auto{
    bottom: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-px{
    bottom: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-0\.5{
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-1\.5{
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-2\.5{
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-3\.5{
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-1{
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-2{
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-3{
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-4{
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-5{
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-6{
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-7{
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-8{
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-9{
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-10{
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-11{
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-12{
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-14{
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-16{
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-20{
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-24{
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-28{
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-32{
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-36{
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-40{
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-44{
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-48{
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-52{
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-56{
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-60{
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-64{
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-72{
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-80{
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-96{
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-px{
    bottom: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-0\.5{
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-1\.5{
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-2\.5{
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-3\.5{
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-1\/2{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-1\/3{
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-2\/3{
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-1\/4{
    bottom: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-2\/4{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-3\/4{
    bottom: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bottom-full{
    bottom: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-1\/2{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-1\/3{
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-2\/3{
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-1\/4{
    bottom: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-2\/4{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-3\/4{
    bottom: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--bottom-full{
    bottom: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-1{
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-2{
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-3{
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-4{
    left: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-5{
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-6{
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-7{
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-8{
    left: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-9{
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-10{
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-11{
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-12{
    left: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-14{
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-16{
    left: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-20{
    left: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-24{
    left: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-28{
    left: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-32{
    left: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-36{
    left: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-40{
    left: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-44{
    left: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-48{
    left: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-52{
    left: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-56{
    left: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-60{
    left: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-64{
    left: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-72{
    left: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-80{
    left: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-96{
    left: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-auto{
    left: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-px{
    left: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-0\.5{
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-1\.5{
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-2\.5{
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-3\.5{
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-1{
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-2{
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-3{
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-4{
    left: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-5{
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-6{
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-7{
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-8{
    left: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-9{
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-10{
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-11{
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-12{
    left: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-14{
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-16{
    left: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-20{
    left: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-24{
    left: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-28{
    left: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-32{
    left: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-36{
    left: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-40{
    left: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-44{
    left: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-48{
    left: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-52{
    left: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-56{
    left: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-60{
    left: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-64{
    left: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-72{
    left: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-80{
    left: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-96{
    left: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-px{
    left: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-0\.5{
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-1\.5{
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-2\.5{
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-3\.5{
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-1\/2{
    left: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-1\/3{
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-2\/3{
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-1\/4{
    left: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-2\/4{
    left: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-3\/4{
    left: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-left-full{
    left: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-1\/2{
    left: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-1\/3{
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-2\/3{
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-1\/4{
    left: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-2\/4{
    left: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-3\/4{
    left: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--left-full{
    left: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-isolate{
    isolation: isolate
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-isolation-auto{
    isolation: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-0{
    z-index: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-10{
    z-index: 10
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-20{
    z-index: 20
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-30{
    z-index: 30
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-40{
    z-index: 40
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-50{
    z-index: 50
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-z-auto{
    z-index: auto
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-0:focus-within{
    z-index: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-10:focus-within{
    z-index: 10
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-20:focus-within{
    z-index: 20
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-30:focus-within{
    z-index: 30
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-40:focus-within{
    z-index: 40
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-50:focus-within{
    z-index: 50
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-z-auto:focus-within{
    z-index: auto
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-0:focus{
    z-index: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-10:focus{
    z-index: 10
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-20:focus{
    z-index: 20
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-30:focus{
    z-index: 30
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-40:focus{
    z-index: 40
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-50:focus{
    z-index: 50
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-z-auto:focus{
    z-index: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-1{
    order: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-2{
    order: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-3{
    order: 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-4{
    order: 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-5{
    order: 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-6{
    order: 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-7{
    order: 7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-8{
    order: 8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-9{
    order: 9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-10{
    order: 10
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-11{
    order: 11
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-12{
    order: 12
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-first{
    order: -9999
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-last{
    order: 9999
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-order-none{
    order: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-auto{
    grid-column: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-1{
    grid-column: span 1 / span 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-2{
    grid-column: span 2 / span 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-3{
    grid-column: span 3 / span 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-4{
    grid-column: span 4 / span 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-5{
    grid-column: span 5 / span 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-6{
    grid-column: span 6 / span 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-7{
    grid-column: span 7 / span 7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-8{
    grid-column: span 8 / span 8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-9{
    grid-column: span 9 / span 9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-10{
    grid-column: span 10 / span 10
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-11{
    grid-column: span 11 / span 11
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-12{
    grid-column: span 12 / span 12
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-span-full{
    grid-column: 1 / -1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-1{
    grid-column-start: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-2{
    grid-column-start: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-3{
    grid-column-start: 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-4{
    grid-column-start: 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-5{
    grid-column-start: 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-6{
    grid-column-start: 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-7{
    grid-column-start: 7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-8{
    grid-column-start: 8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-9{
    grid-column-start: 9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-10{
    grid-column-start: 10
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-11{
    grid-column-start: 11
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-12{
    grid-column-start: 12
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-13{
    grid-column-start: 13
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-start-auto{
    grid-column-start: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-1{
    grid-column-end: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-2{
    grid-column-end: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-3{
    grid-column-end: 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-4{
    grid-column-end: 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-5{
    grid-column-end: 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-6{
    grid-column-end: 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-7{
    grid-column-end: 7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-8{
    grid-column-end: 8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-9{
    grid-column-end: 9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-10{
    grid-column-end: 10
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-11{
    grid-column-end: 11
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-12{
    grid-column-end: 12
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-13{
    grid-column-end: 13
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-col-end-auto{
    grid-column-end: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-auto{
    grid-row: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-1{
    grid-row: span 1 / span 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-2{
    grid-row: span 2 / span 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-3{
    grid-row: span 3 / span 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-4{
    grid-row: span 4 / span 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-5{
    grid-row: span 5 / span 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-6{
    grid-row: span 6 / span 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-span-full{
    grid-row: 1 / -1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-1{
    grid-row-start: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-2{
    grid-row-start: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-3{
    grid-row-start: 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-4{
    grid-row-start: 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-5{
    grid-row-start: 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-6{
    grid-row-start: 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-7{
    grid-row-start: 7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-start-auto{
    grid-row-start: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-1{
    grid-row-end: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-2{
    grid-row-end: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-3{
    grid-row-end: 3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-4{
    grid-row-end: 4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-5{
    grid-row-end: 5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-6{
    grid-row-end: 6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-7{
    grid-row-end: 7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-row-end-auto{
    grid-row-end: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-float-right{
    float: right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-float-left{
    float: left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-float-none{
    float: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-clear-left{
    clear: left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-clear-right{
    clear: right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-clear-both{
    clear: both
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-clear-none{
    clear: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-1{
    margin: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-2{
    margin: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-3{
    margin: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-4{
    margin: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-5{
    margin: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-6{
    margin: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-7{
    margin: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-8{
    margin: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-9{
    margin: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-10{
    margin: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-11{
    margin: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-12{
    margin: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-14{
    margin: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-16{
    margin: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-20{
    margin: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-24{
    margin: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-28{
    margin: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-32{
    margin: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-36{
    margin: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-40{
    margin: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-44{
    margin: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-48{
    margin: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-52{
    margin: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-56{
    margin: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-60{
    margin: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-64{
    margin: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-72{
    margin: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-80{
    margin: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-96{
    margin: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-auto{
    margin: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-px{
    margin: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-0\.5{
    margin: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-1\.5{
    margin: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-2\.5{
    margin: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-m-3\.5{
    margin: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-1{
    margin: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-2{
    margin: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-3{
    margin: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-4{
    margin: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-5{
    margin: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-6{
    margin: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-7{
    margin: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-8{
    margin: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-9{
    margin: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-10{
    margin: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-11{
    margin: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-12{
    margin: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-14{
    margin: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-16{
    margin: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-20{
    margin: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-24{
    margin: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-28{
    margin: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-32{
    margin: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-36{
    margin: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-40{
    margin: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-44{
    margin: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-48{
    margin: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-52{
    margin: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-56{
    margin: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-60{
    margin: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-64{
    margin: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-72{
    margin: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-80{
    margin: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-96{
    margin: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-px{
    margin: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-0\.5{
    margin: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-1\.5{
    margin: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-2\.5{
    margin: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--m-3\.5{
    margin: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-7{
    margin-left: 1.75rem;
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-9{
    margin-left: 2.25rem;
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-11{
    margin-left: 2.75rem;
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-14{
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-28{
    margin-left: 7rem;
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-36{
    margin-left: 9rem;
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-44{
    margin-left: 11rem;
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-52{
    margin-left: 13rem;
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-60{
    margin-left: 15rem;
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-72{
    margin-left: 18rem;
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-80{
    margin-left: 20rem;
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-96{
    margin-left: 24rem;
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-0\.5{
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-1\.5{
    margin-left: 0.375rem;
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-2\.5{
    margin-left: 0.625rem;
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mx-3\.5{
    margin-left: 0.875rem;
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-7{
    margin-left: -1.75rem;
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-9{
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-11{
    margin-left: -2.75rem;
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-14{
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-28{
    margin-left: -7rem;
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-36{
    margin-left: -9rem;
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-44{
    margin-left: -11rem;
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-52{
    margin-left: -13rem;
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-60{
    margin-left: -15rem;
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-72{
    margin-left: -18rem;
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-80{
    margin-left: -20rem;
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-96{
    margin-left: -24rem;
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-0\.5{
    margin-left: -0.125rem;
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-1\.5{
    margin-left: -0.375rem;
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-2\.5{
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mx-3\.5{
    margin-left: -0.875rem;
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-7{
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-9{
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-11{
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-14{
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-28{
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-36{
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-44{
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-52{
    margin-top: 13rem;
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-60{
    margin-top: 15rem;
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-72{
    margin-top: 18rem;
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-80{
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-96{
    margin-top: 24rem;
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-0\.5{
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-1\.5{
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-2\.5{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-my-3\.5{
    margin-top: 0.875rem;
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-7{
    margin-top: -1.75rem;
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-9{
    margin-top: -2.25rem;
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-11{
    margin-top: -2.75rem;
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-14{
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-28{
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-36{
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-44{
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-52{
    margin-top: -13rem;
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-60{
    margin-top: -15rem;
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-72{
    margin-top: -18rem;
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-80{
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-96{
    margin-top: -24rem;
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-0\.5{
    margin-top: -0.125rem;
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-2\.5{
    margin-top: -0.625rem;
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--my-3\.5{
    margin-top: -0.875rem;
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-1{
    margin-top: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-2{
    margin-top: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-3{
    margin-top: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-4{
    margin-top: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-5{
    margin-top: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-6{
    margin-top: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-7{
    margin-top: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-8{
    margin-top: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-9{
    margin-top: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-10{
    margin-top: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-11{
    margin-top: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-12{
    margin-top: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-14{
    margin-top: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-16{
    margin-top: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-20{
    margin-top: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-24{
    margin-top: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-28{
    margin-top: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-32{
    margin-top: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-36{
    margin-top: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-40{
    margin-top: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-44{
    margin-top: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-48{
    margin-top: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-52{
    margin-top: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-56{
    margin-top: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-60{
    margin-top: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-64{
    margin-top: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-72{
    margin-top: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-80{
    margin-top: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-96{
    margin-top: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-auto{
    margin-top: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-px{
    margin-top: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-0\.5{
    margin-top: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-1\.5{
    margin-top: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-2\.5{
    margin-top: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mt-3\.5{
    margin-top: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-1{
    margin-top: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-2{
    margin-top: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-3{
    margin-top: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-4{
    margin-top: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-5{
    margin-top: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-6{
    margin-top: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-7{
    margin-top: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-8{
    margin-top: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-9{
    margin-top: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-10{
    margin-top: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-11{
    margin-top: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-12{
    margin-top: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-14{
    margin-top: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-16{
    margin-top: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-20{
    margin-top: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-24{
    margin-top: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-28{
    margin-top: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-32{
    margin-top: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-36{
    margin-top: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-40{
    margin-top: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-44{
    margin-top: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-48{
    margin-top: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-52{
    margin-top: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-56{
    margin-top: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-60{
    margin-top: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-64{
    margin-top: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-72{
    margin-top: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-80{
    margin-top: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-96{
    margin-top: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-px{
    margin-top: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-0\.5{
    margin-top: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-1\.5{
    margin-top: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-2\.5{
    margin-top: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mt-3\.5{
    margin-top: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-1{
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-2{
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-3{
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-4{
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-5{
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-6{
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-7{
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-8{
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-9{
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-10{
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-11{
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-12{
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-14{
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-16{
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-20{
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-24{
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-28{
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-32{
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-36{
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-40{
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-44{
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-48{
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-52{
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-56{
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-60{
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-64{
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-72{
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-80{
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-96{
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-auto{
    margin-right: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-px{
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-0\.5{
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-1\.5{
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-2\.5{
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mr-3\.5{
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-1{
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-2{
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-3{
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-4{
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-5{
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-6{
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-7{
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-8{
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-9{
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-10{
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-11{
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-12{
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-14{
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-16{
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-20{
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-24{
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-28{
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-32{
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-36{
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-40{
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-44{
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-48{
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-52{
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-56{
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-60{
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-64{
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-72{
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-80{
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-96{
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-px{
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-0\.5{
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-1\.5{
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-2\.5{
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mr-3\.5{
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-1{
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-2{
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-3{
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-4{
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-5{
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-6{
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-7{
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-8{
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-9{
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-10{
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-11{
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-12{
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-14{
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-16{
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-20{
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-24{
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-28{
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-32{
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-36{
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-40{
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-44{
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-48{
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-52{
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-56{
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-60{
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-64{
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-72{
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-80{
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-96{
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-auto{
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-px{
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-0\.5{
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-1\.5{
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-2\.5{
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mb-3\.5{
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-1{
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-2{
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-3{
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-4{
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-5{
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-6{
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-7{
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-8{
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-9{
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-10{
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-11{
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-12{
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-14{
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-16{
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-20{
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-24{
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-28{
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-32{
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-36{
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-40{
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-44{
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-48{
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-52{
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-56{
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-60{
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-64{
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-72{
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-80{
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-96{
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-px{
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-0\.5{
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-1\.5{
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-2\.5{
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--mb-3\.5{
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-1{
    margin-left: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-2{
    margin-left: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-3{
    margin-left: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-4{
    margin-left: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-5{
    margin-left: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-6{
    margin-left: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-7{
    margin-left: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-8{
    margin-left: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-9{
    margin-left: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-10{
    margin-left: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-11{
    margin-left: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-12{
    margin-left: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-14{
    margin-left: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-16{
    margin-left: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-20{
    margin-left: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-24{
    margin-left: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-28{
    margin-left: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-32{
    margin-left: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-36{
    margin-left: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-40{
    margin-left: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-44{
    margin-left: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-48{
    margin-left: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-52{
    margin-left: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-56{
    margin-left: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-60{
    margin-left: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-64{
    margin-left: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-72{
    margin-left: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-80{
    margin-left: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-96{
    margin-left: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-auto{
    margin-left: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-px{
    margin-left: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-0\.5{
    margin-left: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-1\.5{
    margin-left: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-2\.5{
    margin-left: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ml-3\.5{
    margin-left: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-1{
    margin-left: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-2{
    margin-left: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-3{
    margin-left: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-4{
    margin-left: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-5{
    margin-left: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-6{
    margin-left: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-7{
    margin-left: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-8{
    margin-left: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-9{
    margin-left: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-10{
    margin-left: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-11{
    margin-left: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-12{
    margin-left: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-14{
    margin-left: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-16{
    margin-left: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-20{
    margin-left: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-24{
    margin-left: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-28{
    margin-left: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-32{
    margin-left: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-36{
    margin-left: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-40{
    margin-left: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-44{
    margin-left: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-48{
    margin-left: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-52{
    margin-left: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-56{
    margin-left: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-60{
    margin-left: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-64{
    margin-left: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-72{
    margin-left: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-80{
    margin-left: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-96{
    margin-left: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-px{
    margin-left: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-0\.5{
    margin-left: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-1\.5{
    margin-left: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-2\.5{
    margin-left: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--ml-3\.5{
    margin-left: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-box-border{
    box-sizing: border-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-box-content{
    box-sizing: content-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-block{
    display: block
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inline-block{
    display: inline-block
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inline{
    display: inline
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex{
    display: flex
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inline-flex{
    display: inline-flex
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table{
    display: table
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inline-table{
    display: inline-table
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-caption{
    display: table-caption
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-cell{
    display: table-cell
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-column{
    display: table-column
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-column-group{
    display: table-column-group
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-footer-group{
    display: table-footer-group
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-header-group{
    display: table-header-group
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-row-group{
    display: table-row-group
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-row{
    display: table-row
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flow-root{
    display: flow-root
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid{
    display: grid
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-inline-grid{
    display: inline-grid
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contents{
    display: contents
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-list-item{
    display: list-item
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hidden{
    display: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-0{
    height: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1{
    height: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-2{
    height: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-3{
    height: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-4{
    height: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-5{
    height: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-6{
    height: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-7{
    height: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-8{
    height: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-9{
    height: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-10{
    height: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-11{
    height: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-12{
    height: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-14{
    height: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-16{
    height: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-20{
    height: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-24{
    height: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-28{
    height: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-32{
    height: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-36{
    height: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-40{
    height: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-44{
    height: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-48{
    height: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-52{
    height: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-56{
    height: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-60{
    height: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-64{
    height: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-72{
    height: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-80{
    height: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-96{
    height: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-auto{
    height: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-px{
    height: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-0\.5{
    height: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1\.5{
    height: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-2\.5{
    height: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-3\.5{
    height: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1\/2{
    height: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1\/3{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-2\/3{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1\/4{
    height: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-2\/4{
    height: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-3\/4{
    height: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1\/5{
    height: 20%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-2\/5{
    height: 40%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-3\/5{
    height: 60%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-4\/5{
    height: 80%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-1\/6{
    height: 16.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-2\/6{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-3\/6{
    height: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-4\/6{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-5\/6{
    height: 83.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-full{
    height: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-h-screen{
    height: 100vh
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-0{
    max-height: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-1{
    max-height: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-2{
    max-height: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-3{
    max-height: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-4{
    max-height: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-5{
    max-height: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-6{
    max-height: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-7{
    max-height: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-8{
    max-height: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-9{
    max-height: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-10{
    max-height: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-11{
    max-height: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-12{
    max-height: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-14{
    max-height: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-16{
    max-height: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-20{
    max-height: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-24{
    max-height: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-28{
    max-height: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-32{
    max-height: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-36{
    max-height: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-40{
    max-height: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-44{
    max-height: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-48{
    max-height: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-52{
    max-height: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-56{
    max-height: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-60{
    max-height: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-64{
    max-height: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-72{
    max-height: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-80{
    max-height: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-96{
    max-height: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-px{
    max-height: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-0\.5{
    max-height: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-1\.5{
    max-height: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-2\.5{
    max-height: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-3\.5{
    max-height: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-full{
    max-height: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-h-screen{
    max-height: 100vh
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-h-0{
    min-height: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-h-full{
    min-height: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-h-screen{
    min-height: 100vh
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-0{
    width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1{
    width: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2{
    width: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-3{
    width: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-4{
    width: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-5{
    width: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-6{
    width: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-7{
    width: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-8{
    width: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-9{
    width: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-10{
    width: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-11{
    width: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-12{
    width: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-14{
    width: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-16{
    width: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-20{
    width: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-24{
    width: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-28{
    width: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-32{
    width: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-36{
    width: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-40{
    width: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-44{
    width: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-48{
    width: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-52{
    width: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-56{
    width: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-60{
    width: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-64{
    width: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-72{
    width: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-80{
    width: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-96{
    width: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-auto{
    width: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-px{
    width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-0\.5{
    width: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\.5{
    width: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2\.5{
    width: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-3\.5{
    width: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\/2{
    width: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\/3{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2\/3{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\/4{
    width: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2\/4{
    width: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-3\/4{
    width: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\/5{
    width: 20%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2\/5{
    width: 40%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-3\/5{
    width: 60%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-4\/5{
    width: 80%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\/6{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2\/6{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-3\/6{
    width: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-4\/6{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-5\/6{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-1\/12{
    width: 8.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-2\/12{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-3\/12{
    width: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-4\/12{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-5\/12{
    width: 41.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-6\/12{
    width: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-7\/12{
    width: 58.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-8\/12{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-9\/12{
    width: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-10\/12{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-11\/12{
    width: 91.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-full{
    width: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-screen{
    width: 100vw
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-min{
    width: min-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-w-max{
    width: max-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-w-0{
    min-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-w-full{
    min-width: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-w-min{
    min-width: min-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-min-w-max{
    min-width: max-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-0{
    max-width: 0rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-none{
    max-width: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-xs{
    max-width: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-sm{
    max-width: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-md{
    max-width: 28rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-lg{
    max-width: 32rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-xl{
    max-width: 36rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-2xl{
    max-width: 42rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-3xl{
    max-width: 48rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-4xl{
    max-width: 56rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-5xl{
    max-width: 64rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-6xl{
    max-width: 72rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-7xl{
    max-width: 80rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-full{
    max-width: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-min{
    max-width: min-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-max{
    max-width: max-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-prose{
    max-width: 65ch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-screen-sm{
    max-width: 640px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-screen-md{
    max-width: 768px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-screen-lg{
    max-width: 1024px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-screen-xl{
    max-width: 1280px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-max-w-screen-2xl{
    max-width: 1536px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-1{
    flex: 1 1 0%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-auto{
    flex: 1 1 auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-initial{
    flex: 0 1 auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-none{
    flex: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-shrink-0{
    flex-shrink: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-shrink{
    flex-shrink: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-grow-0{
    flex-grow: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-grow{
    flex-grow: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-auto{
    table-layout: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-table-fixed{
    table-layout: fixed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-collapse{
    border-collapse: collapse
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-separate{
    border-collapse: separate
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-center{
    transform-origin: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-top{
    transform-origin: top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-top-right{
    transform-origin: top right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-right{
    transform-origin: right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-bottom-right{
    transform-origin: bottom right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-bottom{
    transform-origin: bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-bottom-left{
    transform-origin: bottom left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-left{
    transform-origin: left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-origin-top-left{
    transform-origin: top left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transform-none{
    transform: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-1{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-2{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-3{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-4{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-5{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-6{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-7{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-8{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-9{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-10{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-11{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-12{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-14{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-16{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-20{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-24{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-28{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-32{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-36{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-40{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-44{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-48{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-52{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-56{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-60{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-64{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-72{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-80{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-96{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-px{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-0\.5{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-1\.5{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-2\.5{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-3\.5{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-1{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-2{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-3{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-4{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-5{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-6{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-7{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-8{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-9{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-10{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-11{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-12{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-14{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-16{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-20{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-24{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-28{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-32{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-36{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-40{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-44{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-48{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-52{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-56{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-60{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-64{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-72{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-80{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-96{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-px{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-0\.5{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-1\.5{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-2\.5{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-3\.5{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-1\/2{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-1\/3{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-2\/3{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-1\/4{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-2\/4{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-3\/4{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-x-full{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-1\/2{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-1\/3{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-2\/3{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-1\/4{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-2\/4{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-3\/4{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-x-full{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-1{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-2{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-3{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-4{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-5{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-6{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-7{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-8{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-9{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-10{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-11{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-12{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-14{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-16{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-20{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-24{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-28{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-32{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-36{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-40{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-44{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-48{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-52{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-56{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-60{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-64{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-72{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-80{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-96{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-px{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-0\.5{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-1\.5{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-2\.5{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-3\.5{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-1{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-2{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-3{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-4{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-5{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-6{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-7{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-8{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-9{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-10{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-11{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-12{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-14{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-16{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-20{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-24{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-28{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-32{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-36{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-40{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-44{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-48{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-52{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-56{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-60{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-64{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-72{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-80{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-96{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-px{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-0\.5{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-1\.5{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-2\.5{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-3\.5{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-1\/2{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-1\/3{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-2\/3{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-1\/4{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-2\/4{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-3\/4{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-translate-y-full{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-1\/2{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-1\/3{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-2\/3{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-1\/4{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-2\/4{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-3\/4{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--translate-y-full{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-1:hover{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-2:hover{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-3:hover{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-4:hover{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-5:hover{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-6:hover{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-7:hover{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-8:hover{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-9:hover{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-10:hover{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-11:hover{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-12:hover{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-14:hover{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-16:hover{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-20:hover{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-24:hover{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-28:hover{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-32:hover{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-36:hover{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-40:hover{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-44:hover{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-48:hover{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-52:hover{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-56:hover{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-60:hover{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-64:hover{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-72:hover{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-80:hover{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-96:hover{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-px:hover{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-1:hover{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-2:hover{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-3:hover{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-4:hover{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-5:hover{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-6:hover{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-7:hover{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-8:hover{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-9:hover{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-10:hover{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-11:hover{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-12:hover{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-14:hover{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-16:hover{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-20:hover{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-24:hover{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-28:hover{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-32:hover{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-36:hover{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-40:hover{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-44:hover{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-48:hover{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-52:hover{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-56:hover{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-60:hover{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-64:hover{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-72:hover{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-80:hover{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-96:hover{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-px:hover{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-1\/2:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-1\/4:hover{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-2\/4:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-3\/4:hover{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-x-full:hover{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-1\/2:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-1\/4:hover{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-2\/4:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-3\/4:hover{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-x-full:hover{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-1:hover{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-2:hover{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-3:hover{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-4:hover{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-5:hover{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-6:hover{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-7:hover{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-8:hover{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-9:hover{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-10:hover{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-11:hover{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-12:hover{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-14:hover{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-16:hover{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-20:hover{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-24:hover{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-28:hover{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-32:hover{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-36:hover{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-40:hover{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-44:hover{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-48:hover{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-52:hover{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-56:hover{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-60:hover{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-64:hover{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-72:hover{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-80:hover{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-96:hover{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-px:hover{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-1:hover{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-2:hover{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-3:hover{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-4:hover{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-5:hover{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-6:hover{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-7:hover{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-8:hover{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-9:hover{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-10:hover{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-11:hover{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-12:hover{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-14:hover{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-16:hover{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-20:hover{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-24:hover{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-28:hover{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-32:hover{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-36:hover{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-40:hover{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-44:hover{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-48:hover{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-52:hover{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-56:hover{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-60:hover{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-64:hover{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-72:hover{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-80:hover{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-96:hover{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-px:hover{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-1\/2:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-1\/4:hover{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-2\/4:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-3\/4:hover{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-translate-y-full:hover{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-1\/2:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-1\/4:hover{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-2\/4:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-3\/4:hover{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--translate-y-full:hover{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-1:focus{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-2:focus{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-3:focus{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-4:focus{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-5:focus{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-6:focus{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-7:focus{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-8:focus{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-9:focus{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-10:focus{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-11:focus{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-12:focus{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-14:focus{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-16:focus{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-20:focus{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-24:focus{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-28:focus{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-32:focus{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-36:focus{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-40:focus{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-44:focus{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-48:focus{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-52:focus{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-56:focus{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-60:focus{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-64:focus{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-72:focus{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-80:focus{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-96:focus{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-px:focus{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-1:focus{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-2:focus{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-3:focus{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-4:focus{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-5:focus{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-6:focus{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-7:focus{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-8:focus{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-9:focus{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-10:focus{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-11:focus{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-12:focus{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-14:focus{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-16:focus{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-20:focus{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-24:focus{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-28:focus{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-32:focus{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-36:focus{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-40:focus{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-44:focus{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-48:focus{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-52:focus{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-56:focus{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-60:focus{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-64:focus{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-72:focus{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-80:focus{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-96:focus{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-px:focus{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-1\/2:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-1\/4:focus{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-2\/4:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-3\/4:focus{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-x-full:focus{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-1\/2:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-1\/4:focus{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-2\/4:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-3\/4:focus{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-x-full:focus{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-1:focus{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-2:focus{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-3:focus{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-4:focus{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-5:focus{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-6:focus{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-7:focus{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-8:focus{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-9:focus{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-10:focus{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-11:focus{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-12:focus{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-14:focus{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-16:focus{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-20:focus{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-24:focus{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-28:focus{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-32:focus{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-36:focus{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-40:focus{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-44:focus{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-48:focus{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-52:focus{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-56:focus{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-60:focus{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-64:focus{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-72:focus{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-80:focus{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-96:focus{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-px:focus{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-1:focus{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-2:focus{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-3:focus{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-4:focus{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-5:focus{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-6:focus{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-7:focus{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-8:focus{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-9:focus{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-10:focus{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-11:focus{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-12:focus{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-14:focus{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-16:focus{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-20:focus{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-24:focus{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-28:focus{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-32:focus{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-36:focus{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-40:focus{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-44:focus{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-48:focus{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-52:focus{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-56:focus{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-60:focus{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-64:focus{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-72:focus{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-80:focus{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-96:focus{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-px:focus{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-1\/2:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-1\/4:focus{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-2\/4:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-3\/4:focus{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-translate-y-full:focus{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-1\/2:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-1\/4:focus{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-2\/4:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-3\/4:focus{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--translate-y-full:focus{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-0{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-1{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-2{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-3{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-6{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-12{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-45{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-90{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rotate-180{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-180{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-90{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-45{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-12{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-6{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-3{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-2{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--rotate-1{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-0:hover{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-1:hover{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-2:hover{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-3:hover{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-6:hover{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-12:hover{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-45:hover{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-90:hover{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-rotate-180:hover{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-180:hover{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-90:hover{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-45:hover{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-12:hover{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-6:hover{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-3:hover{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-2:hover{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--rotate-1:hover{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-0:focus{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-1:focus{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-2:focus{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-3:focus{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-6:focus{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-12:focus{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-45:focus{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-90:focus{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-rotate-180:focus{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-180:focus{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-90:focus{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-45:focus{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-12:focus{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-6:focus{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-3:focus{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-2:focus{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--rotate-1:focus{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-x-0{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-x-1{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-x-2{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-x-3{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-x-6{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-x-12{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-x-12{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-x-6{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-x-3{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-x-2{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-x-1{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-y-0{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-y-1{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-y-2{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-y-3{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-y-6{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-skew-y-12{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-y-12{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-y-6{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-y-3{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-y-2{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--skew-y-1{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-x-0:hover{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-x-1:hover{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-x-2:hover{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-x-3:hover{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-x-6:hover{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-x-12:hover{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-x-12:hover{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-x-6:hover{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-x-3:hover{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-x-2:hover{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-x-1:hover{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-y-0:hover{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-y-1:hover{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-y-2:hover{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-y-3:hover{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-y-6:hover{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-skew-y-12:hover{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-y-12:hover{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-y-6:hover{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-y-3:hover{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-y-2:hover{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget--skew-y-1:hover{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-x-0:focus{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-x-1:focus{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-x-2:focus{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-x-3:focus{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-x-6:focus{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-x-12:focus{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-x-12:focus{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-x-6:focus{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-x-3:focus{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-x-2:focus{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-x-1:focus{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-y-0:focus{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-y-1:focus{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-y-2:focus{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-y-3:focus{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-y-6:focus{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-skew-y-12:focus{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-y-12:focus{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-y-6:focus{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-y-3:focus{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-y-2:focus{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget--skew-y-1:focus{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-0{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-50{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-75{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-90{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-95{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-100{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-105{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-110{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-125{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-x-150{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-0{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-50{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-75{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-90{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-95{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-100{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-105{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-110{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-125{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-scale-y-150{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-0:hover{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-50:hover{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-75:hover{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-90:hover{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-95:hover{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-100:hover{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-105:hover{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-110:hover{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-125:hover{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-x-150:hover{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-0:hover{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-50:hover{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-75:hover{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-90:hover{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-95:hover{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-100:hover{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-105:hover{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-110:hover{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-125:hover{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-scale-y-150:hover{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-0:focus{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-50:focus{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-75:focus{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-90:focus{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-95:focus{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-100:focus{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-105:focus{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-110:focus{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-125:focus{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-x-150:focus{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-0:focus{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-50:focus{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-75:focus{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-90:focus{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-95:focus{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-100:focus{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-105:focus{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-110:focus{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-125:focus{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-scale-y-150:focus{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-animate-none{
    animation: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-animate-spin{
    animation: skindr-widget-spin 1s linear infinite
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-animate-ping{
    animation: skindr-widget-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-animate-pulse{
    animation: skindr-widget-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-animate-bounce{
    animation: skindr-widget-bounce 1s infinite
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-auto{
    cursor: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-default{
    cursor: default
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-pointer{
    cursor: pointer
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-wait{
    cursor: wait
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-text{
    cursor: text
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-move{
    cursor: move
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-help{
    cursor: help
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-cursor-not-allowed{
    cursor: not-allowed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-select-none{
    -webkit-user-select: none;
            user-select: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-select-text{
    -webkit-user-select: text;
            user-select: text
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-select-all{
    -webkit-user-select: all;
            user-select: all
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-select-auto{
    -webkit-user-select: auto;
            user-select: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-resize-none{
    resize: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-resize-y{
    resize: vertical
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-resize-x{
    resize: horizontal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-resize{
    resize: both
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-list-inside{
    list-style-position: inside
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-list-outside{
    list-style-position: outside
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-list-none{
    list-style-type: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-list-disc{
    list-style-type: disc
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-list-decimal{
    list-style-type: decimal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-appearance-none{
    -webkit-appearance: none;
            appearance: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-cols-auto{
    grid-auto-columns: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-cols-min{
    grid-auto-columns: min-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-cols-max{
    grid-auto-columns: max-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-flow-row{
    grid-auto-flow: row
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-flow-col{
    grid-auto-flow: column
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-rows-auto{
    grid-auto-rows: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-rows-min{
    grid-auto-rows: min-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-rows-max{
    grid-auto-rows: max-content
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-cols-none{
    grid-template-columns: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grid-rows-none{
    grid-template-rows: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-row{
    flex-direction: row
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-row-reverse{
    flex-direction: row-reverse
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-col{
    flex-direction: column
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-col-reverse{
    flex-direction: column-reverse
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-wrap{
    flex-wrap: wrap
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-flex-nowrap{
    flex-wrap: nowrap
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-center{
    place-content: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-start{
    place-content: start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-end{
    place-content: end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-between{
    place-content: space-between
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-around{
    place-content: space-around
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-evenly{
    place-content: space-evenly
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-content-stretch{
    place-content: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-items-start{
    place-items: start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-items-end{
    place-items: end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-items-center{
    place-items: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-items-stretch{
    place-items: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-content-center{
    align-content: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-content-start{
    align-content: flex-start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-content-end{
    align-content: flex-end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-content-between{
    align-content: space-between
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-content-around{
    align-content: space-around
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-content-evenly{
    align-content: space-evenly
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-items-start{
    align-items: flex-start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-items-end{
    align-items: flex-end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-items-center{
    align-items: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-items-baseline{
    align-items: baseline
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-items-stretch{
    align-items: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-start{
    justify-content: flex-start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-end{
    justify-content: flex-end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-center{
    justify-content: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-between{
    justify-content: space-between
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-around{
    justify-content: space-around
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-evenly{
    justify-content: space-evenly
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-items-start{
    justify-items: start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-items-end{
    justify-items: end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-items-center{
    justify-items: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-items-stretch{
    justify-items: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-0{
    gap: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-1{
    gap: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-2{
    gap: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-3{
    gap: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-4{
    gap: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-5{
    gap: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-6{
    gap: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-7{
    gap: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-8{
    gap: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-9{
    gap: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-10{
    gap: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-11{
    gap: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-12{
    gap: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-14{
    gap: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-16{
    gap: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-20{
    gap: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-24{
    gap: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-28{
    gap: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-32{
    gap: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-36{
    gap: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-40{
    gap: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-44{
    gap: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-48{
    gap: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-52{
    gap: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-56{
    gap: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-60{
    gap: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-64{
    gap: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-72{
    gap: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-80{
    gap: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-96{
    gap: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-px{
    gap: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-0\.5{
    gap: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-1\.5{
    gap: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-2\.5{
    gap: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-3\.5{
    gap: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-0{
    column-gap: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-1{
    column-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-2{
    column-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-3{
    column-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-4{
    column-gap: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-5{
    column-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-6{
    column-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-7{
    column-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-8{
    column-gap: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-9{
    column-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-10{
    column-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-11{
    column-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-12{
    column-gap: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-14{
    column-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-16{
    column-gap: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-20{
    column-gap: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-24{
    column-gap: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-28{
    column-gap: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-32{
    column-gap: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-36{
    column-gap: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-40{
    column-gap: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-44{
    column-gap: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-48{
    column-gap: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-52{
    column-gap: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-56{
    column-gap: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-60{
    column-gap: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-64{
    column-gap: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-72{
    column-gap: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-80{
    column-gap: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-96{
    column-gap: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-px{
    column-gap: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-0\.5{
    column-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-1\.5{
    column-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-2\.5{
    column-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-x-3\.5{
    column-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-0{
    row-gap: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-1{
    row-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-2{
    row-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-3{
    row-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-4{
    row-gap: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-5{
    row-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-6{
    row-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-7{
    row-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-8{
    row-gap: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-9{
    row-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-10{
    row-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-11{
    row-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-12{
    row-gap: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-14{
    row-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-16{
    row-gap: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-20{
    row-gap: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-24{
    row-gap: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-28{
    row-gap: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-32{
    row-gap: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-36{
    row-gap: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-40{
    row-gap: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-44{
    row-gap: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-48{
    row-gap: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-52{
    row-gap: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-56{
    row-gap: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-60{
    row-gap: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-64{
    row-gap: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-72{
    row-gap: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-80{
    row-gap: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-96{
    row-gap: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-px{
    row-gap: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-0\.5{
    row-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-1\.5{
    row-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-2\.5{
    row-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-gap-y-3\.5{
    row-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-self-auto{
    place-self: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-self-start{
    place-self: start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-self-end{
    place-self: end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-self-center{
    place-self: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-place-self-stretch{
    place-self: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-self-auto{
    align-self: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-self-start{
    align-self: flex-start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-self-end{
    align-self: flex-end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-self-center{
    align-self: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-self-stretch{
    align-self: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-self-baseline{
    align-self: baseline
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-self-auto{
    justify-self: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-self-start{
    justify-self: start
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-self-end{
    justify-self: end
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-self-center{
    justify-self: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-justify-self-stretch{
    justify-self: stretch
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-auto{
    overflow: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-hidden{
    overflow: hidden
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-visible{
    overflow: visible
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-scroll{
    overflow: scroll
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-x-auto{
    overflow-x: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-y-auto{
    overflow-y: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-x-hidden{
    overflow-x: hidden
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-y-hidden{
    overflow-y: hidden
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-x-visible{
    overflow-x: visible
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-y-visible{
    overflow-y: visible
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-x-scroll{
    overflow-x: scroll
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-y-scroll{
    overflow-y: scroll
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-auto{
    overscroll-behavior: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-contain{
    overscroll-behavior: contain
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-none{
    overscroll-behavior: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-y-none{
    overscroll-behavior-y: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overscroll-x-none{
    overscroll-behavior-x: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-ellipsis{
    text-overflow: ellipsis
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-overflow-clip{
    text-overflow: clip
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-whitespace-normal{
    white-space: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-whitespace-nowrap{
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-whitespace-pre{
    white-space: pre
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-whitespace-pre-line{
    white-space: pre-line
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-break-normal{
    overflow-wrap: normal;
    word-break: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-break-words{
    overflow-wrap: break-word
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-break-all{
    word-break: break-all
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-none{
    border-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-sm{
    border-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded{
    border-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-md{
    border-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-lg{
    border-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-xl{
    border-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-2xl{
    border-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-3xl{
    border-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-full{
    border-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-none{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-none{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-none{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-none{
    border-top-left-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-md{
    border-top-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-xl{
    border-top-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-2xl{
    border-top-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-3xl{
    border-top-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-none{
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-md{
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-xl{
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-2xl{
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-3xl{
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-none{
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-md{
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-xl{
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-2xl{
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-none{
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-md{
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-2xl{
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-0{
    border-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-2{
    border-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-4{
    border-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-8{
    border-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border{
    border-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-t-0{
    border-top-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-t-2{
    border-top-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-t-4{
    border-top-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-t-8{
    border-top-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-t{
    border-top-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-r-0{
    border-right-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-r-2{
    border-right-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-r-4{
    border-right-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-r-8{
    border-right-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-r{
    border-right-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-b-0{
    border-bottom-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-b-2{
    border-bottom-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-b-4{
    border-bottom-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-b-8{
    border-bottom-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-b{
    border-bottom-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-l-0{
    border-left-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-l-2{
    border-left-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-l-4{
    border-left-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-l-8{
    border-left-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-l{
    border-left-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-solid{
    border-style: solid
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-dashed{
    border-style: dashed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-dotted{
    border-style: dotted
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-double{
    border-style: double
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-none{
    border-style: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-transparent:focus-within{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-current:focus-within{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-black:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-white:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-gray-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-red-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-yellow-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-green-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-blue-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-indigo-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-purple-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-pink-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-transparent:hover{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-current:hover{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-red-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-yellow-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-green-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-indigo-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-purple-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-pink-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-transparent:focus{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-current:focus{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-red-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-yellow-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-green-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-indigo-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-purple-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-pink-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-0:focus-within{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-border-opacity-100:focus-within{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-0:hover{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-5:hover{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-10:hover{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-20:hover{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-25:hover{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-30:hover{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-40:hover{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-50:hover{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-60:hover{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-70:hover{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-75:hover{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-80:hover{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-90:hover{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-95:hover{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-border-opacity-100:hover{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-0:focus{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-5:focus{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-10:focus{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-20:focus{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-25:focus{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-30:focus{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-40:focus{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-50:focus{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-60:focus{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-70:focus{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-75:focus{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-80:focus{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-90:focus{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-95:focus{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-border-opacity-100:focus{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-transparent:focus-within{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-current:focus-within{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-black:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-white:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-gray-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-red-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-green-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-blue-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-purple-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-pink-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-transparent:hover{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-current:hover{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-red-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-yellow-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-green-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-indigo-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-purple-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-pink-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-transparent:focus{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-current:focus{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-red-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-yellow-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-green-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-indigo-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-purple-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-pink-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-0:hover{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-bg-opacity-100:hover{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-0:focus{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-bg-opacity-100:focus{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-none{
    background-image: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-black{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-50{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-100{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-200{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-300{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-400{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-500{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-600{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-700{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-800{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-gray-900{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-50{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-100{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-200{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-300{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-400{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-500{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-600{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-700{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-800{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-red-900{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-50{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-100{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-200{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-300{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-400{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-500{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-600{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-700{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-800{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-yellow-900{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-50{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-100{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-200{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-300{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-400{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-500{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-600{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-700{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-800{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-green-900{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-50{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-100{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-200{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-300{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-400{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-500{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-600{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-700{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-800{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-blue-900{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-50{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-100{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-200{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-300{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-400{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-500{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-600{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-700{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-800{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-indigo-900{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-50{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-100{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-200{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-300{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-400{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-500{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-600{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-700{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-800{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-purple-900{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-50{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-100{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-200{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-300{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-400{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-500{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-600{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-700{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-800{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-from-pink-900{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-black:hover{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-50:hover{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-300:hover{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-400:hover{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-500:hover{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-600:hover{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-700:hover{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-800:hover{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-gray-900:hover{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-50:hover{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-100:hover{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-200:hover{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-300:hover{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-400:hover{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-500:hover{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-600:hover{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-700:hover{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-800:hover{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-red-900:hover{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-200:hover{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-600:hover{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-700:hover{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-800:hover{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-yellow-900:hover{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-50:hover{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-100:hover{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-200:hover{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-300:hover{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-400:hover{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-500:hover{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-600:hover{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-700:hover{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-800:hover{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-green-900:hover{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-50:hover{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-100:hover{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-300:hover{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-400:hover{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-500:hover{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-600:hover{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-800:hover{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-400:hover{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-500:hover{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-700:hover{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-800:hover{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-indigo-900:hover{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-100:hover{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-400:hover{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-600:hover{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-700:hover{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-800:hover{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-purple-900:hover{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-100:hover{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-400:hover{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-500:hover{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-600:hover{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-700:hover{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-800:hover{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-from-pink-900:hover{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-black:focus{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-50:focus{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-300:focus{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-400:focus{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-500:focus{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-600:focus{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-700:focus{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-800:focus{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-gray-900:focus{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-50:focus{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-100:focus{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-200:focus{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-300:focus{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-400:focus{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-500:focus{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-600:focus{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-700:focus{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-800:focus{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-red-900:focus{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-200:focus{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-600:focus{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-700:focus{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-800:focus{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-yellow-900:focus{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-50:focus{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-100:focus{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-200:focus{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-300:focus{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-400:focus{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-500:focus{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-600:focus{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-700:focus{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-800:focus{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-green-900:focus{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-50:focus{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-100:focus{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-300:focus{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-400:focus{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-500:focus{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-600:focus{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-800:focus{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-400:focus{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-500:focus{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-700:focus{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-800:focus{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-indigo-900:focus{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-100:focus{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-400:focus{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-600:focus{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-700:focus{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-800:focus{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-purple-900:focus{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-100:focus{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-400:focus{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-500:focus{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-600:focus{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-700:focus{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-800:focus{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-from-pink-900:focus{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-transparent{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-current{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-black{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-white{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-50{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-100{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-200{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-300{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-400{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-500{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-600{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-700{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-800{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-gray-900{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-50{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-100{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-200{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-300{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-400{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-500{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-600{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-700{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-800{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-red-900{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-50{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-100{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-200{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-300{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-400{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-500{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-600{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-700{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-800{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-yellow-900{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-50{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-100{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-200{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-300{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-400{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-500{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-600{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-700{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-800{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-green-900{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-50{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-100{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-200{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-300{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-400{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-500{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-600{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-700{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-800{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-blue-900{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-50{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-100{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-200{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-300{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-400{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-500{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-600{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-700{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-800{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-indigo-900{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-50{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-100{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-200{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-300{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-400{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-500{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-600{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-700{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-800{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-purple-900{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-50{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-100{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-200{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-300{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-400{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-500{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-600{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-700{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-800{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-to-pink-900{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-transparent:hover{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-current:hover{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-black:hover{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-white:hover{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-50:hover{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-300:hover{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-400:hover{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-500:hover{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-600:hover{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-700:hover{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-800:hover{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-gray-900:hover{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-50:hover{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-100:hover{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-200:hover{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-300:hover{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-400:hover{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-500:hover{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-600:hover{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-700:hover{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-800:hover{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-red-900:hover{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-200:hover{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-600:hover{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-700:hover{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-800:hover{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-yellow-900:hover{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-50:hover{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-100:hover{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-200:hover{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-300:hover{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-400:hover{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-500:hover{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-600:hover{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-700:hover{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-800:hover{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-green-900:hover{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-50:hover{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-100:hover{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-300:hover{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-400:hover{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-500:hover{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-600:hover{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-800:hover{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-400:hover{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-500:hover{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-700:hover{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-800:hover{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-indigo-900:hover{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-100:hover{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-400:hover{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-600:hover{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-700:hover{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-800:hover{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-purple-900:hover{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-100:hover{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-400:hover{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-500:hover{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-600:hover{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-700:hover{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-800:hover{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-to-pink-900:hover{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-transparent:focus{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-current:focus{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-black:focus{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-white:focus{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-50:focus{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-300:focus{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-400:focus{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-500:focus{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-600:focus{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-700:focus{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-800:focus{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-gray-900:focus{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-50:focus{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-100:focus{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-200:focus{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-300:focus{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-400:focus{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-500:focus{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-600:focus{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-700:focus{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-800:focus{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-red-900:focus{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-200:focus{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-600:focus{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-700:focus{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-800:focus{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-yellow-900:focus{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-50:focus{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-100:focus{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-200:focus{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-300:focus{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-400:focus{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-500:focus{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-600:focus{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-700:focus{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-800:focus{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-green-900:focus{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-50:focus{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-100:focus{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-300:focus{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-400:focus{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-500:focus{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-600:focus{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-800:focus{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-400:focus{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-500:focus{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-700:focus{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-800:focus{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-indigo-900:focus{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-100:focus{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-400:focus{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-600:focus{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-700:focus{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-800:focus{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-purple-900:focus{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-100:focus{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-400:focus{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-500:focus{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-600:focus{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-700:focus{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-800:focus{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-to-pink-900:focus{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-decoration-slice{
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-decoration-clone{
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-auto{
    background-size: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-cover{
    background-size: cover
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-contain{
    background-size: contain
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-fixed{
    background-attachment: fixed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-local{
    background-attachment: local
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-scroll{
    background-attachment: scroll
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-clip-border{
    background-clip: border-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-clip-padding{
    background-clip: padding-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-clip-content{
    background-clip: content-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-bottom{
    background-position: bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-center{
    background-position: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-left{
    background-position: left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-left-bottom{
    background-position: left bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-left-top{
    background-position: left top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-right{
    background-position: right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-right-bottom{
    background-position: right bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-right-top{
    background-position: right top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-top{
    background-position: top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-repeat{
    background-repeat: repeat
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-no-repeat{
    background-repeat: no-repeat
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-repeat-x{
    background-repeat: repeat-x
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-repeat-y{
    background-repeat: repeat-y
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-repeat-round{
    background-repeat: round
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-repeat-space{
    background-repeat: space
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-origin-border{
    background-origin: border-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-origin-padding{
    background-origin: padding-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-origin-content{
    background-origin: content-box
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-fill-current{
    fill: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-stroke-current{
    stroke: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-stroke-0{
    stroke-width: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-stroke-1{
    stroke-width: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-stroke-2{
    stroke-width: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-contain{
    object-fit: contain
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-cover{
    object-fit: cover
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-fill{
    object-fit: fill
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-none{
    object-fit: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-scale-down{
    object-fit: scale-down
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-bottom{
    object-position: bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-center{
    object-position: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-left{
    object-position: left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-left-bottom{
    object-position: left bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-left-top{
    object-position: left top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-right{
    object-position: right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-right-bottom{
    object-position: right bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-right-top{
    object-position: right top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-object-top{
    object-position: top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-0{
    padding: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-1{
    padding: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-2{
    padding: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-3{
    padding: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-4{
    padding: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-5{
    padding: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-6{
    padding: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-7{
    padding: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-8{
    padding: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-9{
    padding: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-10{
    padding: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-11{
    padding: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-12{
    padding: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-14{
    padding: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-16{
    padding: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-20{
    padding: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-24{
    padding: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-28{
    padding: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-32{
    padding: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-36{
    padding: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-40{
    padding: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-44{
    padding: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-48{
    padding: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-52{
    padding: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-56{
    padding: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-60{
    padding: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-64{
    padding: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-72{
    padding: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-80{
    padding: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-96{
    padding: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-px{
    padding: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-0\.5{
    padding: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-1\.5{
    padding: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-2\.5{
    padding: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-p-3\.5{
    padding: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-0{
    padding-left: 0px;
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-9{
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-11{
    padding-left: 2.75rem;
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-14{
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-28{
    padding-left: 7rem;
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-36{
    padding-left: 9rem;
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-44{
    padding-left: 11rem;
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-52{
    padding-left: 13rem;
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-60{
    padding-left: 15rem;
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-72{
    padding-left: 18rem;
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-80{
    padding-left: 20rem;
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-96{
    padding-left: 24rem;
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-0{
    padding-top: 0px;
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-7{
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-9{
    padding-top: 2.25rem;
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-11{
    padding-top: 2.75rem;
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-14{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-28{
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-36{
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-44{
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-52{
    padding-top: 13rem;
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-60{
    padding-top: 15rem;
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-72{
    padding-top: 18rem;
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-80{
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-96{
    padding-top: 24rem;
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-0{
    padding-top: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-1{
    padding-top: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-2{
    padding-top: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-3{
    padding-top: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-4{
    padding-top: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-5{
    padding-top: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-6{
    padding-top: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-7{
    padding-top: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-8{
    padding-top: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-9{
    padding-top: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-10{
    padding-top: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-11{
    padding-top: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-12{
    padding-top: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-14{
    padding-top: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-16{
    padding-top: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-20{
    padding-top: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-24{
    padding-top: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-28{
    padding-top: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-32{
    padding-top: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-36{
    padding-top: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-40{
    padding-top: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-44{
    padding-top: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-48{
    padding-top: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-52{
    padding-top: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-56{
    padding-top: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-60{
    padding-top: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-64{
    padding-top: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-72{
    padding-top: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-80{
    padding-top: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-96{
    padding-top: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-px{
    padding-top: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-0\.5{
    padding-top: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-1\.5{
    padding-top: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-2\.5{
    padding-top: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pt-3\.5{
    padding-top: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-0{
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-1{
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-2{
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-3{
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-4{
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-5{
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-6{
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-7{
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-8{
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-9{
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-10{
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-11{
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-12{
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-14{
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-16{
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-20{
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-24{
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-28{
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-32{
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-36{
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-40{
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-44{
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-48{
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-52{
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-56{
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-60{
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-64{
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-72{
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-80{
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-96{
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-px{
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-0\.5{
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-1\.5{
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-2\.5{
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pr-3\.5{
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-0{
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-1{
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-2{
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-3{
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-4{
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-5{
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-6{
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-7{
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-8{
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-9{
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-10{
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-11{
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-12{
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-14{
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-16{
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-20{
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-24{
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-28{
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-32{
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-36{
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-40{
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-44{
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-48{
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-52{
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-56{
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-60{
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-64{
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-72{
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-80{
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-96{
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-px{
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-0\.5{
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-1\.5{
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-2\.5{
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pb-3\.5{
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-0{
    padding-left: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-1{
    padding-left: 0.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-2{
    padding-left: 0.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-3{
    padding-left: 0.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-4{
    padding-left: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-5{
    padding-left: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-6{
    padding-left: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-7{
    padding-left: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-8{
    padding-left: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-9{
    padding-left: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-10{
    padding-left: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-11{
    padding-left: 2.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-12{
    padding-left: 3rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-14{
    padding-left: 3.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-16{
    padding-left: 4rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-20{
    padding-left: 5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-24{
    padding-left: 6rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-28{
    padding-left: 7rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-32{
    padding-left: 8rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-36{
    padding-left: 9rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-40{
    padding-left: 10rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-44{
    padding-left: 11rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-48{
    padding-left: 12rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-52{
    padding-left: 13rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-56{
    padding-left: 14rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-60{
    padding-left: 15rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-64{
    padding-left: 16rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-72{
    padding-left: 18rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-80{
    padding-left: 20rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-96{
    padding-left: 24rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-px{
    padding-left: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-0\.5{
    padding-left: 0.125rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-1\.5{
    padding-left: 0.375rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-2\.5{
    padding-left: 0.625rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-pl-3\.5{
    padding-left: 0.875rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-left{
    text-align: left
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-center{
    text-align: center
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-right{
    text-align: right
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-justify{
    text-align: justify
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-align-baseline{
    vertical-align: baseline
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-align-top{
    vertical-align: top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-align-middle{
    vertical-align: middle
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-align-bottom{
    vertical-align: bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-align-text-top{
    vertical-align: text-top
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-align-text-bottom{
    vertical-align: text-bottom
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-xs{
    font-size: 0.75rem;
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-base{
    font-size: 1rem;
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-2xl{
    font-size: 1.5rem;
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-5xl{
    font-size: 3rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-6xl{
    font-size: 3.75rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-7xl{
    font-size: 4.5rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-8xl{
    font-size: 6rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-9xl{
    font-size: 8rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-thin{
    font-weight: 100
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-extralight{
    font-weight: 200
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-light{
    font-weight: 300
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-normal{
    font-weight: 400
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-medium{
    font-weight: 500
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-semibold{
    font-weight: 600
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-bold{
    font-weight: 700
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-extrabold{
    font-weight: 800
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-font-black{
    font-weight: 900
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-uppercase{
    text-transform: uppercase
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-lowercase{
    text-transform: lowercase
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-capitalize{
    text-transform: capitalize
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-normal-case{
    text-transform: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-italic{
    font-style: italic
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-not-italic{
    font-style: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ordinal, #skindrWidget.skindrWidget .sm\:skindr-widget-slashed-zero, #skindrWidget.skindrWidget .sm\:skindr-widget-lining-nums, #skindrWidget.skindrWidget .sm\:skindr-widget-oldstyle-nums, #skindrWidget.skindrWidget .sm\:skindr-widget-proportional-nums, #skindrWidget.skindrWidget .sm\:skindr-widget-tabular-nums, #skindrWidget.skindrWidget .sm\:skindr-widget-diagonal-fractions, #skindrWidget.skindrWidget .sm\:skindr-widget-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-normal-nums{
    font-variant-numeric: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ordinal{
    --tw-ordinal: ordinal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-slashed-zero{
    --tw-slashed-zero: slashed-zero
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-lining-nums{
    --tw-numeric-figure: lining-nums
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-proportional-nums{
    --tw-numeric-spacing: proportional-nums
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tabular-nums{
    --tw-numeric-spacing: tabular-nums
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-3{
    line-height: .75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-4{
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-5{
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-6{
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-7{
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-8{
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-9{
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-10{
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-none{
    line-height: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-tight{
    line-height: 1.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-snug{
    line-height: 1.375
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-normal{
    line-height: 1.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-relaxed{
    line-height: 1.625
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-leading-loose{
    line-height: 2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tracking-tighter{
    letter-spacing: -0.05em
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tracking-tight{
    letter-spacing: -0.025em
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tracking-normal{
    letter-spacing: 0em
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tracking-wide{
    letter-spacing: 0.025em
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tracking-wider{
    letter-spacing: 0.05em
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-tracking-widest{
    letter-spacing: 0.1em
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-transparent:focus-within{
    color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-current:focus-within{
    color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-black:focus-within{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-white:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-gray-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-red-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-yellow-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-green-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-blue-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-indigo-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-purple-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-pink-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-transparent:hover{
    color: transparent
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-current:hover{
    color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-50:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-200:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-400:hover{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-500:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-600:hover{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-700:hover{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-800:hover{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-red-900:hover{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-50:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-200:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-400:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-500:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-600:hover{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-700:hover{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-800:hover{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-yellow-900:hover{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-50:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-100:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-200:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-300:hover{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-400:hover{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-500:hover{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-600:hover{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-700:hover{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-800:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-green-900:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-50:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-50:hover{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-100:hover{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-200:hover{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-300:hover{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-400:hover{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-500:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-700:hover{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-800:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-indigo-900:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-50:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-100:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-200:hover{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-300:hover{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-400:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-500:hover{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-600:hover{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-700:hover{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-800:hover{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-purple-900:hover{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-50:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-100:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-200:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-300:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-400:hover{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-500:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-600:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-700:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-800:hover{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-pink-900:hover{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-transparent:focus{
    color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-current:focus{
    color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-50:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-50:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-200:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-400:focus{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-500:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-600:focus{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-700:focus{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-800:focus{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-red-900:focus{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-50:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-200:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-400:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-500:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-600:focus{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-700:focus{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-800:focus{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-yellow-900:focus{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-50:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-100:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-200:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-300:focus{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-400:focus{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-500:focus{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-600:focus{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-700:focus{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-800:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-green-900:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-50:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-50:focus{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-100:focus{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-200:focus{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-300:focus{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-400:focus{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-500:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-600:focus{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-700:focus{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-800:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-indigo-900:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-50:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-100:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-200:focus{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-300:focus{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-400:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-500:focus{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-600:focus{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-700:focus{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-800:focus{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-purple-900:focus{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-50:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-100:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-200:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-300:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-400:focus{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-500:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-600:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-700:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-800:focus{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-pink-900:focus{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-0:focus-within{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-text-opacity-100:focus-within{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-0:hover{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-5:hover{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-10:hover{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-20:hover{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-25:hover{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-30:hover{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-40:hover{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-50:hover{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-60:hover{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-70:hover{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-75:hover{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-80:hover{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-90:hover{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-95:hover{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-text-opacity-100:hover{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-0:focus{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-5:focus{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-10:focus{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-20:focus{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-25:focus{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-30:focus{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-40:focus{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-50:focus{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-60:focus{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-70:focus{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-75:focus{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-80:focus{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-90:focus{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-95:focus{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-text-opacity-100:focus{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-underline:focus-within{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-line-through:focus-within{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-no-underline:focus-within{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-underline:hover{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-line-through:hover{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-no-underline:hover{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-underline:focus{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-line-through:focus{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-no-underline:focus{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-transparent::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-current::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-current:focus::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-0:focus-within{
    opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-5:focus-within{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-10:focus-within{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-20:focus-within{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-25:focus-within{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-30:focus-within{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-40:focus-within{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-50:focus-within{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-60:focus-within{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-70:focus-within{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-75:focus-within{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-80:focus-within{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-90:focus-within{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-95:focus-within{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-opacity-100:focus-within{
    opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-0:hover{
    opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-5:hover{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-10:hover{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-20:hover{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-25:hover{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-30:hover{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-40:hover{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-50:hover{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-60:hover{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-70:hover{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-75:hover{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-80:hover{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-90:hover{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-95:hover{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-opacity-100:hover{
    opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-0:focus{
    opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-5:focus{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-10:focus{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-20:focus{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-25:focus{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-30:focus{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-40:focus{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-50:focus{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-60:focus{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-70:focus{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-75:focus{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-80:focus{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-90:focus{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-95:focus{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-opacity-100:focus{
    opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-normal{
    background-blend-mode: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-multiply{
    background-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-screen{
    background-blend-mode: screen
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-overlay{
    background-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-darken{
    background-blend-mode: darken
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-lighten{
    background-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-color-dodge{
    background-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-color-burn{
    background-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-hard-light{
    background-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-soft-light{
    background-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-difference{
    background-blend-mode: difference
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-exclusion{
    background-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-hue{
    background-blend-mode: hue
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-saturation{
    background-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-color{
    background-blend-mode: color
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-bg-blend-luminosity{
    background-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-normal{
    mix-blend-mode: normal
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-multiply{
    mix-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-screen{
    mix-blend-mode: screen
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-overlay{
    mix-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-darken{
    mix-blend-mode: darken
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-lighten{
    mix-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-color-dodge{
    mix-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-color-burn{
    mix-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-hard-light{
    mix-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-soft-light{
    mix-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-difference{
    mix-blend-mode: difference
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-exclusion{
    mix-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-hue{
    mix-blend-mode: hue
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-saturation{
    mix-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-color{
    mix-blend-mode: color
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-mix-blend-luminosity{
    mix-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .sm\:group-hover\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:hover\:skindr-widget-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-outline-white:focus-within{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-outline-black:focus-within{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-inset{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-inset:focus-within{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-inset:focus{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-transparent{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-current{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-red-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-yellow-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-green-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-indigo-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-purple-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-pink-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-transparent:focus-within{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-current:focus-within{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-red-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-green-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-purple-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-pink-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-transparent:focus{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-current:focus{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-red-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-yellow-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-green-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-indigo-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-purple-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-pink-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-0{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-5{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-10{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-20{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-25{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-30{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-40{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-50{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-60{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-70{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-75{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-80{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-90{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-95{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-opacity-100{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-0:focus{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-opacity-100:focus{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-0{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-1{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-2{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-4{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-8{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-0:focus{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-1:focus{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-2:focus{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-4:focus{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-8:focus{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-transparent{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-current{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-black{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-white{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-700{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-gray-900{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-400{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-400{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-500{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-600{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-700{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-800{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ring-offset-pink-900{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .sm\:focus-within\:skindr-widget-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-black:focus{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-white:focus{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .sm\:focus\:skindr-widget-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-filter-none{
    filter: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-0{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-none{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-sm{
    --tw-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur{
    --tw-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-md{
    --tw-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-lg{
    --tw-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-xl{
    --tw-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-2xl{
    --tw-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-blur-3xl{
    --tw-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-0{
    --tw-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-50{
    --tw-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-75{
    --tw-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-90{
    --tw-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-95{
    --tw-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-100{
    --tw-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-105{
    --tw-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-110{
    --tw-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-125{
    --tw-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-150{
    --tw-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-brightness-200{
    --tw-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-0{
    --tw-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-50{
    --tw-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-75{
    --tw-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-100{
    --tw-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-125{
    --tw-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-150{
    --tw-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-contrast-200{
    --tw-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grayscale-0{
    --tw-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-grayscale{
    --tw-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-invert-0{
    --tw-invert: invert(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-invert{
    --tw-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-saturate-0{
    --tw-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-saturate-50{
    --tw-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-saturate-100{
    --tw-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-saturate-150{
    --tw-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-saturate-200{
    --tw-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-sepia-0{
    --tw-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-sepia{
    --tw-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-filter-none{
    -webkit-backdrop-filter: none;
            backdrop-filter: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-0{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-none{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur{
    --tw-backdrop-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-invert-0{
    --tw-backdrop-invert: invert(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-invert{
    --tw-backdrop-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition-none{
    transition-property: none
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition-shadow{
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-75{
    transition-delay: 75ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-100{
    transition-delay: 100ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-150{
    transition-delay: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-200{
    transition-delay: 200ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-300{
    transition-delay: 300ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-500{
    transition-delay: 500ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-700{
    transition-delay: 700ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-delay-1000{
    transition-delay: 1000ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-75{
    transition-duration: 75ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-100{
    transition-duration: 100ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-150{
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-200{
    transition-duration: 200ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-300{
    transition-duration: 300ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-500{
    transition-duration: 500ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-700{
    transition-duration: 700ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-duration-1000{
    transition-duration: 1000ms
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ease-linear{
    transition-timing-function: linear
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  #skindrWidget.skindrWidget .sm\:skindr-widget-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 768px){
  .md\:skindr-widget-container{
    width: 100%
  }

  @media (min-width: 640px){
    .md\:skindr-widget-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .md\:skindr-widget-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .md\:skindr-widget-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .md\:skindr-widget-container{
      max-width: 1280px
    }
  }

  @media (min-width: 1536px){
    .md\:skindr-widget-container{
      max-width: 1536px
    }
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-sr-only:focus-within{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-not-sr-only:focus-within{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pointer-events-none{
    pointer-events: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pointer-events-auto{
    pointer-events: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-visible{
    visibility: visible
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-invisible{
    visibility: hidden
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-static{
    position: static
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-fixed{
    position: fixed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-absolute{
    position: absolute
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-relative{
    position: relative
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-sticky{
    position: sticky
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-1{
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-2{
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-3{
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-4{
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-5{
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-6{
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-7{
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-8{
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-9{
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-10{
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-11{
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-12{
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-14{
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-16{
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-20{
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-24{
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-28{
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-32{
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-36{
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-40{
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-44{
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-48{
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-52{
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-56{
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-60{
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-64{
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-72{
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-80{
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-96{
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-px{
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-0\.5{
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-1\.5{
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-2\.5{
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-3\.5{
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-1{
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-2{
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-3{
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-4{
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-5{
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-6{
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-7{
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-8{
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-9{
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-10{
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-11{
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-12{
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-14{
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-16{
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-20{
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-24{
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-28{
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-32{
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-36{
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-40{
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-44{
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-48{
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-52{
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-56{
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-60{
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-64{
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-72{
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-80{
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-96{
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-px{
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-0\.5{
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-1\.5{
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-2\.5{
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-3\.5{
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-1\/2{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-1\/3{
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-2\/3{
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-1\/4{
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-2\/4{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-3\/4{
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-full{
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-1\/2{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-1\/3{
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-2\/3{
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-1\/4{
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-2\/4{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-3\/4{
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-full{
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-1{
    left: 0.25rem;
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-2{
    left: 0.5rem;
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-3{
    left: 0.75rem;
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-4{
    left: 1rem;
    right: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-5{
    left: 1.25rem;
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-6{
    left: 1.5rem;
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-7{
    left: 1.75rem;
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-8{
    left: 2rem;
    right: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-9{
    left: 2.25rem;
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-10{
    left: 2.5rem;
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-11{
    left: 2.75rem;
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-12{
    left: 3rem;
    right: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-14{
    left: 3.5rem;
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-16{
    left: 4rem;
    right: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-20{
    left: 5rem;
    right: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-24{
    left: 6rem;
    right: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-28{
    left: 7rem;
    right: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-32{
    left: 8rem;
    right: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-36{
    left: 9rem;
    right: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-40{
    left: 10rem;
    right: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-44{
    left: 11rem;
    right: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-48{
    left: 12rem;
    right: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-52{
    left: 13rem;
    right: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-56{
    left: 14rem;
    right: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-60{
    left: 15rem;
    right: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-64{
    left: 16rem;
    right: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-72{
    left: 18rem;
    right: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-80{
    left: 20rem;
    right: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-96{
    left: 24rem;
    right: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-auto{
    left: auto;
    right: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-px{
    left: 1px;
    right: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-0\.5{
    left: 0.125rem;
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-1\.5{
    left: 0.375rem;
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-2\.5{
    left: 0.625rem;
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-3\.5{
    left: 0.875rem;
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-1{
    left: -0.25rem;
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-2{
    left: -0.5rem;
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-3{
    left: -0.75rem;
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-4{
    left: -1rem;
    right: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-5{
    left: -1.25rem;
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-6{
    left: -1.5rem;
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-7{
    left: -1.75rem;
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-8{
    left: -2rem;
    right: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-9{
    left: -2.25rem;
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-10{
    left: -2.5rem;
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-11{
    left: -2.75rem;
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-12{
    left: -3rem;
    right: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-14{
    left: -3.5rem;
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-16{
    left: -4rem;
    right: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-20{
    left: -5rem;
    right: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-24{
    left: -6rem;
    right: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-28{
    left: -7rem;
    right: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-32{
    left: -8rem;
    right: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-36{
    left: -9rem;
    right: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-40{
    left: -10rem;
    right: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-44{
    left: -11rem;
    right: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-48{
    left: -12rem;
    right: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-52{
    left: -13rem;
    right: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-56{
    left: -14rem;
    right: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-60{
    left: -15rem;
    right: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-64{
    left: -16rem;
    right: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-72{
    left: -18rem;
    right: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-80{
    left: -20rem;
    right: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-96{
    left: -24rem;
    right: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-px{
    left: -1px;
    right: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-0\.5{
    left: -0.125rem;
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-1\.5{
    left: -0.375rem;
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-2\.5{
    left: -0.625rem;
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-3\.5{
    left: -0.875rem;
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-1\/2{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-1\/3{
    left: 33.333333%;
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-2\/3{
    left: 66.666667%;
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-1\/4{
    left: 25%;
    right: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-2\/4{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-3\/4{
    left: 75%;
    right: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-x-full{
    left: 100%;
    right: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-1\/2{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-1\/3{
    left: -33.333333%;
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-2\/3{
    left: -66.666667%;
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-1\/4{
    left: -25%;
    right: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-2\/4{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-3\/4{
    left: -75%;
    right: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-x-full{
    left: -100%;
    right: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-1{
    top: 0.25rem;
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-2{
    top: 0.5rem;
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-3{
    top: 0.75rem;
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-4{
    top: 1rem;
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-5{
    top: 1.25rem;
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-6{
    top: 1.5rem;
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-7{
    top: 1.75rem;
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-8{
    top: 2rem;
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-9{
    top: 2.25rem;
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-10{
    top: 2.5rem;
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-11{
    top: 2.75rem;
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-12{
    top: 3rem;
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-14{
    top: 3.5rem;
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-16{
    top: 4rem;
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-20{
    top: 5rem;
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-24{
    top: 6rem;
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-28{
    top: 7rem;
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-32{
    top: 8rem;
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-36{
    top: 9rem;
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-40{
    top: 10rem;
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-44{
    top: 11rem;
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-48{
    top: 12rem;
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-52{
    top: 13rem;
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-56{
    top: 14rem;
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-60{
    top: 15rem;
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-64{
    top: 16rem;
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-72{
    top: 18rem;
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-80{
    top: 20rem;
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-96{
    top: 24rem;
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-auto{
    top: auto;
    bottom: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-px{
    top: 1px;
    bottom: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-0\.5{
    top: 0.125rem;
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-1\.5{
    top: 0.375rem;
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-2\.5{
    top: 0.625rem;
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-3\.5{
    top: 0.875rem;
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-1{
    top: -0.25rem;
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-2{
    top: -0.5rem;
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-3{
    top: -0.75rem;
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-4{
    top: -1rem;
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-5{
    top: -1.25rem;
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-6{
    top: -1.5rem;
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-7{
    top: -1.75rem;
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-8{
    top: -2rem;
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-9{
    top: -2.25rem;
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-10{
    top: -2.5rem;
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-11{
    top: -2.75rem;
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-12{
    top: -3rem;
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-14{
    top: -3.5rem;
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-16{
    top: -4rem;
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-20{
    top: -5rem;
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-24{
    top: -6rem;
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-28{
    top: -7rem;
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-32{
    top: -8rem;
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-36{
    top: -9rem;
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-40{
    top: -10rem;
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-44{
    top: -11rem;
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-48{
    top: -12rem;
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-52{
    top: -13rem;
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-56{
    top: -14rem;
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-60{
    top: -15rem;
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-64{
    top: -16rem;
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-72{
    top: -18rem;
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-80{
    top: -20rem;
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-96{
    top: -24rem;
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-px{
    top: -1px;
    bottom: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-0\.5{
    top: -0.125rem;
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-1\.5{
    top: -0.375rem;
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-2\.5{
    top: -0.625rem;
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-3\.5{
    top: -0.875rem;
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-1\/2{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-1\/3{
    top: 33.333333%;
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-2\/3{
    top: 66.666667%;
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-1\/4{
    top: 25%;
    bottom: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-2\/4{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-3\/4{
    top: 75%;
    bottom: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inset-y-full{
    top: 100%;
    bottom: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-1\/2{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-1\/3{
    top: -33.333333%;
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-2\/3{
    top: -66.666667%;
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-1\/4{
    top: -25%;
    bottom: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-2\/4{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-3\/4{
    top: -75%;
    bottom: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--inset-y-full{
    top: -100%;
    bottom: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-1{
    top: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-2{
    top: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-3{
    top: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-4{
    top: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-5{
    top: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-6{
    top: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-7{
    top: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-8{
    top: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-9{
    top: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-10{
    top: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-11{
    top: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-12{
    top: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-14{
    top: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-16{
    top: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-20{
    top: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-24{
    top: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-28{
    top: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-32{
    top: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-36{
    top: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-40{
    top: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-44{
    top: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-48{
    top: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-52{
    top: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-56{
    top: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-60{
    top: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-64{
    top: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-72{
    top: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-80{
    top: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-96{
    top: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-auto{
    top: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-px{
    top: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-0\.5{
    top: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-1\.5{
    top: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-2\.5{
    top: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-3\.5{
    top: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-1{
    top: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-2{
    top: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-3{
    top: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-4{
    top: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-5{
    top: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-6{
    top: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-7{
    top: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-8{
    top: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-9{
    top: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-10{
    top: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-11{
    top: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-12{
    top: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-14{
    top: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-16{
    top: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-20{
    top: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-24{
    top: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-28{
    top: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-32{
    top: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-36{
    top: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-40{
    top: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-44{
    top: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-48{
    top: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-52{
    top: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-56{
    top: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-60{
    top: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-64{
    top: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-72{
    top: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-80{
    top: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-96{
    top: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-px{
    top: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-0\.5{
    top: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-1\.5{
    top: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-2\.5{
    top: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-3\.5{
    top: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-1\/2{
    top: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-1\/3{
    top: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-2\/3{
    top: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-1\/4{
    top: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-2\/4{
    top: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-3\/4{
    top: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-top-full{
    top: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-1\/2{
    top: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-1\/3{
    top: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-2\/3{
    top: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-1\/4{
    top: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-2\/4{
    top: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-3\/4{
    top: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--top-full{
    top: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-1{
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-2{
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-3{
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-4{
    right: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-5{
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-6{
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-7{
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-8{
    right: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-9{
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-10{
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-11{
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-12{
    right: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-14{
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-16{
    right: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-20{
    right: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-24{
    right: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-28{
    right: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-32{
    right: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-36{
    right: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-40{
    right: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-44{
    right: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-48{
    right: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-52{
    right: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-56{
    right: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-60{
    right: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-64{
    right: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-72{
    right: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-80{
    right: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-96{
    right: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-auto{
    right: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-px{
    right: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-0\.5{
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-1\.5{
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-2\.5{
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-3\.5{
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-1{
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-2{
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-3{
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-4{
    right: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-5{
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-6{
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-7{
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-8{
    right: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-9{
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-10{
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-11{
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-12{
    right: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-14{
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-16{
    right: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-20{
    right: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-24{
    right: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-28{
    right: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-32{
    right: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-36{
    right: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-40{
    right: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-44{
    right: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-48{
    right: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-52{
    right: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-56{
    right: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-60{
    right: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-64{
    right: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-72{
    right: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-80{
    right: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-96{
    right: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-px{
    right: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-0\.5{
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-1\.5{
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-2\.5{
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-3\.5{
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-1\/2{
    right: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-1\/3{
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-2\/3{
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-1\/4{
    right: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-2\/4{
    right: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-3\/4{
    right: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-right-full{
    right: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-1\/2{
    right: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-1\/3{
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-2\/3{
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-1\/4{
    right: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-2\/4{
    right: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-3\/4{
    right: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--right-full{
    right: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-1{
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-2{
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-3{
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-4{
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-5{
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-6{
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-7{
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-8{
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-9{
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-10{
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-11{
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-12{
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-14{
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-16{
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-20{
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-24{
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-28{
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-32{
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-36{
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-40{
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-44{
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-48{
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-52{
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-56{
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-60{
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-64{
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-72{
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-80{
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-96{
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-auto{
    bottom: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-px{
    bottom: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-0\.5{
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-1\.5{
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-2\.5{
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-3\.5{
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-1{
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-2{
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-3{
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-4{
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-5{
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-6{
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-7{
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-8{
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-9{
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-10{
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-11{
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-12{
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-14{
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-16{
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-20{
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-24{
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-28{
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-32{
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-36{
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-40{
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-44{
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-48{
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-52{
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-56{
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-60{
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-64{
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-72{
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-80{
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-96{
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-px{
    bottom: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-0\.5{
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-1\.5{
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-2\.5{
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-3\.5{
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-1\/2{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-1\/3{
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-2\/3{
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-1\/4{
    bottom: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-2\/4{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-3\/4{
    bottom: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bottom-full{
    bottom: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-1\/2{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-1\/3{
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-2\/3{
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-1\/4{
    bottom: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-2\/4{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-3\/4{
    bottom: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--bottom-full{
    bottom: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-1{
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-2{
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-3{
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-4{
    left: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-5{
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-6{
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-7{
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-8{
    left: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-9{
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-10{
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-11{
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-12{
    left: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-14{
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-16{
    left: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-20{
    left: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-24{
    left: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-28{
    left: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-32{
    left: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-36{
    left: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-40{
    left: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-44{
    left: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-48{
    left: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-52{
    left: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-56{
    left: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-60{
    left: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-64{
    left: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-72{
    left: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-80{
    left: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-96{
    left: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-auto{
    left: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-px{
    left: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-0\.5{
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-1\.5{
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-2\.5{
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-3\.5{
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-1{
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-2{
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-3{
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-4{
    left: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-5{
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-6{
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-7{
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-8{
    left: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-9{
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-10{
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-11{
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-12{
    left: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-14{
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-16{
    left: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-20{
    left: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-24{
    left: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-28{
    left: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-32{
    left: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-36{
    left: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-40{
    left: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-44{
    left: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-48{
    left: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-52{
    left: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-56{
    left: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-60{
    left: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-64{
    left: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-72{
    left: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-80{
    left: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-96{
    left: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-px{
    left: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-0\.5{
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-1\.5{
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-2\.5{
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-3\.5{
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-1\/2{
    left: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-1\/3{
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-2\/3{
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-1\/4{
    left: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-2\/4{
    left: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-3\/4{
    left: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-left-full{
    left: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-1\/2{
    left: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-1\/3{
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-2\/3{
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-1\/4{
    left: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-2\/4{
    left: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-3\/4{
    left: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--left-full{
    left: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-isolate{
    isolation: isolate
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-isolation-auto{
    isolation: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-0{
    z-index: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-10{
    z-index: 10
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-20{
    z-index: 20
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-30{
    z-index: 30
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-40{
    z-index: 40
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-50{
    z-index: 50
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-z-auto{
    z-index: auto
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-0:focus-within{
    z-index: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-10:focus-within{
    z-index: 10
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-20:focus-within{
    z-index: 20
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-30:focus-within{
    z-index: 30
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-40:focus-within{
    z-index: 40
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-50:focus-within{
    z-index: 50
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-z-auto:focus-within{
    z-index: auto
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-0:focus{
    z-index: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-10:focus{
    z-index: 10
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-20:focus{
    z-index: 20
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-30:focus{
    z-index: 30
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-40:focus{
    z-index: 40
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-50:focus{
    z-index: 50
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-z-auto:focus{
    z-index: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-1{
    order: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-2{
    order: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-3{
    order: 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-4{
    order: 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-5{
    order: 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-6{
    order: 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-7{
    order: 7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-8{
    order: 8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-9{
    order: 9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-10{
    order: 10
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-11{
    order: 11
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-12{
    order: 12
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-first{
    order: -9999
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-last{
    order: 9999
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-order-none{
    order: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-auto{
    grid-column: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-1{
    grid-column: span 1 / span 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-2{
    grid-column: span 2 / span 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-3{
    grid-column: span 3 / span 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-4{
    grid-column: span 4 / span 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-5{
    grid-column: span 5 / span 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-6{
    grid-column: span 6 / span 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-7{
    grid-column: span 7 / span 7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-8{
    grid-column: span 8 / span 8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-9{
    grid-column: span 9 / span 9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-10{
    grid-column: span 10 / span 10
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-11{
    grid-column: span 11 / span 11
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-12{
    grid-column: span 12 / span 12
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-span-full{
    grid-column: 1 / -1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-1{
    grid-column-start: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-2{
    grid-column-start: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-3{
    grid-column-start: 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-4{
    grid-column-start: 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-5{
    grid-column-start: 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-6{
    grid-column-start: 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-7{
    grid-column-start: 7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-8{
    grid-column-start: 8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-9{
    grid-column-start: 9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-10{
    grid-column-start: 10
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-11{
    grid-column-start: 11
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-12{
    grid-column-start: 12
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-13{
    grid-column-start: 13
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-start-auto{
    grid-column-start: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-1{
    grid-column-end: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-2{
    grid-column-end: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-3{
    grid-column-end: 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-4{
    grid-column-end: 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-5{
    grid-column-end: 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-6{
    grid-column-end: 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-7{
    grid-column-end: 7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-8{
    grid-column-end: 8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-9{
    grid-column-end: 9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-10{
    grid-column-end: 10
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-11{
    grid-column-end: 11
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-12{
    grid-column-end: 12
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-13{
    grid-column-end: 13
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-col-end-auto{
    grid-column-end: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-auto{
    grid-row: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-1{
    grid-row: span 1 / span 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-2{
    grid-row: span 2 / span 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-3{
    grid-row: span 3 / span 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-4{
    grid-row: span 4 / span 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-5{
    grid-row: span 5 / span 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-6{
    grid-row: span 6 / span 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-span-full{
    grid-row: 1 / -1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-1{
    grid-row-start: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-2{
    grid-row-start: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-3{
    grid-row-start: 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-4{
    grid-row-start: 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-5{
    grid-row-start: 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-6{
    grid-row-start: 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-7{
    grid-row-start: 7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-start-auto{
    grid-row-start: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-1{
    grid-row-end: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-2{
    grid-row-end: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-3{
    grid-row-end: 3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-4{
    grid-row-end: 4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-5{
    grid-row-end: 5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-6{
    grid-row-end: 6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-7{
    grid-row-end: 7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-row-end-auto{
    grid-row-end: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-float-right{
    float: right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-float-left{
    float: left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-float-none{
    float: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-clear-left{
    clear: left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-clear-right{
    clear: right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-clear-both{
    clear: both
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-clear-none{
    clear: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-1{
    margin: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-2{
    margin: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-3{
    margin: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-4{
    margin: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-5{
    margin: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-6{
    margin: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-7{
    margin: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-8{
    margin: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-9{
    margin: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-10{
    margin: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-11{
    margin: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-12{
    margin: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-14{
    margin: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-16{
    margin: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-20{
    margin: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-24{
    margin: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-28{
    margin: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-32{
    margin: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-36{
    margin: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-40{
    margin: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-44{
    margin: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-48{
    margin: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-52{
    margin: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-56{
    margin: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-60{
    margin: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-64{
    margin: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-72{
    margin: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-80{
    margin: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-96{
    margin: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-auto{
    margin: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-px{
    margin: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-0\.5{
    margin: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-1\.5{
    margin: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-2\.5{
    margin: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-m-3\.5{
    margin: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-1{
    margin: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-2{
    margin: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-3{
    margin: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-4{
    margin: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-5{
    margin: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-6{
    margin: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-7{
    margin: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-8{
    margin: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-9{
    margin: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-10{
    margin: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-11{
    margin: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-12{
    margin: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-14{
    margin: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-16{
    margin: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-20{
    margin: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-24{
    margin: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-28{
    margin: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-32{
    margin: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-36{
    margin: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-40{
    margin: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-44{
    margin: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-48{
    margin: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-52{
    margin: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-56{
    margin: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-60{
    margin: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-64{
    margin: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-72{
    margin: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-80{
    margin: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-96{
    margin: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-px{
    margin: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-0\.5{
    margin: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-1\.5{
    margin: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-2\.5{
    margin: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--m-3\.5{
    margin: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-7{
    margin-left: 1.75rem;
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-9{
    margin-left: 2.25rem;
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-11{
    margin-left: 2.75rem;
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-14{
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-28{
    margin-left: 7rem;
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-36{
    margin-left: 9rem;
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-44{
    margin-left: 11rem;
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-52{
    margin-left: 13rem;
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-60{
    margin-left: 15rem;
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-72{
    margin-left: 18rem;
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-80{
    margin-left: 20rem;
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-96{
    margin-left: 24rem;
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-0\.5{
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-1\.5{
    margin-left: 0.375rem;
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-2\.5{
    margin-left: 0.625rem;
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mx-3\.5{
    margin-left: 0.875rem;
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-7{
    margin-left: -1.75rem;
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-9{
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-11{
    margin-left: -2.75rem;
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-14{
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-28{
    margin-left: -7rem;
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-36{
    margin-left: -9rem;
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-44{
    margin-left: -11rem;
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-52{
    margin-left: -13rem;
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-60{
    margin-left: -15rem;
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-72{
    margin-left: -18rem;
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-80{
    margin-left: -20rem;
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-96{
    margin-left: -24rem;
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-0\.5{
    margin-left: -0.125rem;
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-1\.5{
    margin-left: -0.375rem;
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-2\.5{
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mx-3\.5{
    margin-left: -0.875rem;
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-7{
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-9{
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-11{
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-14{
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-28{
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-36{
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-44{
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-52{
    margin-top: 13rem;
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-60{
    margin-top: 15rem;
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-72{
    margin-top: 18rem;
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-80{
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-96{
    margin-top: 24rem;
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-0\.5{
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-1\.5{
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-2\.5{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-my-3\.5{
    margin-top: 0.875rem;
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-7{
    margin-top: -1.75rem;
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-9{
    margin-top: -2.25rem;
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-11{
    margin-top: -2.75rem;
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-14{
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-28{
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-36{
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-44{
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-52{
    margin-top: -13rem;
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-60{
    margin-top: -15rem;
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-72{
    margin-top: -18rem;
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-80{
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-96{
    margin-top: -24rem;
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-0\.5{
    margin-top: -0.125rem;
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-2\.5{
    margin-top: -0.625rem;
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--my-3\.5{
    margin-top: -0.875rem;
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-1{
    margin-top: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-2{
    margin-top: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-3{
    margin-top: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-4{
    margin-top: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-5{
    margin-top: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-6{
    margin-top: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-7{
    margin-top: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-8{
    margin-top: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-9{
    margin-top: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-10{
    margin-top: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-11{
    margin-top: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-12{
    margin-top: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-14{
    margin-top: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-16{
    margin-top: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-20{
    margin-top: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-24{
    margin-top: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-28{
    margin-top: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-32{
    margin-top: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-36{
    margin-top: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-40{
    margin-top: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-44{
    margin-top: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-48{
    margin-top: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-52{
    margin-top: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-56{
    margin-top: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-60{
    margin-top: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-64{
    margin-top: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-72{
    margin-top: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-80{
    margin-top: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-96{
    margin-top: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-auto{
    margin-top: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-px{
    margin-top: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-0\.5{
    margin-top: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-1\.5{
    margin-top: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-2\.5{
    margin-top: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mt-3\.5{
    margin-top: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-1{
    margin-top: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-2{
    margin-top: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-3{
    margin-top: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-4{
    margin-top: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-5{
    margin-top: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-6{
    margin-top: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-7{
    margin-top: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-8{
    margin-top: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-9{
    margin-top: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-10{
    margin-top: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-11{
    margin-top: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-12{
    margin-top: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-14{
    margin-top: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-16{
    margin-top: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-20{
    margin-top: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-24{
    margin-top: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-28{
    margin-top: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-32{
    margin-top: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-36{
    margin-top: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-40{
    margin-top: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-44{
    margin-top: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-48{
    margin-top: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-52{
    margin-top: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-56{
    margin-top: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-60{
    margin-top: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-64{
    margin-top: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-72{
    margin-top: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-80{
    margin-top: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-96{
    margin-top: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-px{
    margin-top: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-0\.5{
    margin-top: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-1\.5{
    margin-top: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-2\.5{
    margin-top: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mt-3\.5{
    margin-top: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-1{
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-2{
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-3{
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-4{
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-5{
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-6{
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-7{
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-8{
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-9{
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-10{
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-11{
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-12{
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-14{
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-16{
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-20{
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-24{
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-28{
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-32{
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-36{
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-40{
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-44{
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-48{
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-52{
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-56{
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-60{
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-64{
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-72{
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-80{
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-96{
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-auto{
    margin-right: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-px{
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-0\.5{
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-1\.5{
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-2\.5{
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mr-3\.5{
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-1{
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-2{
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-3{
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-4{
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-5{
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-6{
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-7{
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-8{
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-9{
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-10{
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-11{
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-12{
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-14{
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-16{
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-20{
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-24{
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-28{
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-32{
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-36{
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-40{
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-44{
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-48{
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-52{
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-56{
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-60{
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-64{
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-72{
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-80{
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-96{
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-px{
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-0\.5{
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-1\.5{
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-2\.5{
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mr-3\.5{
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-1{
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-2{
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-3{
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-4{
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-5{
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-6{
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-7{
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-8{
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-9{
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-10{
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-11{
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-12{
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-14{
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-16{
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-20{
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-24{
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-28{
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-32{
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-36{
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-40{
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-44{
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-48{
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-52{
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-56{
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-60{
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-64{
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-72{
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-80{
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-96{
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-auto{
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-px{
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-0\.5{
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-1\.5{
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-2\.5{
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mb-3\.5{
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-1{
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-2{
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-3{
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-4{
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-5{
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-6{
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-7{
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-8{
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-9{
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-10{
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-11{
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-12{
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-14{
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-16{
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-20{
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-24{
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-28{
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-32{
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-36{
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-40{
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-44{
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-48{
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-52{
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-56{
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-60{
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-64{
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-72{
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-80{
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-96{
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-px{
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-0\.5{
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-1\.5{
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-2\.5{
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--mb-3\.5{
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-1{
    margin-left: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-2{
    margin-left: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-3{
    margin-left: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-4{
    margin-left: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-5{
    margin-left: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-6{
    margin-left: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-7{
    margin-left: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-8{
    margin-left: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-9{
    margin-left: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-10{
    margin-left: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-11{
    margin-left: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-12{
    margin-left: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-14{
    margin-left: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-16{
    margin-left: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-20{
    margin-left: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-24{
    margin-left: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-28{
    margin-left: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-32{
    margin-left: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-36{
    margin-left: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-40{
    margin-left: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-44{
    margin-left: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-48{
    margin-left: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-52{
    margin-left: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-56{
    margin-left: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-60{
    margin-left: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-64{
    margin-left: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-72{
    margin-left: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-80{
    margin-left: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-96{
    margin-left: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-auto{
    margin-left: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-px{
    margin-left: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-0\.5{
    margin-left: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-1\.5{
    margin-left: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-2\.5{
    margin-left: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ml-3\.5{
    margin-left: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-1{
    margin-left: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-2{
    margin-left: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-3{
    margin-left: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-4{
    margin-left: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-5{
    margin-left: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-6{
    margin-left: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-7{
    margin-left: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-8{
    margin-left: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-9{
    margin-left: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-10{
    margin-left: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-11{
    margin-left: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-12{
    margin-left: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-14{
    margin-left: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-16{
    margin-left: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-20{
    margin-left: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-24{
    margin-left: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-28{
    margin-left: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-32{
    margin-left: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-36{
    margin-left: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-40{
    margin-left: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-44{
    margin-left: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-48{
    margin-left: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-52{
    margin-left: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-56{
    margin-left: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-60{
    margin-left: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-64{
    margin-left: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-72{
    margin-left: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-80{
    margin-left: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-96{
    margin-left: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-px{
    margin-left: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-0\.5{
    margin-left: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-1\.5{
    margin-left: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-2\.5{
    margin-left: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--ml-3\.5{
    margin-left: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-box-border{
    box-sizing: border-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-box-content{
    box-sizing: content-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-block{
    display: block
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inline-block{
    display: inline-block
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inline{
    display: inline
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex{
    display: flex
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inline-flex{
    display: inline-flex
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table{
    display: table
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inline-table{
    display: inline-table
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-caption{
    display: table-caption
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-cell{
    display: table-cell
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-column{
    display: table-column
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-column-group{
    display: table-column-group
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-footer-group{
    display: table-footer-group
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-header-group{
    display: table-header-group
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-row-group{
    display: table-row-group
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-row{
    display: table-row
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flow-root{
    display: flow-root
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid{
    display: grid
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-inline-grid{
    display: inline-grid
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contents{
    display: contents
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-list-item{
    display: list-item
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hidden{
    display: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-0{
    height: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1{
    height: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-2{
    height: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-3{
    height: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-4{
    height: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-5{
    height: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-6{
    height: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-7{
    height: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-8{
    height: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-9{
    height: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-10{
    height: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-11{
    height: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-12{
    height: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-14{
    height: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-16{
    height: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-20{
    height: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-24{
    height: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-28{
    height: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-32{
    height: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-36{
    height: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-40{
    height: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-44{
    height: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-48{
    height: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-52{
    height: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-56{
    height: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-60{
    height: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-64{
    height: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-72{
    height: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-80{
    height: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-96{
    height: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-auto{
    height: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-px{
    height: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-0\.5{
    height: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1\.5{
    height: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-2\.5{
    height: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-3\.5{
    height: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1\/2{
    height: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1\/3{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-2\/3{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1\/4{
    height: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-2\/4{
    height: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-3\/4{
    height: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1\/5{
    height: 20%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-2\/5{
    height: 40%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-3\/5{
    height: 60%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-4\/5{
    height: 80%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-1\/6{
    height: 16.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-2\/6{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-3\/6{
    height: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-4\/6{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-5\/6{
    height: 83.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-full{
    height: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-h-screen{
    height: 100vh
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-0{
    max-height: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-1{
    max-height: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-2{
    max-height: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-3{
    max-height: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-4{
    max-height: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-5{
    max-height: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-6{
    max-height: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-7{
    max-height: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-8{
    max-height: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-9{
    max-height: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-10{
    max-height: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-11{
    max-height: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-12{
    max-height: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-14{
    max-height: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-16{
    max-height: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-20{
    max-height: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-24{
    max-height: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-28{
    max-height: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-32{
    max-height: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-36{
    max-height: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-40{
    max-height: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-44{
    max-height: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-48{
    max-height: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-52{
    max-height: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-56{
    max-height: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-60{
    max-height: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-64{
    max-height: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-72{
    max-height: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-80{
    max-height: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-96{
    max-height: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-px{
    max-height: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-0\.5{
    max-height: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-1\.5{
    max-height: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-2\.5{
    max-height: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-3\.5{
    max-height: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-full{
    max-height: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-h-screen{
    max-height: 100vh
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-h-0{
    min-height: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-h-full{
    min-height: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-h-screen{
    min-height: 100vh
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-0{
    width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1{
    width: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2{
    width: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-3{
    width: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-4{
    width: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-5{
    width: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-6{
    width: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-7{
    width: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-8{
    width: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-9{
    width: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-10{
    width: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-11{
    width: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-12{
    width: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-14{
    width: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-16{
    width: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-20{
    width: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-24{
    width: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-28{
    width: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-32{
    width: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-36{
    width: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-40{
    width: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-44{
    width: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-48{
    width: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-52{
    width: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-56{
    width: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-60{
    width: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-64{
    width: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-72{
    width: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-80{
    width: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-96{
    width: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-auto{
    width: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-px{
    width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-0\.5{
    width: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\.5{
    width: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2\.5{
    width: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-3\.5{
    width: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\/2{
    width: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\/3{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2\/3{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\/4{
    width: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2\/4{
    width: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-3\/4{
    width: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\/5{
    width: 20%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2\/5{
    width: 40%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-3\/5{
    width: 60%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-4\/5{
    width: 80%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\/6{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2\/6{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-3\/6{
    width: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-4\/6{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-5\/6{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-1\/12{
    width: 8.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-2\/12{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-3\/12{
    width: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-4\/12{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-5\/12{
    width: 41.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-6\/12{
    width: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-7\/12{
    width: 58.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-8\/12{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-9\/12{
    width: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-10\/12{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-11\/12{
    width: 91.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-full{
    width: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-screen{
    width: 100vw
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-min{
    width: min-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-w-max{
    width: max-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-w-0{
    min-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-w-full{
    min-width: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-w-min{
    min-width: min-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-min-w-max{
    min-width: max-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-0{
    max-width: 0rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-none{
    max-width: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-xs{
    max-width: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-sm{
    max-width: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-md{
    max-width: 28rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-lg{
    max-width: 32rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-xl{
    max-width: 36rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-2xl{
    max-width: 42rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-3xl{
    max-width: 48rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-4xl{
    max-width: 56rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-5xl{
    max-width: 64rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-6xl{
    max-width: 72rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-7xl{
    max-width: 80rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-full{
    max-width: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-min{
    max-width: min-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-max{
    max-width: max-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-prose{
    max-width: 65ch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-screen-sm{
    max-width: 640px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-screen-md{
    max-width: 768px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-screen-lg{
    max-width: 1024px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-screen-xl{
    max-width: 1280px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-max-w-screen-2xl{
    max-width: 1536px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-1{
    flex: 1 1 0%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-auto{
    flex: 1 1 auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-initial{
    flex: 0 1 auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-none{
    flex: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-shrink-0{
    flex-shrink: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-shrink{
    flex-shrink: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-grow-0{
    flex-grow: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-grow{
    flex-grow: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-auto{
    table-layout: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-table-fixed{
    table-layout: fixed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-collapse{
    border-collapse: collapse
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-separate{
    border-collapse: separate
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-center{
    transform-origin: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-top{
    transform-origin: top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-top-right{
    transform-origin: top right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-right{
    transform-origin: right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-bottom-right{
    transform-origin: bottom right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-bottom{
    transform-origin: bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-bottom-left{
    transform-origin: bottom left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-left{
    transform-origin: left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-origin-top-left{
    transform-origin: top left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transform-none{
    transform: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-1{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-2{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-3{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-4{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-5{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-6{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-7{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-8{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-9{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-10{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-11{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-12{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-14{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-16{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-20{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-24{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-28{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-32{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-36{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-40{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-44{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-48{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-52{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-56{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-60{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-64{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-72{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-80{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-96{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-px{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-0\.5{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-1\.5{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-2\.5{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-3\.5{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-1{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-2{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-3{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-4{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-5{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-6{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-7{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-8{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-9{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-10{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-11{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-12{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-14{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-16{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-20{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-24{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-28{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-32{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-36{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-40{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-44{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-48{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-52{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-56{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-60{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-64{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-72{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-80{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-96{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-px{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-0\.5{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-1\.5{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-2\.5{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-3\.5{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-1\/2{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-1\/3{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-2\/3{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-1\/4{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-2\/4{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-3\/4{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-x-full{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-1\/2{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-1\/3{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-2\/3{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-1\/4{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-2\/4{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-3\/4{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-x-full{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-1{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-2{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-3{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-4{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-5{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-6{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-7{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-8{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-9{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-10{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-11{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-12{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-14{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-16{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-20{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-24{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-28{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-32{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-36{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-40{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-44{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-48{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-52{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-56{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-60{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-64{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-72{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-80{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-96{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-px{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-0\.5{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-1\.5{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-2\.5{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-3\.5{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-1{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-2{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-3{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-4{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-5{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-6{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-7{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-8{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-9{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-10{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-11{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-12{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-14{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-16{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-20{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-24{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-28{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-32{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-36{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-40{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-44{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-48{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-52{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-56{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-60{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-64{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-72{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-80{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-96{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-px{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-0\.5{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-1\.5{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-2\.5{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-3\.5{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-1\/2{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-1\/3{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-2\/3{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-1\/4{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-2\/4{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-3\/4{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-translate-y-full{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-1\/2{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-1\/3{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-2\/3{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-1\/4{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-2\/4{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-3\/4{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--translate-y-full{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-1:hover{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-2:hover{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-3:hover{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-4:hover{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-5:hover{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-6:hover{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-7:hover{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-8:hover{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-9:hover{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-10:hover{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-11:hover{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-12:hover{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-14:hover{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-16:hover{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-20:hover{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-24:hover{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-28:hover{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-32:hover{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-36:hover{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-40:hover{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-44:hover{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-48:hover{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-52:hover{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-56:hover{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-60:hover{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-64:hover{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-72:hover{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-80:hover{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-96:hover{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-px:hover{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-1:hover{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-2:hover{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-3:hover{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-4:hover{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-5:hover{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-6:hover{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-7:hover{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-8:hover{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-9:hover{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-10:hover{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-11:hover{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-12:hover{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-14:hover{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-16:hover{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-20:hover{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-24:hover{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-28:hover{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-32:hover{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-36:hover{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-40:hover{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-44:hover{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-48:hover{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-52:hover{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-56:hover{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-60:hover{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-64:hover{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-72:hover{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-80:hover{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-96:hover{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-px:hover{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-1\/2:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-1\/4:hover{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-2\/4:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-3\/4:hover{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-x-full:hover{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-1\/2:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-1\/4:hover{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-2\/4:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-3\/4:hover{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-x-full:hover{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-1:hover{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-2:hover{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-3:hover{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-4:hover{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-5:hover{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-6:hover{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-7:hover{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-8:hover{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-9:hover{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-10:hover{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-11:hover{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-12:hover{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-14:hover{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-16:hover{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-20:hover{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-24:hover{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-28:hover{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-32:hover{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-36:hover{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-40:hover{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-44:hover{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-48:hover{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-52:hover{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-56:hover{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-60:hover{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-64:hover{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-72:hover{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-80:hover{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-96:hover{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-px:hover{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-1:hover{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-2:hover{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-3:hover{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-4:hover{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-5:hover{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-6:hover{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-7:hover{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-8:hover{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-9:hover{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-10:hover{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-11:hover{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-12:hover{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-14:hover{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-16:hover{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-20:hover{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-24:hover{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-28:hover{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-32:hover{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-36:hover{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-40:hover{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-44:hover{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-48:hover{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-52:hover{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-56:hover{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-60:hover{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-64:hover{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-72:hover{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-80:hover{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-96:hover{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-px:hover{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-1\/2:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-1\/4:hover{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-2\/4:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-3\/4:hover{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-translate-y-full:hover{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-1\/2:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-1\/4:hover{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-2\/4:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-3\/4:hover{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--translate-y-full:hover{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-1:focus{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-2:focus{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-3:focus{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-4:focus{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-5:focus{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-6:focus{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-7:focus{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-8:focus{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-9:focus{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-10:focus{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-11:focus{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-12:focus{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-14:focus{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-16:focus{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-20:focus{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-24:focus{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-28:focus{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-32:focus{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-36:focus{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-40:focus{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-44:focus{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-48:focus{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-52:focus{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-56:focus{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-60:focus{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-64:focus{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-72:focus{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-80:focus{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-96:focus{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-px:focus{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-1:focus{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-2:focus{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-3:focus{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-4:focus{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-5:focus{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-6:focus{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-7:focus{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-8:focus{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-9:focus{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-10:focus{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-11:focus{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-12:focus{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-14:focus{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-16:focus{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-20:focus{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-24:focus{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-28:focus{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-32:focus{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-36:focus{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-40:focus{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-44:focus{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-48:focus{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-52:focus{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-56:focus{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-60:focus{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-64:focus{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-72:focus{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-80:focus{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-96:focus{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-px:focus{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-1\/2:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-1\/4:focus{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-2\/4:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-3\/4:focus{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-x-full:focus{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-1\/2:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-1\/4:focus{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-2\/4:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-3\/4:focus{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-x-full:focus{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-1:focus{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-2:focus{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-3:focus{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-4:focus{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-5:focus{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-6:focus{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-7:focus{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-8:focus{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-9:focus{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-10:focus{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-11:focus{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-12:focus{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-14:focus{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-16:focus{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-20:focus{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-24:focus{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-28:focus{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-32:focus{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-36:focus{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-40:focus{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-44:focus{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-48:focus{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-52:focus{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-56:focus{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-60:focus{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-64:focus{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-72:focus{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-80:focus{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-96:focus{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-px:focus{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-1:focus{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-2:focus{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-3:focus{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-4:focus{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-5:focus{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-6:focus{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-7:focus{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-8:focus{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-9:focus{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-10:focus{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-11:focus{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-12:focus{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-14:focus{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-16:focus{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-20:focus{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-24:focus{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-28:focus{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-32:focus{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-36:focus{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-40:focus{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-44:focus{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-48:focus{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-52:focus{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-56:focus{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-60:focus{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-64:focus{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-72:focus{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-80:focus{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-96:focus{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-px:focus{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-1\/2:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-1\/4:focus{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-2\/4:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-3\/4:focus{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-translate-y-full:focus{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-1\/2:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-1\/4:focus{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-2\/4:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-3\/4:focus{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--translate-y-full:focus{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-0{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-1{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-2{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-3{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-6{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-12{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-45{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-90{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rotate-180{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-180{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-90{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-45{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-12{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-6{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-3{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-2{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--rotate-1{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-0:hover{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-1:hover{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-2:hover{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-3:hover{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-6:hover{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-12:hover{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-45:hover{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-90:hover{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-rotate-180:hover{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-180:hover{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-90:hover{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-45:hover{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-12:hover{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-6:hover{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-3:hover{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-2:hover{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--rotate-1:hover{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-0:focus{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-1:focus{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-2:focus{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-3:focus{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-6:focus{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-12:focus{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-45:focus{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-90:focus{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-rotate-180:focus{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-180:focus{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-90:focus{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-45:focus{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-12:focus{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-6:focus{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-3:focus{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-2:focus{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--rotate-1:focus{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-x-0{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-x-1{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-x-2{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-x-3{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-x-6{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-x-12{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-x-12{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-x-6{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-x-3{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-x-2{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-x-1{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-y-0{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-y-1{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-y-2{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-y-3{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-y-6{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-skew-y-12{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-y-12{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-y-6{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-y-3{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-y-2{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--skew-y-1{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-x-0:hover{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-x-1:hover{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-x-2:hover{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-x-3:hover{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-x-6:hover{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-x-12:hover{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-x-12:hover{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-x-6:hover{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-x-3:hover{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-x-2:hover{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-x-1:hover{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-y-0:hover{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-y-1:hover{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-y-2:hover{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-y-3:hover{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-y-6:hover{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-skew-y-12:hover{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-y-12:hover{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-y-6:hover{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-y-3:hover{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-y-2:hover{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget--skew-y-1:hover{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-x-0:focus{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-x-1:focus{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-x-2:focus{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-x-3:focus{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-x-6:focus{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-x-12:focus{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-x-12:focus{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-x-6:focus{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-x-3:focus{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-x-2:focus{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-x-1:focus{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-y-0:focus{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-y-1:focus{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-y-2:focus{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-y-3:focus{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-y-6:focus{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-skew-y-12:focus{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-y-12:focus{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-y-6:focus{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-y-3:focus{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-y-2:focus{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget--skew-y-1:focus{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-0{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-50{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-75{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-90{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-95{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-100{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-105{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-110{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-125{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-x-150{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-0{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-50{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-75{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-90{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-95{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-100{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-105{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-110{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-125{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-scale-y-150{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-0:hover{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-50:hover{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-75:hover{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-90:hover{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-95:hover{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-100:hover{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-105:hover{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-110:hover{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-125:hover{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-x-150:hover{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-0:hover{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-50:hover{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-75:hover{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-90:hover{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-95:hover{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-100:hover{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-105:hover{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-110:hover{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-125:hover{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-scale-y-150:hover{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-0:focus{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-50:focus{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-75:focus{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-90:focus{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-95:focus{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-100:focus{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-105:focus{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-110:focus{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-125:focus{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-x-150:focus{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-0:focus{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-50:focus{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-75:focus{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-90:focus{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-95:focus{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-100:focus{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-105:focus{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-110:focus{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-125:focus{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-scale-y-150:focus{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-animate-none{
    animation: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-animate-spin{
    animation: skindr-widget-spin 1s linear infinite
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-animate-ping{
    animation: skindr-widget-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-animate-pulse{
    animation: skindr-widget-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-animate-bounce{
    animation: skindr-widget-bounce 1s infinite
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-auto{
    cursor: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-default{
    cursor: default
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-pointer{
    cursor: pointer
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-wait{
    cursor: wait
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-text{
    cursor: text
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-move{
    cursor: move
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-help{
    cursor: help
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-cursor-not-allowed{
    cursor: not-allowed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-select-none{
    -webkit-user-select: none;
            user-select: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-select-text{
    -webkit-user-select: text;
            user-select: text
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-select-all{
    -webkit-user-select: all;
            user-select: all
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-select-auto{
    -webkit-user-select: auto;
            user-select: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-resize-none{
    resize: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-resize-y{
    resize: vertical
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-resize-x{
    resize: horizontal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-resize{
    resize: both
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-list-inside{
    list-style-position: inside
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-list-outside{
    list-style-position: outside
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-list-none{
    list-style-type: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-list-disc{
    list-style-type: disc
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-list-decimal{
    list-style-type: decimal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-appearance-none{
    -webkit-appearance: none;
            appearance: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-cols-auto{
    grid-auto-columns: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-cols-min{
    grid-auto-columns: min-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-cols-max{
    grid-auto-columns: max-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-flow-row{
    grid-auto-flow: row
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-flow-col{
    grid-auto-flow: column
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-rows-auto{
    grid-auto-rows: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-rows-min{
    grid-auto-rows: min-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-rows-max{
    grid-auto-rows: max-content
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-cols-none{
    grid-template-columns: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grid-rows-none{
    grid-template-rows: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-row{
    flex-direction: row
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-row-reverse{
    flex-direction: row-reverse
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-col{
    flex-direction: column
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-col-reverse{
    flex-direction: column-reverse
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-wrap{
    flex-wrap: wrap
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-flex-nowrap{
    flex-wrap: nowrap
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-center{
    place-content: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-start{
    place-content: start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-end{
    place-content: end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-between{
    place-content: space-between
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-around{
    place-content: space-around
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-evenly{
    place-content: space-evenly
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-content-stretch{
    place-content: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-items-start{
    place-items: start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-items-end{
    place-items: end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-items-center{
    place-items: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-items-stretch{
    place-items: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-content-center{
    align-content: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-content-start{
    align-content: flex-start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-content-end{
    align-content: flex-end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-content-between{
    align-content: space-between
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-content-around{
    align-content: space-around
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-content-evenly{
    align-content: space-evenly
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-items-start{
    align-items: flex-start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-items-end{
    align-items: flex-end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-items-center{
    align-items: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-items-baseline{
    align-items: baseline
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-items-stretch{
    align-items: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-start{
    justify-content: flex-start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-end{
    justify-content: flex-end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-center{
    justify-content: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-between{
    justify-content: space-between
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-around{
    justify-content: space-around
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-evenly{
    justify-content: space-evenly
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-items-start{
    justify-items: start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-items-end{
    justify-items: end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-items-center{
    justify-items: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-items-stretch{
    justify-items: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-0{
    gap: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-1{
    gap: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-2{
    gap: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-3{
    gap: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-4{
    gap: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-5{
    gap: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-6{
    gap: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-7{
    gap: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-8{
    gap: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-9{
    gap: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-10{
    gap: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-11{
    gap: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-12{
    gap: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-14{
    gap: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-16{
    gap: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-20{
    gap: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-24{
    gap: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-28{
    gap: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-32{
    gap: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-36{
    gap: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-40{
    gap: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-44{
    gap: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-48{
    gap: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-52{
    gap: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-56{
    gap: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-60{
    gap: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-64{
    gap: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-72{
    gap: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-80{
    gap: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-96{
    gap: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-px{
    gap: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-0\.5{
    gap: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-1\.5{
    gap: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-2\.5{
    gap: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-3\.5{
    gap: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-0{
    column-gap: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-1{
    column-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-2{
    column-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-3{
    column-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-4{
    column-gap: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-5{
    column-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-6{
    column-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-7{
    column-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-8{
    column-gap: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-9{
    column-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-10{
    column-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-11{
    column-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-12{
    column-gap: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-14{
    column-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-16{
    column-gap: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-20{
    column-gap: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-24{
    column-gap: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-28{
    column-gap: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-32{
    column-gap: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-36{
    column-gap: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-40{
    column-gap: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-44{
    column-gap: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-48{
    column-gap: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-52{
    column-gap: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-56{
    column-gap: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-60{
    column-gap: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-64{
    column-gap: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-72{
    column-gap: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-80{
    column-gap: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-96{
    column-gap: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-px{
    column-gap: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-0\.5{
    column-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-1\.5{
    column-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-2\.5{
    column-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-x-3\.5{
    column-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-0{
    row-gap: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-1{
    row-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-2{
    row-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-3{
    row-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-4{
    row-gap: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-5{
    row-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-6{
    row-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-7{
    row-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-8{
    row-gap: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-9{
    row-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-10{
    row-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-11{
    row-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-12{
    row-gap: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-14{
    row-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-16{
    row-gap: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-20{
    row-gap: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-24{
    row-gap: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-28{
    row-gap: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-32{
    row-gap: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-36{
    row-gap: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-40{
    row-gap: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-44{
    row-gap: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-48{
    row-gap: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-52{
    row-gap: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-56{
    row-gap: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-60{
    row-gap: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-64{
    row-gap: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-72{
    row-gap: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-80{
    row-gap: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-96{
    row-gap: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-px{
    row-gap: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-0\.5{
    row-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-1\.5{
    row-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-2\.5{
    row-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-gap-y-3\.5{
    row-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-self-auto{
    place-self: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-self-start{
    place-self: start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-self-end{
    place-self: end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-self-center{
    place-self: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-place-self-stretch{
    place-self: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-self-auto{
    align-self: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-self-start{
    align-self: flex-start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-self-end{
    align-self: flex-end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-self-center{
    align-self: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-self-stretch{
    align-self: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-self-baseline{
    align-self: baseline
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-self-auto{
    justify-self: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-self-start{
    justify-self: start
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-self-end{
    justify-self: end
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-self-center{
    justify-self: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-justify-self-stretch{
    justify-self: stretch
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-auto{
    overflow: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-hidden{
    overflow: hidden
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-visible{
    overflow: visible
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-scroll{
    overflow: scroll
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-x-auto{
    overflow-x: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-y-auto{
    overflow-y: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-x-hidden{
    overflow-x: hidden
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-y-hidden{
    overflow-y: hidden
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-x-visible{
    overflow-x: visible
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-y-visible{
    overflow-y: visible
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-x-scroll{
    overflow-x: scroll
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-y-scroll{
    overflow-y: scroll
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-auto{
    overscroll-behavior: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-contain{
    overscroll-behavior: contain
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-none{
    overscroll-behavior: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-y-none{
    overscroll-behavior-y: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overscroll-x-none{
    overscroll-behavior-x: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-ellipsis{
    text-overflow: ellipsis
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-overflow-clip{
    text-overflow: clip
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-whitespace-normal{
    white-space: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-whitespace-nowrap{
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-whitespace-pre{
    white-space: pre
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-whitespace-pre-line{
    white-space: pre-line
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-break-normal{
    overflow-wrap: normal;
    word-break: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-break-words{
    overflow-wrap: break-word
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-break-all{
    word-break: break-all
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-none{
    border-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-sm{
    border-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded{
    border-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-md{
    border-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-lg{
    border-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-xl{
    border-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-2xl{
    border-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-3xl{
    border-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-full{
    border-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-none{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-none{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-none{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-none{
    border-top-left-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-md{
    border-top-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-xl{
    border-top-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-2xl{
    border-top-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-3xl{
    border-top-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-none{
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-md{
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-xl{
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-2xl{
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-3xl{
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-none{
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-md{
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-xl{
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-2xl{
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-none{
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-md{
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-2xl{
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-0{
    border-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-2{
    border-width: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-4{
    border-width: 4px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-8{
    border-width: 8px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border{
    border-width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-t-0{
    border-top-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-t-2{
    border-top-width: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-t-4{
    border-top-width: 4px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-t-8{
    border-top-width: 8px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-t{
    border-top-width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-r-0{
    border-right-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-r-2{
    border-right-width: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-r-4{
    border-right-width: 4px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-r-8{
    border-right-width: 8px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-r{
    border-right-width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-b-0{
    border-bottom-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-b-2{
    border-bottom-width: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-b-4{
    border-bottom-width: 4px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-b-8{
    border-bottom-width: 8px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-b{
    border-bottom-width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-l-0{
    border-left-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-l-2{
    border-left-width: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-l-4{
    border-left-width: 4px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-l-8{
    border-left-width: 8px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-l{
    border-left-width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-solid{
    border-style: solid
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-dashed{
    border-style: dashed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-dotted{
    border-style: dotted
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-double{
    border-style: double
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-none{
    border-style: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-transparent:focus-within{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-current:focus-within{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-black:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-white:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-gray-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-red-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-yellow-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-green-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-blue-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-indigo-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-purple-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-pink-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-transparent:hover{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-current:hover{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-red-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-yellow-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-green-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-indigo-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-purple-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-pink-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-transparent:focus{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-current:focus{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-red-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-yellow-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-green-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-indigo-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-purple-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-pink-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-0:focus-within{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-border-opacity-100:focus-within{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-0:hover{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-5:hover{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-10:hover{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-20:hover{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-25:hover{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-30:hover{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-40:hover{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-50:hover{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-60:hover{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-70:hover{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-75:hover{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-80:hover{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-90:hover{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-95:hover{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-border-opacity-100:hover{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-0:focus{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-5:focus{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-10:focus{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-20:focus{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-25:focus{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-30:focus{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-40:focus{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-50:focus{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-60:focus{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-70:focus{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-75:focus{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-80:focus{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-90:focus{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-95:focus{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-border-opacity-100:focus{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-transparent:focus-within{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-current:focus-within{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-black:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-white:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-gray-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-red-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-green-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-blue-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-purple-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-pink-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-transparent:hover{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-current:hover{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-red-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-yellow-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-green-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-indigo-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-purple-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-pink-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-transparent:focus{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-current:focus{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-red-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-yellow-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-green-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-indigo-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-purple-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-pink-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-0:hover{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-bg-opacity-100:hover{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-0:focus{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-bg-opacity-100:focus{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-none{
    background-image: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-black{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-50{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-100{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-200{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-300{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-400{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-500{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-600{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-700{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-800{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-gray-900{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-50{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-100{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-200{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-300{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-400{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-500{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-600{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-700{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-800{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-red-900{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-50{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-100{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-200{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-300{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-400{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-500{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-600{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-700{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-800{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-yellow-900{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-50{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-100{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-200{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-300{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-400{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-500{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-600{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-700{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-800{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-green-900{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-50{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-100{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-200{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-300{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-400{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-500{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-600{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-700{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-800{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-blue-900{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-50{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-100{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-200{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-300{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-400{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-500{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-600{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-700{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-800{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-indigo-900{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-50{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-100{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-200{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-300{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-400{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-500{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-600{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-700{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-800{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-purple-900{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-50{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-100{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-200{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-300{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-400{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-500{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-600{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-700{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-800{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-from-pink-900{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-black:hover{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-50:hover{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-300:hover{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-400:hover{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-500:hover{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-600:hover{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-700:hover{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-800:hover{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-gray-900:hover{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-50:hover{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-100:hover{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-200:hover{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-300:hover{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-400:hover{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-500:hover{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-600:hover{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-700:hover{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-800:hover{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-red-900:hover{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-200:hover{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-600:hover{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-700:hover{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-800:hover{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-yellow-900:hover{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-50:hover{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-100:hover{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-200:hover{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-300:hover{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-400:hover{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-500:hover{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-600:hover{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-700:hover{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-800:hover{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-green-900:hover{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-50:hover{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-100:hover{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-300:hover{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-400:hover{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-500:hover{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-600:hover{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-800:hover{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-400:hover{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-500:hover{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-700:hover{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-800:hover{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-indigo-900:hover{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-100:hover{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-400:hover{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-600:hover{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-700:hover{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-800:hover{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-purple-900:hover{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-100:hover{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-400:hover{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-500:hover{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-600:hover{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-700:hover{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-800:hover{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-from-pink-900:hover{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-black:focus{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-50:focus{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-300:focus{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-400:focus{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-500:focus{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-600:focus{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-700:focus{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-800:focus{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-gray-900:focus{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-50:focus{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-100:focus{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-200:focus{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-300:focus{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-400:focus{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-500:focus{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-600:focus{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-700:focus{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-800:focus{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-red-900:focus{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-200:focus{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-600:focus{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-700:focus{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-800:focus{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-yellow-900:focus{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-50:focus{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-100:focus{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-200:focus{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-300:focus{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-400:focus{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-500:focus{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-600:focus{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-700:focus{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-800:focus{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-green-900:focus{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-50:focus{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-100:focus{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-300:focus{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-400:focus{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-500:focus{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-600:focus{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-800:focus{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-400:focus{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-500:focus{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-700:focus{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-800:focus{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-indigo-900:focus{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-100:focus{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-400:focus{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-600:focus{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-700:focus{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-800:focus{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-purple-900:focus{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-100:focus{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-400:focus{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-500:focus{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-600:focus{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-700:focus{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-800:focus{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-from-pink-900:focus{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-transparent{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-current{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-black{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-white{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-50{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-100{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-200{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-300{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-400{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-500{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-600{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-700{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-800{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-gray-900{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-50{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-100{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-200{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-300{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-400{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-500{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-600{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-700{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-800{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-red-900{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-50{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-100{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-200{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-300{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-400{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-500{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-600{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-700{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-800{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-yellow-900{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-50{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-100{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-200{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-300{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-400{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-500{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-600{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-700{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-800{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-green-900{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-50{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-100{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-200{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-300{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-400{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-500{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-600{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-700{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-800{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-blue-900{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-50{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-100{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-200{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-300{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-400{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-500{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-600{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-700{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-800{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-indigo-900{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-50{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-100{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-200{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-300{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-400{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-500{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-600{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-700{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-800{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-purple-900{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-50{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-100{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-200{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-300{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-400{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-500{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-600{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-700{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-800{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-to-pink-900{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-transparent:hover{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-current:hover{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-black:hover{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-white:hover{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-50:hover{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-300:hover{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-400:hover{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-500:hover{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-600:hover{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-700:hover{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-800:hover{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-gray-900:hover{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-50:hover{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-100:hover{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-200:hover{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-300:hover{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-400:hover{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-500:hover{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-600:hover{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-700:hover{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-800:hover{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-red-900:hover{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-200:hover{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-600:hover{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-700:hover{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-800:hover{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-yellow-900:hover{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-50:hover{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-100:hover{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-200:hover{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-300:hover{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-400:hover{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-500:hover{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-600:hover{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-700:hover{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-800:hover{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-green-900:hover{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-50:hover{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-100:hover{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-300:hover{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-400:hover{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-500:hover{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-600:hover{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-800:hover{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-400:hover{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-500:hover{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-700:hover{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-800:hover{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-indigo-900:hover{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-100:hover{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-400:hover{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-600:hover{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-700:hover{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-800:hover{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-purple-900:hover{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-100:hover{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-400:hover{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-500:hover{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-600:hover{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-700:hover{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-800:hover{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-to-pink-900:hover{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-transparent:focus{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-current:focus{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-black:focus{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-white:focus{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-50:focus{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-300:focus{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-400:focus{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-500:focus{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-600:focus{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-700:focus{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-800:focus{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-gray-900:focus{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-50:focus{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-100:focus{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-200:focus{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-300:focus{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-400:focus{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-500:focus{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-600:focus{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-700:focus{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-800:focus{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-red-900:focus{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-200:focus{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-600:focus{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-700:focus{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-800:focus{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-yellow-900:focus{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-50:focus{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-100:focus{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-200:focus{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-300:focus{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-400:focus{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-500:focus{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-600:focus{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-700:focus{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-800:focus{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-green-900:focus{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-50:focus{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-100:focus{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-300:focus{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-400:focus{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-500:focus{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-600:focus{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-800:focus{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-400:focus{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-500:focus{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-700:focus{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-800:focus{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-indigo-900:focus{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-100:focus{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-400:focus{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-600:focus{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-700:focus{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-800:focus{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-purple-900:focus{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-100:focus{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-400:focus{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-500:focus{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-600:focus{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-700:focus{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-800:focus{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-to-pink-900:focus{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-decoration-slice{
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-decoration-clone{
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-auto{
    background-size: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-cover{
    background-size: cover
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-contain{
    background-size: contain
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-fixed{
    background-attachment: fixed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-local{
    background-attachment: local
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-scroll{
    background-attachment: scroll
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-clip-border{
    background-clip: border-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-clip-padding{
    background-clip: padding-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-clip-content{
    background-clip: content-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-bottom{
    background-position: bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-center{
    background-position: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-left{
    background-position: left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-left-bottom{
    background-position: left bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-left-top{
    background-position: left top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-right{
    background-position: right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-right-bottom{
    background-position: right bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-right-top{
    background-position: right top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-top{
    background-position: top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-repeat{
    background-repeat: repeat
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-no-repeat{
    background-repeat: no-repeat
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-repeat-x{
    background-repeat: repeat-x
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-repeat-y{
    background-repeat: repeat-y
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-repeat-round{
    background-repeat: round
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-repeat-space{
    background-repeat: space
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-origin-border{
    background-origin: border-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-origin-padding{
    background-origin: padding-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-origin-content{
    background-origin: content-box
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-fill-current{
    fill: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-stroke-current{
    stroke: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-stroke-0{
    stroke-width: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-stroke-1{
    stroke-width: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-stroke-2{
    stroke-width: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-contain{
    object-fit: contain
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-cover{
    object-fit: cover
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-fill{
    object-fit: fill
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-none{
    object-fit: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-scale-down{
    object-fit: scale-down
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-bottom{
    object-position: bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-center{
    object-position: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-left{
    object-position: left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-left-bottom{
    object-position: left bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-left-top{
    object-position: left top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-right{
    object-position: right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-right-bottom{
    object-position: right bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-right-top{
    object-position: right top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-object-top{
    object-position: top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-0{
    padding: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-1{
    padding: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-2{
    padding: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-3{
    padding: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-4{
    padding: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-5{
    padding: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-6{
    padding: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-7{
    padding: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-8{
    padding: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-9{
    padding: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-10{
    padding: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-11{
    padding: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-12{
    padding: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-14{
    padding: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-16{
    padding: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-20{
    padding: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-24{
    padding: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-28{
    padding: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-32{
    padding: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-36{
    padding: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-40{
    padding: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-44{
    padding: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-48{
    padding: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-52{
    padding: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-56{
    padding: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-60{
    padding: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-64{
    padding: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-72{
    padding: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-80{
    padding: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-96{
    padding: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-px{
    padding: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-0\.5{
    padding: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-1\.5{
    padding: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-2\.5{
    padding: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-p-3\.5{
    padding: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-0{
    padding-left: 0px;
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-9{
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-11{
    padding-left: 2.75rem;
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-14{
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-28{
    padding-left: 7rem;
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-36{
    padding-left: 9rem;
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-44{
    padding-left: 11rem;
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-52{
    padding-left: 13rem;
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-60{
    padding-left: 15rem;
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-72{
    padding-left: 18rem;
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-80{
    padding-left: 20rem;
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-96{
    padding-left: 24rem;
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-0{
    padding-top: 0px;
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-7{
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-9{
    padding-top: 2.25rem;
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-11{
    padding-top: 2.75rem;
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-14{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-28{
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-36{
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-44{
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-52{
    padding-top: 13rem;
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-60{
    padding-top: 15rem;
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-72{
    padding-top: 18rem;
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-80{
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-96{
    padding-top: 24rem;
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-0{
    padding-top: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-1{
    padding-top: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-2{
    padding-top: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-3{
    padding-top: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-4{
    padding-top: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-5{
    padding-top: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-6{
    padding-top: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-7{
    padding-top: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-8{
    padding-top: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-9{
    padding-top: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-10{
    padding-top: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-11{
    padding-top: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-12{
    padding-top: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-14{
    padding-top: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-16{
    padding-top: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-20{
    padding-top: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-24{
    padding-top: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-28{
    padding-top: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-32{
    padding-top: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-36{
    padding-top: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-40{
    padding-top: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-44{
    padding-top: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-48{
    padding-top: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-52{
    padding-top: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-56{
    padding-top: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-60{
    padding-top: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-64{
    padding-top: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-72{
    padding-top: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-80{
    padding-top: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-96{
    padding-top: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-px{
    padding-top: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-0\.5{
    padding-top: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-1\.5{
    padding-top: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-2\.5{
    padding-top: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pt-3\.5{
    padding-top: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-0{
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-1{
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-2{
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-3{
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-4{
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-5{
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-6{
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-7{
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-8{
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-9{
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-10{
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-11{
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-12{
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-14{
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-16{
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-20{
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-24{
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-28{
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-32{
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-36{
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-40{
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-44{
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-48{
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-52{
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-56{
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-60{
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-64{
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-72{
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-80{
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-96{
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-px{
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-0\.5{
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-1\.5{
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-2\.5{
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pr-3\.5{
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-0{
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-1{
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-2{
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-3{
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-4{
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-5{
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-6{
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-7{
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-8{
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-9{
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-10{
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-11{
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-12{
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-14{
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-16{
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-20{
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-24{
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-28{
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-32{
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-36{
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-40{
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-44{
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-48{
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-52{
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-56{
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-60{
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-64{
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-72{
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-80{
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-96{
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-px{
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-0\.5{
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-1\.5{
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-2\.5{
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pb-3\.5{
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-0{
    padding-left: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-1{
    padding-left: 0.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-2{
    padding-left: 0.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-3{
    padding-left: 0.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-4{
    padding-left: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-5{
    padding-left: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-6{
    padding-left: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-7{
    padding-left: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-8{
    padding-left: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-9{
    padding-left: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-10{
    padding-left: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-11{
    padding-left: 2.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-12{
    padding-left: 3rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-14{
    padding-left: 3.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-16{
    padding-left: 4rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-20{
    padding-left: 5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-24{
    padding-left: 6rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-28{
    padding-left: 7rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-32{
    padding-left: 8rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-36{
    padding-left: 9rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-40{
    padding-left: 10rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-44{
    padding-left: 11rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-48{
    padding-left: 12rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-52{
    padding-left: 13rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-56{
    padding-left: 14rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-60{
    padding-left: 15rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-64{
    padding-left: 16rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-72{
    padding-left: 18rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-80{
    padding-left: 20rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-96{
    padding-left: 24rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-px{
    padding-left: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-0\.5{
    padding-left: 0.125rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-1\.5{
    padding-left: 0.375rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-2\.5{
    padding-left: 0.625rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-pl-3\.5{
    padding-left: 0.875rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-left{
    text-align: left
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-center{
    text-align: center
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-right{
    text-align: right
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-justify{
    text-align: justify
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-align-baseline{
    vertical-align: baseline
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-align-top{
    vertical-align: top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-align-middle{
    vertical-align: middle
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-align-bottom{
    vertical-align: bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-align-text-top{
    vertical-align: text-top
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-align-text-bottom{
    vertical-align: text-bottom
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-xs{
    font-size: 0.75rem;
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-base{
    font-size: 1rem;
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-2xl{
    font-size: 1.5rem;
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-5xl{
    font-size: 3rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-6xl{
    font-size: 3.75rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-7xl{
    font-size: 4.5rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-8xl{
    font-size: 6rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-9xl{
    font-size: 8rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-thin{
    font-weight: 100
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-extralight{
    font-weight: 200
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-light{
    font-weight: 300
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-normal{
    font-weight: 400
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-medium{
    font-weight: 500
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-semibold{
    font-weight: 600
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-bold{
    font-weight: 700
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-extrabold{
    font-weight: 800
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-font-black{
    font-weight: 900
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-uppercase{
    text-transform: uppercase
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-lowercase{
    text-transform: lowercase
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-capitalize{
    text-transform: capitalize
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-normal-case{
    text-transform: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-italic{
    font-style: italic
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-not-italic{
    font-style: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ordinal, #skindrWidget.skindrWidget .md\:skindr-widget-slashed-zero, #skindrWidget.skindrWidget .md\:skindr-widget-lining-nums, #skindrWidget.skindrWidget .md\:skindr-widget-oldstyle-nums, #skindrWidget.skindrWidget .md\:skindr-widget-proportional-nums, #skindrWidget.skindrWidget .md\:skindr-widget-tabular-nums, #skindrWidget.skindrWidget .md\:skindr-widget-diagonal-fractions, #skindrWidget.skindrWidget .md\:skindr-widget-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-normal-nums{
    font-variant-numeric: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ordinal{
    --tw-ordinal: ordinal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-slashed-zero{
    --tw-slashed-zero: slashed-zero
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-lining-nums{
    --tw-numeric-figure: lining-nums
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-proportional-nums{
    --tw-numeric-spacing: proportional-nums
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tabular-nums{
    --tw-numeric-spacing: tabular-nums
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-3{
    line-height: .75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-4{
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-5{
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-6{
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-7{
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-8{
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-9{
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-10{
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-none{
    line-height: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-tight{
    line-height: 1.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-snug{
    line-height: 1.375
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-normal{
    line-height: 1.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-relaxed{
    line-height: 1.625
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-leading-loose{
    line-height: 2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tracking-tighter{
    letter-spacing: -0.05em
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tracking-tight{
    letter-spacing: -0.025em
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tracking-normal{
    letter-spacing: 0em
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tracking-wide{
    letter-spacing: 0.025em
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tracking-wider{
    letter-spacing: 0.05em
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-tracking-widest{
    letter-spacing: 0.1em
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-transparent:focus-within{
    color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-current:focus-within{
    color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-black:focus-within{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-white:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-gray-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-red-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-yellow-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-green-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-blue-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-indigo-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-purple-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-pink-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-transparent:hover{
    color: transparent
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-current:hover{
    color: currentColor
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-50:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-200:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-400:hover{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-500:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-600:hover{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-700:hover{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-800:hover{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-red-900:hover{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-50:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-200:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-400:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-500:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-600:hover{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-700:hover{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-800:hover{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-yellow-900:hover{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-50:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-100:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-200:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-300:hover{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-400:hover{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-500:hover{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-600:hover{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-700:hover{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-800:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-green-900:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-50:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-50:hover{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-100:hover{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-200:hover{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-300:hover{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-400:hover{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-500:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-700:hover{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-800:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-indigo-900:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-50:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-100:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-200:hover{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-300:hover{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-400:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-500:hover{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-600:hover{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-700:hover{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-800:hover{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-purple-900:hover{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-50:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-100:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-200:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-300:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-400:hover{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-500:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-600:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-700:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-800:hover{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-pink-900:hover{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-transparent:focus{
    color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-current:focus{
    color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-50:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-50:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-200:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-400:focus{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-500:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-600:focus{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-700:focus{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-800:focus{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-red-900:focus{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-50:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-200:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-400:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-500:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-600:focus{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-700:focus{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-800:focus{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-yellow-900:focus{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-50:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-100:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-200:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-300:focus{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-400:focus{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-500:focus{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-600:focus{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-700:focus{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-800:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-green-900:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-50:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-50:focus{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-100:focus{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-200:focus{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-300:focus{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-400:focus{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-500:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-600:focus{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-700:focus{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-800:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-indigo-900:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-50:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-100:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-200:focus{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-300:focus{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-400:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-500:focus{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-600:focus{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-700:focus{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-800:focus{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-purple-900:focus{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-50:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-100:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-200:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-300:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-400:focus{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-500:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-600:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-700:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-800:focus{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-pink-900:focus{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-0:focus-within{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-text-opacity-100:focus-within{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-0:hover{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-5:hover{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-10:hover{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-20:hover{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-25:hover{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-30:hover{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-40:hover{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-50:hover{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-60:hover{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-70:hover{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-75:hover{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-80:hover{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-90:hover{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-95:hover{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-text-opacity-100:hover{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-0:focus{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-5:focus{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-10:focus{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-20:focus{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-25:focus{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-30:focus{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-40:focus{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-50:focus{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-60:focus{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-70:focus{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-75:focus{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-80:focus{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-90:focus{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-95:focus{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-text-opacity-100:focus{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-underline:focus-within{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-line-through:focus-within{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-no-underline:focus-within{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-underline:hover{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-line-through:hover{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-no-underline:hover{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-underline:focus{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-line-through:focus{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-no-underline:focus{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-transparent::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-current::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-current:focus::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-0:focus-within{
    opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-5:focus-within{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-10:focus-within{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-20:focus-within{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-25:focus-within{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-30:focus-within{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-40:focus-within{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-50:focus-within{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-60:focus-within{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-70:focus-within{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-75:focus-within{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-80:focus-within{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-90:focus-within{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-95:focus-within{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-opacity-100:focus-within{
    opacity: 1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-0:hover{
    opacity: 0
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-5:hover{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-10:hover{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-20:hover{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-25:hover{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-30:hover{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-40:hover{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-50:hover{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-60:hover{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-70:hover{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-75:hover{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-80:hover{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-90:hover{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-95:hover{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-opacity-100:hover{
    opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-0:focus{
    opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-5:focus{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-10:focus{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-20:focus{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-25:focus{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-30:focus{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-40:focus{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-50:focus{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-60:focus{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-70:focus{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-75:focus{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-80:focus{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-90:focus{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-95:focus{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-opacity-100:focus{
    opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-normal{
    background-blend-mode: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-multiply{
    background-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-screen{
    background-blend-mode: screen
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-overlay{
    background-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-darken{
    background-blend-mode: darken
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-lighten{
    background-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-color-dodge{
    background-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-color-burn{
    background-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-hard-light{
    background-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-soft-light{
    background-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-difference{
    background-blend-mode: difference
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-exclusion{
    background-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-hue{
    background-blend-mode: hue
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-saturation{
    background-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-color{
    background-blend-mode: color
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-bg-blend-luminosity{
    background-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-normal{
    mix-blend-mode: normal
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-multiply{
    mix-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-screen{
    mix-blend-mode: screen
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-overlay{
    mix-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-darken{
    mix-blend-mode: darken
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-lighten{
    mix-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-color-dodge{
    mix-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-color-burn{
    mix-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-hard-light{
    mix-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-soft-light{
    mix-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-difference{
    mix-blend-mode: difference
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-exclusion{
    mix-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-hue{
    mix-blend-mode: hue
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-saturation{
    mix-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-color{
    mix-blend-mode: color
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-mix-blend-luminosity{
    mix-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .md\:group-hover\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:hover\:skindr-widget-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-outline-white:focus-within{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-outline-black:focus-within{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-inset{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-inset:focus-within{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-inset:focus{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-transparent{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-current{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-red-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-yellow-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-green-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-indigo-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-purple-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-pink-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-transparent:focus-within{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-current:focus-within{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-red-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-green-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-purple-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-pink-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-transparent:focus{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-current:focus{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-red-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-yellow-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-green-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-indigo-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-purple-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-pink-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-0{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-5{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-10{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-20{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-25{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-30{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-40{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-50{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-60{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-70{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-75{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-80{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-90{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-95{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-opacity-100{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-0:focus{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-opacity-100:focus{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-0{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-1{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-2{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-4{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-8{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-0:focus{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-1:focus{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-2:focus{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-4:focus{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-8:focus{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-transparent{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-current{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-black{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-white{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-700{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-gray-900{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-400{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-400{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-500{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-600{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-700{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-800{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ring-offset-pink-900{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .md\:focus-within\:skindr-widget-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-black:focus{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-white:focus{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .md\:focus\:skindr-widget-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-filter-none{
    filter: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-0{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-none{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-sm{
    --tw-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur{
    --tw-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-md{
    --tw-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-lg{
    --tw-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-xl{
    --tw-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-2xl{
    --tw-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-blur-3xl{
    --tw-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-0{
    --tw-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-50{
    --tw-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-75{
    --tw-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-90{
    --tw-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-95{
    --tw-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-100{
    --tw-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-105{
    --tw-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-110{
    --tw-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-125{
    --tw-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-150{
    --tw-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-brightness-200{
    --tw-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-0{
    --tw-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-50{
    --tw-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-75{
    --tw-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-100{
    --tw-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-125{
    --tw-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-150{
    --tw-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-contrast-200{
    --tw-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grayscale-0{
    --tw-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-grayscale{
    --tw-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-invert-0{
    --tw-invert: invert(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-invert{
    --tw-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-saturate-0{
    --tw-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-saturate-50{
    --tw-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-saturate-100{
    --tw-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-saturate-150{
    --tw-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-saturate-200{
    --tw-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-sepia-0{
    --tw-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-sepia{
    --tw-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-filter-none{
    -webkit-backdrop-filter: none;
            backdrop-filter: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-0{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-none{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur{
    --tw-backdrop-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-invert-0{
    --tw-backdrop-invert: invert(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-invert{
    --tw-backdrop-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition-none{
    transition-property: none
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition-shadow{
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-75{
    transition-delay: 75ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-100{
    transition-delay: 100ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-150{
    transition-delay: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-200{
    transition-delay: 200ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-300{
    transition-delay: 300ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-500{
    transition-delay: 500ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-700{
    transition-delay: 700ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-delay-1000{
    transition-delay: 1000ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-75{
    transition-duration: 75ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-100{
    transition-duration: 100ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-150{
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-200{
    transition-duration: 200ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-300{
    transition-duration: 300ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-500{
    transition-duration: 500ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-700{
    transition-duration: 700ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-duration-1000{
    transition-duration: 1000ms
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ease-linear{
    transition-timing-function: linear
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  #skindrWidget.skindrWidget .md\:skindr-widget-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 1024px){
  .lg\:skindr-widget-container{
    width: 100%
  }

  @media (min-width: 640px){
    .lg\:skindr-widget-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .lg\:skindr-widget-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .lg\:skindr-widget-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .lg\:skindr-widget-container{
      max-width: 1280px
    }
  }

  @media (min-width: 1536px){
    .lg\:skindr-widget-container{
      max-width: 1536px
    }
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-sr-only:focus-within{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-not-sr-only:focus-within{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pointer-events-none{
    pointer-events: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pointer-events-auto{
    pointer-events: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-visible{
    visibility: visible
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-invisible{
    visibility: hidden
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-static{
    position: static
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-fixed{
    position: fixed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-absolute{
    position: absolute
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-relative{
    position: relative
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-sticky{
    position: sticky
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-1{
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-2{
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-3{
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-4{
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-5{
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-6{
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-7{
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-8{
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-9{
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-10{
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-11{
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-12{
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-14{
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-16{
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-20{
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-24{
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-28{
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-32{
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-36{
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-40{
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-44{
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-48{
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-52{
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-56{
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-60{
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-64{
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-72{
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-80{
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-96{
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-px{
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-0\.5{
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-1\.5{
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-2\.5{
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-3\.5{
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-1{
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-2{
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-3{
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-4{
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-5{
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-6{
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-7{
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-8{
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-9{
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-10{
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-11{
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-12{
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-14{
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-16{
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-20{
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-24{
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-28{
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-32{
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-36{
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-40{
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-44{
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-48{
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-52{
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-56{
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-60{
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-64{
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-72{
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-80{
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-96{
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-px{
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-0\.5{
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-1\.5{
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-2\.5{
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-3\.5{
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-1\/2{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-1\/3{
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-2\/3{
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-1\/4{
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-2\/4{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-3\/4{
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-full{
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-1\/2{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-1\/3{
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-2\/3{
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-1\/4{
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-2\/4{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-3\/4{
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-full{
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-1{
    left: 0.25rem;
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-2{
    left: 0.5rem;
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-3{
    left: 0.75rem;
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-4{
    left: 1rem;
    right: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-5{
    left: 1.25rem;
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-6{
    left: 1.5rem;
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-7{
    left: 1.75rem;
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-8{
    left: 2rem;
    right: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-9{
    left: 2.25rem;
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-10{
    left: 2.5rem;
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-11{
    left: 2.75rem;
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-12{
    left: 3rem;
    right: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-14{
    left: 3.5rem;
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-16{
    left: 4rem;
    right: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-20{
    left: 5rem;
    right: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-24{
    left: 6rem;
    right: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-28{
    left: 7rem;
    right: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-32{
    left: 8rem;
    right: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-36{
    left: 9rem;
    right: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-40{
    left: 10rem;
    right: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-44{
    left: 11rem;
    right: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-48{
    left: 12rem;
    right: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-52{
    left: 13rem;
    right: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-56{
    left: 14rem;
    right: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-60{
    left: 15rem;
    right: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-64{
    left: 16rem;
    right: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-72{
    left: 18rem;
    right: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-80{
    left: 20rem;
    right: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-96{
    left: 24rem;
    right: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-auto{
    left: auto;
    right: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-px{
    left: 1px;
    right: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-0\.5{
    left: 0.125rem;
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-1\.5{
    left: 0.375rem;
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-2\.5{
    left: 0.625rem;
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-3\.5{
    left: 0.875rem;
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-1{
    left: -0.25rem;
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-2{
    left: -0.5rem;
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-3{
    left: -0.75rem;
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-4{
    left: -1rem;
    right: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-5{
    left: -1.25rem;
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-6{
    left: -1.5rem;
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-7{
    left: -1.75rem;
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-8{
    left: -2rem;
    right: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-9{
    left: -2.25rem;
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-10{
    left: -2.5rem;
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-11{
    left: -2.75rem;
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-12{
    left: -3rem;
    right: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-14{
    left: -3.5rem;
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-16{
    left: -4rem;
    right: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-20{
    left: -5rem;
    right: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-24{
    left: -6rem;
    right: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-28{
    left: -7rem;
    right: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-32{
    left: -8rem;
    right: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-36{
    left: -9rem;
    right: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-40{
    left: -10rem;
    right: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-44{
    left: -11rem;
    right: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-48{
    left: -12rem;
    right: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-52{
    left: -13rem;
    right: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-56{
    left: -14rem;
    right: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-60{
    left: -15rem;
    right: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-64{
    left: -16rem;
    right: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-72{
    left: -18rem;
    right: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-80{
    left: -20rem;
    right: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-96{
    left: -24rem;
    right: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-px{
    left: -1px;
    right: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-0\.5{
    left: -0.125rem;
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-1\.5{
    left: -0.375rem;
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-2\.5{
    left: -0.625rem;
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-3\.5{
    left: -0.875rem;
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-1\/2{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-1\/3{
    left: 33.333333%;
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-2\/3{
    left: 66.666667%;
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-1\/4{
    left: 25%;
    right: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-2\/4{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-3\/4{
    left: 75%;
    right: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-x-full{
    left: 100%;
    right: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-1\/2{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-1\/3{
    left: -33.333333%;
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-2\/3{
    left: -66.666667%;
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-1\/4{
    left: -25%;
    right: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-2\/4{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-3\/4{
    left: -75%;
    right: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-x-full{
    left: -100%;
    right: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-1{
    top: 0.25rem;
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-2{
    top: 0.5rem;
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-3{
    top: 0.75rem;
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-4{
    top: 1rem;
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-5{
    top: 1.25rem;
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-6{
    top: 1.5rem;
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-7{
    top: 1.75rem;
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-8{
    top: 2rem;
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-9{
    top: 2.25rem;
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-10{
    top: 2.5rem;
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-11{
    top: 2.75rem;
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-12{
    top: 3rem;
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-14{
    top: 3.5rem;
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-16{
    top: 4rem;
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-20{
    top: 5rem;
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-24{
    top: 6rem;
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-28{
    top: 7rem;
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-32{
    top: 8rem;
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-36{
    top: 9rem;
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-40{
    top: 10rem;
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-44{
    top: 11rem;
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-48{
    top: 12rem;
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-52{
    top: 13rem;
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-56{
    top: 14rem;
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-60{
    top: 15rem;
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-64{
    top: 16rem;
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-72{
    top: 18rem;
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-80{
    top: 20rem;
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-96{
    top: 24rem;
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-auto{
    top: auto;
    bottom: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-px{
    top: 1px;
    bottom: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-0\.5{
    top: 0.125rem;
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-1\.5{
    top: 0.375rem;
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-2\.5{
    top: 0.625rem;
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-3\.5{
    top: 0.875rem;
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-1{
    top: -0.25rem;
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-2{
    top: -0.5rem;
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-3{
    top: -0.75rem;
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-4{
    top: -1rem;
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-5{
    top: -1.25rem;
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-6{
    top: -1.5rem;
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-7{
    top: -1.75rem;
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-8{
    top: -2rem;
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-9{
    top: -2.25rem;
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-10{
    top: -2.5rem;
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-11{
    top: -2.75rem;
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-12{
    top: -3rem;
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-14{
    top: -3.5rem;
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-16{
    top: -4rem;
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-20{
    top: -5rem;
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-24{
    top: -6rem;
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-28{
    top: -7rem;
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-32{
    top: -8rem;
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-36{
    top: -9rem;
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-40{
    top: -10rem;
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-44{
    top: -11rem;
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-48{
    top: -12rem;
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-52{
    top: -13rem;
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-56{
    top: -14rem;
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-60{
    top: -15rem;
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-64{
    top: -16rem;
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-72{
    top: -18rem;
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-80{
    top: -20rem;
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-96{
    top: -24rem;
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-px{
    top: -1px;
    bottom: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-0\.5{
    top: -0.125rem;
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-1\.5{
    top: -0.375rem;
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-2\.5{
    top: -0.625rem;
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-3\.5{
    top: -0.875rem;
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-1\/2{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-1\/3{
    top: 33.333333%;
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-2\/3{
    top: 66.666667%;
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-1\/4{
    top: 25%;
    bottom: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-2\/4{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-3\/4{
    top: 75%;
    bottom: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inset-y-full{
    top: 100%;
    bottom: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-1\/2{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-1\/3{
    top: -33.333333%;
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-2\/3{
    top: -66.666667%;
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-1\/4{
    top: -25%;
    bottom: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-2\/4{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-3\/4{
    top: -75%;
    bottom: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--inset-y-full{
    top: -100%;
    bottom: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-1{
    top: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-2{
    top: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-3{
    top: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-4{
    top: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-5{
    top: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-6{
    top: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-7{
    top: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-8{
    top: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-9{
    top: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-10{
    top: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-11{
    top: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-12{
    top: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-14{
    top: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-16{
    top: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-20{
    top: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-24{
    top: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-28{
    top: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-32{
    top: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-36{
    top: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-40{
    top: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-44{
    top: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-48{
    top: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-52{
    top: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-56{
    top: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-60{
    top: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-64{
    top: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-72{
    top: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-80{
    top: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-96{
    top: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-auto{
    top: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-px{
    top: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-0\.5{
    top: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-1\.5{
    top: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-2\.5{
    top: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-3\.5{
    top: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-1{
    top: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-2{
    top: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-3{
    top: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-4{
    top: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-5{
    top: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-6{
    top: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-7{
    top: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-8{
    top: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-9{
    top: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-10{
    top: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-11{
    top: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-12{
    top: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-14{
    top: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-16{
    top: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-20{
    top: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-24{
    top: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-28{
    top: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-32{
    top: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-36{
    top: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-40{
    top: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-44{
    top: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-48{
    top: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-52{
    top: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-56{
    top: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-60{
    top: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-64{
    top: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-72{
    top: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-80{
    top: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-96{
    top: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-px{
    top: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-0\.5{
    top: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-1\.5{
    top: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-2\.5{
    top: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-3\.5{
    top: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-1\/2{
    top: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-1\/3{
    top: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-2\/3{
    top: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-1\/4{
    top: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-2\/4{
    top: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-3\/4{
    top: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-top-full{
    top: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-1\/2{
    top: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-1\/3{
    top: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-2\/3{
    top: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-1\/4{
    top: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-2\/4{
    top: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-3\/4{
    top: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--top-full{
    top: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-1{
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-2{
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-3{
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-4{
    right: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-5{
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-6{
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-7{
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-8{
    right: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-9{
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-10{
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-11{
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-12{
    right: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-14{
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-16{
    right: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-20{
    right: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-24{
    right: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-28{
    right: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-32{
    right: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-36{
    right: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-40{
    right: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-44{
    right: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-48{
    right: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-52{
    right: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-56{
    right: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-60{
    right: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-64{
    right: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-72{
    right: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-80{
    right: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-96{
    right: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-auto{
    right: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-px{
    right: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-0\.5{
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-1\.5{
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-2\.5{
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-3\.5{
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-1{
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-2{
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-3{
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-4{
    right: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-5{
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-6{
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-7{
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-8{
    right: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-9{
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-10{
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-11{
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-12{
    right: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-14{
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-16{
    right: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-20{
    right: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-24{
    right: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-28{
    right: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-32{
    right: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-36{
    right: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-40{
    right: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-44{
    right: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-48{
    right: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-52{
    right: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-56{
    right: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-60{
    right: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-64{
    right: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-72{
    right: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-80{
    right: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-96{
    right: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-px{
    right: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-0\.5{
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-1\.5{
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-2\.5{
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-3\.5{
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-1\/2{
    right: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-1\/3{
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-2\/3{
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-1\/4{
    right: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-2\/4{
    right: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-3\/4{
    right: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-right-full{
    right: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-1\/2{
    right: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-1\/3{
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-2\/3{
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-1\/4{
    right: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-2\/4{
    right: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-3\/4{
    right: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--right-full{
    right: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-1{
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-2{
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-3{
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-4{
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-5{
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-6{
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-7{
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-8{
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-9{
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-10{
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-11{
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-12{
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-14{
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-16{
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-20{
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-24{
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-28{
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-32{
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-36{
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-40{
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-44{
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-48{
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-52{
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-56{
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-60{
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-64{
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-72{
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-80{
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-96{
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-auto{
    bottom: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-px{
    bottom: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-0\.5{
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-1\.5{
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-2\.5{
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-3\.5{
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-1{
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-2{
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-3{
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-4{
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-5{
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-6{
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-7{
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-8{
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-9{
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-10{
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-11{
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-12{
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-14{
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-16{
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-20{
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-24{
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-28{
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-32{
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-36{
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-40{
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-44{
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-48{
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-52{
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-56{
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-60{
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-64{
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-72{
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-80{
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-96{
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-px{
    bottom: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-0\.5{
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-1\.5{
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-2\.5{
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-3\.5{
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-1\/2{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-1\/3{
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-2\/3{
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-1\/4{
    bottom: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-2\/4{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-3\/4{
    bottom: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bottom-full{
    bottom: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-1\/2{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-1\/3{
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-2\/3{
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-1\/4{
    bottom: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-2\/4{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-3\/4{
    bottom: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--bottom-full{
    bottom: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-1{
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-2{
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-3{
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-4{
    left: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-5{
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-6{
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-7{
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-8{
    left: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-9{
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-10{
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-11{
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-12{
    left: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-14{
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-16{
    left: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-20{
    left: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-24{
    left: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-28{
    left: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-32{
    left: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-36{
    left: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-40{
    left: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-44{
    left: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-48{
    left: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-52{
    left: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-56{
    left: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-60{
    left: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-64{
    left: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-72{
    left: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-80{
    left: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-96{
    left: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-auto{
    left: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-px{
    left: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-0\.5{
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-1\.5{
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-2\.5{
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-3\.5{
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-1{
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-2{
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-3{
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-4{
    left: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-5{
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-6{
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-7{
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-8{
    left: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-9{
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-10{
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-11{
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-12{
    left: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-14{
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-16{
    left: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-20{
    left: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-24{
    left: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-28{
    left: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-32{
    left: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-36{
    left: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-40{
    left: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-44{
    left: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-48{
    left: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-52{
    left: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-56{
    left: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-60{
    left: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-64{
    left: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-72{
    left: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-80{
    left: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-96{
    left: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-px{
    left: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-0\.5{
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-1\.5{
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-2\.5{
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-3\.5{
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-1\/2{
    left: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-1\/3{
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-2\/3{
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-1\/4{
    left: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-2\/4{
    left: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-3\/4{
    left: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-left-full{
    left: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-1\/2{
    left: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-1\/3{
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-2\/3{
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-1\/4{
    left: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-2\/4{
    left: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-3\/4{
    left: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--left-full{
    left: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-isolate{
    isolation: isolate
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-isolation-auto{
    isolation: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-0{
    z-index: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-10{
    z-index: 10
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-20{
    z-index: 20
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-30{
    z-index: 30
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-40{
    z-index: 40
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-50{
    z-index: 50
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-z-auto{
    z-index: auto
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-0:focus-within{
    z-index: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-10:focus-within{
    z-index: 10
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-20:focus-within{
    z-index: 20
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-30:focus-within{
    z-index: 30
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-40:focus-within{
    z-index: 40
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-50:focus-within{
    z-index: 50
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-z-auto:focus-within{
    z-index: auto
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-0:focus{
    z-index: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-10:focus{
    z-index: 10
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-20:focus{
    z-index: 20
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-30:focus{
    z-index: 30
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-40:focus{
    z-index: 40
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-50:focus{
    z-index: 50
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-z-auto:focus{
    z-index: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-1{
    order: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-2{
    order: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-3{
    order: 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-4{
    order: 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-5{
    order: 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-6{
    order: 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-7{
    order: 7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-8{
    order: 8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-9{
    order: 9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-10{
    order: 10
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-11{
    order: 11
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-12{
    order: 12
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-first{
    order: -9999
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-last{
    order: 9999
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-order-none{
    order: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-auto{
    grid-column: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-1{
    grid-column: span 1 / span 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-2{
    grid-column: span 2 / span 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-3{
    grid-column: span 3 / span 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-4{
    grid-column: span 4 / span 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-5{
    grid-column: span 5 / span 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-6{
    grid-column: span 6 / span 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-7{
    grid-column: span 7 / span 7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-8{
    grid-column: span 8 / span 8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-9{
    grid-column: span 9 / span 9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-10{
    grid-column: span 10 / span 10
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-11{
    grid-column: span 11 / span 11
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-12{
    grid-column: span 12 / span 12
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-span-full{
    grid-column: 1 / -1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-1{
    grid-column-start: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-2{
    grid-column-start: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-3{
    grid-column-start: 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-4{
    grid-column-start: 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-5{
    grid-column-start: 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-6{
    grid-column-start: 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-7{
    grid-column-start: 7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-8{
    grid-column-start: 8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-9{
    grid-column-start: 9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-10{
    grid-column-start: 10
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-11{
    grid-column-start: 11
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-12{
    grid-column-start: 12
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-13{
    grid-column-start: 13
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-start-auto{
    grid-column-start: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-1{
    grid-column-end: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-2{
    grid-column-end: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-3{
    grid-column-end: 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-4{
    grid-column-end: 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-5{
    grid-column-end: 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-6{
    grid-column-end: 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-7{
    grid-column-end: 7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-8{
    grid-column-end: 8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-9{
    grid-column-end: 9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-10{
    grid-column-end: 10
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-11{
    grid-column-end: 11
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-12{
    grid-column-end: 12
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-13{
    grid-column-end: 13
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-col-end-auto{
    grid-column-end: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-auto{
    grid-row: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-1{
    grid-row: span 1 / span 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-2{
    grid-row: span 2 / span 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-3{
    grid-row: span 3 / span 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-4{
    grid-row: span 4 / span 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-5{
    grid-row: span 5 / span 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-6{
    grid-row: span 6 / span 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-span-full{
    grid-row: 1 / -1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-1{
    grid-row-start: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-2{
    grid-row-start: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-3{
    grid-row-start: 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-4{
    grid-row-start: 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-5{
    grid-row-start: 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-6{
    grid-row-start: 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-7{
    grid-row-start: 7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-start-auto{
    grid-row-start: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-1{
    grid-row-end: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-2{
    grid-row-end: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-3{
    grid-row-end: 3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-4{
    grid-row-end: 4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-5{
    grid-row-end: 5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-6{
    grid-row-end: 6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-7{
    grid-row-end: 7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-row-end-auto{
    grid-row-end: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-float-right{
    float: right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-float-left{
    float: left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-float-none{
    float: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-clear-left{
    clear: left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-clear-right{
    clear: right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-clear-both{
    clear: both
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-clear-none{
    clear: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-1{
    margin: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-2{
    margin: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-3{
    margin: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-4{
    margin: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-5{
    margin: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-6{
    margin: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-7{
    margin: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-8{
    margin: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-9{
    margin: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-10{
    margin: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-11{
    margin: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-12{
    margin: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-14{
    margin: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-16{
    margin: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-20{
    margin: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-24{
    margin: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-28{
    margin: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-32{
    margin: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-36{
    margin: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-40{
    margin: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-44{
    margin: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-48{
    margin: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-52{
    margin: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-56{
    margin: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-60{
    margin: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-64{
    margin: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-72{
    margin: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-80{
    margin: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-96{
    margin: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-auto{
    margin: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-px{
    margin: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-0\.5{
    margin: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-1\.5{
    margin: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-2\.5{
    margin: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-m-3\.5{
    margin: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-1{
    margin: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-2{
    margin: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-3{
    margin: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-4{
    margin: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-5{
    margin: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-6{
    margin: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-7{
    margin: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-8{
    margin: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-9{
    margin: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-10{
    margin: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-11{
    margin: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-12{
    margin: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-14{
    margin: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-16{
    margin: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-20{
    margin: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-24{
    margin: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-28{
    margin: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-32{
    margin: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-36{
    margin: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-40{
    margin: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-44{
    margin: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-48{
    margin: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-52{
    margin: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-56{
    margin: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-60{
    margin: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-64{
    margin: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-72{
    margin: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-80{
    margin: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-96{
    margin: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-px{
    margin: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-0\.5{
    margin: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-1\.5{
    margin: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-2\.5{
    margin: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--m-3\.5{
    margin: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-7{
    margin-left: 1.75rem;
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-9{
    margin-left: 2.25rem;
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-11{
    margin-left: 2.75rem;
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-14{
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-28{
    margin-left: 7rem;
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-36{
    margin-left: 9rem;
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-44{
    margin-left: 11rem;
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-52{
    margin-left: 13rem;
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-60{
    margin-left: 15rem;
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-72{
    margin-left: 18rem;
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-80{
    margin-left: 20rem;
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-96{
    margin-left: 24rem;
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-0\.5{
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-1\.5{
    margin-left: 0.375rem;
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-2\.5{
    margin-left: 0.625rem;
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mx-3\.5{
    margin-left: 0.875rem;
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-7{
    margin-left: -1.75rem;
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-9{
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-11{
    margin-left: -2.75rem;
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-14{
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-28{
    margin-left: -7rem;
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-36{
    margin-left: -9rem;
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-44{
    margin-left: -11rem;
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-52{
    margin-left: -13rem;
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-60{
    margin-left: -15rem;
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-72{
    margin-left: -18rem;
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-80{
    margin-left: -20rem;
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-96{
    margin-left: -24rem;
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-0\.5{
    margin-left: -0.125rem;
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-1\.5{
    margin-left: -0.375rem;
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-2\.5{
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mx-3\.5{
    margin-left: -0.875rem;
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-7{
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-9{
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-11{
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-14{
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-28{
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-36{
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-44{
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-52{
    margin-top: 13rem;
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-60{
    margin-top: 15rem;
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-72{
    margin-top: 18rem;
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-80{
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-96{
    margin-top: 24rem;
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-0\.5{
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-1\.5{
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-2\.5{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-my-3\.5{
    margin-top: 0.875rem;
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-7{
    margin-top: -1.75rem;
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-9{
    margin-top: -2.25rem;
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-11{
    margin-top: -2.75rem;
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-14{
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-28{
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-36{
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-44{
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-52{
    margin-top: -13rem;
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-60{
    margin-top: -15rem;
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-72{
    margin-top: -18rem;
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-80{
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-96{
    margin-top: -24rem;
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-0\.5{
    margin-top: -0.125rem;
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-2\.5{
    margin-top: -0.625rem;
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--my-3\.5{
    margin-top: -0.875rem;
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-1{
    margin-top: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-2{
    margin-top: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-3{
    margin-top: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-4{
    margin-top: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-5{
    margin-top: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-6{
    margin-top: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-7{
    margin-top: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-8{
    margin-top: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-9{
    margin-top: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-10{
    margin-top: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-11{
    margin-top: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-12{
    margin-top: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-14{
    margin-top: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-16{
    margin-top: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-20{
    margin-top: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-24{
    margin-top: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-28{
    margin-top: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-32{
    margin-top: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-36{
    margin-top: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-40{
    margin-top: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-44{
    margin-top: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-48{
    margin-top: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-52{
    margin-top: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-56{
    margin-top: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-60{
    margin-top: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-64{
    margin-top: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-72{
    margin-top: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-80{
    margin-top: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-96{
    margin-top: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-auto{
    margin-top: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-px{
    margin-top: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-0\.5{
    margin-top: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-1\.5{
    margin-top: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-2\.5{
    margin-top: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mt-3\.5{
    margin-top: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-1{
    margin-top: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-2{
    margin-top: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-3{
    margin-top: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-4{
    margin-top: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-5{
    margin-top: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-6{
    margin-top: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-7{
    margin-top: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-8{
    margin-top: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-9{
    margin-top: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-10{
    margin-top: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-11{
    margin-top: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-12{
    margin-top: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-14{
    margin-top: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-16{
    margin-top: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-20{
    margin-top: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-24{
    margin-top: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-28{
    margin-top: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-32{
    margin-top: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-36{
    margin-top: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-40{
    margin-top: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-44{
    margin-top: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-48{
    margin-top: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-52{
    margin-top: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-56{
    margin-top: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-60{
    margin-top: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-64{
    margin-top: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-72{
    margin-top: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-80{
    margin-top: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-96{
    margin-top: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-px{
    margin-top: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-0\.5{
    margin-top: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-1\.5{
    margin-top: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-2\.5{
    margin-top: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mt-3\.5{
    margin-top: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-1{
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-2{
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-3{
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-4{
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-5{
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-6{
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-7{
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-8{
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-9{
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-10{
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-11{
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-12{
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-14{
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-16{
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-20{
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-24{
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-28{
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-32{
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-36{
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-40{
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-44{
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-48{
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-52{
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-56{
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-60{
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-64{
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-72{
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-80{
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-96{
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-auto{
    margin-right: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-px{
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-0\.5{
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-1\.5{
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-2\.5{
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mr-3\.5{
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-1{
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-2{
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-3{
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-4{
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-5{
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-6{
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-7{
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-8{
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-9{
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-10{
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-11{
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-12{
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-14{
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-16{
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-20{
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-24{
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-28{
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-32{
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-36{
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-40{
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-44{
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-48{
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-52{
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-56{
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-60{
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-64{
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-72{
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-80{
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-96{
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-px{
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-0\.5{
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-1\.5{
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-2\.5{
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mr-3\.5{
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-1{
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-2{
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-3{
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-4{
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-5{
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-6{
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-7{
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-8{
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-9{
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-10{
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-11{
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-12{
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-14{
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-16{
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-20{
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-24{
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-28{
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-32{
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-36{
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-40{
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-44{
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-48{
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-52{
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-56{
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-60{
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-64{
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-72{
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-80{
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-96{
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-auto{
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-px{
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-0\.5{
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-1\.5{
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-2\.5{
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mb-3\.5{
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-1{
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-2{
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-3{
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-4{
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-5{
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-6{
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-7{
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-8{
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-9{
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-10{
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-11{
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-12{
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-14{
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-16{
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-20{
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-24{
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-28{
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-32{
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-36{
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-40{
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-44{
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-48{
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-52{
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-56{
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-60{
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-64{
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-72{
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-80{
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-96{
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-px{
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-0\.5{
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-1\.5{
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-2\.5{
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--mb-3\.5{
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-1{
    margin-left: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-2{
    margin-left: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-3{
    margin-left: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-4{
    margin-left: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-5{
    margin-left: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-6{
    margin-left: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-7{
    margin-left: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-8{
    margin-left: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-9{
    margin-left: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-10{
    margin-left: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-11{
    margin-left: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-12{
    margin-left: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-14{
    margin-left: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-16{
    margin-left: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-20{
    margin-left: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-24{
    margin-left: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-28{
    margin-left: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-32{
    margin-left: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-36{
    margin-left: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-40{
    margin-left: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-44{
    margin-left: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-48{
    margin-left: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-52{
    margin-left: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-56{
    margin-left: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-60{
    margin-left: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-64{
    margin-left: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-72{
    margin-left: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-80{
    margin-left: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-96{
    margin-left: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-auto{
    margin-left: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-px{
    margin-left: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-0\.5{
    margin-left: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-1\.5{
    margin-left: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-2\.5{
    margin-left: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ml-3\.5{
    margin-left: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-1{
    margin-left: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-2{
    margin-left: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-3{
    margin-left: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-4{
    margin-left: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-5{
    margin-left: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-6{
    margin-left: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-7{
    margin-left: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-8{
    margin-left: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-9{
    margin-left: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-10{
    margin-left: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-11{
    margin-left: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-12{
    margin-left: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-14{
    margin-left: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-16{
    margin-left: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-20{
    margin-left: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-24{
    margin-left: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-28{
    margin-left: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-32{
    margin-left: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-36{
    margin-left: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-40{
    margin-left: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-44{
    margin-left: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-48{
    margin-left: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-52{
    margin-left: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-56{
    margin-left: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-60{
    margin-left: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-64{
    margin-left: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-72{
    margin-left: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-80{
    margin-left: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-96{
    margin-left: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-px{
    margin-left: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-0\.5{
    margin-left: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-1\.5{
    margin-left: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-2\.5{
    margin-left: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--ml-3\.5{
    margin-left: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-box-border{
    box-sizing: border-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-box-content{
    box-sizing: content-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-block{
    display: block
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inline-block{
    display: inline-block
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inline{
    display: inline
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex{
    display: flex
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inline-flex{
    display: inline-flex
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table{
    display: table
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inline-table{
    display: inline-table
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-caption{
    display: table-caption
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-cell{
    display: table-cell
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-column{
    display: table-column
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-column-group{
    display: table-column-group
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-footer-group{
    display: table-footer-group
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-header-group{
    display: table-header-group
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-row-group{
    display: table-row-group
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-row{
    display: table-row
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flow-root{
    display: flow-root
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid{
    display: grid
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-inline-grid{
    display: inline-grid
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contents{
    display: contents
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-list-item{
    display: list-item
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hidden{
    display: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-0{
    height: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1{
    height: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-2{
    height: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-3{
    height: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-4{
    height: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-5{
    height: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-6{
    height: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-7{
    height: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-8{
    height: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-9{
    height: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-10{
    height: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-11{
    height: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-12{
    height: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-14{
    height: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-16{
    height: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-20{
    height: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-24{
    height: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-28{
    height: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-32{
    height: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-36{
    height: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-40{
    height: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-44{
    height: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-48{
    height: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-52{
    height: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-56{
    height: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-60{
    height: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-64{
    height: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-72{
    height: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-80{
    height: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-96{
    height: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-auto{
    height: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-px{
    height: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-0\.5{
    height: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1\.5{
    height: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-2\.5{
    height: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-3\.5{
    height: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1\/2{
    height: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1\/3{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-2\/3{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1\/4{
    height: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-2\/4{
    height: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-3\/4{
    height: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1\/5{
    height: 20%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-2\/5{
    height: 40%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-3\/5{
    height: 60%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-4\/5{
    height: 80%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-1\/6{
    height: 16.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-2\/6{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-3\/6{
    height: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-4\/6{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-5\/6{
    height: 83.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-full{
    height: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-h-screen{
    height: 100vh
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-0{
    max-height: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-1{
    max-height: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-2{
    max-height: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-3{
    max-height: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-4{
    max-height: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-5{
    max-height: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-6{
    max-height: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-7{
    max-height: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-8{
    max-height: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-9{
    max-height: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-10{
    max-height: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-11{
    max-height: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-12{
    max-height: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-14{
    max-height: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-16{
    max-height: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-20{
    max-height: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-24{
    max-height: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-28{
    max-height: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-32{
    max-height: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-36{
    max-height: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-40{
    max-height: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-44{
    max-height: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-48{
    max-height: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-52{
    max-height: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-56{
    max-height: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-60{
    max-height: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-64{
    max-height: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-72{
    max-height: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-80{
    max-height: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-96{
    max-height: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-px{
    max-height: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-0\.5{
    max-height: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-1\.5{
    max-height: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-2\.5{
    max-height: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-3\.5{
    max-height: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-full{
    max-height: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-h-screen{
    max-height: 100vh
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-h-0{
    min-height: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-h-full{
    min-height: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-h-screen{
    min-height: 100vh
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-0{
    width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1{
    width: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2{
    width: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-3{
    width: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-4{
    width: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-5{
    width: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-6{
    width: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-7{
    width: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-8{
    width: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-9{
    width: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-10{
    width: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-11{
    width: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-12{
    width: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-14{
    width: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-16{
    width: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-20{
    width: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-24{
    width: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-28{
    width: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-32{
    width: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-36{
    width: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-40{
    width: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-44{
    width: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-48{
    width: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-52{
    width: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-56{
    width: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-60{
    width: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-64{
    width: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-72{
    width: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-80{
    width: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-96{
    width: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-auto{
    width: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-px{
    width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-0\.5{
    width: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\.5{
    width: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2\.5{
    width: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-3\.5{
    width: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\/2{
    width: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\/3{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2\/3{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\/4{
    width: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2\/4{
    width: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-3\/4{
    width: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\/5{
    width: 20%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2\/5{
    width: 40%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-3\/5{
    width: 60%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-4\/5{
    width: 80%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\/6{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2\/6{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-3\/6{
    width: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-4\/6{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-5\/6{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-1\/12{
    width: 8.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-2\/12{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-3\/12{
    width: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-4\/12{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-5\/12{
    width: 41.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-6\/12{
    width: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-7\/12{
    width: 58.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-8\/12{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-9\/12{
    width: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-10\/12{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-11\/12{
    width: 91.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-full{
    width: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-screen{
    width: 100vw
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-min{
    width: min-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-w-max{
    width: max-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-w-0{
    min-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-w-full{
    min-width: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-w-min{
    min-width: min-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-min-w-max{
    min-width: max-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-0{
    max-width: 0rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-none{
    max-width: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-xs{
    max-width: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-sm{
    max-width: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-md{
    max-width: 28rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-lg{
    max-width: 32rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-xl{
    max-width: 36rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-2xl{
    max-width: 42rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-3xl{
    max-width: 48rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-4xl{
    max-width: 56rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-5xl{
    max-width: 64rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-6xl{
    max-width: 72rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-7xl{
    max-width: 80rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-full{
    max-width: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-min{
    max-width: min-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-max{
    max-width: max-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-prose{
    max-width: 65ch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-screen-sm{
    max-width: 640px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-screen-md{
    max-width: 768px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-screen-lg{
    max-width: 1024px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-screen-xl{
    max-width: 1280px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-max-w-screen-2xl{
    max-width: 1536px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-1{
    flex: 1 1 0%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-auto{
    flex: 1 1 auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-initial{
    flex: 0 1 auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-none{
    flex: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-shrink-0{
    flex-shrink: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-shrink{
    flex-shrink: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-grow-0{
    flex-grow: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-grow{
    flex-grow: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-auto{
    table-layout: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-table-fixed{
    table-layout: fixed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-collapse{
    border-collapse: collapse
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-separate{
    border-collapse: separate
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-center{
    transform-origin: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-top{
    transform-origin: top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-top-right{
    transform-origin: top right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-right{
    transform-origin: right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-bottom-right{
    transform-origin: bottom right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-bottom{
    transform-origin: bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-bottom-left{
    transform-origin: bottom left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-left{
    transform-origin: left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-origin-top-left{
    transform-origin: top left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transform-none{
    transform: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-1{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-2{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-3{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-4{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-5{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-6{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-7{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-8{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-9{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-10{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-11{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-12{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-14{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-16{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-20{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-24{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-28{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-32{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-36{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-40{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-44{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-48{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-52{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-56{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-60{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-64{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-72{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-80{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-96{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-px{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-0\.5{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-1\.5{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-2\.5{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-3\.5{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-1{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-2{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-3{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-4{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-5{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-6{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-7{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-8{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-9{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-10{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-11{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-12{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-14{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-16{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-20{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-24{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-28{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-32{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-36{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-40{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-44{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-48{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-52{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-56{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-60{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-64{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-72{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-80{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-96{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-px{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-0\.5{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-1\.5{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-2\.5{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-3\.5{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-1\/2{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-1\/3{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-2\/3{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-1\/4{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-2\/4{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-3\/4{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-x-full{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-1\/2{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-1\/3{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-2\/3{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-1\/4{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-2\/4{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-3\/4{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-x-full{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-1{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-2{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-3{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-4{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-5{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-6{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-7{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-8{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-9{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-10{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-11{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-12{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-14{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-16{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-20{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-24{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-28{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-32{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-36{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-40{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-44{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-48{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-52{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-56{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-60{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-64{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-72{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-80{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-96{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-px{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-0\.5{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-1\.5{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-2\.5{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-3\.5{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-1{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-2{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-3{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-4{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-5{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-6{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-7{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-8{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-9{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-10{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-11{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-12{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-14{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-16{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-20{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-24{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-28{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-32{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-36{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-40{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-44{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-48{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-52{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-56{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-60{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-64{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-72{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-80{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-96{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-px{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-0\.5{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-1\.5{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-2\.5{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-3\.5{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-1\/2{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-1\/3{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-2\/3{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-1\/4{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-2\/4{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-3\/4{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-translate-y-full{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-1\/2{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-1\/3{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-2\/3{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-1\/4{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-2\/4{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-3\/4{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--translate-y-full{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-1:hover{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-2:hover{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-3:hover{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-4:hover{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-5:hover{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-6:hover{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-7:hover{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-8:hover{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-9:hover{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-10:hover{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-11:hover{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-12:hover{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-14:hover{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-16:hover{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-20:hover{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-24:hover{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-28:hover{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-32:hover{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-36:hover{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-40:hover{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-44:hover{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-48:hover{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-52:hover{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-56:hover{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-60:hover{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-64:hover{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-72:hover{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-80:hover{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-96:hover{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-px:hover{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-1:hover{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-2:hover{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-3:hover{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-4:hover{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-5:hover{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-6:hover{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-7:hover{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-8:hover{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-9:hover{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-10:hover{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-11:hover{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-12:hover{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-14:hover{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-16:hover{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-20:hover{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-24:hover{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-28:hover{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-32:hover{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-36:hover{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-40:hover{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-44:hover{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-48:hover{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-52:hover{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-56:hover{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-60:hover{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-64:hover{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-72:hover{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-80:hover{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-96:hover{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-px:hover{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-1\/2:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-1\/4:hover{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-2\/4:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-3\/4:hover{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-x-full:hover{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-1\/2:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-1\/4:hover{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-2\/4:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-3\/4:hover{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-x-full:hover{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-1:hover{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-2:hover{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-3:hover{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-4:hover{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-5:hover{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-6:hover{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-7:hover{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-8:hover{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-9:hover{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-10:hover{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-11:hover{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-12:hover{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-14:hover{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-16:hover{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-20:hover{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-24:hover{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-28:hover{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-32:hover{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-36:hover{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-40:hover{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-44:hover{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-48:hover{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-52:hover{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-56:hover{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-60:hover{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-64:hover{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-72:hover{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-80:hover{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-96:hover{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-px:hover{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-1:hover{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-2:hover{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-3:hover{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-4:hover{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-5:hover{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-6:hover{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-7:hover{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-8:hover{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-9:hover{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-10:hover{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-11:hover{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-12:hover{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-14:hover{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-16:hover{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-20:hover{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-24:hover{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-28:hover{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-32:hover{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-36:hover{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-40:hover{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-44:hover{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-48:hover{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-52:hover{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-56:hover{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-60:hover{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-64:hover{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-72:hover{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-80:hover{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-96:hover{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-px:hover{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-1\/2:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-1\/4:hover{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-2\/4:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-3\/4:hover{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-translate-y-full:hover{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-1\/2:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-1\/4:hover{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-2\/4:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-3\/4:hover{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--translate-y-full:hover{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-1:focus{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-2:focus{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-3:focus{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-4:focus{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-5:focus{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-6:focus{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-7:focus{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-8:focus{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-9:focus{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-10:focus{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-11:focus{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-12:focus{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-14:focus{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-16:focus{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-20:focus{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-24:focus{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-28:focus{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-32:focus{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-36:focus{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-40:focus{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-44:focus{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-48:focus{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-52:focus{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-56:focus{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-60:focus{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-64:focus{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-72:focus{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-80:focus{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-96:focus{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-px:focus{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-1:focus{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-2:focus{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-3:focus{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-4:focus{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-5:focus{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-6:focus{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-7:focus{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-8:focus{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-9:focus{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-10:focus{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-11:focus{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-12:focus{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-14:focus{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-16:focus{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-20:focus{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-24:focus{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-28:focus{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-32:focus{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-36:focus{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-40:focus{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-44:focus{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-48:focus{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-52:focus{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-56:focus{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-60:focus{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-64:focus{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-72:focus{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-80:focus{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-96:focus{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-px:focus{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-1\/2:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-1\/4:focus{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-2\/4:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-3\/4:focus{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-x-full:focus{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-1\/2:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-1\/4:focus{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-2\/4:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-3\/4:focus{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-x-full:focus{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-1:focus{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-2:focus{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-3:focus{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-4:focus{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-5:focus{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-6:focus{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-7:focus{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-8:focus{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-9:focus{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-10:focus{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-11:focus{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-12:focus{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-14:focus{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-16:focus{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-20:focus{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-24:focus{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-28:focus{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-32:focus{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-36:focus{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-40:focus{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-44:focus{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-48:focus{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-52:focus{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-56:focus{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-60:focus{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-64:focus{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-72:focus{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-80:focus{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-96:focus{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-px:focus{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-1:focus{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-2:focus{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-3:focus{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-4:focus{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-5:focus{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-6:focus{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-7:focus{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-8:focus{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-9:focus{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-10:focus{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-11:focus{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-12:focus{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-14:focus{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-16:focus{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-20:focus{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-24:focus{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-28:focus{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-32:focus{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-36:focus{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-40:focus{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-44:focus{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-48:focus{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-52:focus{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-56:focus{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-60:focus{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-64:focus{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-72:focus{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-80:focus{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-96:focus{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-px:focus{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-1\/2:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-1\/4:focus{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-2\/4:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-3\/4:focus{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-translate-y-full:focus{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-1\/2:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-1\/4:focus{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-2\/4:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-3\/4:focus{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--translate-y-full:focus{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-0{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-1{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-2{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-3{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-6{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-12{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-45{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-90{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rotate-180{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-180{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-90{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-45{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-12{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-6{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-3{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-2{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--rotate-1{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-0:hover{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-1:hover{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-2:hover{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-3:hover{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-6:hover{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-12:hover{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-45:hover{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-90:hover{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-rotate-180:hover{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-180:hover{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-90:hover{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-45:hover{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-12:hover{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-6:hover{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-3:hover{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-2:hover{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--rotate-1:hover{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-0:focus{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-1:focus{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-2:focus{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-3:focus{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-6:focus{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-12:focus{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-45:focus{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-90:focus{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-rotate-180:focus{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-180:focus{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-90:focus{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-45:focus{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-12:focus{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-6:focus{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-3:focus{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-2:focus{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--rotate-1:focus{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-x-0{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-x-1{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-x-2{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-x-3{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-x-6{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-x-12{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-x-12{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-x-6{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-x-3{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-x-2{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-x-1{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-y-0{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-y-1{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-y-2{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-y-3{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-y-6{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-skew-y-12{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-y-12{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-y-6{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-y-3{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-y-2{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--skew-y-1{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-x-0:hover{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-x-1:hover{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-x-2:hover{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-x-3:hover{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-x-6:hover{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-x-12:hover{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-x-12:hover{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-x-6:hover{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-x-3:hover{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-x-2:hover{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-x-1:hover{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-y-0:hover{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-y-1:hover{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-y-2:hover{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-y-3:hover{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-y-6:hover{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-skew-y-12:hover{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-y-12:hover{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-y-6:hover{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-y-3:hover{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-y-2:hover{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget--skew-y-1:hover{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-x-0:focus{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-x-1:focus{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-x-2:focus{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-x-3:focus{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-x-6:focus{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-x-12:focus{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-x-12:focus{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-x-6:focus{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-x-3:focus{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-x-2:focus{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-x-1:focus{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-y-0:focus{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-y-1:focus{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-y-2:focus{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-y-3:focus{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-y-6:focus{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-skew-y-12:focus{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-y-12:focus{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-y-6:focus{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-y-3:focus{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-y-2:focus{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget--skew-y-1:focus{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-0{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-50{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-75{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-90{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-95{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-100{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-105{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-110{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-125{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-x-150{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-0{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-50{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-75{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-90{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-95{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-100{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-105{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-110{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-125{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-scale-y-150{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-0:hover{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-50:hover{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-75:hover{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-90:hover{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-95:hover{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-100:hover{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-105:hover{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-110:hover{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-125:hover{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-x-150:hover{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-0:hover{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-50:hover{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-75:hover{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-90:hover{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-95:hover{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-100:hover{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-105:hover{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-110:hover{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-125:hover{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-scale-y-150:hover{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-0:focus{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-50:focus{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-75:focus{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-90:focus{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-95:focus{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-100:focus{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-105:focus{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-110:focus{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-125:focus{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-x-150:focus{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-0:focus{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-50:focus{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-75:focus{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-90:focus{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-95:focus{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-100:focus{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-105:focus{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-110:focus{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-125:focus{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-scale-y-150:focus{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-animate-none{
    animation: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-animate-spin{
    animation: skindr-widget-spin 1s linear infinite
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-animate-ping{
    animation: skindr-widget-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-animate-pulse{
    animation: skindr-widget-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-animate-bounce{
    animation: skindr-widget-bounce 1s infinite
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-auto{
    cursor: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-default{
    cursor: default
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-pointer{
    cursor: pointer
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-wait{
    cursor: wait
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-text{
    cursor: text
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-move{
    cursor: move
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-help{
    cursor: help
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-cursor-not-allowed{
    cursor: not-allowed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-select-none{
    -webkit-user-select: none;
            user-select: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-select-text{
    -webkit-user-select: text;
            user-select: text
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-select-all{
    -webkit-user-select: all;
            user-select: all
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-select-auto{
    -webkit-user-select: auto;
            user-select: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-resize-none{
    resize: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-resize-y{
    resize: vertical
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-resize-x{
    resize: horizontal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-resize{
    resize: both
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-list-inside{
    list-style-position: inside
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-list-outside{
    list-style-position: outside
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-list-none{
    list-style-type: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-list-disc{
    list-style-type: disc
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-list-decimal{
    list-style-type: decimal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-appearance-none{
    -webkit-appearance: none;
            appearance: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-cols-auto{
    grid-auto-columns: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-cols-min{
    grid-auto-columns: min-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-cols-max{
    grid-auto-columns: max-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-flow-row{
    grid-auto-flow: row
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-flow-col{
    grid-auto-flow: column
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-rows-auto{
    grid-auto-rows: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-rows-min{
    grid-auto-rows: min-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-rows-max{
    grid-auto-rows: max-content
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-cols-none{
    grid-template-columns: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grid-rows-none{
    grid-template-rows: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-row{
    flex-direction: row
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-row-reverse{
    flex-direction: row-reverse
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-col{
    flex-direction: column
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-col-reverse{
    flex-direction: column-reverse
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-wrap{
    flex-wrap: wrap
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-flex-nowrap{
    flex-wrap: nowrap
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-center{
    place-content: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-start{
    place-content: start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-end{
    place-content: end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-between{
    place-content: space-between
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-around{
    place-content: space-around
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-evenly{
    place-content: space-evenly
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-content-stretch{
    place-content: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-items-start{
    place-items: start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-items-end{
    place-items: end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-items-center{
    place-items: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-items-stretch{
    place-items: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-content-center{
    align-content: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-content-start{
    align-content: flex-start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-content-end{
    align-content: flex-end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-content-between{
    align-content: space-between
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-content-around{
    align-content: space-around
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-content-evenly{
    align-content: space-evenly
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-items-start{
    align-items: flex-start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-items-end{
    align-items: flex-end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-items-center{
    align-items: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-items-baseline{
    align-items: baseline
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-items-stretch{
    align-items: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-start{
    justify-content: flex-start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-end{
    justify-content: flex-end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-center{
    justify-content: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-between{
    justify-content: space-between
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-around{
    justify-content: space-around
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-evenly{
    justify-content: space-evenly
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-items-start{
    justify-items: start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-items-end{
    justify-items: end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-items-center{
    justify-items: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-items-stretch{
    justify-items: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-0{
    gap: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-1{
    gap: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-2{
    gap: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-3{
    gap: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-4{
    gap: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-5{
    gap: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-6{
    gap: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-7{
    gap: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-8{
    gap: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-9{
    gap: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-10{
    gap: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-11{
    gap: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-12{
    gap: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-14{
    gap: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-16{
    gap: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-20{
    gap: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-24{
    gap: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-28{
    gap: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-32{
    gap: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-36{
    gap: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-40{
    gap: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-44{
    gap: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-48{
    gap: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-52{
    gap: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-56{
    gap: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-60{
    gap: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-64{
    gap: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-72{
    gap: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-80{
    gap: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-96{
    gap: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-px{
    gap: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-0\.5{
    gap: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-1\.5{
    gap: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-2\.5{
    gap: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-3\.5{
    gap: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-0{
    column-gap: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-1{
    column-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-2{
    column-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-3{
    column-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-4{
    column-gap: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-5{
    column-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-6{
    column-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-7{
    column-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-8{
    column-gap: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-9{
    column-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-10{
    column-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-11{
    column-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-12{
    column-gap: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-14{
    column-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-16{
    column-gap: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-20{
    column-gap: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-24{
    column-gap: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-28{
    column-gap: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-32{
    column-gap: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-36{
    column-gap: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-40{
    column-gap: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-44{
    column-gap: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-48{
    column-gap: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-52{
    column-gap: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-56{
    column-gap: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-60{
    column-gap: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-64{
    column-gap: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-72{
    column-gap: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-80{
    column-gap: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-96{
    column-gap: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-px{
    column-gap: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-0\.5{
    column-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-1\.5{
    column-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-2\.5{
    column-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-x-3\.5{
    column-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-0{
    row-gap: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-1{
    row-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-2{
    row-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-3{
    row-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-4{
    row-gap: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-5{
    row-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-6{
    row-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-7{
    row-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-8{
    row-gap: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-9{
    row-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-10{
    row-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-11{
    row-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-12{
    row-gap: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-14{
    row-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-16{
    row-gap: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-20{
    row-gap: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-24{
    row-gap: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-28{
    row-gap: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-32{
    row-gap: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-36{
    row-gap: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-40{
    row-gap: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-44{
    row-gap: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-48{
    row-gap: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-52{
    row-gap: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-56{
    row-gap: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-60{
    row-gap: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-64{
    row-gap: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-72{
    row-gap: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-80{
    row-gap: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-96{
    row-gap: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-px{
    row-gap: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-0\.5{
    row-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-1\.5{
    row-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-2\.5{
    row-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-gap-y-3\.5{
    row-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-self-auto{
    place-self: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-self-start{
    place-self: start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-self-end{
    place-self: end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-self-center{
    place-self: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-place-self-stretch{
    place-self: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-self-auto{
    align-self: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-self-start{
    align-self: flex-start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-self-end{
    align-self: flex-end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-self-center{
    align-self: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-self-stretch{
    align-self: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-self-baseline{
    align-self: baseline
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-self-auto{
    justify-self: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-self-start{
    justify-self: start
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-self-end{
    justify-self: end
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-self-center{
    justify-self: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-justify-self-stretch{
    justify-self: stretch
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-auto{
    overflow: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-hidden{
    overflow: hidden
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-visible{
    overflow: visible
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-scroll{
    overflow: scroll
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-x-auto{
    overflow-x: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-y-auto{
    overflow-y: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-x-hidden{
    overflow-x: hidden
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-y-hidden{
    overflow-y: hidden
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-x-visible{
    overflow-x: visible
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-y-visible{
    overflow-y: visible
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-x-scroll{
    overflow-x: scroll
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-y-scroll{
    overflow-y: scroll
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-auto{
    overscroll-behavior: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-contain{
    overscroll-behavior: contain
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-none{
    overscroll-behavior: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-y-none{
    overscroll-behavior-y: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overscroll-x-none{
    overscroll-behavior-x: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-ellipsis{
    text-overflow: ellipsis
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-overflow-clip{
    text-overflow: clip
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-whitespace-normal{
    white-space: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-whitespace-nowrap{
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-whitespace-pre{
    white-space: pre
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-whitespace-pre-line{
    white-space: pre-line
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-break-normal{
    overflow-wrap: normal;
    word-break: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-break-words{
    overflow-wrap: break-word
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-break-all{
    word-break: break-all
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-none{
    border-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-sm{
    border-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded{
    border-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-md{
    border-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-lg{
    border-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-xl{
    border-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-2xl{
    border-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-3xl{
    border-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-full{
    border-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-none{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-none{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-none{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-none{
    border-top-left-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-md{
    border-top-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-xl{
    border-top-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-2xl{
    border-top-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-3xl{
    border-top-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-none{
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-md{
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-xl{
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-2xl{
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-3xl{
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-none{
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-md{
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-xl{
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-2xl{
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-none{
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-md{
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-2xl{
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-0{
    border-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-2{
    border-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-4{
    border-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-8{
    border-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border{
    border-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-t-0{
    border-top-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-t-2{
    border-top-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-t-4{
    border-top-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-t-8{
    border-top-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-t{
    border-top-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-r-0{
    border-right-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-r-2{
    border-right-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-r-4{
    border-right-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-r-8{
    border-right-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-r{
    border-right-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-b-0{
    border-bottom-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-b-2{
    border-bottom-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-b-4{
    border-bottom-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-b-8{
    border-bottom-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-b{
    border-bottom-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-l-0{
    border-left-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-l-2{
    border-left-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-l-4{
    border-left-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-l-8{
    border-left-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-l{
    border-left-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-solid{
    border-style: solid
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-dashed{
    border-style: dashed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-dotted{
    border-style: dotted
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-double{
    border-style: double
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-none{
    border-style: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-transparent:focus-within{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-current:focus-within{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-black:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-white:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-gray-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-red-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-yellow-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-green-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-blue-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-indigo-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-purple-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-pink-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-transparent:hover{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-current:hover{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-red-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-yellow-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-green-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-indigo-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-purple-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-pink-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-transparent:focus{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-current:focus{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-red-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-yellow-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-green-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-indigo-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-purple-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-pink-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-0:focus-within{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-border-opacity-100:focus-within{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-0:hover{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-5:hover{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-10:hover{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-20:hover{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-25:hover{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-30:hover{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-40:hover{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-50:hover{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-60:hover{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-70:hover{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-75:hover{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-80:hover{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-90:hover{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-95:hover{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-border-opacity-100:hover{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-0:focus{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-5:focus{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-10:focus{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-20:focus{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-25:focus{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-30:focus{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-40:focus{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-50:focus{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-60:focus{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-70:focus{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-75:focus{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-80:focus{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-90:focus{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-95:focus{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-border-opacity-100:focus{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-transparent:focus-within{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-current:focus-within{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-black:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-white:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-gray-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-red-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-green-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-blue-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-purple-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-pink-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-transparent:hover{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-current:hover{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-red-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-yellow-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-green-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-indigo-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-purple-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-pink-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-transparent:focus{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-current:focus{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-red-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-yellow-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-green-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-indigo-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-purple-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-pink-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-0:hover{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-bg-opacity-100:hover{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-0:focus{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-bg-opacity-100:focus{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-none{
    background-image: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-black{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-50{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-100{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-200{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-300{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-400{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-500{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-600{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-700{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-800{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-gray-900{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-50{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-100{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-200{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-300{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-400{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-500{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-600{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-700{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-800{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-red-900{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-50{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-100{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-200{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-300{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-400{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-500{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-600{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-700{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-800{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-yellow-900{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-50{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-100{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-200{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-300{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-400{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-500{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-600{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-700{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-800{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-green-900{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-50{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-100{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-200{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-300{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-400{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-500{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-600{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-700{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-800{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-blue-900{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-50{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-100{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-200{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-300{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-400{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-500{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-600{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-700{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-800{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-indigo-900{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-50{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-100{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-200{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-300{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-400{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-500{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-600{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-700{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-800{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-purple-900{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-50{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-100{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-200{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-300{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-400{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-500{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-600{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-700{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-800{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-from-pink-900{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-black:hover{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-50:hover{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-300:hover{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-400:hover{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-500:hover{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-600:hover{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-700:hover{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-800:hover{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-gray-900:hover{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-50:hover{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-100:hover{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-200:hover{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-300:hover{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-400:hover{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-500:hover{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-600:hover{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-700:hover{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-800:hover{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-red-900:hover{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-200:hover{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-600:hover{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-700:hover{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-800:hover{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-yellow-900:hover{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-50:hover{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-100:hover{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-200:hover{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-300:hover{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-400:hover{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-500:hover{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-600:hover{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-700:hover{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-800:hover{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-green-900:hover{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-50:hover{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-100:hover{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-300:hover{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-400:hover{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-500:hover{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-600:hover{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-800:hover{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-400:hover{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-500:hover{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-700:hover{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-800:hover{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-indigo-900:hover{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-100:hover{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-400:hover{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-600:hover{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-700:hover{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-800:hover{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-purple-900:hover{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-100:hover{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-400:hover{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-500:hover{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-600:hover{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-700:hover{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-800:hover{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-from-pink-900:hover{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-black:focus{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-50:focus{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-300:focus{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-400:focus{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-500:focus{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-600:focus{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-700:focus{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-800:focus{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-gray-900:focus{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-50:focus{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-100:focus{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-200:focus{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-300:focus{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-400:focus{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-500:focus{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-600:focus{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-700:focus{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-800:focus{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-red-900:focus{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-200:focus{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-600:focus{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-700:focus{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-800:focus{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-yellow-900:focus{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-50:focus{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-100:focus{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-200:focus{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-300:focus{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-400:focus{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-500:focus{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-600:focus{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-700:focus{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-800:focus{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-green-900:focus{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-50:focus{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-100:focus{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-300:focus{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-400:focus{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-500:focus{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-600:focus{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-800:focus{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-400:focus{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-500:focus{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-700:focus{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-800:focus{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-indigo-900:focus{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-100:focus{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-400:focus{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-600:focus{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-700:focus{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-800:focus{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-purple-900:focus{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-100:focus{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-400:focus{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-500:focus{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-600:focus{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-700:focus{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-800:focus{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-from-pink-900:focus{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-transparent{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-current{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-black{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-white{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-50{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-100{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-200{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-300{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-400{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-500{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-600{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-700{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-800{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-gray-900{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-50{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-100{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-200{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-300{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-400{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-500{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-600{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-700{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-800{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-red-900{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-50{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-100{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-200{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-300{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-400{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-500{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-600{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-700{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-800{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-yellow-900{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-50{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-100{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-200{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-300{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-400{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-500{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-600{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-700{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-800{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-green-900{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-50{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-100{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-200{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-300{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-400{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-500{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-600{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-700{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-800{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-blue-900{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-50{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-100{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-200{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-300{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-400{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-500{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-600{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-700{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-800{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-indigo-900{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-50{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-100{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-200{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-300{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-400{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-500{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-600{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-700{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-800{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-purple-900{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-50{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-100{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-200{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-300{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-400{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-500{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-600{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-700{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-800{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-to-pink-900{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-transparent:hover{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-current:hover{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-black:hover{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-white:hover{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-50:hover{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-300:hover{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-400:hover{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-500:hover{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-600:hover{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-700:hover{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-800:hover{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-gray-900:hover{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-50:hover{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-100:hover{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-200:hover{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-300:hover{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-400:hover{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-500:hover{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-600:hover{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-700:hover{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-800:hover{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-red-900:hover{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-200:hover{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-600:hover{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-700:hover{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-800:hover{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-yellow-900:hover{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-50:hover{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-100:hover{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-200:hover{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-300:hover{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-400:hover{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-500:hover{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-600:hover{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-700:hover{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-800:hover{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-green-900:hover{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-50:hover{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-100:hover{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-300:hover{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-400:hover{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-500:hover{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-600:hover{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-800:hover{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-400:hover{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-500:hover{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-700:hover{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-800:hover{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-indigo-900:hover{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-100:hover{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-400:hover{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-600:hover{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-700:hover{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-800:hover{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-purple-900:hover{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-100:hover{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-400:hover{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-500:hover{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-600:hover{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-700:hover{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-800:hover{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-to-pink-900:hover{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-transparent:focus{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-current:focus{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-black:focus{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-white:focus{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-50:focus{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-300:focus{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-400:focus{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-500:focus{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-600:focus{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-700:focus{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-800:focus{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-gray-900:focus{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-50:focus{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-100:focus{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-200:focus{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-300:focus{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-400:focus{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-500:focus{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-600:focus{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-700:focus{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-800:focus{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-red-900:focus{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-200:focus{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-600:focus{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-700:focus{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-800:focus{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-yellow-900:focus{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-50:focus{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-100:focus{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-200:focus{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-300:focus{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-400:focus{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-500:focus{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-600:focus{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-700:focus{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-800:focus{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-green-900:focus{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-50:focus{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-100:focus{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-300:focus{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-400:focus{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-500:focus{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-600:focus{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-800:focus{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-400:focus{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-500:focus{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-700:focus{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-800:focus{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-indigo-900:focus{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-100:focus{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-400:focus{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-600:focus{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-700:focus{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-800:focus{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-purple-900:focus{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-100:focus{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-400:focus{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-500:focus{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-600:focus{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-700:focus{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-800:focus{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-to-pink-900:focus{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-decoration-slice{
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-decoration-clone{
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-auto{
    background-size: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-cover{
    background-size: cover
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-contain{
    background-size: contain
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-fixed{
    background-attachment: fixed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-local{
    background-attachment: local
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-scroll{
    background-attachment: scroll
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-clip-border{
    background-clip: border-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-clip-padding{
    background-clip: padding-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-clip-content{
    background-clip: content-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-bottom{
    background-position: bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-center{
    background-position: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-left{
    background-position: left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-left-bottom{
    background-position: left bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-left-top{
    background-position: left top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-right{
    background-position: right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-right-bottom{
    background-position: right bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-right-top{
    background-position: right top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-top{
    background-position: top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-repeat{
    background-repeat: repeat
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-no-repeat{
    background-repeat: no-repeat
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-repeat-x{
    background-repeat: repeat-x
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-repeat-y{
    background-repeat: repeat-y
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-repeat-round{
    background-repeat: round
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-repeat-space{
    background-repeat: space
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-origin-border{
    background-origin: border-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-origin-padding{
    background-origin: padding-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-origin-content{
    background-origin: content-box
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-fill-current{
    fill: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-stroke-current{
    stroke: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-stroke-0{
    stroke-width: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-stroke-1{
    stroke-width: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-stroke-2{
    stroke-width: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-contain{
    object-fit: contain
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-cover{
    object-fit: cover
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-fill{
    object-fit: fill
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-none{
    object-fit: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-scale-down{
    object-fit: scale-down
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-bottom{
    object-position: bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-center{
    object-position: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-left{
    object-position: left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-left-bottom{
    object-position: left bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-left-top{
    object-position: left top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-right{
    object-position: right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-right-bottom{
    object-position: right bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-right-top{
    object-position: right top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-object-top{
    object-position: top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-0{
    padding: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-1{
    padding: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-2{
    padding: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-3{
    padding: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-4{
    padding: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-5{
    padding: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-6{
    padding: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-7{
    padding: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-8{
    padding: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-9{
    padding: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-10{
    padding: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-11{
    padding: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-12{
    padding: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-14{
    padding: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-16{
    padding: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-20{
    padding: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-24{
    padding: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-28{
    padding: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-32{
    padding: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-36{
    padding: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-40{
    padding: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-44{
    padding: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-48{
    padding: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-52{
    padding: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-56{
    padding: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-60{
    padding: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-64{
    padding: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-72{
    padding: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-80{
    padding: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-96{
    padding: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-px{
    padding: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-0\.5{
    padding: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-1\.5{
    padding: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-2\.5{
    padding: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-p-3\.5{
    padding: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-0{
    padding-left: 0px;
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-9{
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-11{
    padding-left: 2.75rem;
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-14{
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-28{
    padding-left: 7rem;
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-36{
    padding-left: 9rem;
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-44{
    padding-left: 11rem;
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-52{
    padding-left: 13rem;
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-60{
    padding-left: 15rem;
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-72{
    padding-left: 18rem;
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-80{
    padding-left: 20rem;
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-96{
    padding-left: 24rem;
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-0{
    padding-top: 0px;
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-7{
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-9{
    padding-top: 2.25rem;
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-11{
    padding-top: 2.75rem;
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-14{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-28{
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-36{
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-44{
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-52{
    padding-top: 13rem;
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-60{
    padding-top: 15rem;
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-72{
    padding-top: 18rem;
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-80{
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-96{
    padding-top: 24rem;
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-0{
    padding-top: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-1{
    padding-top: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-2{
    padding-top: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-3{
    padding-top: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-4{
    padding-top: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-5{
    padding-top: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-6{
    padding-top: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-7{
    padding-top: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-8{
    padding-top: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-9{
    padding-top: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-10{
    padding-top: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-11{
    padding-top: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-12{
    padding-top: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-14{
    padding-top: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-16{
    padding-top: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-20{
    padding-top: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-24{
    padding-top: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-28{
    padding-top: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-32{
    padding-top: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-36{
    padding-top: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-40{
    padding-top: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-44{
    padding-top: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-48{
    padding-top: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-52{
    padding-top: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-56{
    padding-top: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-60{
    padding-top: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-64{
    padding-top: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-72{
    padding-top: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-80{
    padding-top: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-96{
    padding-top: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-px{
    padding-top: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-0\.5{
    padding-top: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-1\.5{
    padding-top: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-2\.5{
    padding-top: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pt-3\.5{
    padding-top: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-0{
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-1{
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-2{
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-3{
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-4{
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-5{
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-6{
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-7{
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-8{
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-9{
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-10{
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-11{
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-12{
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-14{
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-16{
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-20{
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-24{
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-28{
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-32{
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-36{
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-40{
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-44{
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-48{
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-52{
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-56{
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-60{
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-64{
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-72{
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-80{
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-96{
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-px{
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-0\.5{
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-1\.5{
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-2\.5{
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pr-3\.5{
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-0{
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-1{
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-2{
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-3{
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-4{
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-5{
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-6{
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-7{
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-8{
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-9{
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-10{
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-11{
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-12{
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-14{
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-16{
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-20{
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-24{
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-28{
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-32{
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-36{
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-40{
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-44{
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-48{
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-52{
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-56{
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-60{
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-64{
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-72{
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-80{
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-96{
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-px{
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-0\.5{
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-1\.5{
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-2\.5{
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pb-3\.5{
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-0{
    padding-left: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-1{
    padding-left: 0.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-2{
    padding-left: 0.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-3{
    padding-left: 0.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-4{
    padding-left: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-5{
    padding-left: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-6{
    padding-left: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-7{
    padding-left: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-8{
    padding-left: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-9{
    padding-left: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-10{
    padding-left: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-11{
    padding-left: 2.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-12{
    padding-left: 3rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-14{
    padding-left: 3.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-16{
    padding-left: 4rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-20{
    padding-left: 5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-24{
    padding-left: 6rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-28{
    padding-left: 7rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-32{
    padding-left: 8rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-36{
    padding-left: 9rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-40{
    padding-left: 10rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-44{
    padding-left: 11rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-48{
    padding-left: 12rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-52{
    padding-left: 13rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-56{
    padding-left: 14rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-60{
    padding-left: 15rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-64{
    padding-left: 16rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-72{
    padding-left: 18rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-80{
    padding-left: 20rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-96{
    padding-left: 24rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-px{
    padding-left: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-0\.5{
    padding-left: 0.125rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-1\.5{
    padding-left: 0.375rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-2\.5{
    padding-left: 0.625rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-pl-3\.5{
    padding-left: 0.875rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-left{
    text-align: left
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-center{
    text-align: center
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-right{
    text-align: right
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-justify{
    text-align: justify
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-align-baseline{
    vertical-align: baseline
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-align-top{
    vertical-align: top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-align-middle{
    vertical-align: middle
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-align-bottom{
    vertical-align: bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-align-text-top{
    vertical-align: text-top
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-align-text-bottom{
    vertical-align: text-bottom
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-xs{
    font-size: 0.75rem;
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-base{
    font-size: 1rem;
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-2xl{
    font-size: 1.5rem;
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-5xl{
    font-size: 3rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-6xl{
    font-size: 3.75rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-7xl{
    font-size: 4.5rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-8xl{
    font-size: 6rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-9xl{
    font-size: 8rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-thin{
    font-weight: 100
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-extralight{
    font-weight: 200
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-light{
    font-weight: 300
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-normal{
    font-weight: 400
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-medium{
    font-weight: 500
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-semibold{
    font-weight: 600
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-bold{
    font-weight: 700
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-extrabold{
    font-weight: 800
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-font-black{
    font-weight: 900
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-uppercase{
    text-transform: uppercase
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-lowercase{
    text-transform: lowercase
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-capitalize{
    text-transform: capitalize
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-normal-case{
    text-transform: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-italic{
    font-style: italic
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-not-italic{
    font-style: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ordinal, #skindrWidget.skindrWidget .lg\:skindr-widget-slashed-zero, #skindrWidget.skindrWidget .lg\:skindr-widget-lining-nums, #skindrWidget.skindrWidget .lg\:skindr-widget-oldstyle-nums, #skindrWidget.skindrWidget .lg\:skindr-widget-proportional-nums, #skindrWidget.skindrWidget .lg\:skindr-widget-tabular-nums, #skindrWidget.skindrWidget .lg\:skindr-widget-diagonal-fractions, #skindrWidget.skindrWidget .lg\:skindr-widget-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-normal-nums{
    font-variant-numeric: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ordinal{
    --tw-ordinal: ordinal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-slashed-zero{
    --tw-slashed-zero: slashed-zero
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-lining-nums{
    --tw-numeric-figure: lining-nums
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-proportional-nums{
    --tw-numeric-spacing: proportional-nums
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tabular-nums{
    --tw-numeric-spacing: tabular-nums
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-3{
    line-height: .75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-4{
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-5{
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-6{
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-7{
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-8{
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-9{
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-10{
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-none{
    line-height: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-tight{
    line-height: 1.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-snug{
    line-height: 1.375
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-normal{
    line-height: 1.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-relaxed{
    line-height: 1.625
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-leading-loose{
    line-height: 2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tracking-tighter{
    letter-spacing: -0.05em
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tracking-tight{
    letter-spacing: -0.025em
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tracking-normal{
    letter-spacing: 0em
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tracking-wide{
    letter-spacing: 0.025em
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tracking-wider{
    letter-spacing: 0.05em
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-tracking-widest{
    letter-spacing: 0.1em
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-transparent:focus-within{
    color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-current:focus-within{
    color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-black:focus-within{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-white:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-gray-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-red-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-yellow-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-green-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-blue-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-indigo-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-purple-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-pink-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-transparent:hover{
    color: transparent
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-current:hover{
    color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-50:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-200:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-400:hover{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-500:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-600:hover{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-700:hover{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-800:hover{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-red-900:hover{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-50:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-200:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-400:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-500:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-600:hover{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-700:hover{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-800:hover{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-yellow-900:hover{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-50:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-100:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-200:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-300:hover{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-400:hover{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-500:hover{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-600:hover{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-700:hover{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-800:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-green-900:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-50:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-50:hover{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-100:hover{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-200:hover{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-300:hover{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-400:hover{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-500:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-700:hover{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-800:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-indigo-900:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-50:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-100:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-200:hover{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-300:hover{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-400:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-500:hover{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-600:hover{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-700:hover{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-800:hover{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-purple-900:hover{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-50:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-100:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-200:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-300:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-400:hover{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-500:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-600:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-700:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-800:hover{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-pink-900:hover{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-transparent:focus{
    color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-current:focus{
    color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-50:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-50:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-200:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-400:focus{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-500:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-600:focus{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-700:focus{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-800:focus{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-red-900:focus{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-50:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-200:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-400:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-500:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-600:focus{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-700:focus{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-800:focus{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-yellow-900:focus{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-50:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-100:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-200:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-300:focus{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-400:focus{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-500:focus{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-600:focus{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-700:focus{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-800:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-green-900:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-50:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-50:focus{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-100:focus{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-200:focus{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-300:focus{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-400:focus{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-500:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-600:focus{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-700:focus{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-800:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-indigo-900:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-50:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-100:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-200:focus{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-300:focus{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-400:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-500:focus{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-600:focus{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-700:focus{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-800:focus{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-purple-900:focus{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-50:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-100:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-200:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-300:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-400:focus{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-500:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-600:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-700:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-800:focus{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-pink-900:focus{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-0:focus-within{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-text-opacity-100:focus-within{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-0:hover{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-5:hover{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-10:hover{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-20:hover{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-25:hover{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-30:hover{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-40:hover{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-50:hover{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-60:hover{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-70:hover{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-75:hover{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-80:hover{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-90:hover{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-95:hover{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-text-opacity-100:hover{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-0:focus{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-5:focus{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-10:focus{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-20:focus{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-25:focus{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-30:focus{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-40:focus{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-50:focus{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-60:focus{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-70:focus{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-75:focus{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-80:focus{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-90:focus{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-95:focus{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-text-opacity-100:focus{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-underline:focus-within{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-line-through:focus-within{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-no-underline:focus-within{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-underline:hover{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-line-through:hover{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-no-underline:hover{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-underline:focus{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-line-through:focus{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-no-underline:focus{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-transparent::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-current::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-current:focus::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-0:focus-within{
    opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-5:focus-within{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-10:focus-within{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-20:focus-within{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-25:focus-within{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-30:focus-within{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-40:focus-within{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-50:focus-within{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-60:focus-within{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-70:focus-within{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-75:focus-within{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-80:focus-within{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-90:focus-within{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-95:focus-within{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-opacity-100:focus-within{
    opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-0:hover{
    opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-5:hover{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-10:hover{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-20:hover{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-25:hover{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-30:hover{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-40:hover{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-50:hover{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-60:hover{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-70:hover{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-75:hover{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-80:hover{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-90:hover{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-95:hover{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-opacity-100:hover{
    opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-0:focus{
    opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-5:focus{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-10:focus{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-20:focus{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-25:focus{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-30:focus{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-40:focus{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-50:focus{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-60:focus{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-70:focus{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-75:focus{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-80:focus{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-90:focus{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-95:focus{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-opacity-100:focus{
    opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-normal{
    background-blend-mode: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-multiply{
    background-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-screen{
    background-blend-mode: screen
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-overlay{
    background-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-darken{
    background-blend-mode: darken
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-lighten{
    background-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-color-dodge{
    background-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-color-burn{
    background-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-hard-light{
    background-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-soft-light{
    background-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-difference{
    background-blend-mode: difference
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-exclusion{
    background-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-hue{
    background-blend-mode: hue
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-saturation{
    background-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-color{
    background-blend-mode: color
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-bg-blend-luminosity{
    background-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-normal{
    mix-blend-mode: normal
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-multiply{
    mix-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-screen{
    mix-blend-mode: screen
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-overlay{
    mix-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-darken{
    mix-blend-mode: darken
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-lighten{
    mix-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-color-dodge{
    mix-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-color-burn{
    mix-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-hard-light{
    mix-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-soft-light{
    mix-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-difference{
    mix-blend-mode: difference
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-exclusion{
    mix-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-hue{
    mix-blend-mode: hue
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-saturation{
    mix-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-color{
    mix-blend-mode: color
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-mix-blend-luminosity{
    mix-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .lg\:group-hover\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:hover\:skindr-widget-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-outline-white:focus-within{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-outline-black:focus-within{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-inset{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-inset:focus-within{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-inset:focus{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-transparent{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-current{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-red-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-yellow-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-green-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-indigo-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-purple-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-pink-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-transparent:focus-within{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-current:focus-within{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-red-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-green-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-purple-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-pink-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-transparent:focus{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-current:focus{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-red-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-yellow-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-green-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-indigo-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-purple-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-pink-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-0{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-5{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-10{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-20{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-25{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-30{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-40{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-50{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-60{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-70{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-75{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-80{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-90{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-95{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-opacity-100{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-0:focus{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-opacity-100:focus{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-0{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-1{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-2{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-4{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-8{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-0:focus{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-1:focus{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-2:focus{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-4:focus{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-8:focus{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-transparent{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-current{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-black{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-white{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-700{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-gray-900{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-400{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-400{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-500{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-600{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-700{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-800{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ring-offset-pink-900{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .lg\:focus-within\:skindr-widget-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-black:focus{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-white:focus{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .lg\:focus\:skindr-widget-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-filter-none{
    filter: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-0{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-none{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-sm{
    --tw-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur{
    --tw-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-md{
    --tw-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-lg{
    --tw-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-xl{
    --tw-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-2xl{
    --tw-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-blur-3xl{
    --tw-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-0{
    --tw-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-50{
    --tw-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-75{
    --tw-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-90{
    --tw-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-95{
    --tw-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-100{
    --tw-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-105{
    --tw-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-110{
    --tw-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-125{
    --tw-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-150{
    --tw-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-brightness-200{
    --tw-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-0{
    --tw-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-50{
    --tw-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-75{
    --tw-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-100{
    --tw-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-125{
    --tw-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-150{
    --tw-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-contrast-200{
    --tw-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grayscale-0{
    --tw-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-grayscale{
    --tw-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-invert-0{
    --tw-invert: invert(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-invert{
    --tw-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-saturate-0{
    --tw-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-saturate-50{
    --tw-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-saturate-100{
    --tw-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-saturate-150{
    --tw-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-saturate-200{
    --tw-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-sepia-0{
    --tw-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-sepia{
    --tw-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-filter-none{
    -webkit-backdrop-filter: none;
            backdrop-filter: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-0{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-none{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur{
    --tw-backdrop-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-invert-0{
    --tw-backdrop-invert: invert(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-invert{
    --tw-backdrop-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition-none{
    transition-property: none
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition-shadow{
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-75{
    transition-delay: 75ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-100{
    transition-delay: 100ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-150{
    transition-delay: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-200{
    transition-delay: 200ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-300{
    transition-delay: 300ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-500{
    transition-delay: 500ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-700{
    transition-delay: 700ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-delay-1000{
    transition-delay: 1000ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-75{
    transition-duration: 75ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-100{
    transition-duration: 100ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-150{
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-200{
    transition-duration: 200ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-300{
    transition-duration: 300ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-500{
    transition-duration: 500ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-700{
    transition-duration: 700ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-duration-1000{
    transition-duration: 1000ms
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ease-linear{
    transition-timing-function: linear
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  #skindrWidget.skindrWidget .lg\:skindr-widget-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 1280px){
  .xl\:skindr-widget-container{
    width: 100%
  }

  @media (min-width: 640px){
    .xl\:skindr-widget-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .xl\:skindr-widget-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .xl\:skindr-widget-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .xl\:skindr-widget-container{
      max-width: 1280px
    }
  }

  @media (min-width: 1536px){
    .xl\:skindr-widget-container{
      max-width: 1536px
    }
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-sr-only:focus-within{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-not-sr-only:focus-within{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pointer-events-none{
    pointer-events: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pointer-events-auto{
    pointer-events: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-visible{
    visibility: visible
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-invisible{
    visibility: hidden
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-static{
    position: static
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-fixed{
    position: fixed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-absolute{
    position: absolute
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-relative{
    position: relative
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-sticky{
    position: sticky
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-1{
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-2{
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-3{
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-4{
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-5{
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-6{
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-7{
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-8{
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-9{
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-10{
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-11{
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-12{
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-14{
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-16{
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-20{
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-24{
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-28{
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-32{
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-36{
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-40{
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-44{
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-48{
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-52{
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-56{
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-60{
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-64{
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-72{
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-80{
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-96{
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-px{
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-0\.5{
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-1\.5{
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-2\.5{
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-3\.5{
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-1{
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-2{
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-3{
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-4{
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-5{
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-6{
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-7{
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-8{
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-9{
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-10{
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-11{
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-12{
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-14{
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-16{
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-20{
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-24{
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-28{
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-32{
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-36{
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-40{
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-44{
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-48{
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-52{
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-56{
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-60{
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-64{
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-72{
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-80{
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-96{
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-px{
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-0\.5{
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-1\.5{
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-2\.5{
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-3\.5{
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-1\/2{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-1\/3{
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-2\/3{
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-1\/4{
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-2\/4{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-3\/4{
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-full{
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-1\/2{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-1\/3{
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-2\/3{
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-1\/4{
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-2\/4{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-3\/4{
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-full{
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-1{
    left: 0.25rem;
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-2{
    left: 0.5rem;
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-3{
    left: 0.75rem;
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-4{
    left: 1rem;
    right: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-5{
    left: 1.25rem;
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-6{
    left: 1.5rem;
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-7{
    left: 1.75rem;
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-8{
    left: 2rem;
    right: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-9{
    left: 2.25rem;
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-10{
    left: 2.5rem;
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-11{
    left: 2.75rem;
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-12{
    left: 3rem;
    right: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-14{
    left: 3.5rem;
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-16{
    left: 4rem;
    right: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-20{
    left: 5rem;
    right: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-24{
    left: 6rem;
    right: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-28{
    left: 7rem;
    right: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-32{
    left: 8rem;
    right: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-36{
    left: 9rem;
    right: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-40{
    left: 10rem;
    right: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-44{
    left: 11rem;
    right: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-48{
    left: 12rem;
    right: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-52{
    left: 13rem;
    right: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-56{
    left: 14rem;
    right: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-60{
    left: 15rem;
    right: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-64{
    left: 16rem;
    right: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-72{
    left: 18rem;
    right: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-80{
    left: 20rem;
    right: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-96{
    left: 24rem;
    right: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-auto{
    left: auto;
    right: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-px{
    left: 1px;
    right: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-0\.5{
    left: 0.125rem;
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-1\.5{
    left: 0.375rem;
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-2\.5{
    left: 0.625rem;
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-3\.5{
    left: 0.875rem;
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-1{
    left: -0.25rem;
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-2{
    left: -0.5rem;
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-3{
    left: -0.75rem;
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-4{
    left: -1rem;
    right: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-5{
    left: -1.25rem;
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-6{
    left: -1.5rem;
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-7{
    left: -1.75rem;
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-8{
    left: -2rem;
    right: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-9{
    left: -2.25rem;
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-10{
    left: -2.5rem;
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-11{
    left: -2.75rem;
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-12{
    left: -3rem;
    right: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-14{
    left: -3.5rem;
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-16{
    left: -4rem;
    right: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-20{
    left: -5rem;
    right: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-24{
    left: -6rem;
    right: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-28{
    left: -7rem;
    right: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-32{
    left: -8rem;
    right: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-36{
    left: -9rem;
    right: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-40{
    left: -10rem;
    right: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-44{
    left: -11rem;
    right: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-48{
    left: -12rem;
    right: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-52{
    left: -13rem;
    right: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-56{
    left: -14rem;
    right: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-60{
    left: -15rem;
    right: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-64{
    left: -16rem;
    right: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-72{
    left: -18rem;
    right: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-80{
    left: -20rem;
    right: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-96{
    left: -24rem;
    right: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-px{
    left: -1px;
    right: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-0\.5{
    left: -0.125rem;
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-1\.5{
    left: -0.375rem;
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-2\.5{
    left: -0.625rem;
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-3\.5{
    left: -0.875rem;
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-1\/2{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-1\/3{
    left: 33.333333%;
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-2\/3{
    left: 66.666667%;
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-1\/4{
    left: 25%;
    right: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-2\/4{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-3\/4{
    left: 75%;
    right: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-x-full{
    left: 100%;
    right: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-1\/2{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-1\/3{
    left: -33.333333%;
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-2\/3{
    left: -66.666667%;
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-1\/4{
    left: -25%;
    right: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-2\/4{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-3\/4{
    left: -75%;
    right: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-x-full{
    left: -100%;
    right: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-1{
    top: 0.25rem;
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-2{
    top: 0.5rem;
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-3{
    top: 0.75rem;
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-4{
    top: 1rem;
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-5{
    top: 1.25rem;
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-6{
    top: 1.5rem;
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-7{
    top: 1.75rem;
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-8{
    top: 2rem;
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-9{
    top: 2.25rem;
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-10{
    top: 2.5rem;
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-11{
    top: 2.75rem;
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-12{
    top: 3rem;
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-14{
    top: 3.5rem;
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-16{
    top: 4rem;
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-20{
    top: 5rem;
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-24{
    top: 6rem;
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-28{
    top: 7rem;
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-32{
    top: 8rem;
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-36{
    top: 9rem;
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-40{
    top: 10rem;
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-44{
    top: 11rem;
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-48{
    top: 12rem;
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-52{
    top: 13rem;
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-56{
    top: 14rem;
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-60{
    top: 15rem;
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-64{
    top: 16rem;
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-72{
    top: 18rem;
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-80{
    top: 20rem;
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-96{
    top: 24rem;
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-auto{
    top: auto;
    bottom: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-px{
    top: 1px;
    bottom: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-0\.5{
    top: 0.125rem;
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-1\.5{
    top: 0.375rem;
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-2\.5{
    top: 0.625rem;
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-3\.5{
    top: 0.875rem;
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-1{
    top: -0.25rem;
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-2{
    top: -0.5rem;
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-3{
    top: -0.75rem;
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-4{
    top: -1rem;
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-5{
    top: -1.25rem;
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-6{
    top: -1.5rem;
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-7{
    top: -1.75rem;
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-8{
    top: -2rem;
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-9{
    top: -2.25rem;
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-10{
    top: -2.5rem;
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-11{
    top: -2.75rem;
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-12{
    top: -3rem;
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-14{
    top: -3.5rem;
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-16{
    top: -4rem;
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-20{
    top: -5rem;
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-24{
    top: -6rem;
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-28{
    top: -7rem;
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-32{
    top: -8rem;
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-36{
    top: -9rem;
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-40{
    top: -10rem;
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-44{
    top: -11rem;
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-48{
    top: -12rem;
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-52{
    top: -13rem;
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-56{
    top: -14rem;
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-60{
    top: -15rem;
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-64{
    top: -16rem;
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-72{
    top: -18rem;
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-80{
    top: -20rem;
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-96{
    top: -24rem;
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-px{
    top: -1px;
    bottom: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-0\.5{
    top: -0.125rem;
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-1\.5{
    top: -0.375rem;
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-2\.5{
    top: -0.625rem;
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-3\.5{
    top: -0.875rem;
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-1\/2{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-1\/3{
    top: 33.333333%;
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-2\/3{
    top: 66.666667%;
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-1\/4{
    top: 25%;
    bottom: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-2\/4{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-3\/4{
    top: 75%;
    bottom: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inset-y-full{
    top: 100%;
    bottom: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-1\/2{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-1\/3{
    top: -33.333333%;
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-2\/3{
    top: -66.666667%;
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-1\/4{
    top: -25%;
    bottom: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-2\/4{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-3\/4{
    top: -75%;
    bottom: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--inset-y-full{
    top: -100%;
    bottom: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-1{
    top: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-2{
    top: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-3{
    top: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-4{
    top: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-5{
    top: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-6{
    top: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-7{
    top: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-8{
    top: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-9{
    top: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-10{
    top: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-11{
    top: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-12{
    top: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-14{
    top: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-16{
    top: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-20{
    top: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-24{
    top: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-28{
    top: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-32{
    top: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-36{
    top: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-40{
    top: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-44{
    top: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-48{
    top: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-52{
    top: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-56{
    top: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-60{
    top: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-64{
    top: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-72{
    top: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-80{
    top: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-96{
    top: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-auto{
    top: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-px{
    top: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-0\.5{
    top: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-1\.5{
    top: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-2\.5{
    top: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-3\.5{
    top: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-1{
    top: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-2{
    top: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-3{
    top: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-4{
    top: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-5{
    top: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-6{
    top: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-7{
    top: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-8{
    top: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-9{
    top: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-10{
    top: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-11{
    top: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-12{
    top: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-14{
    top: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-16{
    top: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-20{
    top: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-24{
    top: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-28{
    top: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-32{
    top: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-36{
    top: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-40{
    top: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-44{
    top: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-48{
    top: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-52{
    top: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-56{
    top: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-60{
    top: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-64{
    top: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-72{
    top: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-80{
    top: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-96{
    top: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-px{
    top: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-0\.5{
    top: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-1\.5{
    top: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-2\.5{
    top: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-3\.5{
    top: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-1\/2{
    top: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-1\/3{
    top: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-2\/3{
    top: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-1\/4{
    top: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-2\/4{
    top: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-3\/4{
    top: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-top-full{
    top: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-1\/2{
    top: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-1\/3{
    top: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-2\/3{
    top: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-1\/4{
    top: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-2\/4{
    top: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-3\/4{
    top: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--top-full{
    top: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-1{
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-2{
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-3{
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-4{
    right: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-5{
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-6{
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-7{
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-8{
    right: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-9{
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-10{
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-11{
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-12{
    right: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-14{
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-16{
    right: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-20{
    right: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-24{
    right: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-28{
    right: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-32{
    right: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-36{
    right: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-40{
    right: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-44{
    right: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-48{
    right: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-52{
    right: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-56{
    right: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-60{
    right: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-64{
    right: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-72{
    right: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-80{
    right: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-96{
    right: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-auto{
    right: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-px{
    right: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-0\.5{
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-1\.5{
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-2\.5{
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-3\.5{
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-1{
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-2{
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-3{
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-4{
    right: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-5{
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-6{
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-7{
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-8{
    right: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-9{
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-10{
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-11{
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-12{
    right: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-14{
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-16{
    right: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-20{
    right: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-24{
    right: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-28{
    right: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-32{
    right: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-36{
    right: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-40{
    right: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-44{
    right: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-48{
    right: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-52{
    right: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-56{
    right: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-60{
    right: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-64{
    right: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-72{
    right: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-80{
    right: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-96{
    right: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-px{
    right: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-0\.5{
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-1\.5{
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-2\.5{
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-3\.5{
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-1\/2{
    right: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-1\/3{
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-2\/3{
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-1\/4{
    right: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-2\/4{
    right: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-3\/4{
    right: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-right-full{
    right: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-1\/2{
    right: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-1\/3{
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-2\/3{
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-1\/4{
    right: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-2\/4{
    right: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-3\/4{
    right: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--right-full{
    right: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-1{
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-2{
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-3{
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-4{
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-5{
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-6{
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-7{
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-8{
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-9{
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-10{
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-11{
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-12{
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-14{
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-16{
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-20{
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-24{
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-28{
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-32{
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-36{
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-40{
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-44{
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-48{
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-52{
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-56{
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-60{
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-64{
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-72{
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-80{
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-96{
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-auto{
    bottom: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-px{
    bottom: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-0\.5{
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-1\.5{
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-2\.5{
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-3\.5{
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-1{
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-2{
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-3{
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-4{
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-5{
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-6{
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-7{
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-8{
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-9{
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-10{
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-11{
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-12{
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-14{
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-16{
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-20{
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-24{
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-28{
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-32{
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-36{
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-40{
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-44{
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-48{
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-52{
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-56{
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-60{
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-64{
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-72{
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-80{
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-96{
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-px{
    bottom: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-0\.5{
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-1\.5{
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-2\.5{
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-3\.5{
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-1\/2{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-1\/3{
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-2\/3{
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-1\/4{
    bottom: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-2\/4{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-3\/4{
    bottom: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bottom-full{
    bottom: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-1\/2{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-1\/3{
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-2\/3{
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-1\/4{
    bottom: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-2\/4{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-3\/4{
    bottom: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--bottom-full{
    bottom: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-1{
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-2{
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-3{
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-4{
    left: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-5{
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-6{
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-7{
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-8{
    left: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-9{
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-10{
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-11{
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-12{
    left: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-14{
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-16{
    left: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-20{
    left: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-24{
    left: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-28{
    left: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-32{
    left: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-36{
    left: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-40{
    left: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-44{
    left: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-48{
    left: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-52{
    left: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-56{
    left: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-60{
    left: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-64{
    left: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-72{
    left: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-80{
    left: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-96{
    left: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-auto{
    left: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-px{
    left: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-0\.5{
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-1\.5{
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-2\.5{
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-3\.5{
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-1{
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-2{
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-3{
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-4{
    left: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-5{
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-6{
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-7{
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-8{
    left: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-9{
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-10{
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-11{
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-12{
    left: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-14{
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-16{
    left: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-20{
    left: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-24{
    left: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-28{
    left: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-32{
    left: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-36{
    left: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-40{
    left: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-44{
    left: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-48{
    left: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-52{
    left: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-56{
    left: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-60{
    left: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-64{
    left: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-72{
    left: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-80{
    left: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-96{
    left: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-px{
    left: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-0\.5{
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-1\.5{
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-2\.5{
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-3\.5{
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-1\/2{
    left: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-1\/3{
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-2\/3{
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-1\/4{
    left: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-2\/4{
    left: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-3\/4{
    left: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-left-full{
    left: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-1\/2{
    left: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-1\/3{
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-2\/3{
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-1\/4{
    left: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-2\/4{
    left: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-3\/4{
    left: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--left-full{
    left: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-isolate{
    isolation: isolate
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-isolation-auto{
    isolation: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-0{
    z-index: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-10{
    z-index: 10
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-20{
    z-index: 20
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-30{
    z-index: 30
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-40{
    z-index: 40
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-50{
    z-index: 50
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-z-auto{
    z-index: auto
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-0:focus-within{
    z-index: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-10:focus-within{
    z-index: 10
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-20:focus-within{
    z-index: 20
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-30:focus-within{
    z-index: 30
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-40:focus-within{
    z-index: 40
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-50:focus-within{
    z-index: 50
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-z-auto:focus-within{
    z-index: auto
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-0:focus{
    z-index: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-10:focus{
    z-index: 10
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-20:focus{
    z-index: 20
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-30:focus{
    z-index: 30
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-40:focus{
    z-index: 40
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-50:focus{
    z-index: 50
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-z-auto:focus{
    z-index: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-1{
    order: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-2{
    order: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-3{
    order: 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-4{
    order: 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-5{
    order: 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-6{
    order: 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-7{
    order: 7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-8{
    order: 8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-9{
    order: 9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-10{
    order: 10
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-11{
    order: 11
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-12{
    order: 12
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-first{
    order: -9999
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-last{
    order: 9999
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-order-none{
    order: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-auto{
    grid-column: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-1{
    grid-column: span 1 / span 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-2{
    grid-column: span 2 / span 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-3{
    grid-column: span 3 / span 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-4{
    grid-column: span 4 / span 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-5{
    grid-column: span 5 / span 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-6{
    grid-column: span 6 / span 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-7{
    grid-column: span 7 / span 7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-8{
    grid-column: span 8 / span 8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-9{
    grid-column: span 9 / span 9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-10{
    grid-column: span 10 / span 10
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-11{
    grid-column: span 11 / span 11
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-12{
    grid-column: span 12 / span 12
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-span-full{
    grid-column: 1 / -1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-1{
    grid-column-start: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-2{
    grid-column-start: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-3{
    grid-column-start: 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-4{
    grid-column-start: 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-5{
    grid-column-start: 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-6{
    grid-column-start: 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-7{
    grid-column-start: 7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-8{
    grid-column-start: 8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-9{
    grid-column-start: 9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-10{
    grid-column-start: 10
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-11{
    grid-column-start: 11
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-12{
    grid-column-start: 12
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-13{
    grid-column-start: 13
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-start-auto{
    grid-column-start: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-1{
    grid-column-end: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-2{
    grid-column-end: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-3{
    grid-column-end: 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-4{
    grid-column-end: 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-5{
    grid-column-end: 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-6{
    grid-column-end: 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-7{
    grid-column-end: 7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-8{
    grid-column-end: 8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-9{
    grid-column-end: 9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-10{
    grid-column-end: 10
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-11{
    grid-column-end: 11
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-12{
    grid-column-end: 12
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-13{
    grid-column-end: 13
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-col-end-auto{
    grid-column-end: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-auto{
    grid-row: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-1{
    grid-row: span 1 / span 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-2{
    grid-row: span 2 / span 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-3{
    grid-row: span 3 / span 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-4{
    grid-row: span 4 / span 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-5{
    grid-row: span 5 / span 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-6{
    grid-row: span 6 / span 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-span-full{
    grid-row: 1 / -1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-1{
    grid-row-start: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-2{
    grid-row-start: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-3{
    grid-row-start: 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-4{
    grid-row-start: 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-5{
    grid-row-start: 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-6{
    grid-row-start: 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-7{
    grid-row-start: 7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-start-auto{
    grid-row-start: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-1{
    grid-row-end: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-2{
    grid-row-end: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-3{
    grid-row-end: 3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-4{
    grid-row-end: 4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-5{
    grid-row-end: 5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-6{
    grid-row-end: 6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-7{
    grid-row-end: 7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-row-end-auto{
    grid-row-end: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-float-right{
    float: right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-float-left{
    float: left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-float-none{
    float: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-clear-left{
    clear: left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-clear-right{
    clear: right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-clear-both{
    clear: both
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-clear-none{
    clear: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-1{
    margin: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-2{
    margin: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-3{
    margin: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-4{
    margin: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-5{
    margin: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-6{
    margin: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-7{
    margin: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-8{
    margin: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-9{
    margin: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-10{
    margin: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-11{
    margin: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-12{
    margin: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-14{
    margin: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-16{
    margin: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-20{
    margin: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-24{
    margin: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-28{
    margin: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-32{
    margin: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-36{
    margin: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-40{
    margin: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-44{
    margin: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-48{
    margin: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-52{
    margin: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-56{
    margin: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-60{
    margin: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-64{
    margin: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-72{
    margin: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-80{
    margin: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-96{
    margin: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-auto{
    margin: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-px{
    margin: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-0\.5{
    margin: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-1\.5{
    margin: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-2\.5{
    margin: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-m-3\.5{
    margin: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-1{
    margin: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-2{
    margin: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-3{
    margin: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-4{
    margin: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-5{
    margin: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-6{
    margin: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-7{
    margin: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-8{
    margin: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-9{
    margin: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-10{
    margin: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-11{
    margin: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-12{
    margin: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-14{
    margin: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-16{
    margin: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-20{
    margin: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-24{
    margin: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-28{
    margin: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-32{
    margin: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-36{
    margin: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-40{
    margin: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-44{
    margin: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-48{
    margin: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-52{
    margin: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-56{
    margin: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-60{
    margin: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-64{
    margin: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-72{
    margin: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-80{
    margin: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-96{
    margin: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-px{
    margin: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-0\.5{
    margin: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-1\.5{
    margin: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-2\.5{
    margin: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--m-3\.5{
    margin: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-7{
    margin-left: 1.75rem;
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-9{
    margin-left: 2.25rem;
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-11{
    margin-left: 2.75rem;
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-14{
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-28{
    margin-left: 7rem;
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-36{
    margin-left: 9rem;
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-44{
    margin-left: 11rem;
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-52{
    margin-left: 13rem;
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-60{
    margin-left: 15rem;
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-72{
    margin-left: 18rem;
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-80{
    margin-left: 20rem;
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-96{
    margin-left: 24rem;
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-0\.5{
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-1\.5{
    margin-left: 0.375rem;
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-2\.5{
    margin-left: 0.625rem;
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mx-3\.5{
    margin-left: 0.875rem;
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-7{
    margin-left: -1.75rem;
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-9{
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-11{
    margin-left: -2.75rem;
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-14{
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-28{
    margin-left: -7rem;
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-36{
    margin-left: -9rem;
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-44{
    margin-left: -11rem;
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-52{
    margin-left: -13rem;
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-60{
    margin-left: -15rem;
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-72{
    margin-left: -18rem;
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-80{
    margin-left: -20rem;
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-96{
    margin-left: -24rem;
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-0\.5{
    margin-left: -0.125rem;
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-1\.5{
    margin-left: -0.375rem;
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-2\.5{
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mx-3\.5{
    margin-left: -0.875rem;
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-7{
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-9{
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-11{
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-14{
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-28{
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-36{
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-44{
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-52{
    margin-top: 13rem;
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-60{
    margin-top: 15rem;
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-72{
    margin-top: 18rem;
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-80{
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-96{
    margin-top: 24rem;
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-0\.5{
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-1\.5{
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-2\.5{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-my-3\.5{
    margin-top: 0.875rem;
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-7{
    margin-top: -1.75rem;
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-9{
    margin-top: -2.25rem;
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-11{
    margin-top: -2.75rem;
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-14{
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-28{
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-36{
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-44{
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-52{
    margin-top: -13rem;
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-60{
    margin-top: -15rem;
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-72{
    margin-top: -18rem;
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-80{
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-96{
    margin-top: -24rem;
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-0\.5{
    margin-top: -0.125rem;
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-2\.5{
    margin-top: -0.625rem;
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--my-3\.5{
    margin-top: -0.875rem;
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-1{
    margin-top: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-2{
    margin-top: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-3{
    margin-top: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-4{
    margin-top: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-5{
    margin-top: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-6{
    margin-top: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-7{
    margin-top: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-8{
    margin-top: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-9{
    margin-top: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-10{
    margin-top: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-11{
    margin-top: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-12{
    margin-top: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-14{
    margin-top: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-16{
    margin-top: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-20{
    margin-top: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-24{
    margin-top: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-28{
    margin-top: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-32{
    margin-top: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-36{
    margin-top: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-40{
    margin-top: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-44{
    margin-top: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-48{
    margin-top: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-52{
    margin-top: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-56{
    margin-top: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-60{
    margin-top: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-64{
    margin-top: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-72{
    margin-top: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-80{
    margin-top: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-96{
    margin-top: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-auto{
    margin-top: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-px{
    margin-top: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-0\.5{
    margin-top: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-1\.5{
    margin-top: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-2\.5{
    margin-top: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mt-3\.5{
    margin-top: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-1{
    margin-top: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-2{
    margin-top: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-3{
    margin-top: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-4{
    margin-top: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-5{
    margin-top: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-6{
    margin-top: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-7{
    margin-top: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-8{
    margin-top: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-9{
    margin-top: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-10{
    margin-top: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-11{
    margin-top: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-12{
    margin-top: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-14{
    margin-top: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-16{
    margin-top: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-20{
    margin-top: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-24{
    margin-top: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-28{
    margin-top: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-32{
    margin-top: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-36{
    margin-top: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-40{
    margin-top: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-44{
    margin-top: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-48{
    margin-top: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-52{
    margin-top: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-56{
    margin-top: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-60{
    margin-top: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-64{
    margin-top: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-72{
    margin-top: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-80{
    margin-top: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-96{
    margin-top: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-px{
    margin-top: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-0\.5{
    margin-top: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-1\.5{
    margin-top: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-2\.5{
    margin-top: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mt-3\.5{
    margin-top: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-1{
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-2{
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-3{
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-4{
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-5{
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-6{
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-7{
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-8{
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-9{
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-10{
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-11{
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-12{
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-14{
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-16{
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-20{
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-24{
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-28{
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-32{
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-36{
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-40{
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-44{
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-48{
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-52{
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-56{
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-60{
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-64{
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-72{
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-80{
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-96{
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-auto{
    margin-right: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-px{
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-0\.5{
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-1\.5{
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-2\.5{
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mr-3\.5{
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-1{
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-2{
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-3{
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-4{
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-5{
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-6{
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-7{
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-8{
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-9{
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-10{
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-11{
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-12{
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-14{
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-16{
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-20{
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-24{
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-28{
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-32{
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-36{
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-40{
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-44{
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-48{
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-52{
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-56{
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-60{
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-64{
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-72{
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-80{
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-96{
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-px{
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-0\.5{
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-1\.5{
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-2\.5{
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mr-3\.5{
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-1{
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-2{
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-3{
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-4{
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-5{
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-6{
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-7{
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-8{
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-9{
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-10{
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-11{
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-12{
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-14{
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-16{
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-20{
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-24{
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-28{
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-32{
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-36{
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-40{
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-44{
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-48{
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-52{
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-56{
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-60{
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-64{
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-72{
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-80{
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-96{
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-auto{
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-px{
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-0\.5{
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-1\.5{
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-2\.5{
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mb-3\.5{
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-1{
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-2{
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-3{
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-4{
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-5{
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-6{
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-7{
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-8{
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-9{
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-10{
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-11{
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-12{
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-14{
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-16{
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-20{
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-24{
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-28{
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-32{
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-36{
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-40{
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-44{
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-48{
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-52{
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-56{
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-60{
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-64{
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-72{
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-80{
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-96{
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-px{
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-0\.5{
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-1\.5{
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-2\.5{
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--mb-3\.5{
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-1{
    margin-left: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-2{
    margin-left: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-3{
    margin-left: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-4{
    margin-left: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-5{
    margin-left: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-6{
    margin-left: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-7{
    margin-left: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-8{
    margin-left: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-9{
    margin-left: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-10{
    margin-left: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-11{
    margin-left: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-12{
    margin-left: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-14{
    margin-left: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-16{
    margin-left: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-20{
    margin-left: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-24{
    margin-left: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-28{
    margin-left: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-32{
    margin-left: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-36{
    margin-left: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-40{
    margin-left: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-44{
    margin-left: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-48{
    margin-left: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-52{
    margin-left: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-56{
    margin-left: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-60{
    margin-left: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-64{
    margin-left: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-72{
    margin-left: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-80{
    margin-left: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-96{
    margin-left: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-auto{
    margin-left: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-px{
    margin-left: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-0\.5{
    margin-left: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-1\.5{
    margin-left: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-2\.5{
    margin-left: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ml-3\.5{
    margin-left: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-1{
    margin-left: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-2{
    margin-left: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-3{
    margin-left: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-4{
    margin-left: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-5{
    margin-left: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-6{
    margin-left: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-7{
    margin-left: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-8{
    margin-left: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-9{
    margin-left: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-10{
    margin-left: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-11{
    margin-left: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-12{
    margin-left: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-14{
    margin-left: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-16{
    margin-left: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-20{
    margin-left: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-24{
    margin-left: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-28{
    margin-left: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-32{
    margin-left: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-36{
    margin-left: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-40{
    margin-left: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-44{
    margin-left: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-48{
    margin-left: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-52{
    margin-left: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-56{
    margin-left: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-60{
    margin-left: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-64{
    margin-left: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-72{
    margin-left: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-80{
    margin-left: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-96{
    margin-left: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-px{
    margin-left: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-0\.5{
    margin-left: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-1\.5{
    margin-left: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-2\.5{
    margin-left: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--ml-3\.5{
    margin-left: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-box-border{
    box-sizing: border-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-box-content{
    box-sizing: content-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-block{
    display: block
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inline-block{
    display: inline-block
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inline{
    display: inline
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex{
    display: flex
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inline-flex{
    display: inline-flex
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table{
    display: table
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inline-table{
    display: inline-table
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-caption{
    display: table-caption
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-cell{
    display: table-cell
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-column{
    display: table-column
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-column-group{
    display: table-column-group
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-footer-group{
    display: table-footer-group
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-header-group{
    display: table-header-group
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-row-group{
    display: table-row-group
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-row{
    display: table-row
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flow-root{
    display: flow-root
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid{
    display: grid
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-inline-grid{
    display: inline-grid
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contents{
    display: contents
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-list-item{
    display: list-item
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hidden{
    display: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-0{
    height: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1{
    height: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-2{
    height: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-3{
    height: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-4{
    height: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-5{
    height: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-6{
    height: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-7{
    height: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-8{
    height: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-9{
    height: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-10{
    height: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-11{
    height: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-12{
    height: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-14{
    height: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-16{
    height: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-20{
    height: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-24{
    height: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-28{
    height: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-32{
    height: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-36{
    height: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-40{
    height: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-44{
    height: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-48{
    height: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-52{
    height: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-56{
    height: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-60{
    height: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-64{
    height: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-72{
    height: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-80{
    height: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-96{
    height: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-auto{
    height: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-px{
    height: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-0\.5{
    height: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1\.5{
    height: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-2\.5{
    height: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-3\.5{
    height: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1\/2{
    height: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1\/3{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-2\/3{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1\/4{
    height: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-2\/4{
    height: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-3\/4{
    height: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1\/5{
    height: 20%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-2\/5{
    height: 40%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-3\/5{
    height: 60%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-4\/5{
    height: 80%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-1\/6{
    height: 16.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-2\/6{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-3\/6{
    height: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-4\/6{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-5\/6{
    height: 83.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-full{
    height: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-h-screen{
    height: 100vh
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-0{
    max-height: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-1{
    max-height: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-2{
    max-height: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-3{
    max-height: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-4{
    max-height: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-5{
    max-height: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-6{
    max-height: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-7{
    max-height: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-8{
    max-height: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-9{
    max-height: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-10{
    max-height: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-11{
    max-height: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-12{
    max-height: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-14{
    max-height: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-16{
    max-height: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-20{
    max-height: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-24{
    max-height: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-28{
    max-height: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-32{
    max-height: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-36{
    max-height: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-40{
    max-height: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-44{
    max-height: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-48{
    max-height: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-52{
    max-height: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-56{
    max-height: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-60{
    max-height: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-64{
    max-height: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-72{
    max-height: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-80{
    max-height: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-96{
    max-height: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-px{
    max-height: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-0\.5{
    max-height: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-1\.5{
    max-height: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-2\.5{
    max-height: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-3\.5{
    max-height: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-full{
    max-height: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-h-screen{
    max-height: 100vh
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-h-0{
    min-height: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-h-full{
    min-height: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-h-screen{
    min-height: 100vh
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-0{
    width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1{
    width: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2{
    width: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-3{
    width: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-4{
    width: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-5{
    width: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-6{
    width: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-7{
    width: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-8{
    width: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-9{
    width: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-10{
    width: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-11{
    width: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-12{
    width: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-14{
    width: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-16{
    width: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-20{
    width: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-24{
    width: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-28{
    width: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-32{
    width: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-36{
    width: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-40{
    width: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-44{
    width: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-48{
    width: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-52{
    width: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-56{
    width: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-60{
    width: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-64{
    width: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-72{
    width: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-80{
    width: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-96{
    width: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-auto{
    width: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-px{
    width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-0\.5{
    width: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\.5{
    width: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2\.5{
    width: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-3\.5{
    width: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\/2{
    width: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\/3{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2\/3{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\/4{
    width: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2\/4{
    width: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-3\/4{
    width: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\/5{
    width: 20%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2\/5{
    width: 40%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-3\/5{
    width: 60%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-4\/5{
    width: 80%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\/6{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2\/6{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-3\/6{
    width: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-4\/6{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-5\/6{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-1\/12{
    width: 8.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-2\/12{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-3\/12{
    width: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-4\/12{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-5\/12{
    width: 41.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-6\/12{
    width: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-7\/12{
    width: 58.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-8\/12{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-9\/12{
    width: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-10\/12{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-11\/12{
    width: 91.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-full{
    width: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-screen{
    width: 100vw
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-min{
    width: min-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-w-max{
    width: max-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-w-0{
    min-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-w-full{
    min-width: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-w-min{
    min-width: min-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-min-w-max{
    min-width: max-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-0{
    max-width: 0rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-none{
    max-width: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-xs{
    max-width: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-sm{
    max-width: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-md{
    max-width: 28rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-lg{
    max-width: 32rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-xl{
    max-width: 36rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-2xl{
    max-width: 42rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-3xl{
    max-width: 48rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-4xl{
    max-width: 56rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-5xl{
    max-width: 64rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-6xl{
    max-width: 72rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-7xl{
    max-width: 80rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-full{
    max-width: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-min{
    max-width: min-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-max{
    max-width: max-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-prose{
    max-width: 65ch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-screen-sm{
    max-width: 640px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-screen-md{
    max-width: 768px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-screen-lg{
    max-width: 1024px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-screen-xl{
    max-width: 1280px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-max-w-screen-2xl{
    max-width: 1536px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-1{
    flex: 1 1 0%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-auto{
    flex: 1 1 auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-initial{
    flex: 0 1 auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-none{
    flex: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-shrink-0{
    flex-shrink: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-shrink{
    flex-shrink: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-grow-0{
    flex-grow: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-grow{
    flex-grow: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-auto{
    table-layout: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-table-fixed{
    table-layout: fixed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-collapse{
    border-collapse: collapse
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-separate{
    border-collapse: separate
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-center{
    transform-origin: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-top{
    transform-origin: top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-top-right{
    transform-origin: top right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-right{
    transform-origin: right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-bottom-right{
    transform-origin: bottom right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-bottom{
    transform-origin: bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-bottom-left{
    transform-origin: bottom left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-left{
    transform-origin: left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-origin-top-left{
    transform-origin: top left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transform-none{
    transform: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-1{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-2{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-3{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-4{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-5{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-6{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-7{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-8{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-9{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-10{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-11{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-12{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-14{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-16{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-20{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-24{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-28{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-32{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-36{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-40{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-44{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-48{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-52{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-56{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-60{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-64{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-72{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-80{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-96{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-px{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-0\.5{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-1\.5{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-2\.5{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-3\.5{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-1{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-2{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-3{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-4{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-5{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-6{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-7{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-8{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-9{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-10{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-11{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-12{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-14{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-16{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-20{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-24{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-28{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-32{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-36{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-40{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-44{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-48{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-52{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-56{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-60{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-64{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-72{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-80{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-96{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-px{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-0\.5{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-1\.5{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-2\.5{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-3\.5{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-1\/2{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-1\/3{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-2\/3{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-1\/4{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-2\/4{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-3\/4{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-x-full{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-1\/2{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-1\/3{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-2\/3{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-1\/4{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-2\/4{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-3\/4{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-x-full{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-1{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-2{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-3{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-4{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-5{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-6{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-7{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-8{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-9{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-10{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-11{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-12{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-14{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-16{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-20{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-24{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-28{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-32{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-36{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-40{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-44{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-48{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-52{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-56{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-60{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-64{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-72{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-80{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-96{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-px{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-0\.5{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-1\.5{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-2\.5{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-3\.5{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-1{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-2{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-3{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-4{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-5{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-6{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-7{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-8{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-9{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-10{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-11{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-12{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-14{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-16{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-20{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-24{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-28{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-32{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-36{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-40{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-44{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-48{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-52{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-56{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-60{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-64{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-72{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-80{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-96{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-px{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-0\.5{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-1\.5{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-2\.5{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-3\.5{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-1\/2{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-1\/3{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-2\/3{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-1\/4{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-2\/4{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-3\/4{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-translate-y-full{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-1\/2{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-1\/3{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-2\/3{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-1\/4{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-2\/4{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-3\/4{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--translate-y-full{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-1:hover{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-2:hover{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-3:hover{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-4:hover{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-5:hover{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-6:hover{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-7:hover{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-8:hover{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-9:hover{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-10:hover{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-11:hover{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-12:hover{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-14:hover{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-16:hover{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-20:hover{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-24:hover{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-28:hover{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-32:hover{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-36:hover{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-40:hover{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-44:hover{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-48:hover{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-52:hover{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-56:hover{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-60:hover{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-64:hover{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-72:hover{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-80:hover{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-96:hover{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-px:hover{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-1:hover{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-2:hover{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-3:hover{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-4:hover{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-5:hover{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-6:hover{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-7:hover{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-8:hover{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-9:hover{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-10:hover{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-11:hover{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-12:hover{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-14:hover{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-16:hover{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-20:hover{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-24:hover{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-28:hover{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-32:hover{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-36:hover{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-40:hover{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-44:hover{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-48:hover{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-52:hover{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-56:hover{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-60:hover{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-64:hover{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-72:hover{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-80:hover{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-96:hover{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-px:hover{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-1\/2:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-1\/4:hover{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-2\/4:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-3\/4:hover{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-x-full:hover{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-1\/2:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-1\/4:hover{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-2\/4:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-3\/4:hover{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-x-full:hover{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-1:hover{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-2:hover{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-3:hover{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-4:hover{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-5:hover{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-6:hover{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-7:hover{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-8:hover{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-9:hover{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-10:hover{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-11:hover{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-12:hover{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-14:hover{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-16:hover{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-20:hover{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-24:hover{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-28:hover{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-32:hover{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-36:hover{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-40:hover{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-44:hover{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-48:hover{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-52:hover{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-56:hover{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-60:hover{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-64:hover{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-72:hover{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-80:hover{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-96:hover{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-px:hover{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-1:hover{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-2:hover{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-3:hover{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-4:hover{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-5:hover{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-6:hover{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-7:hover{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-8:hover{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-9:hover{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-10:hover{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-11:hover{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-12:hover{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-14:hover{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-16:hover{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-20:hover{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-24:hover{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-28:hover{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-32:hover{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-36:hover{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-40:hover{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-44:hover{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-48:hover{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-52:hover{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-56:hover{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-60:hover{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-64:hover{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-72:hover{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-80:hover{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-96:hover{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-px:hover{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-1\/2:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-1\/4:hover{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-2\/4:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-3\/4:hover{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-translate-y-full:hover{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-1\/2:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-1\/4:hover{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-2\/4:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-3\/4:hover{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--translate-y-full:hover{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-1:focus{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-2:focus{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-3:focus{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-4:focus{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-5:focus{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-6:focus{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-7:focus{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-8:focus{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-9:focus{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-10:focus{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-11:focus{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-12:focus{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-14:focus{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-16:focus{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-20:focus{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-24:focus{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-28:focus{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-32:focus{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-36:focus{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-40:focus{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-44:focus{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-48:focus{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-52:focus{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-56:focus{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-60:focus{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-64:focus{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-72:focus{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-80:focus{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-96:focus{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-px:focus{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-1:focus{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-2:focus{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-3:focus{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-4:focus{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-5:focus{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-6:focus{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-7:focus{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-8:focus{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-9:focus{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-10:focus{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-11:focus{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-12:focus{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-14:focus{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-16:focus{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-20:focus{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-24:focus{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-28:focus{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-32:focus{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-36:focus{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-40:focus{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-44:focus{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-48:focus{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-52:focus{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-56:focus{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-60:focus{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-64:focus{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-72:focus{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-80:focus{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-96:focus{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-px:focus{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-1\/2:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-1\/4:focus{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-2\/4:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-3\/4:focus{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-x-full:focus{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-1\/2:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-1\/4:focus{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-2\/4:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-3\/4:focus{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-x-full:focus{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-1:focus{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-2:focus{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-3:focus{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-4:focus{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-5:focus{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-6:focus{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-7:focus{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-8:focus{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-9:focus{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-10:focus{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-11:focus{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-12:focus{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-14:focus{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-16:focus{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-20:focus{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-24:focus{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-28:focus{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-32:focus{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-36:focus{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-40:focus{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-44:focus{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-48:focus{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-52:focus{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-56:focus{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-60:focus{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-64:focus{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-72:focus{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-80:focus{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-96:focus{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-px:focus{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-1:focus{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-2:focus{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-3:focus{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-4:focus{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-5:focus{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-6:focus{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-7:focus{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-8:focus{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-9:focus{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-10:focus{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-11:focus{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-12:focus{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-14:focus{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-16:focus{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-20:focus{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-24:focus{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-28:focus{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-32:focus{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-36:focus{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-40:focus{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-44:focus{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-48:focus{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-52:focus{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-56:focus{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-60:focus{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-64:focus{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-72:focus{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-80:focus{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-96:focus{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-px:focus{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-1\/2:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-1\/4:focus{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-2\/4:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-3\/4:focus{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-translate-y-full:focus{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-1\/2:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-1\/4:focus{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-2\/4:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-3\/4:focus{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--translate-y-full:focus{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-0{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-1{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-2{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-3{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-6{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-12{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-45{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-90{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rotate-180{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-180{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-90{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-45{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-12{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-6{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-3{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-2{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--rotate-1{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-0:hover{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-1:hover{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-2:hover{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-3:hover{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-6:hover{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-12:hover{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-45:hover{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-90:hover{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-rotate-180:hover{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-180:hover{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-90:hover{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-45:hover{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-12:hover{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-6:hover{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-3:hover{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-2:hover{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--rotate-1:hover{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-0:focus{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-1:focus{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-2:focus{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-3:focus{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-6:focus{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-12:focus{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-45:focus{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-90:focus{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-rotate-180:focus{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-180:focus{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-90:focus{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-45:focus{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-12:focus{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-6:focus{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-3:focus{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-2:focus{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--rotate-1:focus{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-x-0{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-x-1{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-x-2{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-x-3{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-x-6{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-x-12{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-x-12{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-x-6{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-x-3{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-x-2{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-x-1{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-y-0{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-y-1{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-y-2{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-y-3{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-y-6{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-skew-y-12{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-y-12{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-y-6{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-y-3{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-y-2{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--skew-y-1{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-x-0:hover{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-x-1:hover{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-x-2:hover{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-x-3:hover{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-x-6:hover{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-x-12:hover{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-x-12:hover{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-x-6:hover{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-x-3:hover{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-x-2:hover{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-x-1:hover{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-y-0:hover{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-y-1:hover{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-y-2:hover{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-y-3:hover{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-y-6:hover{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-skew-y-12:hover{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-y-12:hover{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-y-6:hover{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-y-3:hover{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-y-2:hover{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget--skew-y-1:hover{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-x-0:focus{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-x-1:focus{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-x-2:focus{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-x-3:focus{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-x-6:focus{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-x-12:focus{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-x-12:focus{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-x-6:focus{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-x-3:focus{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-x-2:focus{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-x-1:focus{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-y-0:focus{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-y-1:focus{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-y-2:focus{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-y-3:focus{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-y-6:focus{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-skew-y-12:focus{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-y-12:focus{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-y-6:focus{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-y-3:focus{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-y-2:focus{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget--skew-y-1:focus{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-0{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-50{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-75{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-90{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-95{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-100{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-105{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-110{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-125{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-x-150{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-0{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-50{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-75{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-90{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-95{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-100{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-105{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-110{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-125{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-scale-y-150{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-0:hover{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-50:hover{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-75:hover{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-90:hover{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-95:hover{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-100:hover{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-105:hover{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-110:hover{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-125:hover{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-x-150:hover{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-0:hover{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-50:hover{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-75:hover{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-90:hover{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-95:hover{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-100:hover{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-105:hover{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-110:hover{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-125:hover{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-scale-y-150:hover{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-0:focus{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-50:focus{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-75:focus{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-90:focus{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-95:focus{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-100:focus{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-105:focus{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-110:focus{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-125:focus{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-x-150:focus{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-0:focus{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-50:focus{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-75:focus{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-90:focus{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-95:focus{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-100:focus{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-105:focus{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-110:focus{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-125:focus{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-scale-y-150:focus{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-animate-none{
    animation: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-animate-spin{
    animation: skindr-widget-spin 1s linear infinite
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-animate-ping{
    animation: skindr-widget-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-animate-pulse{
    animation: skindr-widget-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-animate-bounce{
    animation: skindr-widget-bounce 1s infinite
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-auto{
    cursor: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-default{
    cursor: default
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-pointer{
    cursor: pointer
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-wait{
    cursor: wait
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-text{
    cursor: text
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-move{
    cursor: move
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-help{
    cursor: help
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-cursor-not-allowed{
    cursor: not-allowed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-select-none{
    -webkit-user-select: none;
            user-select: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-select-text{
    -webkit-user-select: text;
            user-select: text
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-select-all{
    -webkit-user-select: all;
            user-select: all
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-select-auto{
    -webkit-user-select: auto;
            user-select: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-resize-none{
    resize: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-resize-y{
    resize: vertical
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-resize-x{
    resize: horizontal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-resize{
    resize: both
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-list-inside{
    list-style-position: inside
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-list-outside{
    list-style-position: outside
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-list-none{
    list-style-type: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-list-disc{
    list-style-type: disc
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-list-decimal{
    list-style-type: decimal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-appearance-none{
    -webkit-appearance: none;
            appearance: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-cols-auto{
    grid-auto-columns: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-cols-min{
    grid-auto-columns: min-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-cols-max{
    grid-auto-columns: max-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-flow-row{
    grid-auto-flow: row
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-flow-col{
    grid-auto-flow: column
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-rows-auto{
    grid-auto-rows: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-rows-min{
    grid-auto-rows: min-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-rows-max{
    grid-auto-rows: max-content
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-cols-none{
    grid-template-columns: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grid-rows-none{
    grid-template-rows: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-row{
    flex-direction: row
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-row-reverse{
    flex-direction: row-reverse
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-col{
    flex-direction: column
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-col-reverse{
    flex-direction: column-reverse
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-wrap{
    flex-wrap: wrap
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-flex-nowrap{
    flex-wrap: nowrap
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-center{
    place-content: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-start{
    place-content: start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-end{
    place-content: end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-between{
    place-content: space-between
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-around{
    place-content: space-around
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-evenly{
    place-content: space-evenly
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-content-stretch{
    place-content: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-items-start{
    place-items: start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-items-end{
    place-items: end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-items-center{
    place-items: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-items-stretch{
    place-items: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-content-center{
    align-content: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-content-start{
    align-content: flex-start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-content-end{
    align-content: flex-end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-content-between{
    align-content: space-between
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-content-around{
    align-content: space-around
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-content-evenly{
    align-content: space-evenly
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-items-start{
    align-items: flex-start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-items-end{
    align-items: flex-end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-items-center{
    align-items: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-items-baseline{
    align-items: baseline
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-items-stretch{
    align-items: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-start{
    justify-content: flex-start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-end{
    justify-content: flex-end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-center{
    justify-content: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-between{
    justify-content: space-between
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-around{
    justify-content: space-around
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-evenly{
    justify-content: space-evenly
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-items-start{
    justify-items: start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-items-end{
    justify-items: end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-items-center{
    justify-items: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-items-stretch{
    justify-items: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-0{
    gap: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-1{
    gap: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-2{
    gap: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-3{
    gap: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-4{
    gap: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-5{
    gap: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-6{
    gap: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-7{
    gap: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-8{
    gap: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-9{
    gap: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-10{
    gap: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-11{
    gap: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-12{
    gap: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-14{
    gap: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-16{
    gap: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-20{
    gap: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-24{
    gap: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-28{
    gap: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-32{
    gap: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-36{
    gap: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-40{
    gap: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-44{
    gap: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-48{
    gap: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-52{
    gap: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-56{
    gap: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-60{
    gap: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-64{
    gap: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-72{
    gap: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-80{
    gap: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-96{
    gap: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-px{
    gap: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-0\.5{
    gap: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-1\.5{
    gap: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-2\.5{
    gap: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-3\.5{
    gap: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-0{
    column-gap: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-1{
    column-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-2{
    column-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-3{
    column-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-4{
    column-gap: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-5{
    column-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-6{
    column-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-7{
    column-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-8{
    column-gap: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-9{
    column-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-10{
    column-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-11{
    column-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-12{
    column-gap: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-14{
    column-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-16{
    column-gap: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-20{
    column-gap: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-24{
    column-gap: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-28{
    column-gap: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-32{
    column-gap: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-36{
    column-gap: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-40{
    column-gap: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-44{
    column-gap: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-48{
    column-gap: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-52{
    column-gap: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-56{
    column-gap: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-60{
    column-gap: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-64{
    column-gap: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-72{
    column-gap: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-80{
    column-gap: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-96{
    column-gap: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-px{
    column-gap: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-0\.5{
    column-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-1\.5{
    column-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-2\.5{
    column-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-x-3\.5{
    column-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-0{
    row-gap: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-1{
    row-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-2{
    row-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-3{
    row-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-4{
    row-gap: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-5{
    row-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-6{
    row-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-7{
    row-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-8{
    row-gap: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-9{
    row-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-10{
    row-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-11{
    row-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-12{
    row-gap: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-14{
    row-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-16{
    row-gap: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-20{
    row-gap: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-24{
    row-gap: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-28{
    row-gap: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-32{
    row-gap: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-36{
    row-gap: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-40{
    row-gap: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-44{
    row-gap: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-48{
    row-gap: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-52{
    row-gap: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-56{
    row-gap: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-60{
    row-gap: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-64{
    row-gap: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-72{
    row-gap: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-80{
    row-gap: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-96{
    row-gap: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-px{
    row-gap: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-0\.5{
    row-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-1\.5{
    row-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-2\.5{
    row-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-gap-y-3\.5{
    row-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-self-auto{
    place-self: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-self-start{
    place-self: start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-self-end{
    place-self: end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-self-center{
    place-self: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-place-self-stretch{
    place-self: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-self-auto{
    align-self: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-self-start{
    align-self: flex-start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-self-end{
    align-self: flex-end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-self-center{
    align-self: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-self-stretch{
    align-self: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-self-baseline{
    align-self: baseline
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-self-auto{
    justify-self: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-self-start{
    justify-self: start
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-self-end{
    justify-self: end
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-self-center{
    justify-self: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-justify-self-stretch{
    justify-self: stretch
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-auto{
    overflow: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-hidden{
    overflow: hidden
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-visible{
    overflow: visible
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-scroll{
    overflow: scroll
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-x-auto{
    overflow-x: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-y-auto{
    overflow-y: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-x-hidden{
    overflow-x: hidden
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-y-hidden{
    overflow-y: hidden
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-x-visible{
    overflow-x: visible
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-y-visible{
    overflow-y: visible
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-x-scroll{
    overflow-x: scroll
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-y-scroll{
    overflow-y: scroll
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-auto{
    overscroll-behavior: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-contain{
    overscroll-behavior: contain
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-none{
    overscroll-behavior: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-y-none{
    overscroll-behavior-y: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overscroll-x-none{
    overscroll-behavior-x: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-ellipsis{
    text-overflow: ellipsis
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-overflow-clip{
    text-overflow: clip
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-whitespace-normal{
    white-space: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-whitespace-nowrap{
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-whitespace-pre{
    white-space: pre
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-whitespace-pre-line{
    white-space: pre-line
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-break-normal{
    overflow-wrap: normal;
    word-break: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-break-words{
    overflow-wrap: break-word
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-break-all{
    word-break: break-all
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-none{
    border-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-sm{
    border-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded{
    border-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-md{
    border-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-lg{
    border-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-xl{
    border-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-2xl{
    border-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-3xl{
    border-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-full{
    border-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-none{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-none{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-none{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-none{
    border-top-left-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-md{
    border-top-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-xl{
    border-top-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-2xl{
    border-top-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-3xl{
    border-top-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-none{
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-md{
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-xl{
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-2xl{
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-3xl{
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-none{
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-md{
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-xl{
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-2xl{
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-none{
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-md{
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-2xl{
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-0{
    border-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-2{
    border-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-4{
    border-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-8{
    border-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border{
    border-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-t-0{
    border-top-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-t-2{
    border-top-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-t-4{
    border-top-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-t-8{
    border-top-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-t{
    border-top-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-r-0{
    border-right-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-r-2{
    border-right-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-r-4{
    border-right-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-r-8{
    border-right-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-r{
    border-right-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-b-0{
    border-bottom-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-b-2{
    border-bottom-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-b-4{
    border-bottom-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-b-8{
    border-bottom-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-b{
    border-bottom-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-l-0{
    border-left-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-l-2{
    border-left-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-l-4{
    border-left-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-l-8{
    border-left-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-l{
    border-left-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-solid{
    border-style: solid
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-dashed{
    border-style: dashed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-dotted{
    border-style: dotted
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-double{
    border-style: double
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-none{
    border-style: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-transparent:focus-within{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-current:focus-within{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-black:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-white:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-gray-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-red-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-yellow-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-green-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-blue-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-indigo-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-purple-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-pink-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-transparent:hover{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-current:hover{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-red-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-yellow-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-green-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-indigo-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-purple-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-pink-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-transparent:focus{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-current:focus{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-red-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-yellow-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-green-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-indigo-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-purple-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-pink-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-0:focus-within{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-border-opacity-100:focus-within{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-0:hover{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-5:hover{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-10:hover{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-20:hover{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-25:hover{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-30:hover{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-40:hover{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-50:hover{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-60:hover{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-70:hover{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-75:hover{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-80:hover{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-90:hover{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-95:hover{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-border-opacity-100:hover{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-0:focus{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-5:focus{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-10:focus{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-20:focus{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-25:focus{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-30:focus{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-40:focus{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-50:focus{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-60:focus{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-70:focus{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-75:focus{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-80:focus{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-90:focus{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-95:focus{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-border-opacity-100:focus{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-transparent:focus-within{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-current:focus-within{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-black:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-white:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-gray-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-red-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-green-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-blue-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-purple-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-pink-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-transparent:hover{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-current:hover{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-red-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-yellow-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-green-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-indigo-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-purple-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-pink-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-transparent:focus{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-current:focus{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-red-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-yellow-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-green-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-indigo-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-purple-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-pink-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-0:hover{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-bg-opacity-100:hover{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-0:focus{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-bg-opacity-100:focus{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-none{
    background-image: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-black{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-50{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-100{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-200{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-300{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-400{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-500{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-600{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-700{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-800{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-gray-900{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-50{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-100{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-200{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-300{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-400{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-500{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-600{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-700{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-800{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-red-900{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-50{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-100{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-200{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-300{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-400{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-500{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-600{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-700{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-800{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-yellow-900{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-50{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-100{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-200{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-300{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-400{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-500{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-600{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-700{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-800{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-green-900{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-50{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-100{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-200{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-300{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-400{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-500{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-600{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-700{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-800{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-blue-900{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-50{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-100{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-200{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-300{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-400{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-500{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-600{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-700{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-800{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-indigo-900{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-50{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-100{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-200{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-300{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-400{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-500{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-600{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-700{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-800{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-purple-900{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-50{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-100{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-200{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-300{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-400{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-500{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-600{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-700{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-800{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-from-pink-900{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-black:hover{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-50:hover{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-300:hover{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-400:hover{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-500:hover{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-600:hover{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-700:hover{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-800:hover{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-gray-900:hover{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-50:hover{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-100:hover{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-200:hover{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-300:hover{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-400:hover{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-500:hover{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-600:hover{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-700:hover{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-800:hover{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-red-900:hover{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-200:hover{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-600:hover{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-700:hover{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-800:hover{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-yellow-900:hover{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-50:hover{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-100:hover{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-200:hover{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-300:hover{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-400:hover{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-500:hover{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-600:hover{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-700:hover{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-800:hover{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-green-900:hover{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-50:hover{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-100:hover{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-300:hover{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-400:hover{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-500:hover{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-600:hover{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-800:hover{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-400:hover{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-500:hover{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-700:hover{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-800:hover{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-indigo-900:hover{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-100:hover{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-400:hover{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-600:hover{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-700:hover{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-800:hover{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-purple-900:hover{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-100:hover{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-400:hover{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-500:hover{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-600:hover{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-700:hover{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-800:hover{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-from-pink-900:hover{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-black:focus{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-50:focus{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-300:focus{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-400:focus{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-500:focus{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-600:focus{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-700:focus{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-800:focus{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-gray-900:focus{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-50:focus{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-100:focus{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-200:focus{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-300:focus{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-400:focus{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-500:focus{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-600:focus{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-700:focus{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-800:focus{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-red-900:focus{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-200:focus{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-600:focus{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-700:focus{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-800:focus{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-yellow-900:focus{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-50:focus{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-100:focus{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-200:focus{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-300:focus{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-400:focus{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-500:focus{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-600:focus{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-700:focus{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-800:focus{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-green-900:focus{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-50:focus{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-100:focus{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-300:focus{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-400:focus{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-500:focus{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-600:focus{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-800:focus{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-400:focus{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-500:focus{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-700:focus{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-800:focus{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-indigo-900:focus{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-100:focus{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-400:focus{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-600:focus{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-700:focus{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-800:focus{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-purple-900:focus{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-100:focus{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-400:focus{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-500:focus{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-600:focus{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-700:focus{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-800:focus{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-from-pink-900:focus{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-transparent{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-current{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-black{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-white{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-50{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-100{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-200{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-300{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-400{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-500{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-600{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-700{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-800{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-gray-900{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-50{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-100{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-200{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-300{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-400{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-500{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-600{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-700{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-800{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-red-900{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-50{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-100{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-200{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-300{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-400{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-500{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-600{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-700{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-800{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-yellow-900{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-50{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-100{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-200{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-300{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-400{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-500{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-600{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-700{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-800{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-green-900{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-50{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-100{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-200{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-300{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-400{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-500{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-600{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-700{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-800{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-blue-900{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-50{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-100{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-200{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-300{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-400{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-500{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-600{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-700{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-800{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-indigo-900{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-50{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-100{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-200{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-300{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-400{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-500{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-600{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-700{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-800{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-purple-900{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-50{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-100{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-200{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-300{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-400{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-500{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-600{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-700{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-800{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-to-pink-900{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-transparent:hover{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-current:hover{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-black:hover{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-white:hover{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-50:hover{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-300:hover{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-400:hover{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-500:hover{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-600:hover{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-700:hover{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-800:hover{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-gray-900:hover{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-50:hover{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-100:hover{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-200:hover{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-300:hover{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-400:hover{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-500:hover{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-600:hover{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-700:hover{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-800:hover{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-red-900:hover{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-200:hover{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-600:hover{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-700:hover{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-800:hover{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-yellow-900:hover{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-50:hover{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-100:hover{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-200:hover{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-300:hover{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-400:hover{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-500:hover{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-600:hover{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-700:hover{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-800:hover{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-green-900:hover{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-50:hover{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-100:hover{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-300:hover{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-400:hover{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-500:hover{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-600:hover{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-800:hover{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-400:hover{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-500:hover{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-700:hover{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-800:hover{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-indigo-900:hover{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-100:hover{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-400:hover{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-600:hover{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-700:hover{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-800:hover{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-purple-900:hover{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-100:hover{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-400:hover{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-500:hover{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-600:hover{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-700:hover{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-800:hover{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-to-pink-900:hover{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-transparent:focus{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-current:focus{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-black:focus{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-white:focus{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-50:focus{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-300:focus{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-400:focus{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-500:focus{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-600:focus{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-700:focus{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-800:focus{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-gray-900:focus{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-50:focus{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-100:focus{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-200:focus{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-300:focus{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-400:focus{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-500:focus{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-600:focus{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-700:focus{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-800:focus{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-red-900:focus{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-200:focus{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-600:focus{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-700:focus{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-800:focus{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-yellow-900:focus{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-50:focus{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-100:focus{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-200:focus{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-300:focus{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-400:focus{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-500:focus{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-600:focus{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-700:focus{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-800:focus{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-green-900:focus{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-50:focus{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-100:focus{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-300:focus{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-400:focus{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-500:focus{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-600:focus{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-800:focus{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-400:focus{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-500:focus{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-700:focus{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-800:focus{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-indigo-900:focus{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-100:focus{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-400:focus{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-600:focus{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-700:focus{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-800:focus{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-purple-900:focus{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-100:focus{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-400:focus{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-500:focus{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-600:focus{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-700:focus{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-800:focus{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-to-pink-900:focus{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-decoration-slice{
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-decoration-clone{
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-auto{
    background-size: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-cover{
    background-size: cover
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-contain{
    background-size: contain
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-fixed{
    background-attachment: fixed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-local{
    background-attachment: local
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-scroll{
    background-attachment: scroll
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-clip-border{
    background-clip: border-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-clip-padding{
    background-clip: padding-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-clip-content{
    background-clip: content-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-bottom{
    background-position: bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-center{
    background-position: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-left{
    background-position: left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-left-bottom{
    background-position: left bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-left-top{
    background-position: left top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-right{
    background-position: right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-right-bottom{
    background-position: right bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-right-top{
    background-position: right top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-top{
    background-position: top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-repeat{
    background-repeat: repeat
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-no-repeat{
    background-repeat: no-repeat
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-repeat-x{
    background-repeat: repeat-x
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-repeat-y{
    background-repeat: repeat-y
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-repeat-round{
    background-repeat: round
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-repeat-space{
    background-repeat: space
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-origin-border{
    background-origin: border-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-origin-padding{
    background-origin: padding-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-origin-content{
    background-origin: content-box
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-fill-current{
    fill: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-stroke-current{
    stroke: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-stroke-0{
    stroke-width: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-stroke-1{
    stroke-width: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-stroke-2{
    stroke-width: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-contain{
    object-fit: contain
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-cover{
    object-fit: cover
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-fill{
    object-fit: fill
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-none{
    object-fit: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-scale-down{
    object-fit: scale-down
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-bottom{
    object-position: bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-center{
    object-position: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-left{
    object-position: left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-left-bottom{
    object-position: left bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-left-top{
    object-position: left top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-right{
    object-position: right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-right-bottom{
    object-position: right bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-right-top{
    object-position: right top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-object-top{
    object-position: top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-0{
    padding: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-1{
    padding: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-2{
    padding: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-3{
    padding: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-4{
    padding: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-5{
    padding: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-6{
    padding: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-7{
    padding: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-8{
    padding: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-9{
    padding: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-10{
    padding: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-11{
    padding: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-12{
    padding: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-14{
    padding: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-16{
    padding: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-20{
    padding: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-24{
    padding: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-28{
    padding: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-32{
    padding: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-36{
    padding: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-40{
    padding: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-44{
    padding: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-48{
    padding: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-52{
    padding: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-56{
    padding: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-60{
    padding: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-64{
    padding: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-72{
    padding: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-80{
    padding: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-96{
    padding: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-px{
    padding: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-0\.5{
    padding: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-1\.5{
    padding: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-2\.5{
    padding: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-p-3\.5{
    padding: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-0{
    padding-left: 0px;
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-9{
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-11{
    padding-left: 2.75rem;
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-14{
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-28{
    padding-left: 7rem;
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-36{
    padding-left: 9rem;
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-44{
    padding-left: 11rem;
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-52{
    padding-left: 13rem;
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-60{
    padding-left: 15rem;
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-72{
    padding-left: 18rem;
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-80{
    padding-left: 20rem;
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-96{
    padding-left: 24rem;
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-0{
    padding-top: 0px;
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-7{
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-9{
    padding-top: 2.25rem;
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-11{
    padding-top: 2.75rem;
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-14{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-28{
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-36{
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-44{
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-52{
    padding-top: 13rem;
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-60{
    padding-top: 15rem;
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-72{
    padding-top: 18rem;
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-80{
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-96{
    padding-top: 24rem;
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-0{
    padding-top: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-1{
    padding-top: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-2{
    padding-top: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-3{
    padding-top: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-4{
    padding-top: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-5{
    padding-top: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-6{
    padding-top: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-7{
    padding-top: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-8{
    padding-top: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-9{
    padding-top: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-10{
    padding-top: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-11{
    padding-top: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-12{
    padding-top: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-14{
    padding-top: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-16{
    padding-top: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-20{
    padding-top: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-24{
    padding-top: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-28{
    padding-top: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-32{
    padding-top: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-36{
    padding-top: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-40{
    padding-top: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-44{
    padding-top: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-48{
    padding-top: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-52{
    padding-top: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-56{
    padding-top: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-60{
    padding-top: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-64{
    padding-top: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-72{
    padding-top: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-80{
    padding-top: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-96{
    padding-top: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-px{
    padding-top: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-0\.5{
    padding-top: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-1\.5{
    padding-top: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-2\.5{
    padding-top: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pt-3\.5{
    padding-top: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-0{
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-1{
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-2{
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-3{
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-4{
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-5{
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-6{
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-7{
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-8{
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-9{
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-10{
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-11{
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-12{
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-14{
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-16{
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-20{
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-24{
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-28{
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-32{
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-36{
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-40{
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-44{
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-48{
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-52{
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-56{
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-60{
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-64{
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-72{
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-80{
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-96{
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-px{
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-0\.5{
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-1\.5{
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-2\.5{
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pr-3\.5{
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-0{
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-1{
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-2{
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-3{
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-4{
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-5{
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-6{
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-7{
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-8{
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-9{
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-10{
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-11{
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-12{
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-14{
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-16{
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-20{
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-24{
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-28{
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-32{
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-36{
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-40{
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-44{
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-48{
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-52{
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-56{
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-60{
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-64{
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-72{
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-80{
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-96{
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-px{
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-0\.5{
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-1\.5{
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-2\.5{
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pb-3\.5{
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-0{
    padding-left: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-1{
    padding-left: 0.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-2{
    padding-left: 0.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-3{
    padding-left: 0.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-4{
    padding-left: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-5{
    padding-left: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-6{
    padding-left: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-7{
    padding-left: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-8{
    padding-left: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-9{
    padding-left: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-10{
    padding-left: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-11{
    padding-left: 2.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-12{
    padding-left: 3rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-14{
    padding-left: 3.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-16{
    padding-left: 4rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-20{
    padding-left: 5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-24{
    padding-left: 6rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-28{
    padding-left: 7rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-32{
    padding-left: 8rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-36{
    padding-left: 9rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-40{
    padding-left: 10rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-44{
    padding-left: 11rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-48{
    padding-left: 12rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-52{
    padding-left: 13rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-56{
    padding-left: 14rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-60{
    padding-left: 15rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-64{
    padding-left: 16rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-72{
    padding-left: 18rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-80{
    padding-left: 20rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-96{
    padding-left: 24rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-px{
    padding-left: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-0\.5{
    padding-left: 0.125rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-1\.5{
    padding-left: 0.375rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-2\.5{
    padding-left: 0.625rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-pl-3\.5{
    padding-left: 0.875rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-left{
    text-align: left
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-center{
    text-align: center
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-right{
    text-align: right
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-justify{
    text-align: justify
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-align-baseline{
    vertical-align: baseline
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-align-top{
    vertical-align: top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-align-middle{
    vertical-align: middle
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-align-bottom{
    vertical-align: bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-align-text-top{
    vertical-align: text-top
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-align-text-bottom{
    vertical-align: text-bottom
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-xs{
    font-size: 0.75rem;
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-base{
    font-size: 1rem;
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-2xl{
    font-size: 1.5rem;
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-5xl{
    font-size: 3rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-6xl{
    font-size: 3.75rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-7xl{
    font-size: 4.5rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-8xl{
    font-size: 6rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-9xl{
    font-size: 8rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-thin{
    font-weight: 100
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-extralight{
    font-weight: 200
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-light{
    font-weight: 300
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-normal{
    font-weight: 400
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-medium{
    font-weight: 500
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-semibold{
    font-weight: 600
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-bold{
    font-weight: 700
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-extrabold{
    font-weight: 800
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-font-black{
    font-weight: 900
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-uppercase{
    text-transform: uppercase
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-lowercase{
    text-transform: lowercase
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-capitalize{
    text-transform: capitalize
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-normal-case{
    text-transform: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-italic{
    font-style: italic
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-not-italic{
    font-style: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ordinal, #skindrWidget.skindrWidget .xl\:skindr-widget-slashed-zero, #skindrWidget.skindrWidget .xl\:skindr-widget-lining-nums, #skindrWidget.skindrWidget .xl\:skindr-widget-oldstyle-nums, #skindrWidget.skindrWidget .xl\:skindr-widget-proportional-nums, #skindrWidget.skindrWidget .xl\:skindr-widget-tabular-nums, #skindrWidget.skindrWidget .xl\:skindr-widget-diagonal-fractions, #skindrWidget.skindrWidget .xl\:skindr-widget-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-normal-nums{
    font-variant-numeric: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ordinal{
    --tw-ordinal: ordinal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-slashed-zero{
    --tw-slashed-zero: slashed-zero
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-lining-nums{
    --tw-numeric-figure: lining-nums
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-proportional-nums{
    --tw-numeric-spacing: proportional-nums
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tabular-nums{
    --tw-numeric-spacing: tabular-nums
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-3{
    line-height: .75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-4{
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-5{
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-6{
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-7{
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-8{
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-9{
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-10{
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-none{
    line-height: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-tight{
    line-height: 1.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-snug{
    line-height: 1.375
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-normal{
    line-height: 1.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-relaxed{
    line-height: 1.625
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-leading-loose{
    line-height: 2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tracking-tighter{
    letter-spacing: -0.05em
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tracking-tight{
    letter-spacing: -0.025em
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tracking-normal{
    letter-spacing: 0em
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tracking-wide{
    letter-spacing: 0.025em
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tracking-wider{
    letter-spacing: 0.05em
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-tracking-widest{
    letter-spacing: 0.1em
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-transparent:focus-within{
    color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-current:focus-within{
    color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-black:focus-within{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-white:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-gray-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-red-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-yellow-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-green-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-blue-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-indigo-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-purple-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-pink-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-transparent:hover{
    color: transparent
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-current:hover{
    color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-50:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-200:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-400:hover{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-500:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-600:hover{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-700:hover{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-800:hover{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-red-900:hover{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-50:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-200:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-400:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-500:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-600:hover{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-700:hover{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-800:hover{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-yellow-900:hover{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-50:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-100:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-200:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-300:hover{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-400:hover{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-500:hover{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-600:hover{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-700:hover{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-800:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-green-900:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-50:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-50:hover{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-100:hover{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-200:hover{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-300:hover{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-400:hover{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-500:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-700:hover{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-800:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-indigo-900:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-50:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-100:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-200:hover{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-300:hover{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-400:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-500:hover{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-600:hover{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-700:hover{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-800:hover{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-purple-900:hover{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-50:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-100:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-200:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-300:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-400:hover{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-500:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-600:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-700:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-800:hover{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-pink-900:hover{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-transparent:focus{
    color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-current:focus{
    color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-50:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-50:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-200:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-400:focus{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-500:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-600:focus{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-700:focus{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-800:focus{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-red-900:focus{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-50:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-200:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-400:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-500:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-600:focus{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-700:focus{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-800:focus{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-yellow-900:focus{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-50:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-100:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-200:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-300:focus{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-400:focus{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-500:focus{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-600:focus{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-700:focus{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-800:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-green-900:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-50:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-50:focus{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-100:focus{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-200:focus{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-300:focus{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-400:focus{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-500:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-600:focus{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-700:focus{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-800:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-indigo-900:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-50:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-100:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-200:focus{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-300:focus{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-400:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-500:focus{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-600:focus{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-700:focus{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-800:focus{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-purple-900:focus{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-50:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-100:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-200:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-300:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-400:focus{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-500:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-600:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-700:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-800:focus{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-pink-900:focus{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-0:focus-within{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-text-opacity-100:focus-within{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-0:hover{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-5:hover{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-10:hover{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-20:hover{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-25:hover{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-30:hover{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-40:hover{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-50:hover{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-60:hover{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-70:hover{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-75:hover{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-80:hover{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-90:hover{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-95:hover{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-text-opacity-100:hover{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-0:focus{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-5:focus{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-10:focus{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-20:focus{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-25:focus{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-30:focus{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-40:focus{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-50:focus{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-60:focus{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-70:focus{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-75:focus{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-80:focus{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-90:focus{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-95:focus{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-text-opacity-100:focus{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-underline:focus-within{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-line-through:focus-within{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-no-underline:focus-within{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-underline:hover{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-line-through:hover{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-no-underline:hover{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-underline:focus{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-line-through:focus{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-no-underline:focus{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-transparent::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-current::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-current:focus::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-0:focus-within{
    opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-5:focus-within{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-10:focus-within{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-20:focus-within{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-25:focus-within{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-30:focus-within{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-40:focus-within{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-50:focus-within{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-60:focus-within{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-70:focus-within{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-75:focus-within{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-80:focus-within{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-90:focus-within{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-95:focus-within{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-opacity-100:focus-within{
    opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-0:hover{
    opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-5:hover{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-10:hover{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-20:hover{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-25:hover{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-30:hover{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-40:hover{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-50:hover{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-60:hover{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-70:hover{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-75:hover{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-80:hover{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-90:hover{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-95:hover{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-opacity-100:hover{
    opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-0:focus{
    opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-5:focus{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-10:focus{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-20:focus{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-25:focus{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-30:focus{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-40:focus{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-50:focus{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-60:focus{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-70:focus{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-75:focus{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-80:focus{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-90:focus{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-95:focus{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-opacity-100:focus{
    opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-normal{
    background-blend-mode: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-multiply{
    background-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-screen{
    background-blend-mode: screen
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-overlay{
    background-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-darken{
    background-blend-mode: darken
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-lighten{
    background-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-color-dodge{
    background-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-color-burn{
    background-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-hard-light{
    background-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-soft-light{
    background-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-difference{
    background-blend-mode: difference
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-exclusion{
    background-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-hue{
    background-blend-mode: hue
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-saturation{
    background-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-color{
    background-blend-mode: color
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-bg-blend-luminosity{
    background-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-normal{
    mix-blend-mode: normal
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-multiply{
    mix-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-screen{
    mix-blend-mode: screen
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-overlay{
    mix-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-darken{
    mix-blend-mode: darken
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-lighten{
    mix-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-color-dodge{
    mix-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-color-burn{
    mix-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-hard-light{
    mix-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-soft-light{
    mix-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-difference{
    mix-blend-mode: difference
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-exclusion{
    mix-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-hue{
    mix-blend-mode: hue
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-saturation{
    mix-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-color{
    mix-blend-mode: color
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-mix-blend-luminosity{
    mix-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .xl\:group-hover\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:hover\:skindr-widget-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-outline-white:focus-within{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-outline-black:focus-within{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-inset{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-inset:focus-within{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-inset:focus{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-transparent{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-current{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-red-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-yellow-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-green-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-indigo-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-purple-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-pink-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-transparent:focus-within{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-current:focus-within{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-red-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-green-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-purple-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-pink-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-transparent:focus{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-current:focus{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-red-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-yellow-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-green-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-indigo-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-purple-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-pink-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-0{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-5{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-10{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-20{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-25{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-30{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-40{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-50{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-60{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-70{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-75{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-80{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-90{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-95{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-opacity-100{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-0:focus{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-opacity-100:focus{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-0{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-1{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-2{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-4{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-8{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-0:focus{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-1:focus{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-2:focus{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-4:focus{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-8:focus{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-transparent{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-current{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-black{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-white{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-700{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-gray-900{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-400{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-400{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-500{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-600{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-700{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-800{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ring-offset-pink-900{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .xl\:focus-within\:skindr-widget-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-black:focus{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-white:focus{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .xl\:focus\:skindr-widget-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-filter-none{
    filter: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-0{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-none{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-sm{
    --tw-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur{
    --tw-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-md{
    --tw-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-lg{
    --tw-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-xl{
    --tw-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-2xl{
    --tw-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-blur-3xl{
    --tw-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-0{
    --tw-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-50{
    --tw-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-75{
    --tw-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-90{
    --tw-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-95{
    --tw-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-100{
    --tw-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-105{
    --tw-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-110{
    --tw-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-125{
    --tw-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-150{
    --tw-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-brightness-200{
    --tw-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-0{
    --tw-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-50{
    --tw-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-75{
    --tw-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-100{
    --tw-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-125{
    --tw-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-150{
    --tw-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-contrast-200{
    --tw-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grayscale-0{
    --tw-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-grayscale{
    --tw-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-invert-0{
    --tw-invert: invert(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-invert{
    --tw-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-saturate-0{
    --tw-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-saturate-50{
    --tw-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-saturate-100{
    --tw-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-saturate-150{
    --tw-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-saturate-200{
    --tw-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-sepia-0{
    --tw-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-sepia{
    --tw-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-filter-none{
    -webkit-backdrop-filter: none;
            backdrop-filter: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-0{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-none{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur{
    --tw-backdrop-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-invert-0{
    --tw-backdrop-invert: invert(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-invert{
    --tw-backdrop-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition-none{
    transition-property: none
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition-shadow{
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-75{
    transition-delay: 75ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-100{
    transition-delay: 100ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-150{
    transition-delay: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-200{
    transition-delay: 200ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-300{
    transition-delay: 300ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-500{
    transition-delay: 500ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-700{
    transition-delay: 700ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-delay-1000{
    transition-delay: 1000ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-75{
    transition-duration: 75ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-100{
    transition-duration: 100ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-150{
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-200{
    transition-duration: 200ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-300{
    transition-duration: 300ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-500{
    transition-duration: 500ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-700{
    transition-duration: 700ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-duration-1000{
    transition-duration: 1000ms
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ease-linear{
    transition-timing-function: linear
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  #skindrWidget.skindrWidget .xl\:skindr-widget-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }
}

@media (min-width: 1536px){
  .\32xl\:skindr-widget-container{
    width: 100%
  }

  @media (min-width: 640px){
    .\32xl\:skindr-widget-container{
      max-width: 640px
    }
  }

  @media (min-width: 768px){
    .\32xl\:skindr-widget-container{
      max-width: 768px
    }
  }

  @media (min-width: 1024px){
    .\32xl\:skindr-widget-container{
      max-width: 1024px
    }
  }

  @media (min-width: 1280px){
    .\32xl\:skindr-widget-container{
      max-width: 1280px
    }
  }

  @media (min-width: 1536px){
    .\32xl\:skindr-widget-container{
      max-width: 1536px
    }
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-not-sr-only{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-sr-only:focus-within{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-not-sr-only:focus-within{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-sr-only:focus{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-not-sr-only:focus{
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pointer-events-none{
    pointer-events: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pointer-events-auto{
    pointer-events: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-visible{
    visibility: visible
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-invisible{
    visibility: hidden
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-static{
    position: static
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-fixed{
    position: fixed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-absolute{
    position: absolute
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-relative{
    position: relative
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-sticky{
    position: sticky
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-1{
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-2{
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-3{
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-4{
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-5{
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-6{
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-7{
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-8{
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-9{
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-10{
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-11{
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-12{
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-14{
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-16{
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-20{
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-24{
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-28{
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-32{
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-36{
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-40{
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-44{
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-48{
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-52{
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-56{
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-60{
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-64{
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-72{
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-80{
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-96{
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-px{
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-0\.5{
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-1\.5{
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-2\.5{
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-3\.5{
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-0{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-1{
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-2{
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-3{
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-4{
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-5{
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-6{
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-7{
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-8{
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-9{
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-10{
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-11{
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-12{
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-14{
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-16{
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-20{
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-24{
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-28{
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-32{
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-36{
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-40{
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-44{
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-48{
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-52{
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-56{
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-60{
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-64{
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-72{
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-80{
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-96{
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-px{
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-0\.5{
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-1\.5{
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-2\.5{
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-3\.5{
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-1\/2{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-1\/3{
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-2\/3{
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-1\/4{
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-2\/4{
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-3\/4{
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-full{
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-1\/2{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-1\/3{
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-2\/3{
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-1\/4{
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-2\/4{
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-3\/4{
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-full{
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-1{
    left: 0.25rem;
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-2{
    left: 0.5rem;
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-3{
    left: 0.75rem;
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-4{
    left: 1rem;
    right: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-5{
    left: 1.25rem;
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-6{
    left: 1.5rem;
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-7{
    left: 1.75rem;
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-8{
    left: 2rem;
    right: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-9{
    left: 2.25rem;
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-10{
    left: 2.5rem;
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-11{
    left: 2.75rem;
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-12{
    left: 3rem;
    right: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-14{
    left: 3.5rem;
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-16{
    left: 4rem;
    right: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-20{
    left: 5rem;
    right: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-24{
    left: 6rem;
    right: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-28{
    left: 7rem;
    right: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-32{
    left: 8rem;
    right: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-36{
    left: 9rem;
    right: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-40{
    left: 10rem;
    right: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-44{
    left: 11rem;
    right: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-48{
    left: 12rem;
    right: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-52{
    left: 13rem;
    right: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-56{
    left: 14rem;
    right: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-60{
    left: 15rem;
    right: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-64{
    left: 16rem;
    right: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-72{
    left: 18rem;
    right: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-80{
    left: 20rem;
    right: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-96{
    left: 24rem;
    right: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-auto{
    left: auto;
    right: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-px{
    left: 1px;
    right: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-0\.5{
    left: 0.125rem;
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-1\.5{
    left: 0.375rem;
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-2\.5{
    left: 0.625rem;
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-3\.5{
    left: 0.875rem;
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-0{
    left: 0px;
    right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-1{
    left: -0.25rem;
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-2{
    left: -0.5rem;
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-3{
    left: -0.75rem;
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-4{
    left: -1rem;
    right: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-5{
    left: -1.25rem;
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-6{
    left: -1.5rem;
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-7{
    left: -1.75rem;
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-8{
    left: -2rem;
    right: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-9{
    left: -2.25rem;
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-10{
    left: -2.5rem;
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-11{
    left: -2.75rem;
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-12{
    left: -3rem;
    right: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-14{
    left: -3.5rem;
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-16{
    left: -4rem;
    right: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-20{
    left: -5rem;
    right: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-24{
    left: -6rem;
    right: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-28{
    left: -7rem;
    right: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-32{
    left: -8rem;
    right: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-36{
    left: -9rem;
    right: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-40{
    left: -10rem;
    right: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-44{
    left: -11rem;
    right: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-48{
    left: -12rem;
    right: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-52{
    left: -13rem;
    right: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-56{
    left: -14rem;
    right: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-60{
    left: -15rem;
    right: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-64{
    left: -16rem;
    right: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-72{
    left: -18rem;
    right: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-80{
    left: -20rem;
    right: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-96{
    left: -24rem;
    right: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-px{
    left: -1px;
    right: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-0\.5{
    left: -0.125rem;
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-1\.5{
    left: -0.375rem;
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-2\.5{
    left: -0.625rem;
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-3\.5{
    left: -0.875rem;
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-1\/2{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-1\/3{
    left: 33.333333%;
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-2\/3{
    left: 66.666667%;
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-1\/4{
    left: 25%;
    right: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-2\/4{
    left: 50%;
    right: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-3\/4{
    left: 75%;
    right: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-x-full{
    left: 100%;
    right: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-1\/2{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-1\/3{
    left: -33.333333%;
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-2\/3{
    left: -66.666667%;
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-1\/4{
    left: -25%;
    right: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-2\/4{
    left: -50%;
    right: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-3\/4{
    left: -75%;
    right: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-x-full{
    left: -100%;
    right: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-1{
    top: 0.25rem;
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-2{
    top: 0.5rem;
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-3{
    top: 0.75rem;
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-4{
    top: 1rem;
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-5{
    top: 1.25rem;
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-6{
    top: 1.5rem;
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-7{
    top: 1.75rem;
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-8{
    top: 2rem;
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-9{
    top: 2.25rem;
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-10{
    top: 2.5rem;
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-11{
    top: 2.75rem;
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-12{
    top: 3rem;
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-14{
    top: 3.5rem;
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-16{
    top: 4rem;
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-20{
    top: 5rem;
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-24{
    top: 6rem;
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-28{
    top: 7rem;
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-32{
    top: 8rem;
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-36{
    top: 9rem;
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-40{
    top: 10rem;
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-44{
    top: 11rem;
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-48{
    top: 12rem;
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-52{
    top: 13rem;
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-56{
    top: 14rem;
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-60{
    top: 15rem;
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-64{
    top: 16rem;
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-72{
    top: 18rem;
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-80{
    top: 20rem;
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-96{
    top: 24rem;
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-auto{
    top: auto;
    bottom: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-px{
    top: 1px;
    bottom: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-0\.5{
    top: 0.125rem;
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-1\.5{
    top: 0.375rem;
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-2\.5{
    top: 0.625rem;
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-3\.5{
    top: 0.875rem;
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-0{
    top: 0px;
    bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-1{
    top: -0.25rem;
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-2{
    top: -0.5rem;
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-3{
    top: -0.75rem;
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-4{
    top: -1rem;
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-5{
    top: -1.25rem;
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-6{
    top: -1.5rem;
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-7{
    top: -1.75rem;
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-8{
    top: -2rem;
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-9{
    top: -2.25rem;
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-10{
    top: -2.5rem;
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-11{
    top: -2.75rem;
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-12{
    top: -3rem;
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-14{
    top: -3.5rem;
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-16{
    top: -4rem;
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-20{
    top: -5rem;
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-24{
    top: -6rem;
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-28{
    top: -7rem;
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-32{
    top: -8rem;
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-36{
    top: -9rem;
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-40{
    top: -10rem;
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-44{
    top: -11rem;
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-48{
    top: -12rem;
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-52{
    top: -13rem;
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-56{
    top: -14rem;
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-60{
    top: -15rem;
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-64{
    top: -16rem;
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-72{
    top: -18rem;
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-80{
    top: -20rem;
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-96{
    top: -24rem;
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-px{
    top: -1px;
    bottom: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-0\.5{
    top: -0.125rem;
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-1\.5{
    top: -0.375rem;
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-2\.5{
    top: -0.625rem;
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-3\.5{
    top: -0.875rem;
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-1\/2{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-1\/3{
    top: 33.333333%;
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-2\/3{
    top: 66.666667%;
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-1\/4{
    top: 25%;
    bottom: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-2\/4{
    top: 50%;
    bottom: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-3\/4{
    top: 75%;
    bottom: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inset-y-full{
    top: 100%;
    bottom: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-1\/2{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-1\/3{
    top: -33.333333%;
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-2\/3{
    top: -66.666667%;
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-1\/4{
    top: -25%;
    bottom: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-2\/4{
    top: -50%;
    bottom: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-3\/4{
    top: -75%;
    bottom: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--inset-y-full{
    top: -100%;
    bottom: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-1{
    top: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-2{
    top: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-3{
    top: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-4{
    top: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-5{
    top: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-6{
    top: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-7{
    top: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-8{
    top: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-9{
    top: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-10{
    top: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-11{
    top: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-12{
    top: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-14{
    top: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-16{
    top: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-20{
    top: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-24{
    top: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-28{
    top: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-32{
    top: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-36{
    top: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-40{
    top: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-44{
    top: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-48{
    top: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-52{
    top: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-56{
    top: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-60{
    top: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-64{
    top: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-72{
    top: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-80{
    top: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-96{
    top: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-auto{
    top: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-px{
    top: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-0\.5{
    top: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-1\.5{
    top: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-2\.5{
    top: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-3\.5{
    top: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-0{
    top: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-1{
    top: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-2{
    top: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-3{
    top: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-4{
    top: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-5{
    top: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-6{
    top: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-7{
    top: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-8{
    top: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-9{
    top: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-10{
    top: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-11{
    top: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-12{
    top: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-14{
    top: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-16{
    top: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-20{
    top: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-24{
    top: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-28{
    top: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-32{
    top: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-36{
    top: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-40{
    top: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-44{
    top: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-48{
    top: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-52{
    top: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-56{
    top: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-60{
    top: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-64{
    top: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-72{
    top: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-80{
    top: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-96{
    top: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-px{
    top: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-0\.5{
    top: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-1\.5{
    top: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-2\.5{
    top: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-3\.5{
    top: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-1\/2{
    top: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-1\/3{
    top: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-2\/3{
    top: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-1\/4{
    top: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-2\/4{
    top: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-3\/4{
    top: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-top-full{
    top: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-1\/2{
    top: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-1\/3{
    top: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-2\/3{
    top: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-1\/4{
    top: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-2\/4{
    top: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-3\/4{
    top: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--top-full{
    top: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-1{
    right: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-2{
    right: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-3{
    right: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-4{
    right: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-5{
    right: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-6{
    right: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-7{
    right: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-8{
    right: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-9{
    right: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-10{
    right: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-11{
    right: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-12{
    right: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-14{
    right: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-16{
    right: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-20{
    right: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-24{
    right: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-28{
    right: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-32{
    right: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-36{
    right: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-40{
    right: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-44{
    right: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-48{
    right: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-52{
    right: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-56{
    right: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-60{
    right: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-64{
    right: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-72{
    right: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-80{
    right: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-96{
    right: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-auto{
    right: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-px{
    right: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-0\.5{
    right: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-1\.5{
    right: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-2\.5{
    right: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-3\.5{
    right: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-0{
    right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-1{
    right: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-2{
    right: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-3{
    right: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-4{
    right: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-5{
    right: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-6{
    right: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-7{
    right: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-8{
    right: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-9{
    right: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-10{
    right: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-11{
    right: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-12{
    right: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-14{
    right: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-16{
    right: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-20{
    right: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-24{
    right: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-28{
    right: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-32{
    right: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-36{
    right: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-40{
    right: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-44{
    right: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-48{
    right: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-52{
    right: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-56{
    right: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-60{
    right: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-64{
    right: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-72{
    right: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-80{
    right: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-96{
    right: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-px{
    right: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-0\.5{
    right: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-1\.5{
    right: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-2\.5{
    right: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-3\.5{
    right: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-1\/2{
    right: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-1\/3{
    right: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-2\/3{
    right: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-1\/4{
    right: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-2\/4{
    right: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-3\/4{
    right: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-right-full{
    right: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-1\/2{
    right: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-1\/3{
    right: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-2\/3{
    right: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-1\/4{
    right: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-2\/4{
    right: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-3\/4{
    right: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--right-full{
    right: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-1{
    bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-2{
    bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-3{
    bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-4{
    bottom: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-5{
    bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-6{
    bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-7{
    bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-8{
    bottom: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-9{
    bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-10{
    bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-11{
    bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-12{
    bottom: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-14{
    bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-16{
    bottom: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-20{
    bottom: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-24{
    bottom: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-28{
    bottom: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-32{
    bottom: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-36{
    bottom: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-40{
    bottom: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-44{
    bottom: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-48{
    bottom: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-52{
    bottom: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-56{
    bottom: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-60{
    bottom: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-64{
    bottom: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-72{
    bottom: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-80{
    bottom: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-96{
    bottom: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-auto{
    bottom: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-px{
    bottom: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-0\.5{
    bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-1\.5{
    bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-2\.5{
    bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-3\.5{
    bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-0{
    bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-1{
    bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-2{
    bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-3{
    bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-4{
    bottom: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-5{
    bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-6{
    bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-7{
    bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-8{
    bottom: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-9{
    bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-10{
    bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-11{
    bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-12{
    bottom: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-14{
    bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-16{
    bottom: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-20{
    bottom: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-24{
    bottom: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-28{
    bottom: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-32{
    bottom: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-36{
    bottom: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-40{
    bottom: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-44{
    bottom: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-48{
    bottom: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-52{
    bottom: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-56{
    bottom: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-60{
    bottom: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-64{
    bottom: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-72{
    bottom: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-80{
    bottom: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-96{
    bottom: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-px{
    bottom: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-0\.5{
    bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-1\.5{
    bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-2\.5{
    bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-3\.5{
    bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-1\/2{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-1\/3{
    bottom: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-2\/3{
    bottom: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-1\/4{
    bottom: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-2\/4{
    bottom: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-3\/4{
    bottom: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bottom-full{
    bottom: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-1\/2{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-1\/3{
    bottom: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-2\/3{
    bottom: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-1\/4{
    bottom: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-2\/4{
    bottom: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-3\/4{
    bottom: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--bottom-full{
    bottom: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-1{
    left: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-2{
    left: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-3{
    left: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-4{
    left: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-5{
    left: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-6{
    left: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-7{
    left: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-8{
    left: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-9{
    left: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-10{
    left: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-11{
    left: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-12{
    left: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-14{
    left: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-16{
    left: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-20{
    left: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-24{
    left: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-28{
    left: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-32{
    left: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-36{
    left: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-40{
    left: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-44{
    left: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-48{
    left: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-52{
    left: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-56{
    left: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-60{
    left: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-64{
    left: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-72{
    left: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-80{
    left: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-96{
    left: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-auto{
    left: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-px{
    left: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-0\.5{
    left: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-1\.5{
    left: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-2\.5{
    left: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-3\.5{
    left: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-0{
    left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-1{
    left: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-2{
    left: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-3{
    left: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-4{
    left: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-5{
    left: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-6{
    left: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-7{
    left: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-8{
    left: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-9{
    left: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-10{
    left: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-11{
    left: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-12{
    left: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-14{
    left: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-16{
    left: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-20{
    left: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-24{
    left: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-28{
    left: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-32{
    left: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-36{
    left: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-40{
    left: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-44{
    left: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-48{
    left: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-52{
    left: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-56{
    left: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-60{
    left: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-64{
    left: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-72{
    left: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-80{
    left: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-96{
    left: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-px{
    left: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-0\.5{
    left: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-1\.5{
    left: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-2\.5{
    left: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-3\.5{
    left: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-1\/2{
    left: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-1\/3{
    left: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-2\/3{
    left: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-1\/4{
    left: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-2\/4{
    left: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-3\/4{
    left: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-left-full{
    left: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-1\/2{
    left: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-1\/3{
    left: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-2\/3{
    left: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-1\/4{
    left: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-2\/4{
    left: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-3\/4{
    left: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--left-full{
    left: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-isolate{
    isolation: isolate
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-isolation-auto{
    isolation: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-0{
    z-index: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-10{
    z-index: 10
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-20{
    z-index: 20
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-30{
    z-index: 30
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-40{
    z-index: 40
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-50{
    z-index: 50
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-z-auto{
    z-index: auto
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-0:focus-within{
    z-index: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-10:focus-within{
    z-index: 10
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-20:focus-within{
    z-index: 20
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-30:focus-within{
    z-index: 30
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-40:focus-within{
    z-index: 40
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-50:focus-within{
    z-index: 50
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-z-auto:focus-within{
    z-index: auto
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-0:focus{
    z-index: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-10:focus{
    z-index: 10
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-20:focus{
    z-index: 20
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-30:focus{
    z-index: 30
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-40:focus{
    z-index: 40
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-50:focus{
    z-index: 50
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-z-auto:focus{
    z-index: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-1{
    order: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-2{
    order: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-3{
    order: 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-4{
    order: 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-5{
    order: 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-6{
    order: 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-7{
    order: 7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-8{
    order: 8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-9{
    order: 9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-10{
    order: 10
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-11{
    order: 11
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-12{
    order: 12
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-first{
    order: -9999
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-last{
    order: 9999
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-order-none{
    order: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-auto{
    grid-column: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-1{
    grid-column: span 1 / span 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-2{
    grid-column: span 2 / span 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-3{
    grid-column: span 3 / span 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-4{
    grid-column: span 4 / span 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-5{
    grid-column: span 5 / span 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-6{
    grid-column: span 6 / span 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-7{
    grid-column: span 7 / span 7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-8{
    grid-column: span 8 / span 8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-9{
    grid-column: span 9 / span 9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-10{
    grid-column: span 10 / span 10
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-11{
    grid-column: span 11 / span 11
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-12{
    grid-column: span 12 / span 12
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-span-full{
    grid-column: 1 / -1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-1{
    grid-column-start: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-2{
    grid-column-start: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-3{
    grid-column-start: 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-4{
    grid-column-start: 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-5{
    grid-column-start: 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-6{
    grid-column-start: 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-7{
    grid-column-start: 7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-8{
    grid-column-start: 8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-9{
    grid-column-start: 9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-10{
    grid-column-start: 10
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-11{
    grid-column-start: 11
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-12{
    grid-column-start: 12
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-13{
    grid-column-start: 13
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-start-auto{
    grid-column-start: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-1{
    grid-column-end: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-2{
    grid-column-end: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-3{
    grid-column-end: 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-4{
    grid-column-end: 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-5{
    grid-column-end: 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-6{
    grid-column-end: 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-7{
    grid-column-end: 7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-8{
    grid-column-end: 8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-9{
    grid-column-end: 9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-10{
    grid-column-end: 10
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-11{
    grid-column-end: 11
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-12{
    grid-column-end: 12
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-13{
    grid-column-end: 13
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-col-end-auto{
    grid-column-end: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-auto{
    grid-row: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-1{
    grid-row: span 1 / span 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-2{
    grid-row: span 2 / span 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-3{
    grid-row: span 3 / span 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-4{
    grid-row: span 4 / span 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-5{
    grid-row: span 5 / span 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-6{
    grid-row: span 6 / span 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-span-full{
    grid-row: 1 / -1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-1{
    grid-row-start: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-2{
    grid-row-start: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-3{
    grid-row-start: 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-4{
    grid-row-start: 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-5{
    grid-row-start: 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-6{
    grid-row-start: 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-7{
    grid-row-start: 7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-start-auto{
    grid-row-start: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-1{
    grid-row-end: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-2{
    grid-row-end: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-3{
    grid-row-end: 3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-4{
    grid-row-end: 4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-5{
    grid-row-end: 5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-6{
    grid-row-end: 6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-7{
    grid-row-end: 7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-row-end-auto{
    grid-row-end: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-float-right{
    float: right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-float-left{
    float: left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-float-none{
    float: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-clear-left{
    clear: left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-clear-right{
    clear: right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-clear-both{
    clear: both
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-clear-none{
    clear: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-1{
    margin: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-2{
    margin: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-3{
    margin: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-4{
    margin: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-5{
    margin: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-6{
    margin: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-7{
    margin: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-8{
    margin: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-9{
    margin: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-10{
    margin: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-11{
    margin: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-12{
    margin: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-14{
    margin: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-16{
    margin: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-20{
    margin: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-24{
    margin: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-28{
    margin: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-32{
    margin: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-36{
    margin: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-40{
    margin: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-44{
    margin: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-48{
    margin: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-52{
    margin: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-56{
    margin: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-60{
    margin: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-64{
    margin: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-72{
    margin: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-80{
    margin: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-96{
    margin: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-auto{
    margin: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-px{
    margin: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-0\.5{
    margin: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-1\.5{
    margin: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-2\.5{
    margin: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-m-3\.5{
    margin: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-0{
    margin: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-1{
    margin: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-2{
    margin: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-3{
    margin: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-4{
    margin: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-5{
    margin: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-6{
    margin: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-7{
    margin: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-8{
    margin: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-9{
    margin: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-10{
    margin: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-11{
    margin: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-12{
    margin: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-14{
    margin: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-16{
    margin: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-20{
    margin: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-24{
    margin: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-28{
    margin: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-32{
    margin: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-36{
    margin: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-40{
    margin: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-44{
    margin: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-48{
    margin: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-52{
    margin: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-56{
    margin: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-60{
    margin: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-64{
    margin: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-72{
    margin: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-80{
    margin: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-96{
    margin: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-px{
    margin: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-0\.5{
    margin: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-1\.5{
    margin: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-2\.5{
    margin: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--m-3\.5{
    margin: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-2{
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-4{
    margin-left: 1rem;
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-6{
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-7{
    margin-left: 1.75rem;
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-8{
    margin-left: 2rem;
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-9{
    margin-left: 2.25rem;
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-10{
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-11{
    margin-left: 2.75rem;
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-12{
    margin-left: 3rem;
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-14{
    margin-left: 3.5rem;
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-16{
    margin-left: 4rem;
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-20{
    margin-left: 5rem;
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-24{
    margin-left: 6rem;
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-28{
    margin-left: 7rem;
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-32{
    margin-left: 8rem;
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-36{
    margin-left: 9rem;
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-40{
    margin-left: 10rem;
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-44{
    margin-left: 11rem;
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-48{
    margin-left: 12rem;
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-52{
    margin-left: 13rem;
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-56{
    margin-left: 14rem;
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-60{
    margin-left: 15rem;
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-64{
    margin-left: 16rem;
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-72{
    margin-left: 18rem;
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-80{
    margin-left: 20rem;
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-96{
    margin-left: 24rem;
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-auto{
    margin-left: auto;
    margin-right: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-px{
    margin-left: 1px;
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-0\.5{
    margin-left: 0.125rem;
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-1\.5{
    margin-left: 0.375rem;
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-2\.5{
    margin-left: 0.625rem;
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mx-3\.5{
    margin-left: 0.875rem;
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-0{
    margin-left: 0px;
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-2{
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-3{
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-4{
    margin-left: -1rem;
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-5{
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-6{
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-7{
    margin-left: -1.75rem;
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-8{
    margin-left: -2rem;
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-9{
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-10{
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-11{
    margin-left: -2.75rem;
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-12{
    margin-left: -3rem;
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-14{
    margin-left: -3.5rem;
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-16{
    margin-left: -4rem;
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-20{
    margin-left: -5rem;
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-24{
    margin-left: -6rem;
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-28{
    margin-left: -7rem;
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-32{
    margin-left: -8rem;
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-36{
    margin-left: -9rem;
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-40{
    margin-left: -10rem;
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-44{
    margin-left: -11rem;
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-48{
    margin-left: -12rem;
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-52{
    margin-left: -13rem;
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-56{
    margin-left: -14rem;
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-60{
    margin-left: -15rem;
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-64{
    margin-left: -16rem;
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-72{
    margin-left: -18rem;
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-80{
    margin-left: -20rem;
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-96{
    margin-left: -24rem;
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-px{
    margin-left: -1px;
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-0\.5{
    margin-left: -0.125rem;
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-1\.5{
    margin-left: -0.375rem;
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-2\.5{
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mx-3\.5{
    margin-left: -0.875rem;
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-4{
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-5{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-7{
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-8{
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-9{
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-11{
    margin-top: 2.75rem;
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-12{
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-14{
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-16{
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-20{
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-24{
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-28{
    margin-top: 7rem;
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-32{
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-36{
    margin-top: 9rem;
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-40{
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-44{
    margin-top: 11rem;
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-48{
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-52{
    margin-top: 13rem;
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-56{
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-60{
    margin-top: 15rem;
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-64{
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-72{
    margin-top: 18rem;
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-80{
    margin-top: 20rem;
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-96{
    margin-top: 24rem;
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-auto{
    margin-top: auto;
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-px{
    margin-top: 1px;
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-0\.5{
    margin-top: 0.125rem;
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-1\.5{
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-2\.5{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-my-3\.5{
    margin-top: 0.875rem;
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-0{
    margin-top: 0px;
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-1{
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-2{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-3{
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-4{
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-5{
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-6{
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-7{
    margin-top: -1.75rem;
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-8{
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-9{
    margin-top: -2.25rem;
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-10{
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-11{
    margin-top: -2.75rem;
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-12{
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-14{
    margin-top: -3.5rem;
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-16{
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-20{
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-24{
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-28{
    margin-top: -7rem;
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-32{
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-36{
    margin-top: -9rem;
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-40{
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-44{
    margin-top: -11rem;
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-48{
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-52{
    margin-top: -13rem;
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-56{
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-60{
    margin-top: -15rem;
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-64{
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-72{
    margin-top: -18rem;
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-80{
    margin-top: -20rem;
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-96{
    margin-top: -24rem;
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-px{
    margin-top: -1px;
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-0\.5{
    margin-top: -0.125rem;
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-2\.5{
    margin-top: -0.625rem;
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--my-3\.5{
    margin-top: -0.875rem;
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-1{
    margin-top: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-2{
    margin-top: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-3{
    margin-top: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-4{
    margin-top: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-5{
    margin-top: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-6{
    margin-top: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-7{
    margin-top: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-8{
    margin-top: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-9{
    margin-top: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-10{
    margin-top: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-11{
    margin-top: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-12{
    margin-top: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-14{
    margin-top: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-16{
    margin-top: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-20{
    margin-top: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-24{
    margin-top: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-28{
    margin-top: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-32{
    margin-top: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-36{
    margin-top: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-40{
    margin-top: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-44{
    margin-top: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-48{
    margin-top: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-52{
    margin-top: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-56{
    margin-top: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-60{
    margin-top: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-64{
    margin-top: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-72{
    margin-top: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-80{
    margin-top: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-96{
    margin-top: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-auto{
    margin-top: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-px{
    margin-top: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-0\.5{
    margin-top: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-1\.5{
    margin-top: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-2\.5{
    margin-top: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mt-3\.5{
    margin-top: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-0{
    margin-top: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-1{
    margin-top: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-2{
    margin-top: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-3{
    margin-top: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-4{
    margin-top: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-5{
    margin-top: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-6{
    margin-top: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-7{
    margin-top: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-8{
    margin-top: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-9{
    margin-top: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-10{
    margin-top: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-11{
    margin-top: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-12{
    margin-top: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-14{
    margin-top: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-16{
    margin-top: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-20{
    margin-top: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-24{
    margin-top: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-28{
    margin-top: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-32{
    margin-top: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-36{
    margin-top: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-40{
    margin-top: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-44{
    margin-top: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-48{
    margin-top: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-52{
    margin-top: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-56{
    margin-top: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-60{
    margin-top: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-64{
    margin-top: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-72{
    margin-top: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-80{
    margin-top: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-96{
    margin-top: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-px{
    margin-top: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-0\.5{
    margin-top: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-1\.5{
    margin-top: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-2\.5{
    margin-top: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mt-3\.5{
    margin-top: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-1{
    margin-right: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-2{
    margin-right: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-3{
    margin-right: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-4{
    margin-right: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-5{
    margin-right: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-6{
    margin-right: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-7{
    margin-right: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-8{
    margin-right: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-9{
    margin-right: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-10{
    margin-right: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-11{
    margin-right: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-12{
    margin-right: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-14{
    margin-right: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-16{
    margin-right: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-20{
    margin-right: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-24{
    margin-right: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-28{
    margin-right: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-32{
    margin-right: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-36{
    margin-right: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-40{
    margin-right: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-44{
    margin-right: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-48{
    margin-right: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-52{
    margin-right: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-56{
    margin-right: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-60{
    margin-right: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-64{
    margin-right: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-72{
    margin-right: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-80{
    margin-right: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-96{
    margin-right: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-auto{
    margin-right: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-px{
    margin-right: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-0\.5{
    margin-right: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-1\.5{
    margin-right: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-2\.5{
    margin-right: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mr-3\.5{
    margin-right: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-0{
    margin-right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-1{
    margin-right: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-2{
    margin-right: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-3{
    margin-right: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-4{
    margin-right: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-5{
    margin-right: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-6{
    margin-right: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-7{
    margin-right: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-8{
    margin-right: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-9{
    margin-right: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-10{
    margin-right: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-11{
    margin-right: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-12{
    margin-right: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-14{
    margin-right: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-16{
    margin-right: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-20{
    margin-right: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-24{
    margin-right: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-28{
    margin-right: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-32{
    margin-right: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-36{
    margin-right: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-40{
    margin-right: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-44{
    margin-right: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-48{
    margin-right: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-52{
    margin-right: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-56{
    margin-right: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-60{
    margin-right: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-64{
    margin-right: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-72{
    margin-right: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-80{
    margin-right: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-96{
    margin-right: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-px{
    margin-right: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-0\.5{
    margin-right: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-1\.5{
    margin-right: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-2\.5{
    margin-right: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mr-3\.5{
    margin-right: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-1{
    margin-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-2{
    margin-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-3{
    margin-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-4{
    margin-bottom: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-5{
    margin-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-6{
    margin-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-7{
    margin-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-8{
    margin-bottom: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-9{
    margin-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-10{
    margin-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-11{
    margin-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-12{
    margin-bottom: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-14{
    margin-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-16{
    margin-bottom: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-20{
    margin-bottom: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-24{
    margin-bottom: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-28{
    margin-bottom: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-32{
    margin-bottom: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-36{
    margin-bottom: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-40{
    margin-bottom: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-44{
    margin-bottom: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-48{
    margin-bottom: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-52{
    margin-bottom: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-56{
    margin-bottom: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-60{
    margin-bottom: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-64{
    margin-bottom: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-72{
    margin-bottom: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-80{
    margin-bottom: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-96{
    margin-bottom: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-auto{
    margin-bottom: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-px{
    margin-bottom: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-0\.5{
    margin-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-1\.5{
    margin-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-2\.5{
    margin-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mb-3\.5{
    margin-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-0{
    margin-bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-1{
    margin-bottom: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-2{
    margin-bottom: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-3{
    margin-bottom: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-4{
    margin-bottom: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-5{
    margin-bottom: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-6{
    margin-bottom: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-7{
    margin-bottom: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-8{
    margin-bottom: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-9{
    margin-bottom: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-10{
    margin-bottom: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-11{
    margin-bottom: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-12{
    margin-bottom: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-14{
    margin-bottom: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-16{
    margin-bottom: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-20{
    margin-bottom: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-24{
    margin-bottom: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-28{
    margin-bottom: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-32{
    margin-bottom: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-36{
    margin-bottom: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-40{
    margin-bottom: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-44{
    margin-bottom: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-48{
    margin-bottom: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-52{
    margin-bottom: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-56{
    margin-bottom: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-60{
    margin-bottom: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-64{
    margin-bottom: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-72{
    margin-bottom: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-80{
    margin-bottom: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-96{
    margin-bottom: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-px{
    margin-bottom: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-0\.5{
    margin-bottom: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-1\.5{
    margin-bottom: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-2\.5{
    margin-bottom: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--mb-3\.5{
    margin-bottom: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-1{
    margin-left: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-2{
    margin-left: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-3{
    margin-left: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-4{
    margin-left: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-5{
    margin-left: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-6{
    margin-left: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-7{
    margin-left: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-8{
    margin-left: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-9{
    margin-left: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-10{
    margin-left: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-11{
    margin-left: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-12{
    margin-left: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-14{
    margin-left: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-16{
    margin-left: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-20{
    margin-left: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-24{
    margin-left: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-28{
    margin-left: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-32{
    margin-left: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-36{
    margin-left: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-40{
    margin-left: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-44{
    margin-left: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-48{
    margin-left: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-52{
    margin-left: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-56{
    margin-left: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-60{
    margin-left: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-64{
    margin-left: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-72{
    margin-left: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-80{
    margin-left: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-96{
    margin-left: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-auto{
    margin-left: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-px{
    margin-left: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-0\.5{
    margin-left: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-1\.5{
    margin-left: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-2\.5{
    margin-left: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ml-3\.5{
    margin-left: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-0{
    margin-left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-1{
    margin-left: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-2{
    margin-left: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-3{
    margin-left: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-4{
    margin-left: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-5{
    margin-left: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-6{
    margin-left: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-7{
    margin-left: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-8{
    margin-left: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-9{
    margin-left: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-10{
    margin-left: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-11{
    margin-left: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-12{
    margin-left: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-14{
    margin-left: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-16{
    margin-left: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-20{
    margin-left: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-24{
    margin-left: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-28{
    margin-left: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-32{
    margin-left: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-36{
    margin-left: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-40{
    margin-left: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-44{
    margin-left: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-48{
    margin-left: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-52{
    margin-left: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-56{
    margin-left: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-60{
    margin-left: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-64{
    margin-left: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-72{
    margin-left: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-80{
    margin-left: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-96{
    margin-left: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-px{
    margin-left: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-0\.5{
    margin-left: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-1\.5{
    margin-left: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-2\.5{
    margin-left: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--ml-3\.5{
    margin-left: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-box-border{
    box-sizing: border-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-box-content{
    box-sizing: content-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-block{
    display: block
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inline-block{
    display: inline-block
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inline{
    display: inline
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex{
    display: flex
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inline-flex{
    display: inline-flex
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table{
    display: table
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inline-table{
    display: inline-table
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-caption{
    display: table-caption
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-cell{
    display: table-cell
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-column{
    display: table-column
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-column-group{
    display: table-column-group
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-footer-group{
    display: table-footer-group
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-header-group{
    display: table-header-group
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-row-group{
    display: table-row-group
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-row{
    display: table-row
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flow-root{
    display: flow-root
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid{
    display: grid
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-inline-grid{
    display: inline-grid
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contents{
    display: contents
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-list-item{
    display: list-item
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hidden{
    display: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-0{
    height: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1{
    height: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-2{
    height: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-3{
    height: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-4{
    height: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-5{
    height: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-6{
    height: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-7{
    height: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-8{
    height: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-9{
    height: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-10{
    height: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-11{
    height: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-12{
    height: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-14{
    height: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-16{
    height: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-20{
    height: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-24{
    height: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-28{
    height: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-32{
    height: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-36{
    height: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-40{
    height: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-44{
    height: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-48{
    height: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-52{
    height: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-56{
    height: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-60{
    height: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-64{
    height: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-72{
    height: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-80{
    height: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-96{
    height: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-auto{
    height: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-px{
    height: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-0\.5{
    height: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1\.5{
    height: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-2\.5{
    height: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-3\.5{
    height: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1\/2{
    height: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1\/3{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-2\/3{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1\/4{
    height: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-2\/4{
    height: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-3\/4{
    height: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1\/5{
    height: 20%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-2\/5{
    height: 40%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-3\/5{
    height: 60%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-4\/5{
    height: 80%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-1\/6{
    height: 16.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-2\/6{
    height: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-3\/6{
    height: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-4\/6{
    height: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-5\/6{
    height: 83.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-full{
    height: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-h-screen{
    height: 100vh
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-0{
    max-height: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-1{
    max-height: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-2{
    max-height: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-3{
    max-height: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-4{
    max-height: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-5{
    max-height: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-6{
    max-height: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-7{
    max-height: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-8{
    max-height: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-9{
    max-height: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-10{
    max-height: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-11{
    max-height: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-12{
    max-height: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-14{
    max-height: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-16{
    max-height: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-20{
    max-height: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-24{
    max-height: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-28{
    max-height: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-32{
    max-height: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-36{
    max-height: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-40{
    max-height: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-44{
    max-height: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-48{
    max-height: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-52{
    max-height: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-56{
    max-height: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-60{
    max-height: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-64{
    max-height: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-72{
    max-height: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-80{
    max-height: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-96{
    max-height: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-px{
    max-height: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-0\.5{
    max-height: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-1\.5{
    max-height: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-2\.5{
    max-height: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-3\.5{
    max-height: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-full{
    max-height: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-h-screen{
    max-height: 100vh
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-h-0{
    min-height: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-h-full{
    min-height: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-h-screen{
    min-height: 100vh
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-0{
    width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1{
    width: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2{
    width: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-3{
    width: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-4{
    width: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-5{
    width: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-6{
    width: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-7{
    width: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-8{
    width: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-9{
    width: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-10{
    width: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-11{
    width: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-12{
    width: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-14{
    width: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-16{
    width: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-20{
    width: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-24{
    width: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-28{
    width: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-32{
    width: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-36{
    width: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-40{
    width: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-44{
    width: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-48{
    width: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-52{
    width: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-56{
    width: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-60{
    width: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-64{
    width: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-72{
    width: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-80{
    width: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-96{
    width: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-auto{
    width: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-px{
    width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-0\.5{
    width: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\.5{
    width: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2\.5{
    width: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-3\.5{
    width: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\/2{
    width: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\/3{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2\/3{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\/4{
    width: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2\/4{
    width: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-3\/4{
    width: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\/5{
    width: 20%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2\/5{
    width: 40%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-3\/5{
    width: 60%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-4\/5{
    width: 80%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\/6{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2\/6{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-3\/6{
    width: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-4\/6{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-5\/6{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-1\/12{
    width: 8.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-2\/12{
    width: 16.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-3\/12{
    width: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-4\/12{
    width: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-5\/12{
    width: 41.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-6\/12{
    width: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-7\/12{
    width: 58.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-8\/12{
    width: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-9\/12{
    width: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-10\/12{
    width: 83.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-11\/12{
    width: 91.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-full{
    width: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-screen{
    width: 100vw
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-min{
    width: min-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-w-max{
    width: max-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-w-0{
    min-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-w-full{
    min-width: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-w-min{
    min-width: min-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-min-w-max{
    min-width: max-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-0{
    max-width: 0rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-none{
    max-width: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-xs{
    max-width: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-sm{
    max-width: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-md{
    max-width: 28rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-lg{
    max-width: 32rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-xl{
    max-width: 36rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-2xl{
    max-width: 42rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-3xl{
    max-width: 48rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-4xl{
    max-width: 56rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-5xl{
    max-width: 64rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-6xl{
    max-width: 72rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-7xl{
    max-width: 80rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-full{
    max-width: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-min{
    max-width: min-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-max{
    max-width: max-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-prose{
    max-width: 65ch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-screen-sm{
    max-width: 640px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-screen-md{
    max-width: 768px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-screen-lg{
    max-width: 1024px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-screen-xl{
    max-width: 1280px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-max-w-screen-2xl{
    max-width: 1536px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-1{
    flex: 1 1 0%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-auto{
    flex: 1 1 auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-initial{
    flex: 0 1 auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-none{
    flex: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-shrink-0{
    flex-shrink: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-shrink{
    flex-shrink: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-grow-0{
    flex-grow: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-grow{
    flex-grow: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-auto{
    table-layout: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-table-fixed{
    table-layout: fixed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-collapse{
    border-collapse: collapse
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-separate{
    border-collapse: separate
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-center{
    transform-origin: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-top{
    transform-origin: top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-top-right{
    transform-origin: top right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-right{
    transform-origin: right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-bottom-right{
    transform-origin: bottom right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-bottom{
    transform-origin: bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-bottom-left{
    transform-origin: bottom left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-left{
    transform-origin: left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-origin-top-left{
    transform-origin: top left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transform{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transform-gpu{
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transform-none{
    transform: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-1{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-2{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-3{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-4{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-5{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-6{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-7{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-8{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-9{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-10{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-11{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-12{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-14{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-16{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-20{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-24{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-28{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-32{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-36{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-40{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-44{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-48{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-52{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-56{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-60{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-64{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-72{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-80{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-96{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-px{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-0\.5{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-1\.5{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-2\.5{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-3\.5{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-0{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-1{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-2{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-3{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-4{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-5{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-6{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-7{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-8{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-9{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-10{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-11{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-12{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-14{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-16{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-20{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-24{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-28{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-32{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-36{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-40{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-44{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-48{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-52{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-56{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-60{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-64{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-72{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-80{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-96{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-px{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-0\.5{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-1\.5{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-2\.5{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-3\.5{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-1\/2{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-1\/3{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-2\/3{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-1\/4{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-2\/4{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-3\/4{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-x-full{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-1\/2{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-1\/3{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-2\/3{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-1\/4{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-2\/4{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-3\/4{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-x-full{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-1{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-2{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-3{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-4{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-5{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-6{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-7{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-8{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-9{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-10{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-11{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-12{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-14{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-16{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-20{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-24{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-28{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-32{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-36{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-40{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-44{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-48{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-52{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-56{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-60{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-64{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-72{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-80{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-96{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-px{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-0\.5{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-1\.5{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-2\.5{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-3\.5{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-0{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-1{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-2{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-3{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-4{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-5{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-6{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-7{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-8{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-9{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-10{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-11{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-12{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-14{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-16{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-20{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-24{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-28{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-32{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-36{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-40{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-44{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-48{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-52{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-56{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-60{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-64{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-72{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-80{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-96{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-px{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-0\.5{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-1\.5{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-2\.5{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-3\.5{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-1\/2{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-1\/3{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-2\/3{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-1\/4{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-2\/4{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-3\/4{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-translate-y-full{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-1\/2{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-1\/3{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-2\/3{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-1\/4{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-2\/4{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-3\/4{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--translate-y-full{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-1:hover{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-2:hover{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-3:hover{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-4:hover{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-5:hover{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-6:hover{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-7:hover{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-8:hover{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-9:hover{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-10:hover{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-11:hover{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-12:hover{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-14:hover{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-16:hover{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-20:hover{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-24:hover{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-28:hover{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-32:hover{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-36:hover{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-40:hover{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-44:hover{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-48:hover{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-52:hover{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-56:hover{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-60:hover{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-64:hover{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-72:hover{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-80:hover{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-96:hover{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-px:hover{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-0:hover{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-1:hover{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-2:hover{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-3:hover{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-4:hover{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-5:hover{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-6:hover{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-7:hover{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-8:hover{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-9:hover{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-10:hover{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-11:hover{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-12:hover{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-14:hover{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-16:hover{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-20:hover{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-24:hover{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-28:hover{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-32:hover{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-36:hover{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-40:hover{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-44:hover{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-48:hover{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-52:hover{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-56:hover{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-60:hover{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-64:hover{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-72:hover{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-80:hover{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-96:hover{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-px:hover{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-1\/2:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-1\/4:hover{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-2\/4:hover{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-3\/4:hover{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-x-full:hover{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-1\/2:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-1\/4:hover{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-2\/4:hover{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-3\/4:hover{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-x-full:hover{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-1:hover{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-2:hover{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-3:hover{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-4:hover{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-5:hover{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-6:hover{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-7:hover{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-8:hover{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-9:hover{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-10:hover{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-11:hover{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-12:hover{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-14:hover{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-16:hover{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-20:hover{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-24:hover{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-28:hover{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-32:hover{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-36:hover{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-40:hover{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-44:hover{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-48:hover{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-52:hover{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-56:hover{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-60:hover{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-64:hover{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-72:hover{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-80:hover{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-96:hover{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-px:hover{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-0:hover{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-1:hover{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-2:hover{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-3:hover{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-4:hover{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-5:hover{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-6:hover{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-7:hover{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-8:hover{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-9:hover{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-10:hover{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-11:hover{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-12:hover{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-14:hover{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-16:hover{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-20:hover{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-24:hover{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-28:hover{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-32:hover{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-36:hover{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-40:hover{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-44:hover{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-48:hover{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-52:hover{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-56:hover{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-60:hover{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-64:hover{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-72:hover{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-80:hover{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-96:hover{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-px:hover{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-1\/2:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-1\/4:hover{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-2\/4:hover{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-3\/4:hover{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-translate-y-full:hover{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-1\/2:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-1\/4:hover{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-2\/4:hover{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-3\/4:hover{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--translate-y-full:hover{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-1:focus{
    --tw-translate-x: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-2:focus{
    --tw-translate-x: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-3:focus{
    --tw-translate-x: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-4:focus{
    --tw-translate-x: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-5:focus{
    --tw-translate-x: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-6:focus{
    --tw-translate-x: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-7:focus{
    --tw-translate-x: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-8:focus{
    --tw-translate-x: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-9:focus{
    --tw-translate-x: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-10:focus{
    --tw-translate-x: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-11:focus{
    --tw-translate-x: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-12:focus{
    --tw-translate-x: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-14:focus{
    --tw-translate-x: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-16:focus{
    --tw-translate-x: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-20:focus{
    --tw-translate-x: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-24:focus{
    --tw-translate-x: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-28:focus{
    --tw-translate-x: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-32:focus{
    --tw-translate-x: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-36:focus{
    --tw-translate-x: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-40:focus{
    --tw-translate-x: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-44:focus{
    --tw-translate-x: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-48:focus{
    --tw-translate-x: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-52:focus{
    --tw-translate-x: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-56:focus{
    --tw-translate-x: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-60:focus{
    --tw-translate-x: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-64:focus{
    --tw-translate-x: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-72:focus{
    --tw-translate-x: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-80:focus{
    --tw-translate-x: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-96:focus{
    --tw-translate-x: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-px:focus{
    --tw-translate-x: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-0:focus{
    --tw-translate-x: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-1:focus{
    --tw-translate-x: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-2:focus{
    --tw-translate-x: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-3:focus{
    --tw-translate-x: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-4:focus{
    --tw-translate-x: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-5:focus{
    --tw-translate-x: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-6:focus{
    --tw-translate-x: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-7:focus{
    --tw-translate-x: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-8:focus{
    --tw-translate-x: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-9:focus{
    --tw-translate-x: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-10:focus{
    --tw-translate-x: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-11:focus{
    --tw-translate-x: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-12:focus{
    --tw-translate-x: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-14:focus{
    --tw-translate-x: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-16:focus{
    --tw-translate-x: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-20:focus{
    --tw-translate-x: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-24:focus{
    --tw-translate-x: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-28:focus{
    --tw-translate-x: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-32:focus{
    --tw-translate-x: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-36:focus{
    --tw-translate-x: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-40:focus{
    --tw-translate-x: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-44:focus{
    --tw-translate-x: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-48:focus{
    --tw-translate-x: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-52:focus{
    --tw-translate-x: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-56:focus{
    --tw-translate-x: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-60:focus{
    --tw-translate-x: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-64:focus{
    --tw-translate-x: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-72:focus{
    --tw-translate-x: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-80:focus{
    --tw-translate-x: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-96:focus{
    --tw-translate-x: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-px:focus{
    --tw-translate-x: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-1\/2:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-1\/4:focus{
    --tw-translate-x: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-2\/4:focus{
    --tw-translate-x: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-3\/4:focus{
    --tw-translate-x: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-x-full:focus{
    --tw-translate-x: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-1\/2:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-1\/4:focus{
    --tw-translate-x: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-2\/4:focus{
    --tw-translate-x: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-3\/4:focus{
    --tw-translate-x: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-x-full:focus{
    --tw-translate-x: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-1:focus{
    --tw-translate-y: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-2:focus{
    --tw-translate-y: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-3:focus{
    --tw-translate-y: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-4:focus{
    --tw-translate-y: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-5:focus{
    --tw-translate-y: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-6:focus{
    --tw-translate-y: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-7:focus{
    --tw-translate-y: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-8:focus{
    --tw-translate-y: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-9:focus{
    --tw-translate-y: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-10:focus{
    --tw-translate-y: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-11:focus{
    --tw-translate-y: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-12:focus{
    --tw-translate-y: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-14:focus{
    --tw-translate-y: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-16:focus{
    --tw-translate-y: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-20:focus{
    --tw-translate-y: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-24:focus{
    --tw-translate-y: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-28:focus{
    --tw-translate-y: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-32:focus{
    --tw-translate-y: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-36:focus{
    --tw-translate-y: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-40:focus{
    --tw-translate-y: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-44:focus{
    --tw-translate-y: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-48:focus{
    --tw-translate-y: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-52:focus{
    --tw-translate-y: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-56:focus{
    --tw-translate-y: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-60:focus{
    --tw-translate-y: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-64:focus{
    --tw-translate-y: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-72:focus{
    --tw-translate-y: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-80:focus{
    --tw-translate-y: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-96:focus{
    --tw-translate-y: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-px:focus{
    --tw-translate-y: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-0:focus{
    --tw-translate-y: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-1:focus{
    --tw-translate-y: -0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-2:focus{
    --tw-translate-y: -0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-3:focus{
    --tw-translate-y: -0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-4:focus{
    --tw-translate-y: -1rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-5:focus{
    --tw-translate-y: -1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-6:focus{
    --tw-translate-y: -1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-7:focus{
    --tw-translate-y: -1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-8:focus{
    --tw-translate-y: -2rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-9:focus{
    --tw-translate-y: -2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-10:focus{
    --tw-translate-y: -2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-11:focus{
    --tw-translate-y: -2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-12:focus{
    --tw-translate-y: -3rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-14:focus{
    --tw-translate-y: -3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-16:focus{
    --tw-translate-y: -4rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-20:focus{
    --tw-translate-y: -5rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-24:focus{
    --tw-translate-y: -6rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-28:focus{
    --tw-translate-y: -7rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-32:focus{
    --tw-translate-y: -8rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-36:focus{
    --tw-translate-y: -9rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-40:focus{
    --tw-translate-y: -10rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-44:focus{
    --tw-translate-y: -11rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-48:focus{
    --tw-translate-y: -12rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-52:focus{
    --tw-translate-y: -13rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-56:focus{
    --tw-translate-y: -14rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-60:focus{
    --tw-translate-y: -15rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-64:focus{
    --tw-translate-y: -16rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-72:focus{
    --tw-translate-y: -18rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-80:focus{
    --tw-translate-y: -20rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-96:focus{
    --tw-translate-y: -24rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-px:focus{
    --tw-translate-y: -1px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-1\/2:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-1\/4:focus{
    --tw-translate-y: 25%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-2\/4:focus{
    --tw-translate-y: 50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-3\/4:focus{
    --tw-translate-y: 75%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-translate-y-full:focus{
    --tw-translate-y: 100%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-1\/2:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-1\/4:focus{
    --tw-translate-y: -25%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-2\/4:focus{
    --tw-translate-y: -50%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-3\/4:focus{
    --tw-translate-y: -75%
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--translate-y-full:focus{
    --tw-translate-y: -100%
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-0{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-1{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-2{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-3{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-6{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-12{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-45{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-90{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rotate-180{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-180{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-90{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-45{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-12{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-6{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-3{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-2{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--rotate-1{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-0:hover{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-1:hover{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-2:hover{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-3:hover{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-6:hover{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-12:hover{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-45:hover{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-90:hover{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-rotate-180:hover{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-180:hover{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-90:hover{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-45:hover{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-12:hover{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-6:hover{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-3:hover{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-2:hover{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--rotate-1:hover{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-0:focus{
    --tw-rotate: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-1:focus{
    --tw-rotate: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-2:focus{
    --tw-rotate: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-3:focus{
    --tw-rotate: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-6:focus{
    --tw-rotate: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-12:focus{
    --tw-rotate: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-45:focus{
    --tw-rotate: 45deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-90:focus{
    --tw-rotate: 90deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-rotate-180:focus{
    --tw-rotate: 180deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-180:focus{
    --tw-rotate: -180deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-90:focus{
    --tw-rotate: -90deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-45:focus{
    --tw-rotate: -45deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-12:focus{
    --tw-rotate: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-6:focus{
    --tw-rotate: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-3:focus{
    --tw-rotate: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-2:focus{
    --tw-rotate: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--rotate-1:focus{
    --tw-rotate: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-x-0{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-x-1{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-x-2{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-x-3{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-x-6{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-x-12{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-x-12{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-x-6{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-x-3{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-x-2{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-x-1{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-y-0{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-y-1{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-y-2{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-y-3{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-y-6{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-skew-y-12{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-y-12{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-y-6{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-y-3{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-y-2{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--skew-y-1{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-x-0:hover{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-x-1:hover{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-x-2:hover{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-x-3:hover{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-x-6:hover{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-x-12:hover{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-x-12:hover{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-x-6:hover{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-x-3:hover{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-x-2:hover{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-x-1:hover{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-y-0:hover{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-y-1:hover{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-y-2:hover{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-y-3:hover{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-y-6:hover{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-skew-y-12:hover{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-y-12:hover{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-y-6:hover{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-y-3:hover{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-y-2:hover{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget--skew-y-1:hover{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-x-0:focus{
    --tw-skew-x: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-x-1:focus{
    --tw-skew-x: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-x-2:focus{
    --tw-skew-x: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-x-3:focus{
    --tw-skew-x: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-x-6:focus{
    --tw-skew-x: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-x-12:focus{
    --tw-skew-x: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-x-12:focus{
    --tw-skew-x: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-x-6:focus{
    --tw-skew-x: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-x-3:focus{
    --tw-skew-x: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-x-2:focus{
    --tw-skew-x: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-x-1:focus{
    --tw-skew-x: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-y-0:focus{
    --tw-skew-y: 0deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-y-1:focus{
    --tw-skew-y: 1deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-y-2:focus{
    --tw-skew-y: 2deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-y-3:focus{
    --tw-skew-y: 3deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-y-6:focus{
    --tw-skew-y: 6deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-skew-y-12:focus{
    --tw-skew-y: 12deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-y-12:focus{
    --tw-skew-y: -12deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-y-6:focus{
    --tw-skew-y: -6deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-y-3:focus{
    --tw-skew-y: -3deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-y-2:focus{
    --tw-skew-y: -2deg
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget--skew-y-1:focus{
    --tw-skew-y: -1deg
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-0{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-50{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-75{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-90{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-95{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-100{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-105{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-110{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-125{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-150{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-0:hover{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-50:hover{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-75:hover{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-90:hover{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-95:hover{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-100:hover{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-105:hover{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-110:hover{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-125:hover{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-150:hover{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-0:focus{
    --tw-scale-x: 0;
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-50:focus{
    --tw-scale-x: .5;
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-75:focus{
    --tw-scale-x: .75;
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-90:focus{
    --tw-scale-x: .9;
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-95:focus{
    --tw-scale-x: .95;
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-100:focus{
    --tw-scale-x: 1;
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-105:focus{
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-110:focus{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-125:focus{
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-150:focus{
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-0{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-50{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-75{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-90{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-95{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-100{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-105{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-110{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-125{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-x-150{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-0{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-50{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-75{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-90{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-95{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-100{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-105{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-110{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-125{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-scale-y-150{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-0:hover{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-50:hover{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-75:hover{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-90:hover{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-95:hover{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-100:hover{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-105:hover{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-110:hover{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-125:hover{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-x-150:hover{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-0:hover{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-50:hover{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-75:hover{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-90:hover{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-95:hover{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-100:hover{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-105:hover{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-110:hover{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-125:hover{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-scale-y-150:hover{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-0:focus{
    --tw-scale-x: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-50:focus{
    --tw-scale-x: .5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-75:focus{
    --tw-scale-x: .75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-90:focus{
    --tw-scale-x: .9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-95:focus{
    --tw-scale-x: .95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-100:focus{
    --tw-scale-x: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-105:focus{
    --tw-scale-x: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-110:focus{
    --tw-scale-x: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-125:focus{
    --tw-scale-x: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-x-150:focus{
    --tw-scale-x: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-0:focus{
    --tw-scale-y: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-50:focus{
    --tw-scale-y: .5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-75:focus{
    --tw-scale-y: .75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-90:focus{
    --tw-scale-y: .9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-95:focus{
    --tw-scale-y: .95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-100:focus{
    --tw-scale-y: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-105:focus{
    --tw-scale-y: 1.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-110:focus{
    --tw-scale-y: 1.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-125:focus{
    --tw-scale-y: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-scale-y-150:focus{
    --tw-scale-y: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-animate-none{
    animation: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-animate-spin{
    animation: skindr-widget-spin 1s linear infinite
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-animate-ping{
    animation: skindr-widget-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-animate-pulse{
    animation: skindr-widget-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-animate-bounce{
    animation: skindr-widget-bounce 1s infinite
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-auto{
    cursor: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-default{
    cursor: default
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-pointer{
    cursor: pointer
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-wait{
    cursor: wait
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-text{
    cursor: text
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-move{
    cursor: move
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-help{
    cursor: help
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-cursor-not-allowed{
    cursor: not-allowed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-select-none{
    -webkit-user-select: none;
            user-select: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-select-text{
    -webkit-user-select: text;
            user-select: text
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-select-all{
    -webkit-user-select: all;
            user-select: all
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-select-auto{
    -webkit-user-select: auto;
            user-select: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-resize-none{
    resize: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-resize-y{
    resize: vertical
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-resize-x{
    resize: horizontal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-resize{
    resize: both
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-list-inside{
    list-style-position: inside
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-list-outside{
    list-style-position: outside
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-list-none{
    list-style-type: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-list-disc{
    list-style-type: disc
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-list-decimal{
    list-style-type: decimal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-appearance-none{
    -webkit-appearance: none;
            appearance: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-cols-auto{
    grid-auto-columns: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-cols-min{
    grid-auto-columns: min-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-cols-max{
    grid-auto-columns: max-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-flow-row{
    grid-auto-flow: row
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-flow-col{
    grid-auto-flow: column
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-flow-row-dense{
    grid-auto-flow: row dense
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-flow-col-dense{
    grid-auto-flow: column dense
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-rows-auto{
    grid-auto-rows: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-rows-min{
    grid-auto-rows: min-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-rows-max{
    grid-auto-rows: max-content
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-cols-none{
    grid-template-columns: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grid-rows-none{
    grid-template-rows: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-row{
    flex-direction: row
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-row-reverse{
    flex-direction: row-reverse
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-col{
    flex-direction: column
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-col-reverse{
    flex-direction: column-reverse
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-wrap{
    flex-wrap: wrap
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-wrap-reverse{
    flex-wrap: wrap-reverse
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-flex-nowrap{
    flex-wrap: nowrap
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-center{
    place-content: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-start{
    place-content: start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-end{
    place-content: end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-between{
    place-content: space-between
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-around{
    place-content: space-around
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-evenly{
    place-content: space-evenly
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-content-stretch{
    place-content: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-items-start{
    place-items: start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-items-end{
    place-items: end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-items-center{
    place-items: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-items-stretch{
    place-items: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-content-center{
    align-content: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-content-start{
    align-content: flex-start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-content-end{
    align-content: flex-end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-content-between{
    align-content: space-between
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-content-around{
    align-content: space-around
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-content-evenly{
    align-content: space-evenly
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-items-start{
    align-items: flex-start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-items-end{
    align-items: flex-end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-items-center{
    align-items: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-items-baseline{
    align-items: baseline
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-items-stretch{
    align-items: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-start{
    justify-content: flex-start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-end{
    justify-content: flex-end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-center{
    justify-content: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-between{
    justify-content: space-between
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-around{
    justify-content: space-around
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-evenly{
    justify-content: space-evenly
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-items-start{
    justify-items: start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-items-end{
    justify-items: end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-items-center{
    justify-items: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-items-stretch{
    justify-items: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-0{
    gap: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-1{
    gap: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-2{
    gap: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-3{
    gap: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-4{
    gap: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-5{
    gap: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-6{
    gap: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-7{
    gap: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-8{
    gap: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-9{
    gap: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-10{
    gap: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-11{
    gap: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-12{
    gap: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-14{
    gap: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-16{
    gap: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-20{
    gap: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-24{
    gap: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-28{
    gap: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-32{
    gap: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-36{
    gap: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-40{
    gap: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-44{
    gap: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-48{
    gap: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-52{
    gap: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-56{
    gap: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-60{
    gap: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-64{
    gap: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-72{
    gap: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-80{
    gap: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-96{
    gap: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-px{
    gap: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-0\.5{
    gap: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-1\.5{
    gap: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-2\.5{
    gap: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-3\.5{
    gap: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-0{
    column-gap: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-1{
    column-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-2{
    column-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-3{
    column-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-4{
    column-gap: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-5{
    column-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-6{
    column-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-7{
    column-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-8{
    column-gap: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-9{
    column-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-10{
    column-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-11{
    column-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-12{
    column-gap: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-14{
    column-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-16{
    column-gap: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-20{
    column-gap: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-24{
    column-gap: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-28{
    column-gap: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-32{
    column-gap: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-36{
    column-gap: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-40{
    column-gap: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-44{
    column-gap: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-48{
    column-gap: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-52{
    column-gap: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-56{
    column-gap: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-60{
    column-gap: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-64{
    column-gap: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-72{
    column-gap: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-80{
    column-gap: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-96{
    column-gap: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-px{
    column-gap: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-0\.5{
    column-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-1\.5{
    column-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-2\.5{
    column-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-x-3\.5{
    column-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-0{
    row-gap: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-1{
    row-gap: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-2{
    row-gap: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-3{
    row-gap: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-4{
    row-gap: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-5{
    row-gap: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-6{
    row-gap: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-7{
    row-gap: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-8{
    row-gap: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-9{
    row-gap: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-10{
    row-gap: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-11{
    row-gap: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-12{
    row-gap: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-14{
    row-gap: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-16{
    row-gap: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-20{
    row-gap: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-24{
    row-gap: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-28{
    row-gap: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-32{
    row-gap: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-36{
    row-gap: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-40{
    row-gap: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-44{
    row-gap: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-48{
    row-gap: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-52{
    row-gap: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-56{
    row-gap: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-60{
    row-gap: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-64{
    row-gap: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-72{
    row-gap: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-80{
    row-gap: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-96{
    row-gap: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-px{
    row-gap: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-0\.5{
    row-gap: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-1\.5{
    row-gap: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-2\.5{
    row-gap: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-gap-y-3\.5{
    row-gap: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-self-auto{
    place-self: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-self-start{
    place-self: start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-self-end{
    place-self: end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-self-center{
    place-self: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-place-self-stretch{
    place-self: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-self-auto{
    align-self: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-self-start{
    align-self: flex-start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-self-end{
    align-self: flex-end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-self-center{
    align-self: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-self-stretch{
    align-self: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-self-baseline{
    align-self: baseline
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-self-auto{
    justify-self: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-self-start{
    justify-self: start
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-self-end{
    justify-self: end
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-self-center{
    justify-self: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-justify-self-stretch{
    justify-self: stretch
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-auto{
    overflow: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-hidden{
    overflow: hidden
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-visible{
    overflow: visible
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-scroll{
    overflow: scroll
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-x-auto{
    overflow-x: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-y-auto{
    overflow-y: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-x-hidden{
    overflow-x: hidden
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-y-hidden{
    overflow-y: hidden
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-x-visible{
    overflow-x: visible
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-y-visible{
    overflow-y: visible
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-x-scroll{
    overflow-x: scroll
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-y-scroll{
    overflow-y: scroll
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-auto{
    overscroll-behavior: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-contain{
    overscroll-behavior: contain
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-none{
    overscroll-behavior: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-y-auto{
    overscroll-behavior-y: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-y-contain{
    overscroll-behavior-y: contain
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-y-none{
    overscroll-behavior-y: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-x-auto{
    overscroll-behavior-x: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-x-contain{
    overscroll-behavior-x: contain
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overscroll-x-none{
    overscroll-behavior-x: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-ellipsis{
    text-overflow: ellipsis
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-overflow-clip{
    text-overflow: clip
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-whitespace-normal{
    white-space: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-whitespace-nowrap{
    white-space: nowrap
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-whitespace-pre{
    white-space: pre
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-whitespace-pre-line{
    white-space: pre-line
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-whitespace-pre-wrap{
    white-space: pre-wrap
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-break-normal{
    overflow-wrap: normal;
    word-break: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-break-words{
    overflow-wrap: break-word
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-break-all{
    word-break: break-all
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-none{
    border-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-sm{
    border-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded{
    border-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-md{
    border-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-lg{
    border-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-xl{
    border-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-2xl{
    border-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-3xl{
    border-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-full{
    border-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-none{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-sm{
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-md{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-lg{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-xl{
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-2xl{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-3xl{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-t-full{
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-none{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-sm{
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-md{
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-xl{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-2xl{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-3xl{
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-r-full{
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-none{
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-sm{
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b{
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-md{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-lg{
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-xl{
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-2xl{
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-b-full{
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-sm{
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-md{
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-xl{
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-2xl{
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-3xl{
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-l-full{
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-none{
    border-top-left-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-sm{
    border-top-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl{
    border-top-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-md{
    border-top-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-lg{
    border-top-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-xl{
    border-top-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-2xl{
    border-top-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-3xl{
    border-top-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tl-full{
    border-top-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-none{
    border-top-right-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-sm{
    border-top-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr{
    border-top-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-md{
    border-top-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-lg{
    border-top-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-xl{
    border-top-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-2xl{
    border-top-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-3xl{
    border-top-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-tr-full{
    border-top-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-none{
    border-bottom-right-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-sm{
    border-bottom-right-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br{
    border-bottom-right-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-md{
    border-bottom-right-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-lg{
    border-bottom-right-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-xl{
    border-bottom-right-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-2xl{
    border-bottom-right-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-br-full{
    border-bottom-right-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-none{
    border-bottom-left-radius: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl{
    border-bottom-left-radius: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-md{
    border-bottom-left-radius: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-2xl{
    border-bottom-left-radius: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-rounded-bl-full{
    border-bottom-left-radius: 9999px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-0{
    border-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-2{
    border-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-4{
    border-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-8{
    border-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border{
    border-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-t-0{
    border-top-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-t-2{
    border-top-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-t-4{
    border-top-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-t-8{
    border-top-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-t{
    border-top-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-r-0{
    border-right-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-r-2{
    border-right-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-r-4{
    border-right-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-r-8{
    border-right-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-r{
    border-right-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-b-0{
    border-bottom-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-b-2{
    border-bottom-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-b-4{
    border-bottom-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-b-8{
    border-bottom-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-b{
    border-bottom-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-l-0{
    border-left-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-l-2{
    border-left-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-l-4{
    border-left-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-l-8{
    border-left-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-l{
    border-left-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-solid{
    border-style: solid
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-dashed{
    border-style: dashed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-dotted{
    border-style: dotted
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-double{
    border-style: double
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-none{
    border-style: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-transparent{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-current{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-black{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-white{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-50{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-100{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-300{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-400{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-500{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-700{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-800{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-gray-900{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-50{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-200{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-400{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-700{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-800{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-red-900{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-50{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-100{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-200{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-300{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-400{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-500{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-600{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-700{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-800{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-yellow-900{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-50{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-100{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-200{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-300{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-400{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-600{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-700{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-800{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-green-900{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-50{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-100{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-200{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-300{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-400{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-500{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-700{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-800{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-blue-900{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-50{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-100{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-200{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-300{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-400{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-500{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-600{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-700{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-800{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-indigo-900{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-50{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-100{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-200{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-300{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-400{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-500{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-700{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-800{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-purple-900{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-50{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-100{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-200{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-300{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-400{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-500{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-600{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-700{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-800{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-pink-900{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-transparent:focus-within{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-current:focus-within{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-black:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-white:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-gray-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-red-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-yellow-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-green-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-blue-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-indigo-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-purple-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-50:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-100:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-200:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-300:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-400:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-500:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-600:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-700:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-800:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-pink-900:focus-within{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-transparent:hover{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-current:hover{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-black:hover{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-white:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-gray-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-red-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-yellow-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-green-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-blue-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-indigo-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-purple-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-50:hover{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-200:hover{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-400:hover{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-700:hover{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-800:hover{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-pink-900:hover{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-transparent:focus{
    border-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-current:focus{
    border-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-black:focus{
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-white:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-gray-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-red-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-yellow-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-green-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-blue-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-indigo-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-purple-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-50:focus{
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-100:focus{
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-200:focus{
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-300:focus{
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-400:focus{
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-500:focus{
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-600:focus{
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-700:focus{
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-800:focus{
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-pink-900:focus{
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-0{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-5{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-10{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-20{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-25{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-30{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-40{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-50{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-60{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-70{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-75{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-80{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-90{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-95{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-border-opacity-100{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-0:focus-within{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-border-opacity-100:focus-within{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-0:hover{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-5:hover{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-10:hover{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-20:hover{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-25:hover{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-30:hover{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-40:hover{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-50:hover{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-60:hover{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-70:hover{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-75:hover{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-80:hover{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-90:hover{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-95:hover{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-border-opacity-100:hover{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-0:focus{
    --tw-border-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-5:focus{
    --tw-border-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-10:focus{
    --tw-border-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-20:focus{
    --tw-border-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-25:focus{
    --tw-border-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-30:focus{
    --tw-border-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-40:focus{
    --tw-border-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-50:focus{
    --tw-border-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-60:focus{
    --tw-border-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-70:focus{
    --tw-border-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-75:focus{
    --tw-border-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-80:focus{
    --tw-border-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-90:focus{
    --tw-border-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-95:focus{
    --tw-border-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-border-opacity-100:focus{
    --tw-border-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-transparent{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-current{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-black{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-300{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-400{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-500{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-600{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-700{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-800{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-gray-900{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-50{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-200{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-400{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-700{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-800{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-red-900{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-50{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-100{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-200{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-300{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-400{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-500{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-600{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-700{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-800{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-yellow-900{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-50{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-100{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-200{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-300{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-400{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-500{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-800{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-green-900{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-50{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-200{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-300{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-400{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-500{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-700{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-800{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-blue-900{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-50{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-100{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-200{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-300{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-400{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-500{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-600{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-700{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-800{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-indigo-900{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-50{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-100{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-200{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-300{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-400{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-500{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-700{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-800{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-purple-900{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-50{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-100{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-200{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-300{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-400{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-500{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-600{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-700{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-800{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-pink-900{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-transparent:focus-within{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-current:focus-within{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-black:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-white:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-gray-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-red-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-green-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-blue-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-purple-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-50:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-100:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-200:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-300:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-400:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-500:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-600:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-700:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-800:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-pink-900:focus-within{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-transparent:hover{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-current:hover{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-black:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-gray-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-red-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-yellow-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-green-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-blue-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-indigo-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-purple-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-300:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-400:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-600:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-pink-900:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-transparent:focus{
    background-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-current:focus{
    background-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-black:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-white:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-gray-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-red-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-yellow-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-green-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-blue-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-indigo-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-purple-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-50:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-100:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-200:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-300:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-400:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-500:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-600:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-700:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-800:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-pink-900:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-0{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-5{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-10{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-20{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-25{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-30{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-40{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-50{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-60{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-70{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-75{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-80{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-90{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-95{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-bg-opacity-100{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-0:hover{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-bg-opacity-100:hover{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-0:focus{
    --tw-bg-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-bg-opacity-100:focus{
    --tw-bg-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-none{
    background-image: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-transparent{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-current{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-black{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-white{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-50{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-100{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-200{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-300{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-400{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-500{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-600{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-700{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-800{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-gray-900{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-50{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-100{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-200{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-300{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-400{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-500{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-600{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-700{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-800{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-red-900{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-50{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-100{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-200{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-300{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-400{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-500{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-600{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-700{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-800{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-yellow-900{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-50{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-100{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-200{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-300{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-400{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-500{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-600{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-700{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-800{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-green-900{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-50{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-100{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-200{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-300{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-400{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-500{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-600{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-700{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-800{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-blue-900{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-50{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-100{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-200{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-300{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-400{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-500{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-600{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-700{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-800{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-indigo-900{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-50{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-100{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-200{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-300{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-400{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-500{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-600{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-700{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-800{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-purple-900{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-50{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-100{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-200{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-300{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-400{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-500{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-600{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-700{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-800{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-from-pink-900{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-transparent:hover{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-current:hover{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-black:hover{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-white:hover{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-50:hover{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-300:hover{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-400:hover{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-500:hover{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-600:hover{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-700:hover{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-800:hover{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-gray-900:hover{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-50:hover{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-100:hover{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-200:hover{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-300:hover{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-400:hover{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-500:hover{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-600:hover{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-700:hover{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-800:hover{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-red-900:hover{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-200:hover{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-600:hover{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-700:hover{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-800:hover{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-yellow-900:hover{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-50:hover{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-100:hover{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-200:hover{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-300:hover{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-400:hover{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-500:hover{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-600:hover{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-700:hover{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-800:hover{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-green-900:hover{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-50:hover{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-100:hover{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-300:hover{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-400:hover{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-500:hover{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-600:hover{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-800:hover{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-400:hover{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-500:hover{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-700:hover{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-800:hover{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-indigo-900:hover{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-100:hover{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-400:hover{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-600:hover{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-700:hover{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-800:hover{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-purple-900:hover{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-100:hover{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-400:hover{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-500:hover{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-600:hover{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-700:hover{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-800:hover{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-from-pink-900:hover{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-transparent:focus{
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-current:focus{
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-black:focus{
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-white:focus{
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-50:focus{
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-300:focus{
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-400:focus{
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-500:focus{
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-600:focus{
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-700:focus{
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-800:focus{
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-gray-900:focus{
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-50:focus{
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-100:focus{
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-200:focus{
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-300:focus{
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-400:focus{
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-500:focus{
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-600:focus{
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-700:focus{
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-800:focus{
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-red-900:focus{
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-200:focus{
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-600:focus{
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-700:focus{
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-800:focus{
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-yellow-900:focus{
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-50:focus{
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-100:focus{
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-200:focus{
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-300:focus{
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-400:focus{
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-500:focus{
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-600:focus{
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-700:focus{
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-800:focus{
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-green-900:focus{
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-50:focus{
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-100:focus{
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-300:focus{
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-400:focus{
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-500:focus{
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-600:focus{
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-800:focus{
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-400:focus{
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-500:focus{
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-700:focus{
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-800:focus{
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-indigo-900:focus{
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-100:focus{
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-400:focus{
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-600:focus{
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-700:focus{
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-800:focus{
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-purple-900:focus{
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-100:focus{
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-400:focus{
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-500:focus{
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-600:focus{
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-700:focus{
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-800:focus{
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-from-pink-900:focus{
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-transparent{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-current{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-black{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-white{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-50{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-100{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-200{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-300{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-400{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-500{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-600{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-700{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-800{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-gray-900{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-50{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-100{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-200{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-300{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-400{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-500{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-600{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-700{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-800{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-red-900{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-50{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-100{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-200{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-300{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-400{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-500{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-600{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-700{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-800{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-yellow-900{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-50{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-100{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-200{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-300{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-400{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-500{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-600{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-700{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-800{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-green-900{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-50{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-100{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-200{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-300{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-400{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-500{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-600{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-700{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-800{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-blue-900{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-50{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-100{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-200{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-300{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-400{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-500{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-600{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-700{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-800{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-indigo-900{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-50{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-100{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-200{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-300{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-400{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-500{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-600{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-700{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-800{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-purple-900{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-50{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-100{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-200{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-300{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-400{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-500{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-600{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-700{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-800{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-to-pink-900{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-transparent:hover{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-current:hover{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-black:hover{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-white:hover{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-50:hover{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-300:hover{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-400:hover{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-500:hover{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-600:hover{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-700:hover{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-800:hover{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-gray-900:hover{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-50:hover{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-100:hover{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-200:hover{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-300:hover{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-400:hover{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-500:hover{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-600:hover{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-700:hover{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-800:hover{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-red-900:hover{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-200:hover{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-600:hover{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-700:hover{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-800:hover{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-yellow-900:hover{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-50:hover{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-100:hover{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-200:hover{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-300:hover{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-400:hover{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-500:hover{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-600:hover{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-700:hover{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-800:hover{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-green-900:hover{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-50:hover{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-100:hover{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-300:hover{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-400:hover{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-500:hover{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-600:hover{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-800:hover{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-400:hover{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-500:hover{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-700:hover{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-800:hover{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-indigo-900:hover{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-100:hover{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-400:hover{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-600:hover{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-700:hover{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-800:hover{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-purple-900:hover{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-100:hover{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-400:hover{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-500:hover{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-600:hover{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-700:hover{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-800:hover{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-to-pink-900:hover{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-transparent:focus{
    --tw-gradient-to: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-current:focus{
    --tw-gradient-to: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-black:focus{
    --tw-gradient-to: #000
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-white:focus{
    --tw-gradient-to: #fff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-50:focus{
    --tw-gradient-to: #f9fafb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-300:focus{
    --tw-gradient-to: #d1d5db
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-400:focus{
    --tw-gradient-to: #9ca3af
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-500:focus{
    --tw-gradient-to: #6b7280
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-600:focus{
    --tw-gradient-to: #4b5563
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-700:focus{
    --tw-gradient-to: #374151
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-800:focus{
    --tw-gradient-to: #1f2937
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-gray-900:focus{
    --tw-gradient-to: #111827
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-50:focus{
    --tw-gradient-to: #fef2f2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-100:focus{
    --tw-gradient-to: #fee2e2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-200:focus{
    --tw-gradient-to: #fecaca
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-300:focus{
    --tw-gradient-to: #fca5a5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-400:focus{
    --tw-gradient-to: #f87171
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-500:focus{
    --tw-gradient-to: #ef4444
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-600:focus{
    --tw-gradient-to: #dc2626
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-700:focus{
    --tw-gradient-to: #b91c1c
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-800:focus{
    --tw-gradient-to: #991b1b
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-red-900:focus{
    --tw-gradient-to: #7f1d1d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-200:focus{
    --tw-gradient-to: #fde68a
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-600:focus{
    --tw-gradient-to: #d97706
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-700:focus{
    --tw-gradient-to: #b45309
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-800:focus{
    --tw-gradient-to: #92400e
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-yellow-900:focus{
    --tw-gradient-to: #78350f
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-50:focus{
    --tw-gradient-to: #ecfdf5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-100:focus{
    --tw-gradient-to: #d1fae5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-200:focus{
    --tw-gradient-to: #a7f3d0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-300:focus{
    --tw-gradient-to: #6ee7b7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-400:focus{
    --tw-gradient-to: #34d399
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-500:focus{
    --tw-gradient-to: #10b981
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-600:focus{
    --tw-gradient-to: #059669
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-700:focus{
    --tw-gradient-to: #047857
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-800:focus{
    --tw-gradient-to: #065f46
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-green-900:focus{
    --tw-gradient-to: #064e3b
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-50:focus{
    --tw-gradient-to: #eff6ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-100:focus{
    --tw-gradient-to: #dbeafe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-300:focus{
    --tw-gradient-to: #93c5fd
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-400:focus{
    --tw-gradient-to: #60a5fa
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-500:focus{
    --tw-gradient-to: #3b82f6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-600:focus{
    --tw-gradient-to: #2563eb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-800:focus{
    --tw-gradient-to: #1e40af
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-400:focus{
    --tw-gradient-to: #818cf8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-500:focus{
    --tw-gradient-to: #6366f1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-700:focus{
    --tw-gradient-to: #4338ca
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-800:focus{
    --tw-gradient-to: #3730a3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-indigo-900:focus{
    --tw-gradient-to: #312e81
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-100:focus{
    --tw-gradient-to: #ede9fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-400:focus{
    --tw-gradient-to: #a78bfa
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-600:focus{
    --tw-gradient-to: #7c3aed
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-700:focus{
    --tw-gradient-to: #6d28d9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-800:focus{
    --tw-gradient-to: #5b21b6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-purple-900:focus{
    --tw-gradient-to: #4c1d95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-100:focus{
    --tw-gradient-to: #fce7f3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-400:focus{
    --tw-gradient-to: #f472b6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-500:focus{
    --tw-gradient-to: #ec4899
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-600:focus{
    --tw-gradient-to: #db2777
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-700:focus{
    --tw-gradient-to: #be185d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-800:focus{
    --tw-gradient-to: #9d174d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-to-pink-900:focus{
    --tw-gradient-to: #831843
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-decoration-slice{
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-decoration-clone{
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-auto{
    background-size: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-cover{
    background-size: cover
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-contain{
    background-size: contain
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-fixed{
    background-attachment: fixed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-local{
    background-attachment: local
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-scroll{
    background-attachment: scroll
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-clip-border{
    background-clip: border-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-clip-padding{
    background-clip: padding-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-clip-content{
    background-clip: content-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-clip-text{
    -webkit-background-clip: text;
            background-clip: text
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-bottom{
    background-position: bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-center{
    background-position: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-left{
    background-position: left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-left-bottom{
    background-position: left bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-left-top{
    background-position: left top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-right{
    background-position: right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-right-bottom{
    background-position: right bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-right-top{
    background-position: right top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-top{
    background-position: top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-repeat{
    background-repeat: repeat
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-no-repeat{
    background-repeat: no-repeat
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-repeat-x{
    background-repeat: repeat-x
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-repeat-y{
    background-repeat: repeat-y
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-repeat-round{
    background-repeat: round
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-repeat-space{
    background-repeat: space
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-origin-border{
    background-origin: border-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-origin-padding{
    background-origin: padding-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-origin-content{
    background-origin: content-box
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-fill-current{
    fill: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-stroke-current{
    stroke: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-stroke-0{
    stroke-width: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-stroke-1{
    stroke-width: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-stroke-2{
    stroke-width: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-contain{
    object-fit: contain
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-cover{
    object-fit: cover
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-fill{
    object-fit: fill
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-none{
    object-fit: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-scale-down{
    object-fit: scale-down
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-bottom{
    object-position: bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-center{
    object-position: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-left{
    object-position: left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-left-bottom{
    object-position: left bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-left-top{
    object-position: left top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-right{
    object-position: right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-right-bottom{
    object-position: right bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-right-top{
    object-position: right top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-object-top{
    object-position: top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-0{
    padding: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-1{
    padding: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-2{
    padding: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-3{
    padding: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-4{
    padding: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-5{
    padding: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-6{
    padding: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-7{
    padding: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-8{
    padding: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-9{
    padding: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-10{
    padding: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-11{
    padding: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-12{
    padding: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-14{
    padding: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-16{
    padding: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-20{
    padding: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-24{
    padding: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-28{
    padding: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-32{
    padding: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-36{
    padding: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-40{
    padding: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-44{
    padding: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-48{
    padding: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-52{
    padding: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-56{
    padding: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-60{
    padding: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-64{
    padding: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-72{
    padding: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-80{
    padding: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-96{
    padding: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-px{
    padding: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-0\.5{
    padding: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-1\.5{
    padding: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-2\.5{
    padding: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-p-3\.5{
    padding: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-0{
    padding-left: 0px;
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-4{
    padding-left: 1rem;
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-8{
    padding-left: 2rem;
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-9{
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-11{
    padding-left: 2.75rem;
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-12{
    padding-left: 3rem;
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-14{
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-16{
    padding-left: 4rem;
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-20{
    padding-left: 5rem;
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-24{
    padding-left: 6rem;
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-28{
    padding-left: 7rem;
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-32{
    padding-left: 8rem;
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-36{
    padding-left: 9rem;
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-40{
    padding-left: 10rem;
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-44{
    padding-left: 11rem;
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-48{
    padding-left: 12rem;
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-52{
    padding-left: 13rem;
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-56{
    padding-left: 14rem;
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-60{
    padding-left: 15rem;
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-64{
    padding-left: 16rem;
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-72{
    padding-left: 18rem;
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-80{
    padding-left: 20rem;
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-96{
    padding-left: 24rem;
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-px{
    padding-left: 1px;
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-0\.5{
    padding-left: 0.125rem;
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-1\.5{
    padding-left: 0.375rem;
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-0{
    padding-top: 0px;
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-4{
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-5{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-7{
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-8{
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-9{
    padding-top: 2.25rem;
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-10{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-11{
    padding-top: 2.75rem;
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-12{
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-14{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-16{
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-20{
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-24{
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-28{
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-32{
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-36{
    padding-top: 9rem;
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-40{
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-44{
    padding-top: 11rem;
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-48{
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-52{
    padding-top: 13rem;
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-56{
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-60{
    padding-top: 15rem;
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-64{
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-72{
    padding-top: 18rem;
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-80{
    padding-top: 20rem;
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-96{
    padding-top: 24rem;
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-px{
    padding-top: 1px;
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-0{
    padding-top: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-1{
    padding-top: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-2{
    padding-top: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-3{
    padding-top: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-4{
    padding-top: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-5{
    padding-top: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-6{
    padding-top: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-7{
    padding-top: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-8{
    padding-top: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-9{
    padding-top: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-10{
    padding-top: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-11{
    padding-top: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-12{
    padding-top: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-14{
    padding-top: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-16{
    padding-top: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-20{
    padding-top: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-24{
    padding-top: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-28{
    padding-top: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-32{
    padding-top: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-36{
    padding-top: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-40{
    padding-top: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-44{
    padding-top: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-48{
    padding-top: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-52{
    padding-top: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-56{
    padding-top: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-60{
    padding-top: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-64{
    padding-top: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-72{
    padding-top: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-80{
    padding-top: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-96{
    padding-top: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-px{
    padding-top: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-0\.5{
    padding-top: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-1\.5{
    padding-top: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-2\.5{
    padding-top: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pt-3\.5{
    padding-top: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-0{
    padding-right: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-1{
    padding-right: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-2{
    padding-right: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-3{
    padding-right: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-4{
    padding-right: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-5{
    padding-right: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-6{
    padding-right: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-7{
    padding-right: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-8{
    padding-right: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-9{
    padding-right: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-10{
    padding-right: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-11{
    padding-right: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-12{
    padding-right: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-14{
    padding-right: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-16{
    padding-right: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-20{
    padding-right: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-24{
    padding-right: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-28{
    padding-right: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-32{
    padding-right: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-36{
    padding-right: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-40{
    padding-right: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-44{
    padding-right: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-48{
    padding-right: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-52{
    padding-right: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-56{
    padding-right: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-60{
    padding-right: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-64{
    padding-right: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-72{
    padding-right: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-80{
    padding-right: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-96{
    padding-right: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-px{
    padding-right: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-0\.5{
    padding-right: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-1\.5{
    padding-right: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-2\.5{
    padding-right: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pr-3\.5{
    padding-right: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-0{
    padding-bottom: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-1{
    padding-bottom: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-2{
    padding-bottom: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-3{
    padding-bottom: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-4{
    padding-bottom: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-5{
    padding-bottom: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-6{
    padding-bottom: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-7{
    padding-bottom: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-8{
    padding-bottom: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-9{
    padding-bottom: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-10{
    padding-bottom: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-11{
    padding-bottom: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-12{
    padding-bottom: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-14{
    padding-bottom: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-16{
    padding-bottom: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-20{
    padding-bottom: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-24{
    padding-bottom: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-28{
    padding-bottom: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-32{
    padding-bottom: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-36{
    padding-bottom: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-40{
    padding-bottom: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-44{
    padding-bottom: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-48{
    padding-bottom: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-52{
    padding-bottom: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-56{
    padding-bottom: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-60{
    padding-bottom: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-64{
    padding-bottom: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-72{
    padding-bottom: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-80{
    padding-bottom: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-96{
    padding-bottom: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-px{
    padding-bottom: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-0\.5{
    padding-bottom: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-1\.5{
    padding-bottom: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-2\.5{
    padding-bottom: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pb-3\.5{
    padding-bottom: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-0{
    padding-left: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-1{
    padding-left: 0.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-2{
    padding-left: 0.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-3{
    padding-left: 0.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-4{
    padding-left: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-5{
    padding-left: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-6{
    padding-left: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-7{
    padding-left: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-8{
    padding-left: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-9{
    padding-left: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-10{
    padding-left: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-11{
    padding-left: 2.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-12{
    padding-left: 3rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-14{
    padding-left: 3.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-16{
    padding-left: 4rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-20{
    padding-left: 5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-24{
    padding-left: 6rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-28{
    padding-left: 7rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-32{
    padding-left: 8rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-36{
    padding-left: 9rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-40{
    padding-left: 10rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-44{
    padding-left: 11rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-48{
    padding-left: 12rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-52{
    padding-left: 13rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-56{
    padding-left: 14rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-60{
    padding-left: 15rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-64{
    padding-left: 16rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-72{
    padding-left: 18rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-80{
    padding-left: 20rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-96{
    padding-left: 24rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-px{
    padding-left: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-0\.5{
    padding-left: 0.125rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-1\.5{
    padding-left: 0.375rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-2\.5{
    padding-left: 0.625rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-pl-3\.5{
    padding-left: 0.875rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-left{
    text-align: left
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-center{
    text-align: center
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-right{
    text-align: right
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-justify{
    text-align: justify
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-align-baseline{
    vertical-align: baseline
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-align-top{
    vertical-align: top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-align-middle{
    vertical-align: middle
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-align-bottom{
    vertical-align: bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-align-text-top{
    vertical-align: text-top
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-align-text-bottom{
    vertical-align: text-bottom
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-xs{
    font-size: 0.75rem;
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-base{
    font-size: 1rem;
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-2xl{
    font-size: 1.5rem;
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-5xl{
    font-size: 3rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-6xl{
    font-size: 3.75rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-7xl{
    font-size: 4.5rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-8xl{
    font-size: 6rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-9xl{
    font-size: 8rem;
    line-height: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-thin{
    font-weight: 100
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-extralight{
    font-weight: 200
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-light{
    font-weight: 300
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-normal{
    font-weight: 400
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-medium{
    font-weight: 500
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-semibold{
    font-weight: 600
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-bold{
    font-weight: 700
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-extrabold{
    font-weight: 800
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-font-black{
    font-weight: 900
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-uppercase{
    text-transform: uppercase
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-lowercase{
    text-transform: lowercase
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-capitalize{
    text-transform: capitalize
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-normal-case{
    text-transform: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-italic{
    font-style: italic
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-not-italic{
    font-style: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ordinal, #skindrWidget.skindrWidget .\32xl\:skindr-widget-slashed-zero, #skindrWidget.skindrWidget .\32xl\:skindr-widget-lining-nums, #skindrWidget.skindrWidget .\32xl\:skindr-widget-oldstyle-nums, #skindrWidget.skindrWidget .\32xl\:skindr-widget-proportional-nums, #skindrWidget.skindrWidget .\32xl\:skindr-widget-tabular-nums, #skindrWidget.skindrWidget .\32xl\:skindr-widget-diagonal-fractions, #skindrWidget.skindrWidget .\32xl\:skindr-widget-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-normal-nums{
    font-variant-numeric: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ordinal{
    --tw-ordinal: ordinal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-slashed-zero{
    --tw-slashed-zero: slashed-zero
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-lining-nums{
    --tw-numeric-figure: lining-nums
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-proportional-nums{
    --tw-numeric-spacing: proportional-nums
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tabular-nums{
    --tw-numeric-spacing: tabular-nums
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-3{
    line-height: .75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-4{
    line-height: 1rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-5{
    line-height: 1.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-6{
    line-height: 1.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-7{
    line-height: 1.75rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-8{
    line-height: 2rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-9{
    line-height: 2.25rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-10{
    line-height: 2.5rem
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-none{
    line-height: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-tight{
    line-height: 1.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-snug{
    line-height: 1.375
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-normal{
    line-height: 1.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-relaxed{
    line-height: 1.625
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-leading-loose{
    line-height: 2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tracking-tighter{
    letter-spacing: -0.05em
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tracking-tight{
    letter-spacing: -0.025em
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tracking-normal{
    letter-spacing: 0em
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tracking-wide{
    letter-spacing: 0.025em
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tracking-wider{
    letter-spacing: 0.05em
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-tracking-widest{
    letter-spacing: 0.1em
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-transparent{
    color: transparent
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-current{
    color: currentColor
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-50{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-100{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-200{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-300{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-700{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-800{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-50{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-100{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-200{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-300{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-400{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-700{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-800{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-red-900{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-50{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-100{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-200{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-300{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-400{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-500{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-600{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-700{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-800{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-yellow-900{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-50{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-100{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-200{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-300{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-400{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-600{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-700{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-800{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-green-900{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-50{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-100{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-200{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-300{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-500{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-700{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-800{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-blue-900{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-50{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-100{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-200{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-300{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-400{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-500{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-700{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-800{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-indigo-900{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-50{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-100{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-200{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-300{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-400{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-500{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-700{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-800{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-purple-900{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-50{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-100{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-200{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-300{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-400{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-500{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-600{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-700{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-800{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-pink-900{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-transparent:focus-within{
    color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-current:focus-within{
    color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-black:focus-within{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-white:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-gray-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-red-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-yellow-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-green-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-blue-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-indigo-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-purple-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-50:focus-within{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-100:focus-within{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-200:focus-within{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-300:focus-within{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-400:focus-within{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-500:focus-within{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-600:focus-within{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-700:focus-within{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-800:focus-within{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-pink-900:focus-within{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-transparent:hover{
    color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-current:hover{
    color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-black:hover{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-50:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-100:hover{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-300:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-400:hover{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-600:hover{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-700:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-800:hover{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-gray-900:hover{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-50:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-200:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-400:hover{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-500:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-600:hover{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-700:hover{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-800:hover{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-red-900:hover{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-50:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-100:hover{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-200:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-300:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-400:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-500:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-600:hover{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-700:hover{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-800:hover{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-yellow-900:hover{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-50:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-100:hover{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-200:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-300:hover{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-400:hover{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-500:hover{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-600:hover{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-700:hover{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-800:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-green-900:hover{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-50:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-200:hover{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-300:hover{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-400:hover{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-500:hover{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-600:hover{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-700:hover{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-800:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-blue-900:hover{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-50:hover{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-100:hover{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-200:hover{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-300:hover{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-400:hover{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-500:hover{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-600:hover{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-700:hover{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-800:hover{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-indigo-900:hover{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-50:hover{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-100:hover{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-200:hover{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-300:hover{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-400:hover{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-500:hover{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-600:hover{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-700:hover{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-800:hover{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-purple-900:hover{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-50:hover{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-100:hover{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-200:hover{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-300:hover{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-400:hover{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-500:hover{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-600:hover{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-700:hover{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-800:hover{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-pink-900:hover{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-transparent:focus{
    color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-current:focus{
    color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-black:focus{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-white:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-50:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-100:focus{
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-200:focus{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-300:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-400:focus{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-500:focus{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-600:focus{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-700:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-800:focus{
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-gray-900:focus{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-50:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-200:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-400:focus{
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-500:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-600:focus{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-700:focus{
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-800:focus{
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-red-900:focus{
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-50:focus{
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-100:focus{
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-200:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-300:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-400:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-500:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-600:focus{
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-700:focus{
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-800:focus{
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-yellow-900:focus{
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-50:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-100:focus{
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-200:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-300:focus{
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-400:focus{
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-500:focus{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-600:focus{
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-700:focus{
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-800:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-green-900:focus{
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-50:focus{
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-100:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-200:focus{
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-300:focus{
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-400:focus{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-500:focus{
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-600:focus{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-700:focus{
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-800:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-blue-900:focus{
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-50:focus{
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-100:focus{
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-200:focus{
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-300:focus{
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-400:focus{
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-500:focus{
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-600:focus{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-700:focus{
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-800:focus{
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-indigo-900:focus{
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-50:focus{
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-100:focus{
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-200:focus{
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-300:focus{
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-400:focus{
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-500:focus{
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-600:focus{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-700:focus{
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-800:focus{
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-purple-900:focus{
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-50:focus{
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-100:focus{
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-200:focus{
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-300:focus{
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-400:focus{
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-500:focus{
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-600:focus{
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-700:focus{
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-800:focus{
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-pink-900:focus{
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-0{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-5{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-10{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-20{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-25{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-30{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-40{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-50{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-60{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-70{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-75{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-80{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-90{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-95{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-text-opacity-100{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-0:focus-within{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-text-opacity-100:focus-within{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-0:hover{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-5:hover{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-10:hover{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-20:hover{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-25:hover{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-30:hover{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-40:hover{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-50:hover{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-60:hover{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-70:hover{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-75:hover{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-80:hover{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-90:hover{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-95:hover{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-text-opacity-100:hover{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-0:focus{
    --tw-text-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-5:focus{
    --tw-text-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-10:focus{
    --tw-text-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-20:focus{
    --tw-text-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-25:focus{
    --tw-text-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-30:focus{
    --tw-text-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-40:focus{
    --tw-text-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-50:focus{
    --tw-text-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-60:focus{
    --tw-text-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-70:focus{
    --tw-text-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-75:focus{
    --tw-text-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-80:focus{
    --tw-text-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-90:focus{
    --tw-text-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-95:focus{
    --tw-text-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-text-opacity-100:focus{
    --tw-text-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-underline{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-line-through{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-no-underline{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-underline:focus-within{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-line-through:focus-within{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-no-underline:focus-within{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-underline:hover{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-line-through:hover{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-no-underline:hover{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-underline:focus{
    text-decoration: underline
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-line-through:focus{
    text-decoration: line-through
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-no-underline:focus{
    text-decoration: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-subpixel-antialiased{
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-transparent::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-current::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-transparent:focus::placeholder{
    color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-current:focus::placeholder{
    color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-0{
    opacity: 0
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-5{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-10{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-20{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-25{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-30{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-40{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-50{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-60{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-70{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-75{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-80{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-90{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-95{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-opacity-100{
    opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-0:focus-within{
    opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-5:focus-within{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-10:focus-within{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-20:focus-within{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-25:focus-within{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-30:focus-within{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-40:focus-within{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-50:focus-within{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-60:focus-within{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-70:focus-within{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-75:focus-within{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-80:focus-within{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-90:focus-within{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-95:focus-within{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-opacity-100:focus-within{
    opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-0:hover{
    opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-5:hover{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-10:hover{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-20:hover{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-25:hover{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-30:hover{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-40:hover{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-50:hover{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-60:hover{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-70:hover{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-75:hover{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-80:hover{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-90:hover{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-95:hover{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-opacity-100:hover{
    opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-0:focus{
    opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-5:focus{
    opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-10:focus{
    opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-20:focus{
    opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-25:focus{
    opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-30:focus{
    opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-40:focus{
    opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-50:focus{
    opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-60:focus{
    opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-70:focus{
    opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-75:focus{
    opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-80:focus{
    opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-90:focus{
    opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-95:focus{
    opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-opacity-100:focus{
    opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-normal{
    background-blend-mode: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-multiply{
    background-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-screen{
    background-blend-mode: screen
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-overlay{
    background-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-darken{
    background-blend-mode: darken
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-lighten{
    background-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-color-dodge{
    background-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-color-burn{
    background-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-hard-light{
    background-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-soft-light{
    background-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-difference{
    background-blend-mode: difference
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-exclusion{
    background-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-hue{
    background-blend-mode: hue
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-saturation{
    background-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-color{
    background-blend-mode: color
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-bg-blend-luminosity{
    background-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-normal{
    mix-blend-mode: normal
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-multiply{
    mix-blend-mode: multiply
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-screen{
    mix-blend-mode: screen
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-overlay{
    mix-blend-mode: overlay
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-darken{
    mix-blend-mode: darken
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-lighten{
    mix-blend-mode: lighten
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-color-dodge{
    mix-blend-mode: color-dodge
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-color-burn{
    mix-blend-mode: color-burn
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-hard-light{
    mix-blend-mode: hard-light
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-soft-light{
    mix-blend-mode: soft-light
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-difference{
    mix-blend-mode: difference
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-exclusion{
    mix-blend-mode: exclusion
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-hue{
    mix-blend-mode: hue
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-saturation{
    mix-blend-mode: saturation
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-color{
    mix-blend-mode: color
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-mix-blend-luminosity{
    mix-blend-mode: luminosity
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .skindr-widget-group:hover .\32xl\:group-hover\:skindr-widget-shadow-none{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:hover\:skindr-widget-shadow-none:hover{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-shadow-none:focus{
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-outline-white{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-outline-black{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-outline-white:focus-within{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-outline-black:focus-within{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-outline-white:focus{
    outline: 2px dotted white;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-outline-black:focus{
    outline: 2px dotted black;
    outline-offset: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-inset{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-inset:focus-within{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-inset:focus{
    --tw-ring-inset: inset
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-transparent{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-current{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-black{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-white{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-gray-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-red-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-yellow-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-green-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-blue-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-indigo-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-purple-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-50{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-200{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-300{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-400{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-500{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-600{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-pink-900{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-transparent:focus-within{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-current:focus-within{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-black:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-white:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-gray-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-red-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-green-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-blue-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-purple-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-50:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-100:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-200:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-300:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-400:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-500:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-600:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-700:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-800:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-pink-900:focus-within{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-transparent:focus{
    --tw-ring-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-current:focus{
    --tw-ring-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-black:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-white:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-gray-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-red-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-yellow-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-green-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-blue-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-indigo-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-purple-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-50:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-200:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-400:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-600:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-700:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-800:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-pink-900:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-0{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-5{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-10{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-20{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-25{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-30{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-40{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-50{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-60{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-70{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-75{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-80{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-90{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-95{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-opacity-100{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-0:focus{
    --tw-ring-opacity: 0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-opacity-100:focus{
    --tw-ring-opacity: 1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-0{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-1{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-2{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-4{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-8{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-0:focus{
    --tw-ring-offset-width: 0px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-1:focus{
    --tw-ring-offset-width: 1px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-2:focus{
    --tw-ring-offset-width: 2px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-4:focus{
    --tw-ring-offset-width: 4px
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-8:focus{
    --tw-ring-offset-width: 8px
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-transparent{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-current{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-black{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-white{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-700{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-gray-900{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-400{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-400{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-500{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-600{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-700{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-800{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ring-offset-pink-900{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .\32xl\:focus-within\:skindr-widget-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-black:focus{
    --tw-ring-offset-color: #000
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-white:focus{
    --tw-ring-offset-color: #fff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d
  }

  #skindrWidget.skindrWidget .\32xl\:focus\:skindr-widget-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-filter-none{
    filter: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-0{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-none{
    --tw-blur: blur(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-sm{
    --tw-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur{
    --tw-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-md{
    --tw-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-lg{
    --tw-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-xl{
    --tw-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-2xl{
    --tw-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-blur-3xl{
    --tw-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-0{
    --tw-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-50{
    --tw-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-75{
    --tw-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-90{
    --tw-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-95{
    --tw-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-100{
    --tw-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-105{
    --tw-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-110{
    --tw-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-125{
    --tw-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-150{
    --tw-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-brightness-200{
    --tw-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-0{
    --tw-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-50{
    --tw-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-75{
    --tw-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-100{
    --tw-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-125{
    --tw-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-150{
    --tw-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-contrast-200{
    --tw-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15))
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grayscale-0{
    --tw-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-grayscale{
    --tw-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-invert-0{
    --tw-invert: invert(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-invert{
    --tw-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-saturate-0{
    --tw-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-saturate-50{
    --tw-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-saturate-100{
    --tw-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-saturate-150{
    --tw-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-saturate-200{
    --tw-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-sepia-0{
    --tw-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-sepia{
    --tw-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-filter-none{
    -webkit-backdrop-filter: none;
            backdrop-filter: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-0{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-none{
    --tw-backdrop-blur: blur(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur{
    --tw-backdrop-blur: blur(8px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-invert-0{
    --tw-backdrop-invert: invert(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-invert{
    --tw-backdrop-invert: invert(100%)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition-none{
    transition-property: none
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition-shadow{
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-75{
    transition-delay: 75ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-100{
    transition-delay: 100ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-150{
    transition-delay: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-200{
    transition-delay: 200ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-300{
    transition-delay: 300ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-500{
    transition-delay: 500ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-700{
    transition-delay: 700ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-delay-1000{
    transition-delay: 1000ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-75{
    transition-duration: 75ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-100{
    transition-duration: 100ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-150{
    transition-duration: 150ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-200{
    transition-duration: 200ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-300{
    transition-duration: 300ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-500{
    transition-duration: 500ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-700{
    transition-duration: 700ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-duration-1000{
    transition-duration: 1000ms
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ease-linear{
    transition-timing-function: linear
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  #skindrWidget.skindrWidget .\32xl\:skindr-widget-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }
}
